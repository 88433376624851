import { Space, Switch, Typography } from "antd";
import React from "react";
import RowList from "../../../components/utils/RowList";

export interface IOrderItemCheckListProps {
  fragile: boolean;
  perishable: boolean;
  hazardious: boolean;
  contentCanSpill: boolean;
  explosive: boolean;
  flammable: boolean;
  isSmallScreen?: boolean;
}

const OrderItemCheckList: React.FC<IOrderItemCheckListProps> = (props) => {
  const {
    fragile,
    perishable,
    hazardious,
    contentCanSpill,
    explosive,
    flammable,
    isSmallScreen,
  } = props;

  return (
    <RowList span={isSmallScreen ? 24 : 12} gutter={[16, 16]}>
      <Space>
        <Switch disabled defaultChecked={fragile} />
        <Typography.Text>Fragile</Typography.Text>
      </Space>
      <Space>
        <Switch disabled defaultChecked={perishable} />
        <Typography.Text>Perishable</Typography.Text>
      </Space>
      <Space>
        <Switch disabled defaultChecked={hazardious} />
        <Typography.Text>Hazardous</Typography.Text>
      </Space>
      <Space>
        <Switch disabled defaultChecked={contentCanSpill} />
        <Typography.Text>Content Can Spill</Typography.Text>
      </Space>
      <Space>
        <Switch disabled defaultChecked={explosive} />
        <Typography.Text>Explosive</Typography.Text>
      </Space>
      <Space>
        <Switch disabled defaultChecked={flammable} />
        <Typography.Text>Flammable</Typography.Text>
      </Space>
    </RowList>
  );
};

export default OrderItemCheckList;
