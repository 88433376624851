import { css } from "@emotion/css";
import React from "react";
import { Tooltip } from "antd";

export interface INodeWithTipProps {
  tip: string;
  children: React.ReactNode;
}

const classes = {
  root: css({
    display: "flex",
    width: "100%",
    flex: 1,
    alignItems: "center",
  }),
  tip: css({
    display: "inline-block",
    marginLeft: "2px",
  }),
};

const NodeWithTip: React.FC<INodeWithTipProps> = (props) => {
  const { tip, children } = props;

  return (
    <Tooltip className={classes.root} title={tip}>
      <div>{children}</div>
      {/* <span className={classes.tip}>
        <InfoCircleTwoTone />
      </span> */}
    </Tooltip>
  );
};

export default NodeWithTip;
