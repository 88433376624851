import React from "react";
import { Space, Typography } from "antd";
import { IBranch } from "../../definitions/settings";
import SupportContactList from "./SupportContactList";

export interface ISelectedBranchProps {
  selectedBranch: IBranch;
}

const SelectedBranch: React.FC<ISelectedBranchProps> = (props) => {
  const { selectedBranch } = props;
  return (
    <Space direction="vertical" size={4} style={{ width: "100%" }}>
      <Typography.Text>{selectedBranch.BranchName}</Typography.Text>
      <Typography.Text type="secondary">
        {selectedBranch.Address_}
      </Typography.Text>
      <Typography.Text type="secondary">
        Last updated {selectedBranch.LastUpdated || selectedBranch.DateCreated}
      </Typography.Text>
      <SupportContactList
        contacts={selectedBranch.BranchPhoneNumbers.map((phoneNumber) => {
          return {
            contactName: phoneNumber.ContactName,
            phoneNumber: phoneNumber.PhoneNumber,
          };
        })}
      />
    </Space>
  );
};

export default SelectedBranch;
