import Cookies from "js-cookie";
import { call, delay, fork, put, takeEvery } from "redux-saga/effects";
import OrderBlockAPI from "../../api/endpoints/orderBlock";
import ResourcesAPI from "../../api/endpoints/resources";
import {
  getBlockPriorityApi,
  getBlockTimeTypeApi,
  getBlockTypeApi,
  getCountryCodeApi,
  getCountryCodeObjApi,
  getCountryStateApi,
  getCountryStateCitiesApi,
  getCurrenciesApi,
  getDefectTypeApi,
  getLongCountryCodeApi,
  getOrderCategoriesApi,
  getPackageSizesApi,
  getPackageStatusApi,
  getPaidOptionsApi,
  getStatusTypeApi,
  getTicketPriorityApi,
  getTicketRelatedServicesApi,
  getTicketStatusApi,
  getTrackingFrequencyApi,
  getTrackingTypeApi,
  getWeightUnitApi,
} from "../Api";
import { runSagaActionWithAPIAsync02 } from "../utils";
import * as types from "./ResourceActionType";
import {
  loadBlockDeliveryTypesError,
  loadBlockDeliveryTypesSuccess,
  loadBlockPriorityError,
  loadBlockPrioritySuccess,
  loadBlockTimeTypeError,
  loadBlockTimeTypeSuccess,
  loadBlockTypeError,
  loadBlockTypeSuccess,
  loadCountryCodeError,
  loadCountryCodeObjError,
  loadCountryCodeObjSuccess,
  loadCountryCodeSuccess,
  loadCountryStateCityError,
  loadCountryStateCitySuccess,
  loadCountryStateError,
  loadCountryStateSuccess,
  loadCurrenciesError,
  loadCurrenciesSuccess,
  loadDefectTypeError,
  loadDefectTypeSuccess,
  loadLongCountryCodeError,
  loadLongCountryCodeSuccess,
  loadOrderCategoryError,
  loadOrderCategorySuccess,
  loadPackageSizeError,
  loadPackageSizeSuccess,
  loadPackageStatusError,
  loadPackageStatusSuccess,
  loadPaidOptionError,
  loadPaidOptionSuccess,
  loadStatusTypeError,
  loadStatusTypeSuccess,
  loadTicketPriorityError,
  loadTicketPrioritySuccess,
  loadTicketRelatedServiceError,
  loadTicketRelatedServiceSuccess,
  loadTicketStatusError,
  loadTicketStatusSuccess,
  loadTrackingFreqError,
  loadTrackingFreqSuccess,
  loadTrackingTypeError,
  loadTrackingTypeSuccess,
  loadWeightUnitError,
  loadWeightUnitSuccess,
  orderBlockDriversListActions,
  orderBlockFilterCityListActions,
  orderBlockFilterCountryListActions,
  orderBlockFilterStateListActions,
} from "./resourcesAction";

function* onLoadStatusTypeStartAsync() {
  try {
    const response = yield call(getStatusTypeApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadStatusTypeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadStatusTypeError(error));
  }
}

function* onLoadTrackingTypeStartAsync() {
  try {
    const response = yield call(getTrackingTypeApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadTrackingTypeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadTrackingTypeError(error));
  }
}

function* onLoadTrackingFreqStartAsync() {
  try {
    const response = yield call(getTrackingFrequencyApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadTrackingFreqSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadTrackingFreqError(error));
  }
}

function* onLoadPackageStatusStartAsync() {
  try {
    const response = yield call(getPackageStatusApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadPackageStatusSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadPackageStatusError(error));
  }
}

function* onLoadBlockTimeTypeStartAsync() {
  try {
    const response = yield call(getBlockTimeTypeApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadBlockTimeTypeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadBlockTimeTypeError(error));
  }
}

function* onLoadPackageSizeStartAsync() {
  try {
    const response = yield call(getPackageSizesApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadPackageSizeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadPackageSizeError(error));
  }
}

function* onLoadPaidOptionStartAsync() {
  try {
    const response = yield call(getPaidOptionsApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadPaidOptionSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadPaidOptionError(error));
  }
}

function* onLoadCurrenciesStartAsync() {
  try {
    const response = yield call(getCurrenciesApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadCurrenciesSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadCurrenciesError(error));
  }
}

function* onLoadBlockPriorityStartAsync() {
  try {
    const response = yield call(getBlockPriorityApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadBlockPrioritySuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadBlockPriorityError(error));
  }
}

function* onLoadBlockTypeStartAsync() {
  try {
    const response = yield call(getBlockTypeApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadBlockTypeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadBlockTypeError(error));
  }
}

function* onLoadTicketPriorityStartAsync() {
  try {
    const response = yield call(getTicketPriorityApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadTicketPrioritySuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadTicketPriorityError(error));
  }
}

function* onLoadWeightUnitStartAsync() {
  try {
    const response = yield call(getWeightUnitApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadWeightUnitSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadWeightUnitError(error));
  }
}

function* onLoadOrderCategoryStartAsync() {
  try {
    const response = yield call(getOrderCategoriesApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadOrderCategorySuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadOrderCategoryError(error));
  }
}

function* onLoadCountryStateCitiesStartAsync() {
  try {
    const response = yield call(getCountryStateCitiesApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadCountryStateCitySuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadCountryStateCityError(error));
  }
}

function* onLoadTicketStatusStartAsync() {
  try {
    const response = yield call(getTicketStatusApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadTicketStatusSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadTicketStatusError(error));
  }
}

function* onLoadTicketRelatedServiceStartAsync() {
  try {
    const response = yield call(getTicketRelatedServicesApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadTicketRelatedServiceSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadTicketRelatedServiceError(error));
  }
}

function* onLoadCountryCodeObjStartAsync() {
  try {
    const response = yield call(getCountryCodeObjApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadCountryCodeObjSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadCountryCodeObjError(error));
  }
}

function* onLoadCountryCodeStartAsync() {
  try {
    const response = yield call(getCountryCodeApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadCountryCodeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadCountryCodeError(error));
  }
}

function* onLoadLongCountryCodeStartAsync() {
  try {
    const response = yield call(getLongCountryCodeApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadLongCountryCodeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadLongCountryCodeError(error));
  }
}

function* onLoadStateStartAsync({ payload }) {
  try {
    const response = yield call(getCountryStateApi, payload);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadCountryStateSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadCountryStateError(error));
  }
}

function* onLoadDefectTypeStartAsync() {
  try {
    const response = yield call(getDefectTypeApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadDefectTypeSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadDefectTypeError(error));
  }
}

function* onLoadOrderBlockDeliveryTypesStartAsync() {
  yield runSagaActionWithAPIAsync02(
    ResourcesAPI.getBlockDeliveryTypes,
    loadBlockDeliveryTypesSuccess,
    loadBlockDeliveryTypesError
  );
}

function* onLoadOrderBlockFilterCountryListStartAsync() {
  yield runSagaActionWithAPIAsync02(
    OrderBlockAPI.getFilterCountryList,
    orderBlockFilterCountryListActions.successAction,
    orderBlockFilterCountryListActions.errorAction
  );
}

function* onLoadOrderBlockFilterStateListStartAsync() {
  yield runSagaActionWithAPIAsync02(
    OrderBlockAPI.getFilterStateList,
    orderBlockFilterStateListActions.successAction,
    orderBlockFilterStateListActions.errorAction
  );
}

function* onLoadOrderBlockFilterCityListStartAsync() {
  yield runSagaActionWithAPIAsync02(
    OrderBlockAPI.getFilterCityList,
    orderBlockFilterCityListActions.successAction,
    orderBlockFilterCityListActions.errorAction
  );
}

function* onLoadOrderBlockDriversListStartAsync() {
  yield runSagaActionWithAPIAsync02(
    OrderBlockAPI.getDrivers,
    orderBlockDriversListActions.successAction,
    orderBlockDriversListActions.errorAction
  );
}

function* onBlockTimeStartAsync() {
  yield takeEvery(
    types.LOAD_BLOCK_TIME_TYPE_START,
    onLoadBlockTimeTypeStartAsync
  );
}

function* onLoadState() {
  yield takeEvery(types.LOAD_COUNTRY_STATE_START, onLoadStateStartAsync);
}

function* onLoadStatusType() {
  yield takeEvery(types.LOAD_STATUS_TYPE_START, onLoadStatusTypeStartAsync);
}

function* onLoadTrackingFreq() {
  yield takeEvery(types.LOAD_TRACKING_FREQ_START, onLoadTrackingFreqStartAsync);
}

function* onLoadPackageStatus() {
  yield takeEvery(
    types.LOAD_PACKAGE_STATUS_START,
    onLoadPackageStatusStartAsync
  );
}
function* onLoadPackageSize() {
  yield takeEvery(types.LOAD_PACKAGE_SIZE_START, onLoadPackageSizeStartAsync);
}

function* onLoadPaidOption() {
  yield takeEvery(types.LOAD_PAID_OPTIONS_START, onLoadPaidOptionStartAsync);
}

function* onLoadCurrencies() {
  yield takeEvery(types.LOAD_CURRENCIES_START, onLoadCurrenciesStartAsync);
}

function* onLoadBlockPriority() {
  yield takeEvery(
    types.LOAD_BLOCK_PRIORITY_START,
    onLoadBlockPriorityStartAsync
  );
}

function* onLoadBlockType() {
  yield takeEvery(types.LOAD_BLOCK_TYPE_START, onLoadBlockTypeStartAsync);
}

function* onLoadTicketPriority() {
  yield takeEvery(
    types.LOAD_TICKET_PRIORITY_START,
    onLoadTicketPriorityStartAsync
  );
}

function* onLoadWeightUnit() {
  yield takeEvery(types.LOAD_WEIGHT_UNIT_START, onLoadWeightUnitStartAsync);
}

function* onLoadOrderCategory() {
  yield takeEvery(
    types.LOAD_ORDER_CATEGORY_START,
    onLoadOrderCategoryStartAsync
  );
}

function* onLoadTicketStatus() {
  yield takeEvery(types.LOAD_TICKET_STATUS_START, onLoadTicketStatusStartAsync);
}

function* onLoadTicketRelatedService() {
  yield takeEvery(
    types.LOAD_TICKET_RELATED_SERVICE_START,
    onLoadTicketRelatedServiceStartAsync
  );
}

function* onLoadCountryCode() {
  yield takeEvery(types.LOAD_COUNTRY_CODE_START, onLoadCountryCodeStartAsync);
}

function* onLoadLongCountryCode() {
  yield takeEvery(
    types.LOAD_LONG_COUNTRY_CODE_START,
    onLoadLongCountryCodeStartAsync
  );
}

function* onLoadCountryCodeObj() {
  yield takeEvery(
    types.LOAD_COUNTRY_CODE_OBJ_START,
    onLoadCountryCodeObjStartAsync
  );
}

function* onLoadCountryStateCities() {
  yield takeEvery(
    types.LOAD_COUNTRY_STATE_CITIES_START,
    onLoadCountryStateCitiesStartAsync
  );
}

function* onLoadDefectType() {
  yield takeEvery(types.LOAD_DEFECT_TYPE_START, onLoadDefectTypeStartAsync);
}

function* onLoadTrackingStart() {
  yield takeEvery(types.LOAD_TRACKING_TYPE_START, onLoadTrackingTypeStartAsync);
}

function* onOrderBlockDeliveryTypes() {
  yield takeEvery(
    types.LOAD_BLOCK_DELIVERY_TYPE_START,
    onLoadOrderBlockDeliveryTypesStartAsync
  );
}

function* onOrderBlockFilterCountryList() {
  yield takeEvery(
    types.LOAD_BLOCK_FILTER_COUNTRY_LIST_START,
    onLoadOrderBlockFilterCountryListStartAsync
  );
}

function* onOrderBlockFilterStateList() {
  yield takeEvery(
    types.LOAD_BLOCK_FILTER_STATE_LIST_START,
    onLoadOrderBlockFilterStateListStartAsync
  );
}

function* onOrderBlockFilterCityList() {
  yield takeEvery(
    types.LOAD_BLOCK_FILTER_CITY_LIST_START,
    onLoadOrderBlockFilterCityListStartAsync
  );
}

function* onOrderBlockDriversList() {
  yield takeEvery(
    types.LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_START,
    onLoadOrderBlockDriversListStartAsync
  );
}

export const resourceSaga = [
  fork(onLoadStatusType),
  fork(onLoadCountryStateCities),
  fork(onLoadTrackingFreq),
  fork(onLoadCountryCodeObj),
  fork(onLoadLongCountryCode),
  fork(onLoadCountryCode),
  fork(onLoadTicketRelatedService),
  fork(onLoadTicketStatus),
  fork(onLoadOrderCategory),
  fork(onLoadWeightUnit),
  fork(onLoadTicketPriority),
  fork(onLoadBlockType),
  fork(onLoadBlockPriority),
  fork(onLoadTrackingStart),
  fork(onLoadPaidOption),
  fork(onLoadCurrencies),
  fork(onLoadPackageSize),
  fork(onLoadState),
  fork(onBlockTimeStartAsync),
  fork(onLoadPackageStatus),
  fork(onLoadDefectType),
  fork(onOrderBlockDeliveryTypes),
  fork(onOrderBlockFilterCountryList),
  fork(onOrderBlockFilterStateList),
  fork(onOrderBlockFilterCityList),
  fork(onOrderBlockDriversList),
];
