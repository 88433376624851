import { EllipsisOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { API_URL } from "../../globalVariable";
import {
  filterEmployeeStart,
  loadEmployeeStart,
} from "../../redux/EmployeeRedux/employeeAction";
import { loadMovebotRoleStart } from "../../redux/MovebotPolicyAndPermissionRedux/movebotAction";
import { loadPolicyStart } from "../../redux/PolicyRedux/policyAction";
import { loadStatusTypeStart } from "../../redux/ResourcesRedux/resourcesAction";
import EmployeeSignUp from "../EmployeeSignUp";
import Paginations from "../Pagination";
import "./userlist.css";

function UserList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employees, loading } = useSelector((state) => state.employees);
  const { roles } = useSelector((state) => state.movebotActions);
  const [filter, setFilter] = useState("");
  const [employeeOpen, setEmployeeOpen] = useState(false);
  const { Title } = Typography;
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [detailOpen, setDetailOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countryCodes, setCountryCodes] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [assignedGroupPolicyId, setAssignedGroupPolicyId] = useState("");
  const [assignedRoleId, setAssignedRoleId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [reason, setReason] = useState();
  const [getCountries, setGetCountries] = useState();
  const [employeeStatus, setEmployeeStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState();
  const [statusType, setStatusType] = useState();
  const [loader, setLoader] = useState(false);
  const [resetLoader, setResetLoader] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [radio, setRadio] = useState("");

  const { Option } = Select;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  var queryPage = parseInt(query.get("page"));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryPage);
  const [pageSize, setPageSize] = useState(10);

  const { policies } = useSelector((state) => state.policies);
  const { statusTypes, countryCode } = useSelector((state) => state.resources);

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(loadEmployeeStart(payload));
    dispatch(loadPolicyStart());
    dispatch(loadStatusTypeStart());
    dispatch(loadMovebotRoleStart());
  }, [currentPage, pageSize]);

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
      filter,
    };
    dispatch(filterEmployeeStart(payload));
  }, [filter]);

  function handleChange(value) {
    setFilter(value);
  }

  const handleEmployeeClose = () => {
    setEmployeeOpen(false);
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(loadEmployeeStart(payload));
  };

  function handleCountryChange(value) {
    setCountry(value);
  }

  function handleStateChange(value) {
    setState(value);
  }

  function handleStatusChange(value) {
    setStatusType(value);
  }

  function handleCityChange(value) {
    setCity(value);
  }

  function handleCountryCodeChange(value) {
    setCountryCodes(value);
  }

  function handleRoleChange(value) {
    setAssignedRoleId(value);
  }

  function handlePermissionChange(value) {
    setAssignedGroupPolicyId(value);
  }

  function handleChange(value) {
    setFilter(value);
  }

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDetailModalClose = () => {
    setDetailOpen(false);
    setSelectedEmployee(null);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEmployeeId("");
  };

  const [updateId, setUpdateId] = useState();
  const handleEmployeeModalOpen = (pol) => {
    setEmployeeStatus(true);
    setUpdateId(pol?.EmployeeId);
  };

  const handleEmployeeModalClose = () => {
    setEmployeeStatus(false);
  };

  const updateEmployee = async () => {
    const data = {
      firstName,
      lastName,
      emailAddress: email,
      phoneNumber,
      countryCode: countryCodes,
      addres: {
        street,
        city,
        state,
        zipCode,
        country,
      },
      assignedRoleId,
      assignedGroupPolicyId,
    };

    setLoader(true);

    if (assignedGroupPolicyId.length < 1) {
      setLoader(false);
      return swal({
        text: "Please Select a Role and Assigned a Group Policy to the User",
        icon: "warning",
      });
    } else {
      var response = await fetch(
        API_URL + `Employee/Update?employeeId=${employeeId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // "Authorization": `Bearer ${localStorage.getItem("token")}`
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
          body: JSON.stringify(data),
        }
      );

      var dataResponse = await response.json();

      if (response.ok) {
        return swal({
          text: dataResponse.message,
          icon: "success",
        })
          .then(() => {
            setLoader(false);
            const payload = {
              page,
              pageSize,
            };
            //getEmployees(setEmployeeByType, setLoading, filter)
            dispatch(loadEmployeeStart(payload));
          })
          .then(() => {
            handleEditModalClose();
          });
      } else {
        if (dataResponse.message) {
          setLoader(false);
          return swal({
            text: dataResponse.message,
            icon: "warning",
          });
        } else {
          setLoader(false);
          return swal({
            text: dataResponse.Message,
            icon: "warning",
          });
        }
      }
    }
  };

  const deleteModal = (id) => {
    handleDeleteModalOpen();
    setDeleteId(id);
  };

  const deleteEmployee = async () => {
    const data = {
      reason,
      id: deleteId,
    };

    setLoader(true);
    var response = await fetch(API_URL + "Employee/Delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.message,
        icon: "success",
      })
        .then(() => {
          setLoader(false);
          setReason("");
          //getEmployees(setEmployeeByType, setLoading, filter)
          const payload = {
            page,
            pageSize,
          };
          dispatch(loadEmployeeStart(payload));
        })
        .then(() => {
          handleDeleteModalClose();
        });
    } else {
      if (dataResponse.message) {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };

  const updateEmployeeStatus = async () => {
    const data = {
      employeeId: updateId,
      showStatusReasonToUser: radio === "true" ? true : false,
      reason: statusMessage,
      statusType,
    };

    setLoader(true);
    var response = await fetch(API_URL + `Employee/Status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();
    if (response.ok) {
      return swal({
        text: dataResponse.message,
        icon: "success",
      })
        .then(() => {
          setLoader(false);
          setUpdateId(null);
          setStatusMessage("");
          const payload = {
            page,
            pageSize,
          };
          dispatch(loadEmployeeStart(payload));
        })
        .then(() => {
          handleEmployeeModalClose();
        });
    } else {
      if (dataResponse.message) {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };

  const resetCredential = async (id) => {
    swal({
      text: "You are about to reset driver credential",
    });
    swal({
      title: "Are you sure?",
      text: "You are about to reset a driver credential",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willAccept) => {
      if (willAccept) {
        setResetLoader(true);
        var response = await fetch(
          API_URL + `Employee/ResetDriversCredential?employeeId=${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // "Authorization": `Bearer ${localStorage.getItem("token")}`
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        var dataResponse = await response.json();
        return swal({
          text: dataResponse.message,
          icon: "success",
        }).then(() => {
          setResetLoader(false);
          const payload = {
            page,
            pageSize,
          };
          dispatch(loadEmployeeStart(payload));
        });
      } else {
      }
    });
  };

  const menuOptionsNode = (props) => (
    <Menu>
      <Tooltip title="Edit Employee">
        <Menu.Item
          onClick={() => navigate(`/create-employee`, { state: props })}
        >
          Edit Employee
        </Menu.Item>
      </Tooltip>
      <Menu.Divider />
      <Tooltip title="Update Employee Status">
        <Menu.Item onClick={() => handleEmployeeModalOpen(props)}>
          Update Employee Status
        </Menu.Item>
      </Tooltip>
      {props?.AssignedRole === "Driver" && (
        <Tooltip title="Reset Driver Credential">
          <Menu.Item onClick={() => resetCredential(props.EmployeeId)}>
            Reset Driver Credential
          </Menu.Item>
        </Tooltip>
      )}
      <Menu.Divider />
      <Menu.Divider />
      <Tooltip title="Delete Employee">
        <Menu.Item onClick={() => deleteModal(props?.EmployeeId)}>
          Delete Employee
        </Menu.Item>
      </Tooltip>
    </Menu>
  );

  const onChange = (checked) => {
    setRadio(checked);
  };

  const columns = [
    {
      title: "",
      width: 100,
      ellipsis: true,
      render(text, record) {
        return (
          <img
            style={{ borderRadius: "10%", width: "100%", height: "80px" }}
            src={record?.EmployeeImage}
            alt=""
          />
        );
      },
    },
    {
      title: "Full Name",
      width: 140,
      dataIndex: "FullName",
      key: "FullName",
      render(text, record) {
        return (
          <Link to={`/employee/${record.EmployeeId}`} state={record}>
            {record?.FullName}
          </Link>
        );
      },
    },
    {
      title: "Assigned Role",
      width: 150,
      dataIndex: "AssignedRole",
      key: "AssignedRole",
      render(text, record) {
        return <Tag>{record.AssignedRole}</Tag>;
      },
    },
    {
      title: "Driver Number",
      width: 140,
      dataIndex: "DriverNumber",
      key: "DriverNumber",
      render(text, record) {
        return <Tag>{record.DriverNumber}</Tag>;
      },
    },
    {
      title: "Status",
      width: 170,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return (
          <NodeWithTip tip={record.Status.Description}>
            <Tag color={record.Status.Color}>{record.Status.Name}</Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Email Address",
      width: 150,
      dataIndex: "EmailAddress",
      key: "EmailAddress",
    },
    {
      title: "Phone Number",
      width: 200,
      dataIndex: "PhoneNumber",
      key: "PhoneNumber",
    },
    {
      title: "Assigned Group Policy",
      width: 150,
      dataIndex: "AssignedGroupPolicy",
      key: "AssignedGroupPolicy",
    },
    {
      title: "Last Logged In",
      width: 200,
      dataIndex: "LastLoggedIn",
      key: "LastLoggedIn",
    },
    {
      title: "",
      key: "Actions",
      width: 48,
      fixed: "right",
      render: (unused, record) => {
        return (
          <Dropdown arrow overlay={menuOptionsNode(record)}>
            <Button
              icon={<EllipsisOutlined />}
              // className={buttonClassName}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];

  const onChangeData = (page, pageSize) => {
    navigate(`/employee?page=${page}`);
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onClear = () => {
    console.log("clear");
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="employee-actions">
        <div className="filter-sort">
          <Select
            allowClear
            onClear={() => onClear()}
            defaultValue="Filter by Role"
            onChange={handleChange}
            style={{ padding: "10px 0px 10px 10px", width: "100%" }}
          >
            <Option value="">Filter by Role</Option>
            {roles?.map((ploy) => {
              return <Option value={ploy._RoleType}>{ploy._RoleType}</Option>;
            })}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            id="but"
            onClick={() => navigate("/create-employee")}
          >
            Create Employee
          </Button>
        </div>
      </div>

      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        <div
          style={{
            display: "flex",
            height: "90%",
            // flex: 1,
            width: "100%",
            flexDirection: "column",
            overflowX: "auto",
          }}
        >
          <Table
            bordered
            size="small"
            dataSource={employees?.Data}
            columns={columns}
            rowKey={(row) => row.BlockId}
            pagination={false}
            scroll={{ x: 1500 }}
          />
        </div>
      )}
      <br />
      <Paginations
        data={employees}
        currentPage={isNaN(currentPage) ? page : currentPage}
        onChangeData={onChangeData}
      />

      {/* Modal for deleting employee */}

      <div>
        <Modal
          title="Delete Employee"
          visible={deleteModalOpen}
          footer={null}
          onCancel={handleDeleteModalClose}
        >
          <form className="policy-form">
            <Form.Item
              required
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Reason for Deleting Employee"
            >
              <Input.TextArea
                placeholder="Reason for Deleting Employee"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                name="reason"
                autoSize={{ minRows: 5 }}
                disabled={loading}
              />
            </Form.Item>

            <br />
            <div className="form-btn">
              <Button type="primary" id="but" onClick={() => deleteEmployee()}>
                {loader ? <Spin /> : "Delete Employee"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      {/* Modal for employee details */}
      <div>
        <Modal
          title="Employee Details"
          visible={detailOpen}
          footer={null}
          onCancel={handleDetailModalClose}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Group Name:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.FullName}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Email Address:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.EmailAddress}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Phone Number:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.PhoneNumber}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Address:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.Address}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Zip Code:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.EmployeeRawData.Address?.ZipCode}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Status Info</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.StatusInfo}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Updated By</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.UpdatedBy}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Status:</span>{" "}
              <Title className="address" level={5}>
                <Tag color={selectedEmployee?.data.Status.Color}>
                  {selectedEmployee?.data.Status.Name}
                </Tag>
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Date Created:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.DateCreated_}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Last Updated::</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.LastUpdated}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Last LoggedIn:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.LastLoggedIn}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Assigned Role:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.AssignedRole}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Assigned Group Policy:</span>{" "}
              <Title className="address" level={5}>
                {selectedEmployee?.data.AssignedGroupPolicy}
              </Title>
            </Col>
          </Row>
        </Modal>
      </div>

      {/* Modal for employee update */}
      <div>
        <Modal
          title="Employee Update"
          visible={employeeStatus}
          footer={null}
          onCancel={handleEmployeeModalClose}
        >
          <form className="employee-update-form">
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "10px" }}>Show Reason to User</p>
              <Switch checked={radio} onChange={onChange} />
            </div>
            <Form.Item
              required
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Select Status Type"
            >
              <Select
                placeholder="Select Status Type"
                value={statusType}
                onChange={handleStatusChange}
                // disabled={loading}
              >
                {statusTypes?.map((code) => {
                  return (
                    <Select.Option key={code?.id} value={code?.id}>
                      {code?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              required
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Reason for Changing Status"
            >
              <Input.TextArea
                placeholder="Reason for Deleting"
                value={statusMessage}
                onChange={(e) => setStatusMessage(e.target.value)}
                name="reason"
                autoSize={{ minRows: 5 }}
                disabled={loading}
              />
            </Form.Item>

            <div className="form-btn">
              <Button
                type="primary"
                id="but"
                onClick={() => updateEmployeeStatus()}
              >
                {loader ? <Spin /> : "Update Employee Status"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      {/* Modal employee signup */}

      <div>
        <Modal
          title="Create Employee"
          visible={employeeOpen}
          footer={null}
          onCancel={handleEmployeeClose}
        >
          <EmployeeSignUp change={handleEmployeeClose} />
        </Modal>
      </div>

      {/* Modal for employee edit */}

      <div>
        <Modal
          title="Edit Employee"
          visible={editModalOpen}
          footer={null}
          onCancel={handleEditModalClose}
        >
          <form className="policy-form">
            <div className="employee-form-div">
              <Input
                placeholder="First Name"
                className="half-width"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Input
                placeholder="Last Name"
                className="half-width"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="employee-form-div">
              <Input
                placeholder="Email"
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="employee-form-div">
              <Select
                defaultValue={country}
                style={{ width: "100%" }}
                onChange={handleCountryChange}
              >
                <Option value="Select a Country" disabled>
                  Select a Country
                </Option>
                {getCountries?.map((country) => {
                  return (
                    <Option key={country} value={country}>
                      {country}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className="employee-form-div">
              {country?.length > 0 ? (
                <Select
                  defaultValue={state}
                  className="half-width"
                  onChange={handleStateChange}
                >
                  <Option value="Select State" disabled>
                    Select State
                  </Option>
                  {states?.map((code) => {
                    return (
                      <Option key={code} value={code}>
                        {code}
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                <Select
                  className="half-width"
                  placeholder="Please select a Country"
                  disabled
                />
              )}
              {state?.length > 0 ? (
                <Select
                  defaultValue={city}
                  className="half-width"
                  onChange={handleCityChange}
                >
                  <Option value="Select City" disabled>
                    Select City
                  </Option>
                  {cities?.map((code) => {
                    return (
                      <Option key={code} value={code}>
                        {code}
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                <Select
                  className="half-width"
                  placeholder="Please select a state"
                  disabled
                />
              )}
            </div>
            <div className="employee-form-div">
              <Input
                placeholder="Street"
                className="half-width"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
              <Input
                placeholder="ZipCode"
                className="half-width"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>

            <div className="employee-form-div">
              <Select
                defaultValue={countryCodes}
                className="half-width"
                onChange={handleCountryCodeChange}
              >
                <Option value="Select Country Code" disabled>
                  Select Country Code
                </Option>
                {countryCode?.map((code) => {
                  return (
                    <Option key={code} value={code}>
                      {code}
                    </Option>
                  );
                })}
              </Select>
              <Input
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div className="employee-form-div">
              <Select
                defaultValue={assignedRoleId}
                style={{ width: "100%" }}
                onChange={handleRoleChange}
              >
                <Option value="Assigned Role" disabled>
                  Assigned Role
                </Option>
                {roles?.map((policy) => {
                  return (
                    <Option key={policy.RoleId} value={policy.RoleId}>
                      {policy._RoleType}
                    </Option>
                  );
                })}
              </Select>
              <Select
                defaultValue="Select Permission"
                style={{ width: "100%" }}
                onChange={handlePermissionChange}
              >
                <Option value="Permission" disabled>
                  Select Permission
                </Option>
                {policies?.Data?.map((per) => {
                  return per.RoleInfo.RoleId === assignedRoleId ? (
                    <Option key={per.GroupPolicyId} value={per.GroupPolicyId}>
                      {per.GroupName}
                    </Option>
                  ) : null;
                })}
              </Select>
            </div>

            <div className="form-btn">
              <Button type="primary" id="but" onClick={() => updateEmployee()}>
                {loader ? <Spin /> : "Update Employee"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default UserList;
