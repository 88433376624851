import { IOrder } from "../../definitions/orderBay";
import {
  IFilterOrderItemsInput,
  IOrderBlock,
  IOrderBlockDriver,
  IOrderBlockInput,
  OrderBlockPriority,
  OrderBlockStatus,
  OrderBlockType,
} from "../../definitions/orderBlock";
import {
  IIdWithReasonInput,
  IPaginatedDataInput,
  IPaginatedDataResult,
} from "../../global/types";
import { API_URL } from "../../globalVariable";
import { invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";
import { selectEndpointParam, setEndpointParam } from "../utils";

const orderBlockURL = `${API_URL}OrderBlock`;
const searchOrdersURL = `${orderBlockURL}/SearchBlockOrders`;
const getOrderBlocksURL = `${orderBlockURL}/Page`;
const unpublishBlockURL = `${orderBlockURL}/UnpublishBlock`;
const publishPrivateBlockURL = `${orderBlockURL}/PublishPrivateBlock`;
const publishPublicBlockURL = `${orderBlockURL}/PublishPublicBlock`;
const deleteBlockURL = `${orderBlockURL}/DelBlock`;
const updateBlockOrderURL = `${orderBlockURL}/UpdateBlockOrder`;
const createBlockOrderURL = `${orderBlockURL}/CreateBlockOrder`;
const getOrderInfoURL = `${orderBlockURL}/GetOrderInfo`;
const getDriversURL = `${orderBlockURL}/GetDrivers`;
const filterSearchOrderItemsURL = `${orderBlockURL}/FilterSearchOrderItems`;
const filterOrderItemsURL = `${orderBlockURL}/FilterOrderItems`;
const getFilterCityListURL = `${orderBlockURL}/GetFilterCityList`;
const getFilterCountryListURL = `${orderBlockURL}/GetFilterCountryList`;
const getFilterStateListURL = `${orderBlockURL}/GetFilterStateList`;
const getOrderItemsURL = `${orderBlockURL}/OrderItemPage`;
const getOrderBlockURL = `${orderBlockURL}/OrderItem`;
const adjustPickupDateTimeURL = `${orderBlockURL}/AdjustPickUpExDateTime`;

export interface ISearchOrderBlocksEnpointInput {
  query: string;
}
export type ISearchOrderBlocksEndpointResult = IOrderBlock[];

async function searchOrderBlocks(
  props: ISearchOrderBlocksEnpointInput
): Promise<ISearchOrderBlocksEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "query", props.query);

  const endpointURL = selectEndpointParam(searchOrdersURL, params, props);
  const result = await invokeEndpointWithAuth<ISearchOrderBlocksEndpointResult>(
    {
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    }
  );

  return result;
}

export interface IGetOrderBlocksEndpointInput extends IPaginatedDataInput {
  type?: OrderBlockType;
  priority?: OrderBlockPriority;
  status?: OrderBlockStatus;
}

export interface IGetOrderBlocksEndpointResult
  extends IPaginatedDataResult<IOrderBlock> {}

async function getOrderBlocks(
  props: IGetOrderBlocksEndpointInput = {}
): Promise<IGetOrderBlocksEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "type", props.type);
  setEndpointParam(params, "priority", props.priority);
  setEndpointParam(params, "status", props.status);

  const endpointURL = selectEndpointParam(getOrderBlocksURL, params, props);
  const result = await invokeEndpointWithAuth<IGetOrderBlocksEndpointResult>({
    path: endpointURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export type IUnpublishOrderBlockEndpointInput = IIdWithReasonInput;
export type IUnpublishOrderBlockEndpointResult = IEndpointMessageResult;

async function unpublishOrderBlock(
  props: IUnpublishOrderBlockEndpointInput
): Promise<IUnpublishOrderBlockEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IUnpublishOrderBlockEndpointResult>({
      path: unpublishBlockURL,
      method: "PUT",
      data: props,
    });

  return result;
}

export interface IPublishPrivateBlockEndpointInput {
  blockId: string;
  driverId: string;
}

export type IPublishPrivateBlockEndpointResult = IEndpointMessageResult;

async function publishPrivateBlock(
  props: IPublishPrivateBlockEndpointInput
): Promise<IPublishPrivateBlockEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "blockId", props.blockId);
  setEndpointParam(params, "driverId", props.driverId);

  const endpointURL = selectEndpointParam(
    publishPrivateBlockURL,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IPublishPrivateBlockEndpointResult>({
      path: endpointURL,
      method: "POST",
      data: props,
    });

  return result;
}

export interface IPublishPublicBlockEndpointInput {
  blockId: string;
}

export type IPublishPublicBlockEndpointResult = IEndpointMessageResult;

async function publishPublicBlock(
  props: IPublishPublicBlockEndpointInput
): Promise<IPublishPublicBlockEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "blockId", props.blockId);

  const endpointURL = selectEndpointParam(publishPublicBlockURL, params, props);
  const result =
    await invokeEndpointWithAuth<IPublishPublicBlockEndpointResult>({
      path: endpointURL,
      method: "POST",
      data: props,
    });

  return result;
}

export type IDeleteBlockEndpointInput = IIdWithReasonInput;
export type IDeleteBlockEndpointResult = IEndpointMessageResult;

async function deleteBlock(
  props: IDeleteBlockEndpointInput
): Promise<IDeleteBlockEndpointResult> {
  const result = await invokeEndpointWithAuth<IDeleteBlockEndpointResult>({
    path: deleteBlockURL,
    method: "DELETE",
    data: props,
  });

  return result;
}

export interface IUpdateBlockEndpointInput {
  blockId: string;
  data: IOrderBlockInput;
}

export type IUpdateBlockEndpointResult = IEndpointMessageResult;

async function updateBlock(
  props: IUpdateBlockEndpointInput
): Promise<IUpdateBlockEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "blockId", props.blockId);

  const endpointURL = selectEndpointParam(updateBlockOrderURL, params, props);
  const result = await invokeEndpointWithAuth<IUpdateBlockEndpointResult>({
    path: endpointURL,
    method: "PUT",
    data: props.data,
  });

  return result;
}

export type ICreateBlockOrderEndpointInput = IOrderBlockInput;
export type ICreateOrderBlockEndpointResult = IEndpointMessageResult;

async function createOrderBlock(
  props: ICreateBlockOrderEndpointInput
): Promise<ICreateOrderBlockEndpointResult> {
  const result = await invokeEndpointWithAuth<ICreateOrderBlockEndpointResult>({
    path: createBlockOrderURL,
    method: "POST",
    data: props,
  });

  return result;
}

export interface IGetRegularOrdersByIdEndpointInput {
  orderIds: string[];
}

export type IGetRegularOrdersByIdEndpointResult = IOrder[];

async function getRegularOrdersById(
  props: IGetRegularOrdersByIdEndpointInput
): Promise<IGetRegularOrdersByIdEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetRegularOrdersByIdEndpointResult>({
      path: getOrderInfoURL,
      method: "POST",
      data: props.orderIds,
    });

  return result;
}

export type IGetOrderBlocksDriversEndpointResult = IOrderBlockDriver[];

async function getDrivers(): Promise<IGetOrderBlocksDriversEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetOrderBlocksDriversEndpointResult>({
      path: getDriversURL,
      method: "GET",
    });

  return result;
}

export interface IFilterSearchOrderItemsEndpointInput {
  query: string;
}

export type IFilterSearchOrderItemsEndpointResult = IOrder[];

async function filterSearchOrderItems(
  props: IFilterSearchOrderItemsEndpointInput
): Promise<IFilterSearchOrderItemsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "query", props.query);

  const endpointURL = selectEndpointParam(
    filterSearchOrderItemsURL,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IFilterSearchOrderItemsEndpointResult>({
      path: endpointURL,
      method: "GET",
    });

  return result;
}

export type IFilterOrderItemsEndpointInput = Partial<IFilterOrderItemsInput>;
export type IFilterOrderItemsEndpointResult = IOrder[];

async function filterOrderItems(
  props: IFilterOrderItemsEndpointInput
): Promise<IFilterOrderItemsEndpointResult> {
  const result = await invokeEndpointWithAuth<IFilterOrderItemsEndpointResult>({
    path: filterOrderItemsURL,
    method: "POST",
    data: props,
  });

  return result;
}

export type IGetOrderBlockFilterCityListEndpointResult = string[];

async function getFilterCityList(): Promise<IGetOrderBlockFilterCityListEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetOrderBlockFilterCityListEndpointResult>({
      path: getFilterCityListURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export type IGetOrderBlockFilterCountryListEndpointResult = string[];

async function getFilterCountryList(): Promise<IGetOrderBlockFilterCountryListEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetOrderBlockFilterCountryListEndpointResult>(
      {
        path: getFilterCountryListURL,
        method: "GET",
        defaultValue: [],
      }
    );

  return result;
}

export type IGetOrderBlockFilterStateListEndpointResult = string[];

async function getFilterStateList(): Promise<IGetOrderBlockFilterStateListEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetOrderBlockFilterStateListEndpointResult>({
      path: getFilterStateListURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export type IGetOrderBlockOrderItemsEndpointInput = IPaginatedDataInput;
export type IGetOrderBlockOrderItemsEndpointResult =
  IPaginatedDataResult<IOrder>;

async function getOrderItems(
  props: IGetOrderBlockOrderItemsEndpointInput
): Promise<IGetOrderBlockOrderItemsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);

  const endpointURL = selectEndpointParam(getOrderItemsURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetOrderBlockOrderItemsEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export interface IGetBlockEndpointInput {
  blockId: string;
}

export type IGetBlockEndpointResult = IOrderBlock;

async function getBlock(
  props: IGetBlockEndpointInput
): Promise<IGetBlockEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "blockId", props.blockId);
  const endpointURL = selectEndpointParam(getOrderBlockURL, params, props);
  const result = await invokeEndpointWithAuth<IGetBlockEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export interface IAdjustPickupDateTimeEndpointInput {
  blockId: string;
  publicTypeExpirationDate: string;
  pickUpDateTime: string;
}

async function adjustPickupDateTime(
  props: IAdjustPickupDateTimeEndpointInput
): Promise<IEndpointMessageResult> {
  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: adjustPickupDateTimeURL,
    method: "PUT",
    data: props,
  });

  return result;
}

export default class OrderBlockAPI {
  static searchOrderBlocks = searchOrderBlocks;
  static getOrderBlocks = getOrderBlocks;
  static unpublishOrderBlock = unpublishOrderBlock;
  static publishPrivateBlock = publishPrivateBlock;
  static publishPublicBlock = publishPublicBlock;
  static deleteBlock = deleteBlock;
  static updateBlock = updateBlock;
  static createOrderBlock = createOrderBlock;
  static getRegularOrdersById = getRegularOrdersById;
  static getDrivers = getDrivers;
  static filterSearchOrderItems = filterSearchOrderItems;
  static filterOrderItems = filterOrderItems;
  static getFilterCityList = getFilterCityList;
  static getFilterCountryList = getFilterCountryList;
  static getFilterStateList = getFilterStateList;
  static getOrderItems = getOrderItems;
  static getBlock = getBlock;
  static adjustPickupDateTime = adjustPickupDateTime;
}
