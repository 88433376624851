
import * as types from "./actionType"



export const getStatisticsStart = () => ({
    type: types.GET_STATISTICS_START,
})

export const getStatisticsSuccess = (stat) => ({
    type: types.GET_STATISTICS_SUCCESS,
    payload: stat,
})

export const getStatisticsError = (error) => ({
    type: types.GET_STATISTICS_ERROR,
    payload: error
})


export const getTopBlocksStart = () => ({
    type: types.GET_TOP_BLOCKS_START,
})

export const getTopBlocksSuccess = (stat) => ({
    type: types.GET_TOP_BLOCKS_SUCCESS,
    payload: stat,
})

export const getTopBlocksError = (error) => ({
    type: types.GET_TOP_BLOCKS_ERROR,
    payload: error
})

export const getTopOrdersStart = () => ({
    type: types.GET_TOP_ORDERS_START,
})

export const getTopOrdersSuccess = (stat) => ({
    type: types.GET_TOP_ORDERS_SUCCESS,
    payload: stat,
})

export const getTopOrdersError = (error) => ({
    type: types.GET_TOP_ORDERS_ERROR,
    payload: error
})