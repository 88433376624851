import { Col, Form, Input, Row, Select } from "antd";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { appFormMessages } from "../../global/messages";
import { IPersonInput } from "../../global/types";
import { loadCountryCodeStart } from "../../redux/ResourcesRedux/resourcesAction";
import { IReduxState, IReduxStateResources } from "../../redux/types";
import FormItemError from "../utils/FormItemError";
import AddressFormInput, {
  addressInitialValues,
  addressValidationSchema,
} from "./AddressFormInput";

export interface IPersonFormInput {
  loading?: boolean;
  formik: FormikProps<any>;
  basePath: string;
}

export const personInitialValues: IPersonInput = {
  name: "",
  emailAddress: "",
  phoneNumber: "",
  countryCode: "",
  address: {
    ...addressInitialValues,
  },
};

export const personValidationSchema = {
  name: yup.string().required(appFormMessages.requiredField),
  emailAddress: yup.string(),
  phoneNumber: yup.string().required(appFormMessages.requiredField),
  countryCode: yup.string().required(appFormMessages.requiredField),
  address: yup.object().shape({
    ...addressValidationSchema,
  }),
};

const PersonFormInput: React.FC<IPersonFormInput> = (props) => {
  const { loading, formik, basePath } = props;
  const dispatch = useDispatch();
  function getFieldPath(field: string) {
    return basePath ? `${basePath}.${field}` : field;
  }

  const { countryCode } = useSelector<IReduxState, IReduxStateResources>(
    (state) => state.resources
  );

  useEffect(() => {
    dispatch(loadCountryCodeStart());
  }, [dispatch]);

  const base = ((basePath ? formik.values[basePath] : formik.values) ||
    personInitialValues) as IPersonInput;
  const errors = ((basePath ? formik.errors[basePath] : formik.errors) ||
    {}) as FormikErrors<IPersonInput>;
  const touched = ((basePath ? formik.touched[basePath] : formik.touched) ||
    {}) as FormikTouched<IPersonInput>;

  const recipientNameNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Name"
      help={touched.name && <FormItemError message={errors.name} />}
    >
      <Input
        placeholder="Name"
        value={base.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="name"
        disabled={loading}
      />
    </Form.Item>
  );

  const emailAddressNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Email"
      help={
        touched.emailAddress && <FormItemError message={errors.emailAddress} />
      }
    >
      <Input
        placeholder="Email"
        value={base.emailAddress}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="emailAddress"
        disabled={loading}
      />
    </Form.Item>
  );

  const phoneNumberNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Phone Number"
      help={
        touched.phoneNumber && <FormItemError message={errors.phoneNumber} />
      }
    >
      <Input
        placeholder="Phone Number"
        value={base.phoneNumber}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="phoneNumber"
        disabled={loading}
      />
    </Form.Item>
  );

  const countryCodeNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Country"
      help={
        touched.countryCode && <FormItemError message={errors.countryCode} />
      }
    >
      <Select
        placeholder="Select Country Code"
        value={base.countryCode}
        onChange={(countryCode) =>
          formik.setFieldValue("countryCode", countryCode)
        }
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {countryCode?.map((code: string) => {
          return (
            <Select.Option key={code} value={code}>
              {code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const addressNode = (
    <AddressFormInput
      basePath={getFieldPath("address")}
      formik={formik}
      loading={loading}
    />
  );

  return (
    <div>
      {recipientNameNode}
      {emailAddressNode}
      <Row gutter={16}>
        <Col span={6}>{countryCodeNode}</Col>
        <Col span={18}>{phoneNumberNode}</Col>
      </Row>
      {addressNode}
    </div>
  );
};

export default PersonFormInput;
