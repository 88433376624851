import React from 'react'
import { Pagination } from "antd"

function Paginations({ data, onChangeData, currentPage }) {


    return (
        <Pagination
            current={currentPage}
            onChange={(page, pageSize) => onChangeData(page, pageSize)}
            showSizeChanger={true}
            pageSizeOptions={[10, 20, 50, 100]}
            total={data?.TotalSize}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        />
    )
}

export default Paginations