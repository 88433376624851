import { EllipsisOutlined } from "@ant-design/icons";
import BackupIcon from "@material-ui/icons/Backup";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import LabeledNode from "../../components/utils/LabeledNode";
import { API_URL } from "../../globalVariable";
import { loadUserProfileStart } from "../../redux/ProfileRedux/profileAction";
import {
  loadCountryCodeStart,
  loadCountryStateCityStart,
  loadCountryStateStart,
} from "../../redux/ResourcesRedux/resourcesAction";

function Profile() {
  const dispatch = useDispatch();
  const { userProfile, loading } = useSelector((state) => state.profiles);
  const { countryCode, countryStateCities, getStates } = useSelector(
    (state) => state.resources
  );
  const [userProfileUpdateOpen, setUserProfileUpdateOpen] = useState(false);
  const [userProfilePasswordOpen, setUserProfilePasswordOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countryCodes, setCountryCodes] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [getCities, setGetCities] = useState();

  useEffect(() => {
    dispatch(loadCountryStateCityStart());
    dispatch(loadCountryCodeStart());
    dispatch(loadCountryCodeStart());
  }, []);

  useEffect(() => {
    dispatch(loadCountryStateStart(country));
  }, [country]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          API_URL +
            `Resources/GetCountryStateCities?country=${country}&state=${state}`,
          {
            headers: {
              authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        )
        // .then(res => res.json())
        .then((data) => setGetCities(data.data));
    };
    fetchData();
  }, [state]);

  const { Option } = Select;

  function handleCountryChange(value) {
    setCountry(value);
  }
  function handleStateChange(value) {
    setState(value);
  }
  function handleCityChange(value) {
    setCity(value);
  }
  function handleCountryCodeChange(value) {
    setCountryCodes(value);
  }

  useEffect(() => {
    dispatch(loadUserProfileStart());
  }, []);

  var imageFile = () => {
    return API_URL + `img/default/${userProfile?.EmployeeId}/300/300/p`;
  };

  useEffect(() => {
    imageFile();
  }, []);

  if (loading) {
    return <Spin />;
  }

  const handleUserProfileModalOpen = () => {
    setUserProfileUpdateOpen(true);
    setFirstName(userProfile?.ProfileRawData.FirstName);
    setLastName(userProfile?.ProfileRawData.LastName);
    setEmail(userProfile?.ProfileRawData.EmailAddress);
    setPhoneNumber(userProfile?.ProfileRawData.PhoneNumber);
    setCountryCodes(userProfile?.ProfileRawData.CountryCode);
    setStreet(userProfile?.ProfileRawData?.Address.Street);
    setCountry(userProfile?.ProfileRawData?.Address.Country);
    setZipCode(userProfile?.ProfileRawData?.Address.ZipCode);
    setState(userProfile.ProfileRawData?.Address.State);
    setCity(userProfile?.ProfileRawData?.Address.City);
  };

  const handleUserProfileModalClose = () => {
    setUserProfileUpdateOpen(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setCountryCodes("");
    setStreet("");
    setCountry("");
    setZipCode("");
    setState("");
    setCity("");
  };

  const handleUserPasswordModalOpen = () => {
    setUserProfilePasswordOpen(true);
  };

  const handleUserPasswordModalClose = () => {
    setUserProfilePasswordOpen(false);
  };

  const handleImage = async (event) => {
    var file = event.target.files[0];

    var fileName = file.name;

    let data = new FormData();
    data.append("image", file, fileName);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
      },
    };

    var response = await fetch(
      API_URL + `img/upload?id=${userProfile?.EmployeeId}`,
      {
        method: "POST",
        body: data,
        // options
      }
    );

    var datas = await response.json();
    if (response.ok) {
      return swal({
        text: datas.message,
        icon: "success",
      }).then(() => {
        return window.location.reload();
      });
    } else {
      swal({
        text: datas.message,
        icon: "warning",
      });
    }
  };

  const deleteImage = () => {
    swal({
      title: "Action",
      text: "Do you really want to Delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var response = await fetch(
          API_URL + `img/del?id=${userProfile?.EmployeeId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // "Authorization": `Bearer ${localStorage.getItem("token")}`
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        var data = await response.json();

        if (response.ok) {
          return swal({
            text: data.message,
            icon: "success",
          }).then(() => {
            return window.location.reload();
          });
        } else {
          return swal({
            text: data.message,
            icon: "warning",
          });
        }
      } else {
      }
    });
  };

  const updateProfile = async () => {
    const data = {
      firstName,
      lastName,
      emailAddress: email,
      phoneNumber,
      countryCode: countryCodes,
      address: {
        street,
        city,
        state,
        zipCode,
        country,
      },
    };
    setLoader(true);

    var response = await fetch(API_URL + `Profile/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();
    if (response.ok) {
      setLoader(false);
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        setLoader(false);
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");

        return (window.location.href = "/login");
      });
    } else {
      if (dataResponse.message) {
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      } else {
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };

  const updateUserPassword = async () => {
    const data = {
      currentPwd: currentPassword,
      newPwd: newPassword,
    };
    setLoader(true);
    if (newPassword !== confirmNewPassword) {
      setLoader(false);
      swal({
        text: "New Password does not correlate",
        icon: "warning",
      });
      return;
    }
    var response = await fetch(API_URL + `Profile/UpdatePwd`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });
    var dataResponse = await response.json();
    if (response.ok) {
      setLoader(false);
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        setLoader(false);
        setNewPassword("");
        setCurrentPassword("");
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");

        return (window.location.href = "/login");
      });
    } else {
      if (dataResponse.message) {
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      } else {
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };

  const menuOptionsNode = (
    <Menu>
      <Menu.Item onClick={handleUserProfileModalOpen}>Update Profile</Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={handleUserPasswordModalOpen}>
        Update Password
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row>
          <Col span={18}></Col>
          <Col span={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown arrow overlay={menuOptionsNode}>
                <Button
                  icon={<EllipsisOutlined />}
                  // className={buttonClassName}
                ></Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Divider orientation="left">Employee Information</Divider>
        <div style={{ maxWidth: "900px", margin: "auto" }}>
          <Row wrap gutter={[16, 32]}>
            <Col span={6}>
              <div className="profile-section">
                <img src={imageFile()} />
                <div className="profile-image-actions">
                  <div class="upload-btn-wrapper">
                    <BackupIcon />
                    <input type="file" name="myfile" onChange={handleImage} />
                  </div>
                  <DeleteIcon
                    onClick={() => deleteImage()}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Full Name"
                node={userProfile?.FullName}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Role"
                node={userProfile?.CurrentRole}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Address"
                node={userProfile?.Address}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Email Address"
                node={userProfile?.EmailAddress}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Phone Number"
                node={userProfile?.PhoneNumber}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Last Logged In"
                node={userProfile?.LastLoggedIn}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Last Updated"
                node={userProfile?.LastUpdated}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Country"
                node={userProfile?.ProfileRawData?.Address?.Country}
              />
            </Col>
          </Row>
        </div>
      </Space>

      {/* Update user profile modal */}
      <div>
        <Modal
          title="Update User Info"
          visible={userProfileUpdateOpen}
          footer={null}
          onCancel={handleUserProfileModalClose}
        >
          <form>
            <div className="employee-form-div">
              <Input
                placeholder="First Name"
                style={{ width: "49%" }}
                className="half-width"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Input
                placeholder="Last Name"
                style={{ width: "49%" }}
                className="half-width"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="employee-form-div">
              <Input
                placeholder="Email"
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="employee-form-div">
              <Select
                defaultValue={country}
                style={{ width: "100%" }}
                onChange={handleCountryChange}
              >
                <Option value="Select a Country" disabled>
                  Select a Country
                </Option>
                {countryStateCities?.map((country) => {
                  return <Option value={country}>{country}</Option>;
                })}
              </Select>
            </div>
            <div className="employee-form-div">
              {country?.length > 0 ? (
                <Select
                  style={{ width: "49%" }}
                  defaultValue={state}
                  className="half-width"
                  onChange={handleStateChange}
                >
                  <Option value="Select State" disabled>
                    Select State
                  </Option>
                  {getStates?.map((code) => {
                    return <Option value={code}>{code}</Option>;
                  })}
                </Select>
              ) : (
                <Select
                  className="half-width"
                  placeholder="Please select a Country"
                  disabled
                />
              )}
              {state?.length > 0 ? (
                <Select
                  style={{ width: "49%" }}
                  defaultValue={city}
                  className="half-width"
                  onChange={handleCityChange}
                >
                  <Option value="Select City" disabled>
                    Select City
                  </Option>
                  {getCities?.map((code) => {
                    return <Option value={code}>{code}</Option>;
                  })}
                </Select>
              ) : (
                <Select
                  className="half-width"
                  placeholder="Please select a state"
                  disabled
                />
              )}
            </div>
            <div className="employee-form-div">
              <Input
                placeholder="Street"
                style={{ width: "49%" }}
                className="half-width"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
              <Input
                placeholder="ZipCode"
                style={{ width: "49%" }}
                className="half-width"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>

            <div className="employee-form-div">
              <Select
                defaultValue={countryCodes}
                className="half-width"
                onChange={handleCountryCodeChange}
              >
                <Option value="Select Country Code" disabled>
                  Select Country Code
                </Option>
                {countryCode?.map((code) => {
                  return <Option value={code}>{code}</Option>;
                })}
              </Select>
              <Input
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <br />
            <div className="form-btn">
              <Button type="primary" id="but" onClick={() => updateProfile()}>
                {loader ? <Spin /> : "Update Profile"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      {/* Update user password modal */}
      <div>
        <Modal
          title="Update User Password"
          visible={userProfilePasswordOpen}
          footer={null}
          onCancel={handleUserPasswordModalClose}
        >
          <form>
            <div className="employee-form-div">
              <Input
                placeholder="Current Password"
                type="password"
                style={{ width: "100%" }}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="employee-form-div">
              <Input
                placeholder="New Password"
                type="password"
                style={{ width: "100%" }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="employee-form-div">
              <Input
                placeholder="Confirm New Password"
                type="password"
                style={{ width: "100%" }}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
            <div className="form-btn">
              <Button
                type="primary"
                id="but"
                onClick={() => updateUserPassword()}
              >
                {loader ? <Spin /> : "Update Password"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </Fragment>
  );
}

export default Profile;
