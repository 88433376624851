import { useRequest } from "ahooks";
import React from "react";
import OrderBayAPI from "../../../api/endpoints/orderBay";
import PageError from "../../../components/utils/PageError";
import PageLoading from "../../../components/utils/PageLoading";
import OrderItem, { IOrderItemProps } from "./OrderItem";

export interface IOrderItemContainerProps
  extends Omit<IOrderItemProps, "orderItem"> {
  orderItemId: string;
}

const OrderItemContainer: React.FC<IOrderItemContainerProps> = (props) => {
  const { orderItemId } = props;
  const getOrderItem = React.useCallback(async () => {
    return await OrderBayAPI.getOrderItem({ orderId: orderItemId });
  }, [orderItemId]);

  const { loading, error, data, run } = useRequest(getOrderItem);

  if (error) {
    return (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload Order Item", onClick: run }]}
      />
    );
  } else if (loading || !data) {
    return <PageLoading messageText="Loading order item..." />;
  }

  return <OrderItem {...props} orderItem={data} />;
};

export default OrderItemContainer;
