import Cookies from "js-cookie";
import { call, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
  getBranchApi,
  getPodApi,
  getSettingPaymentApi,
  getSupportTeamApi,
} from "../Api";
import {
  loadBranchError,
  loadBranchSuccess,
  loadPodError,
  loadPodSuccess,
  loadSettingPaymentError,
  loadSettingPaymentSuccess,
  loadSettingTeamError,
  loadSettingTeamSuccess,
} from "./settingAction";
import * as types from "./settingActionType";

function* onPodStartAsync({ payload }) {
  try {
    const response = yield call(getPodApi, payload);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadPodSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadPodError(error));
    if (error?.response?.status === 401) {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return (window.location.href = "/login");
    }
  }
}

function* onSettingPaymentStartAsync() {
  try {
    const response = yield call(getSettingPaymentApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadSettingPaymentSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadSettingPaymentError(error));
    if (error?.response?.status === 401) {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return (window.location.href = "/login");
    }
  }
}

function* onBranchStartAsync() {
  try {
    const response = yield call(getBranchApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadBranchSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadBranchError(error));
    if (error?.response?.status === 401) {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      console.error();
      // return window.location.href = "/login";
    }
  }
}

function* onSettingTeamStartAsync() {
  try {
    const response = yield call(getSupportTeamApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(loadSettingTeamSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    yield put(loadSettingTeamError(error));
    if (error?.response?.status === 401) {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return (window.location.href = "/login");
    }
  }
}

function* onSettingPayments() {
  yield takeEvery(
    types.LOAD_SETTINGS_PAYMENT_START,
    onSettingPaymentStartAsync
  );
}

function* onPod() {
  yield takeEvery(types.LOAD_POD_START, onPodStartAsync);
}

function* onBranch() {
  yield takeEvery(types.LOAD_BRANCH_START, onBranchStartAsync);
}

function* onSettingTeams() {
  yield takeEvery(types.LOAD_SETTINGS_TEAM_START, onSettingTeamStartAsync);
}

export const settingSaga = [
  fork(onSettingPayments),
  fork(onSettingTeams),
  fork(onBranch),
  fork(onPod),
];
