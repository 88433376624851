import React from "react";
import { Col, Row, Typography, Space, Tag } from "antd";
import { IOrderTripHistory } from "../../../definitions/orderBay";
import LabeledNode from "../../../components/utils/LabeledNode";
import { appClasses } from "../../../components/utils/theme";
import DOMPurify from "dompurify";

export interface IOrderItemTripHistoryItemProps {
  item: IOrderTripHistory;
}

const OrderItemTripHistoryItem: React.FC<IOrderItemTripHistoryItemProps> = (
  props
) => {
  const { item } = props;

  return (
    <Row wrap gutter={[32, 24]}>
   
      <Col span={24}>
        <LabeledNode
          direction="vertical"
          label="Trip Info"
          node={
            <Typography.Text className={appClasses.paragraph}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.TripInfo),
                }}
              />
            </Typography.Text>
          }
        />
      </Col>
      <Col span={8}>
        <LabeledNode
          direction="vertical"
          label="Package Status"
          node={  
             <Space 
             direction="vertical" 
             size="small" >
              <Tag style={{ color: item.Status.Color }}>
                {item.Status.Name}
              </Tag>
              <Typography.Text type="secondary">
                {item.Status.Description}
              </Typography.Text>
           </Space>
          }
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="From"
          node={item.FromLocation}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="To"
          node={item.ToLocation}
        />
      </Col>
      <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Driver Name"
          node={item.AssignedDriverName}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Driver Number"
          node={item.AssignedDriverNum}
          style={{ width: "100%" }}
        />
      </Col>
       <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Current Trip"
          node={item.CurrentActiveTrip ? "Yes" : "No"}
        />
      </Col>
      <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Trip Started At"
          node={item.TripStartedAt}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Trip Ended At"
          node={item.TripEndedAt}
          style={{ width: "100%" }}
        />
      </Col>
     
      <Col span={8}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Total Trip Duration"
          node={item.TotalTripDuration}
        />
      </Col>
    </Row>
  );
};

export default OrderItemTripHistoryItem;
