import { Cases } from "@mui/icons-material";
import * as types from "./actionType"

const initialState = {
    audits: [],
    auditRef: [],
    loading: false,
    error: null
}

export const getAuditReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_AUDIT_START:
        case types.GET_AUDIT_REF_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case types.GET_AUDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                audits: action.payload,
                error: null
            };
        case types.GET_AUDIT_REF_SUCCESS:
            return {
                ...state,
                loading: false,
                auditRef: action.payload
            };
        case types.GET_AUDIT_ERROR:
        case types.GET_AUDIT_REF_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}