import React, { useState, useEffect } from 'react'
import "./forget-password.css"

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import { API_URL } from "../../globalVariable"
import swal from "sweetalert"



function ForgetPassword() {
    const [resetState, setResetState] = useState(0)
    const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState()
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [getToken, setGetToken] = useState(null)
    const [newPassword, setNewPassword] = useState()
    const [confirmNewPassword, setConfirmNewPassword] = useState()
    const [resetCountDown, setResetCountDown] = useState()
    const [resetText, setResetText] = useState()



    const resetFunction = () => {
        // setLoading(true)
        fetch(API_URL + `Authentication/InitiateForgotPwd?phoneNumberOrEmail=${emailOrPhoneNumber}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data?.message === "Your forgot password request has been submitted. You should get a confirmation code sent to your phone number or email address if your contact validation is successful.") {
                    setLoading(false)
                    setErrMsg(data?.message)
                    setResetState(1)
                }
                else {
                    setLoading(false)
                    setErrMsg(data?.message)
                }

            })
            .then(() => {
                setTimeout(() => {
                    setErrMsg("")
                }, 6000)
            })
            .catch(err => {
                // setLoading(false)
                console.log({ err })
            })


    }

    useEffect(() => {
        if (isNaN(resetCountDown)) {
            return;
        }
        if (resetCountDown === 0) {
            setResetCountDown()
            return;
        }
        else {
            setTimeout(() => {
                setResetCountDown(resetCountDown - 1);
            }, 1000);
        }


    }, [resetCountDown])

    const resendCode = async (code) => {
        await resetFunction()
        setResetCountDown(code)
        setResetText("A reset code has been sent to you")
    }

    const changePassword = async () => {
        setLoader(true)
        const data = {
            tokenCode: getToken,
            newPassword,
        }

        if (newPassword !== confirmNewPassword) {
            setErrMsg("New password and confirmation password are not equal")
            setLoader(false)
            setTimeout(() => {
                setErrMsg("")
            }, [5000])
            return;
        }
        else {
            var response = await fetch(API_URL + "Authentication/UpdateForgotPwd", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(data)
            })
            var dataResponse = await response.json()

            if (response.ok) {
                return swal({
                    text: dataResponse.message.toLowerCase(),
                    icon: 'success'
                })
                    .then(() => {
                        setLoader(false)
                        return window.location.href = "/login"
                    })

            }
            else {
                setLoader(false)
                return swal({
                    text: dataResponse.message.toLowerCase(),
                    icon: 'warning'
                })
            }

        }

    }

    return (
        <div className="forget">
            <div className="fts-div1">
                <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1623772465/Rectangle_453_zrsfyc.png" />
            </div>

            <div className="forget-p">
                <p className={errMsg.length <= 0 ? "forget-none" : errMsg === "Your forgot password request has been submitted. You should get a confirmation code sent to your phone number or email address if your contact validation is successful."  ? "forget-success" : "forget-msg"}>{errMsg}</p>
                <div className="forget-password">
                    {
                        resetState === 0 ?
                            <div>
                                <h1>Forget Password</h1>

                                <form>
                                    <div className="reset-div">
                                        <label>Email</label>
                                        <TextField id="outlined-basic" value={emailOrPhoneNumber} onChange={(e) => setEmailOrPhoneNumber(e.target.value)} label="Enter Email Address" variant="outlined" className="" />
                                    </div>

                                    <div className="forget-btn">
                                        <Button variant="contained" color="primary" onClick={() => resetFunction()} >
                                            {loading ? "Loading" : "Reset"}
                                        </Button>
                                    </div>
                                </form>
                            </div>

                            :
                            <div className="forget-password">
                                <h1>Reset Password</h1>

                                <form>
                                    <div className="reset-div">
                                        <label>Token</label>
                                        <TextField id="outlined-basic" type="text" value={getToken} onChange={(e) => setGetToken(e.target.value)} label="Enter Token" variant="outlined" className="" autoComplete='false' autoCorrect='false'/>
                                    </div>
                                    <div className="verification">
                                        <p>{resetText}</p>
                                        <p onClick={() => resendCode(60)}>{isNaN(resetCountDown) ? "Reset code" : resetCountDown}</p>
                                    </div>
                                    <div className="reset-div">
                                        <label>New Password</label>
                                        <TextField id="outlined-basic" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} label="Enter New Password" variant="outlined" className="" />
                                    </div>
                                    <div className="reset-div">
                                        <label>Confirm New Password</label>
                                        <TextField id="outlined-basic" type="password" label="Confirm New Password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} variant="outlined" className="" />
                                    </div>

                                    <div className="forget-btn">
                                        <Button variant="contained" color="primary" onClick={() => changePassword()}>
                                            {loader ? "Loading" : " Change Password"}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword
