import {
  Alert, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Switch, Typography
} from "antd";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import { isObject } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import FormItemError from "../../../components/utils/FormItemError";
import { IAcknowledgeParcelInput } from "../../../definitions/orderBay";
import { appFormMessages } from "../../../global/messages";
import {
  loadCurrenciesStart,
  loadPaidOptionStart
} from "../../../redux/ResourcesRedux/resourcesAction";
import { IReduxState, IReduxStateResources } from "../../../redux/types";

const paidOptionOther = "Others";
const validationSchema = yup.object().shape({
  estimatedDeliveryDate: yup.string().required(appFormMessages.requiredField),
  isPaymentOptionPOD: yup.boolean().nullable(),
  paidInfo: yup
    .object()
    .when("isPaymentOptionPOD", {
      is: false,
      then: yup
        .object()
        .shape({
          paidCustomOption: yup.string(),
          paidOption: yup.string().required(appFormMessages.requiredField),
          recieptAttachmentId: yup.string().nullable(),
        })
        .required(),
    })
    .nullable(),
  currencyType: yup.string().required(appFormMessages.requiredField),
  cost: yup.number().required(appFormMessages.requiredField),
  taxPerc: yup.number().nullable(),
  insuranceCost: yup.number().nullable(),
});

const getInitialValues = (parcelId: string, isPayOnDelivery: boolean) => {
  const initialValues: IAcknowledgeParcelInput = {
    parcelId,
    estimatedDeliveryDate: "",
    isPaymentOptionPOD: isPayOnDelivery,
    paidInfo: isPayOnDelivery
      ? null
      : {
          paidCustomOption: "",
          paidOption: "Cash",
          recieptAttachmentId: null,
        },
    currencyType: "Naira",
    cost: 0,
    taxPerc: 0,
    insuranceCost: 0,
  };

  return initialValues;
};

const formItemLabelCol = {
  span: 24,
};

const formItemWrapperCol = { span: 24 };

export interface IOrderBayAcknowledgeFormProps {
  error?: string | null;
  submitting?: boolean;
  parcelId: string;
  isPayOnDelivery: boolean;
  onSubmit: (data: IAcknowledgeParcelInput) => void;
  onCancel: () => void;
}

const OrderBayAcknowledgeForm: React.FC<IOrderBayAcknowledgeFormProps> = (
  props
) => {
  const { parcelId, error, submitting, isPayOnDelivery, onSubmit, onCancel } =
    props;

  const dispatch = useDispatch();
  const formik = useFormik({
    validationSchema,
    initialValues: getInitialValues(parcelId, isPayOnDelivery),
    onSubmit: onSubmit,
  });

  const { currencies, paidOptions } = useSelector<
    IReduxState,
    IReduxStateResources
  >((state) => state.resources);

  useEffect(() => {
    dispatch(loadCurrenciesStart());
    dispatch(loadPaidOptionStart());
  }, [dispatch]);

  const selectedCurrency = currencies?.find(
    (item) => item.id === formik.values.currencyType
  );

  const withCurrency = (text: string) =>
    selectedCurrency ? `${text} (${selectedCurrency.description})` : text;

  const paidInfoTouched = (
    isObject(formik.touched.paidInfo) ? formik.touched.paidInfo : {}
  ) as FormikTouched<NonNullable<IAcknowledgeParcelInput["paidInfo"]>>;
  const paidInfoError = (
    isObject(formik.errors.paidInfo) ? formik.errors.paidInfo : {}
  ) as FormikErrors<NonNullable<IAcknowledgeParcelInput["paidInfo"]>>;

  const estimatedDeliveryDateNode = (
    <Form.Item
      required
      label="Estimated Delivery Date"
      help={
        formik.touched.estimatedDeliveryDate && (
          <FormItemError message={formik.errors.estimatedDeliveryDate} />
        )
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <DatePicker
        format="YYYY-MM-DD"
        disabledDate={(current) => {
          // Can not select days before today
          return current && current < moment();
        }}
        placeholder="Estimated Delivery Date"
        value={
          formik.values.estimatedDeliveryDate
            ? moment(formik.values.estimatedDeliveryDate)
            : null
        }
        onChange={(date) =>
          formik.setFieldValue("estimatedDeliveryDate", date?.toISOString())
        }
        onBlur={formik.handleBlur}
        name={"estimatedDeliveryDate"}
        disabled={submitting}
        style={{ width: "200px" }}
      />
    </Form.Item>
  );

  const isPODNode = (
    <Form.Item label="Is Pay On Delivery">
      <Switch
        checked={formik.values.isPaymentOptionPOD}
        onChange={(value) => formik.setFieldValue("isPaymentOptionPOD", value)}
        disabled={submitting}
      />
    </Form.Item>
  );

  const paidOptionNode = !formik.values.isPaymentOptionPOD && (
    <Form.Item
      required
      label="Payment Option"
      help={
        paidInfoTouched.paidOption && (
          <FormItemError message={paidInfoError.paidOption} />
        )
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <Select
        placeholder="Select Payment Option"
        value={formik.values.paidInfo?.paidOption}
        onChange={(value) => formik.setFieldValue("paidInfo.paidOption", value)}
        onBlur={formik.handleBlur}
        disabled={submitting}
      >
        {paidOptions?.map((code) => {
          return (
            <Select.Option key={code} value={code}>
              {code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const paidCustomOptionNode = formik.values.paidInfo?.paidOption ===
    paidOptionOther && (
    <Form.Item
      required
      label="Payment Custom Option"
      help={
        paidInfoTouched.paidCustomOption && (
          <FormItemError message={paidInfoError.paidCustomOption} />
        )
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <Input
        placeholder="Payment Custom Option"
        value={formik.values.paidInfo?.paidCustomOption}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name={"paidInfo.paidCustomOption"}
        disabled={submitting}
      />
    </Form.Item>
  );

  const currencyTypeNode = (
    <Form.Item
      required
      label="Currency Type"
      help={
        formik.touched.currencyType && (
          <FormItemError message={formik.errors.currencyType} />
        )
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <Select
        placeholder="Select Currency Type"
        value={formik.values.currencyType}
        onChange={(value) => formik.setFieldValue("currencyType", value)}
        onBlur={formik.handleBlur}
        disabled={submitting}
      >
        {currencies?.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const costNode = (
    <Form.Item
      required
      label="Cost"
      help={
        formik.touched.cost && <FormItemError message={formik.errors.cost} />
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <InputNumber
        placeholder={withCurrency("Cost")}
        value={formik.values.cost}
        onChange={(value) => formik.setFieldValue("cost", value)}
        onBlur={formik.handleBlur}
        name={"cost"}
        disabled={submitting}
        style={{ width: "150px" }}
      />
    </Form.Item>
  );

  const taxNode = (
    <Form.Item
      label="Tax Percentage"
      help={
        formik.touched.taxPerc && (
          <FormItemError message={formik.errors.taxPerc} />
        )
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <InputNumber
        placeholder="Tax Percentage"
        value={formik.values.taxPerc}
        onChange={(value) => formik.setFieldValue("taxPerc", value)}
        onBlur={formik.handleBlur}
        name={"taxPerc"}
        max={100}
        min={0}
        disabled={submitting}
      />
    </Form.Item>
  );

  const insuranceCostNode = (
    <Form.Item
      label={withCurrency("Insurance Cost")}
      help={
        formik.touched.insuranceCost && (
          <FormItemError message={formik.errors.insuranceCost} />
        )
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <InputNumber
        placeholder="Insurance Cost"
        value={formik.values.insuranceCost}
        onChange={(value) => formik.setFieldValue("insuranceCost", value)}
        onBlur={formik.handleBlur}
        name={"insuranceCost"}
        disabled={submitting}
        style={{ width: "150px" }}
      />
    </Form.Item>
  );

  const cost = formik.values.cost;
  const taxPercentage = formik.values.taxPerc;
  const insuranceCost = formik.values.insuranceCost;
  const totalCostNode = (
    <Form.Item
      label={withCurrency("Total Cost")}
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <Typography.Text strong>
        {cost + (taxPercentage / 100) * cost + insuranceCost}
      </Typography.Text>
    </Form.Item>
  );

  return (
    <Modal
      visible
      title="Acknowledge Order"
      closable={false}
      onCancel={onCancel}
      onOk={formik.submitForm}
    >
      <form onSubmit={formik.handleSubmit}>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        {estimatedDeliveryDateNode}
        {isPODNode}
        {paidOptionNode}
        {paidCustomOptionNode}
        {currencyTypeNode}
        <Row gutter={16}>
          <Col span={12}>{costNode}</Col>
          <Col span={12}>{taxNode}</Col>
          <Col span={12}>{insuranceCostNode}</Col>
          <Col span={12}>{totalCostNode}</Col>
        </Row>
      </form>
    </Modal>
  );
};

export default OrderBayAcknowledgeForm;
