import React, { useState, useEffect } from 'react'
import { Modal } from "antd"
import LabeledNode from "../../components/utils/LabeledNode";
import { Col, Row, Space, Tag, Switch, Typography, Divider } from "antd";


function OrderBayModal({
    isModalOpen,
    data,
    handleCancel,
}) {


    return (
        <Modal title={data?.OrderName} visible={isModalOpen} onCancel={handleCancel}>
            <Divider orientation="left">Order Information</Divider>
            <Row wrap gutter={[32, 32]}>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Status"
                        node={<Tag color={data?.Status?.Color} >{data?.Status?.Name}</Tag>
                        }
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Package Size"
                        node={data?.PackageEstimatedSize?.Name}
                        
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Order Number"
                        node={data?.OrderItemNumber}
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Order Name"
                        node={data?.OrderName}
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Order Description"
                        node={data?.OrderDescription}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Order Category"
                        node={data?.OrderCategory}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        direction="vertical"
                        label="Message Note"
                        node={
                            data?.MsgNote
                        }
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Estimated Weight"
                        node={data?.EstimatedWeight + data?.EstimatedWeightUnit?.Name}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Last Updated"
                        node={data?.LastUpdated_}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Current Item Location"
                        node={data?.CurrentItemLocation}
                    />
                </Col>
            </Row>
            <Divider orientation="left">CheckList</Divider>
            <Row wrap gutter={[32, 32]}>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Content Can Spill"
                        node={
                            <Tag color={data?.CheckListInfo?.ContentCanSpill ? "green" : "red"} >{data?.CheckListInfo?.ContentCanSpill ? "Yes" : "No"}</Tag>
                        }
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Explosive"
                        node={
                            <Tag color={data?.CheckListInfo?.Explosive ? "green" : "red"} >{data?.CheckListInfo?.Explosive ? "Yes" : "No"}</Tag>
                        }
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Flammable"
                        node={
                            <Tag color={data?.CheckListInfo?.Flammable ? "green" : "red"} >{data?.CheckListInfo?.Flammable ? "Yes" : "No"}</Tag>
                        }
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Fragilel"
                        node={
                            <Tag color={data?.CheckListInfo?.Fragile ? "green" : "red"} >{data?.CheckListInfo?.Fragile ? "Yes" : "No"}</Tag>
                        }
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Hazardious"
                        node={
                            <Tag color={data?.CheckListInfo?.Hazardiousl ? "green" : "red"} >{data?.CheckListInfo?.Hazardiousl ? "Yes" : "No"}</Tag>
                        }
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col span={12}>
                    <LabeledNode
                        nodeIsText
                        direction="vertical"
                        label="Perishable"
                        node={
                            <Tag color={data?.CheckListInfo?.Perishable ? "green" : "red"} >{data?.CheckListInfo?.Perishable ? "Yes" : "No"}</Tag>
                        }
                        style={{ width: "100%" }}
                    />
                </Col>
            </Row>
        </Modal>
    )
}

export default OrderBayModal