import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import * as types from "./actionType"
import { 
   getStatisticsError, getStatisticsSuccess, getTopBlocksError, getTopBlocksSuccess, getTopOrdersError, getTopOrdersSuccess
} from "./action"
import { getTopOrdersApi, getTopBlockssApi, getStatisticApi, getTopBlocksApi } from "../Api"



function* onLoadStatisticsStartAsync() {
    try {
        const response = yield call(getStatisticApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(getStatisticsSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getStatisticsError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadTopBlocksStartAsync() {
    try {
        const response = yield call(getTopBlocksApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(getTopBlocksSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getTopBlocksError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadTopOrdersStartAsync() {
    try {
        const response = yield call(getTopOrdersApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(getTopOrdersSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getTopOrdersError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onStatistics() {
    yield takeEvery(types.GET_STATISTICS_START, onLoadStatisticsStartAsync)
}

function* onTopBlocks() {
    yield takeEvery(types.GET_TOP_BLOCKS_START, onLoadTopBlocksStartAsync)
}

function* onTopOrders() {
    yield takeEvery(types.GET_TOP_ORDERS_START, onLoadTopOrdersStartAsync)
}


export const dashboardSaga = [
    fork(onStatistics),
    fork(onTopBlocks),
    fork(onTopOrders),
]
