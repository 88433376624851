import { Alert, DatePicker, Form, Modal as ModalComponent } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import * as yup from "yup";
import FormItemError from "../../../components/utils/FormItemError";
import { IOrderBlock } from "../../../definitions/orderBlock";
import { appFormMessages } from "../../../global/messages";

const Modal = ModalComponent as React.FC<
  React.ComponentProps<typeof ModalComponent> & { children: React.ReactNode }
>;

const PUBLIC_ORDER_BLOCK_TYPE = "Public";
const validationSchema = yup.object().shape({
  publicTypeExpirationDate: yup.mixed().when("type", {
    is: PUBLIC_ORDER_BLOCK_TYPE,
    then: yup.string().required("Field is required if type is 'public'"),
  }),
  pickUpDateTime: yup.string().required(appFormMessages.requiredField),
});

export interface IOrderBlockAdjustDatesFormValues {
  publicTypeExpirationDate: string;
  pickUpDateTime: string;
  type: string;
}

const getInitialValues = (block: IOrderBlock) => {
  const initialValues: IOrderBlockAdjustDatesFormValues = {
    publicTypeExpirationDate: block.PublicTypeExpirationDate,
    pickUpDateTime: block.BlockPickUpdateTime,
    type: block.Type.Name,
  };

  return initialValues;
};

function disabledDate(current?: moment.Moment) {
  // Can not select days before today
  return !!(current && current < moment().startOf("day"));
}

export interface IOrderBlockAdjustDatesFormProps {
  error?: string | null;
  submitting?: boolean;
  block: IOrderBlock;
  onSubmit: (data: IOrderBlockAdjustDatesFormValues) => void;
  onCancel: () => void;
}

const OrderBlockAdjustDatesForm: React.FC<IOrderBlockAdjustDatesFormProps> = (
  props
) => {
  const { error, submitting, block, onSubmit, onCancel } = props;
  const formik = useFormik({
    validationSchema,
    initialValues: getInitialValues(block),
    onSubmit: onSubmit,
  });

  const pickUpDateTimeNode = (
    <Form.Item
      required
      label="Pickup Time"
      help={
        formik.touched.pickUpDateTime && (
          <FormItemError message={formik.errors.pickUpDateTime} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <DatePicker
        format="YYYY-MM-DD hh:mm A"
        disabledDate={disabledDate}
        // disabledTime={disabledDateTime}
        showTime={{ defaultValue: moment("00:00:00", "hh:mm:ss A") }}
        placeholder="Public block expiration date"
        value={
          formik.values.pickUpDateTime
            ? moment(formik.values.pickUpDateTime)
            : null
        }
        onChange={(date) =>
          formik.setFieldValue("pickUpDateTime", date?.toISOString())
        }
        disabled={submitting}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );

  const publicTypeExpirationDateNode = formik.values.type ===
    PUBLIC_ORDER_BLOCK_TYPE && (
    <Form.Item
      required
      label="Public Block Expiration Date"
      help={
        formik.touched.publicTypeExpirationDate && (
          <FormItemError message={formik.errors.publicTypeExpirationDate} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <DatePicker
        format="YYYY-MM-DD"
        disabledDate={disabledDate}
        placeholder="Public block expiration date"
        value={
          formik.values.publicTypeExpirationDate
            ? moment(formik.values.publicTypeExpirationDate)
            : null
        }
        onChange={(date) =>
          formik.setFieldValue("publicTypeExpirationDate", date?.toISOString())
        }
        disabled={submitting}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );

  return (
    <Modal
      visible
      title="Adjust Order Block Dates"
      onCancel={onCancel}
      onOk={formik.submitForm}
    >
      <form onSubmit={formik.handleSubmit}>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        {publicTypeExpirationDateNode}
        {pickUpDateTimeNode}
      </form>
    </Modal>
  );
};

export default OrderBlockAdjustDatesForm;
