import React from "react";
import PageError from "../../components/utils/PageError";
import PagePagination from "../../components/utils/PagePaination";
import { appClasses } from "../../components/utils/theme";
import { IOrderBlock } from "../../definitions/orderBlock";
import { IUsePaginatedDataResult } from "../../hooks/usePaginatedData";
import OrderBlockPageControls, {
  IOrderBlockPageControlsProps,
} from "./OrderBlockPageControls";
import OrderBlockTable from "./OrderBlockTable";

export interface IOrderBlockPageProps {
  pageData: IUsePaginatedDataResult<IOrderBlock, any>;
  controls: IOrderBlockPageControlsProps;
  onCompleteUpdateBlock: () => void;
  onCompleteDeleteBlock: () => void;
  onSelectUpdateBlock: (blockId: string) => void;
}

const OrderBlockPage: React.FC<IOrderBlockPageProps> = (props) => {
  const {
    pageData,
    controls,
    onCompleteDeleteBlock,
    onCompleteUpdateBlock,
    onSelectUpdateBlock,
  } = props;

  const {
    page,
    isInitialized,
    getPageItems,
    reloadEverything,
    getPageError,
    getPageLoading,
  } = pageData;

  let contentNode: React.ReactNode = null;
  const pageError = getPageError(page);

  if (pageError) {
    contentNode = (
      <PageError
        messageText={pageError}
        actions={[{ text: "Reload", onClick: () => reloadEverything() }]}
      />
    );
  } else if (isInitialized) {
    const pageData = getPageItems(page);
    contentNode = (
      <OrderBlockTable
        loading={getPageLoading(page)}
        data={pageData}
        onCompleteDeleteBlock={onCompleteDeleteBlock}
        onCompleteUpdateBlock={onCompleteUpdateBlock}
        onSelectUpdateBlock={onSelectUpdateBlock}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls}>
        <OrderBlockPageControls {...controls} />
      </div>
      <div className={appClasses.pageContent}>{contentNode}</div>
      {!pageError && (
        <div className={appClasses.pageBottomControls}>
          <PagePagination {...pageData} />
        </div>
      )}
    </div>
  );
};

export default OrderBlockPage;
