import { Typography } from "antd";
import { noop } from "lodash";
import React from "react";
import OrderBlockAPI, {
  IFilterOrderItemsEndpointInput,
  ISearchOrderBlocksEnpointInput,
} from "../../../api/endpoints/orderBlock";
import { appClasses } from "../../../components/utils/theme";
import { IOrder } from "../../../definitions/orderBay";
import useFetchData from "../../../hooks/useFetchData";
import usePaginatedData from "../../../hooks/usePaginatedData";
import { usePaginatedDataFromFetchData } from "../../../hooks/usePaginatedDataFromFetchData";
import OrdersPagedByOrderPage from "../../OrderBayPage/order/OrdersPagedbyOrderPage";
import OrderBlockSelectOrdersControls from "./OrderBlockSelectOrdersControls";

export interface IOrderBlockSelectOrderProps {
  selectedOrders: IOrder[];
  onCommitSelectedOrders: (orders: IOrder[]) => void;
}

enum PageModes {
  Page = "page",
  Search = "search",
  Filter = "filter",
}

function mergeOrders(...ordersList: Array<Array<IOrder>>) {
  const mergedOrders: IOrder[] = [];
  const mergeOrdersMap: Record<string, IOrder> = {};

  ordersList.forEach((orders) => {
    orders.forEach((order) => {
      if (!mergeOrdersMap[order.OrderId]) {
        mergedOrders.push(order);
        mergeOrdersMap[order.OrderId] = order;
      }
    });
  });

  return mergedOrders;
}

const OrderBlockSelectOrder: React.FC<IOrderBlockSelectOrderProps> = (
  props
) => {
  const { selectedOrders, onCommitSelectedOrders } = props;

  // Sets mode
  const [mode, setPageMode] = React.useState(PageModes.Page);

  // Get paginated data
  const pageData = usePaginatedData<IOrder>({
    fetch: OrderBlockAPI.getOrderItems,
    getItemId: (item) => item.OrderId,
  });

  // Search orders
  const searchData = useFetchData<IOrder, ISearchOrderBlocksEnpointInput>({
    fetch: OrderBlockAPI.filterSearchOrderItems,
    manual: true,
  });

  // Convert search data to pagination format
  const searchPaginationData = usePaginatedDataFromFetchData<
    IOrder,
    ISearchOrderBlocksEnpointInput
  >(searchData, (item) => item.OrderId);

  // Filter orders
  const orderFilterData = useFetchData<
    IOrder,
    Partial<IFilterOrderItemsEndpointInput>
  >({
    fetch: OrderBlockAPI.filterOrderItems,
    manual: true,
  });

  // Convert filter data to pagination format
  const orderFilterPaginationData = usePaginatedDataFromFetchData<
    IOrder,
    Partial<IFilterOrderItemsEndpointInput>
  >(orderFilterData, (item) => item.OrderId);

  const filterPageSetFetchExtra = orderFilterPaginationData.setFetchExtra;
  const onEnterOrderFilter = React.useCallback(
    (data: Partial<IFilterOrderItemsEndpointInput>) => {
      filterPageSetFetchExtra(data, true);

      if (mode !== PageModes.Filter) {
        setPageMode(PageModes.Filter);
      }
    },
    [mode, filterPageSetFetchExtra]
  );

  const filterPageClearData = orderFilterPaginationData.clearData;
  const onClearFilterMode = React.useCallback(() => {
    setPageMode(PageModes.Page);
    filterPageSetFetchExtra({});
    filterPageClearData();
  }, [filterPageSetFetchExtra, filterPageClearData]);

  const pageSetSearchExtra = searchPaginationData.setFetchExtra;
  const searchPageClearData = searchPaginationData.clearData;
  const onEnterSearchQuery = React.useCallback(
    (input: string) => {
      pageSetSearchExtra({ query: input }, true);

      if (input === "" && mode === PageModes.Search) {
        setPageMode(PageModes.Page);
        searchPageClearData();
      } else if (input !== "" && mode === PageModes.Page) {
        setPageMode(PageModes.Search);
      }
    },
    [mode, pageSetSearchExtra, searchPageClearData]
  );

  const selectedOrdersKeys = React.useMemo(
    () => selectedOrders.map((order) => order.OrderId),
    [selectedOrders]
  );

  const onUpdateCurrentViewSelectedOrders = React.useCallback(
    (selectedRows: IOrder[], selectedAll: boolean) => {
      if (selectedAll && mode !== PageModes.Page) {
        const currentFetchData =
          mode === PageModes.Filter ? orderFilterData : searchData;
        selectedRows = currentFetchData.data || [];
      }

      onCommitSelectedOrders(mergeOrders(selectedOrders, selectedRows));
    },
    [mode, orderFilterData, searchData, selectedOrders, onCommitSelectedOrders]
  );

  return (
    <div className={appClasses.pageRoot}>
      <Typography.Title level={5}>Available Orders</Typography.Title>
      <div className={appClasses.pageControls} style={{ marginBottom: "16px" }}>
        <OrderBlockSelectOrdersControls
          disabled={
            !pageData.isInitialized ||
            searchData.loading ||
            orderFilterData.loading
          }
          isFilterMode={mode === PageModes.Filter}
          filter={orderFilterData.fetchExtra}
          query={searchData.fetchExtra?.query}
          onClearFilterMode={onClearFilterMode}
          onEnterOrderFilter={onEnterOrderFilter}
          onEnterSearchQuery={onEnterSearchQuery}
        />
      </div>
      <div className={appClasses.pageContent}>
        <OrdersPagedByOrderPage
          {...(mode === PageModes.Page
            ? pageData
            : mode === PageModes.Filter
            ? orderFilterPaginationData
            : searchPaginationData)}
          omitActions
          withCheckbox
          hideSelectAll={mode === PageModes.Page ? true : false}
          selectedRowKeys={selectedOrdersKeys}
          onSelectRows={onUpdateCurrentViewSelectedOrders}
          onCompleteUpdate={noop}
          onCompleteDelete={noop}
        />
      </div>
    </div>
  );
};

export default OrderBlockSelectOrder;
