import { invokeEndpointWithAuth } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";
import { API_URL } from "../../globalVariable";
import { IEmployee } from "../../definitions/employees";

const orderBayURL = `${API_URL}Employee`;
const getEmployeeInfoURL = `${orderBayURL}/GetEmployeeInfo`;

export interface IGetEmployeeInfoEndpointInput {
  employeeId: string;
}

export type IGetEmployeeInfoEndpointResult = IEmployee;

async function getEmployeeInfo(
  props: IGetEmployeeInfoEndpointInput
): Promise<IGetEmployeeInfoEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "employeeId", props.employeeId);

  const endpointURL = selectEndpointParam(getEmployeeInfoURL, params, props);
  const result = await invokeEndpointWithAuth<IGetEmployeeInfoEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export default class EmployeeAPI {
  public static getEmployeeInfo = getEmployeeInfo;
}
