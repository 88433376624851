import {
  Drawer,
  Image,
  Input,
  List,
  Modal,
  Result,
  Select,
  Steps,
  Table,
  Tag,
} from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OrderBayModal from "../../components/OrderBayModal";
import Paginations from "../../components/Pagination";
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { appDimensions } from "../../components/utils/theme";
import { API_URL } from "../../globalVariable";
import {
  loadTrackingFreqStart,
  loadTrackingTypeStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import { getTrackingDriverStart } from "../../redux/TrackingRedux/action";
import "./tracking.css";

function Tracking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedData, setSelectedData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tracking, setTracking] = useState([]);
  const [currentPageSize, setCurrentPageSize] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [loading, setLoading] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  var queryPage = parseInt(query.get("page"));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryPage);
  const [pageSize, setPageSize] = useState(10);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = (data) => {
    setVisible(true);
    setTrackingDetail(data);
  };
  const onClose = () => {
    setVisible(false);
  };

  const { Option } = Select;
  const [type, setType] = useState("");
  const [freq, setFreq] = useState("");
  const [driver, setDriver] = useState("");
  const [errMsg, setErrMsg] = useState();

  const { trackingDrivers } = useSelector((state) => state.trackings);

  const { trackingType, trackingFreq } = useSelector(
    (state) => state.resources
  );

  const [trackingDetail, setTrackingDetail] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [trackingData, setTrackingData] = useState(null);
  const { Step } = Steps;

  const handleDetailModalClose = () => {
    setTrackingDetail(null);
    setShowModal(false);
  };

  const getTrackingData = (payload) => {
    setLoading(true);
    setErrMsg(null);
    try {
      fetch(
        API_URL +
          `Tracking/Page?page_number=${payload?.page}&page_size=${
            payload?.pageSize
          }&type=${payload?.type === undefined ? "" : payload?.type}&freq=${
            payload?.freq === undefined ? "" : payload?.freq
          }&driverId=${
            payload?.driverId === undefined ? "" : payload?.driverId
          }`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.message) {
            setErrMsg(data?.message);
            setLoading(false);
          } else {
            setLoading(false);
            setTrackingData(data);
            setTracking(data?.Data);
            setCurrentPageSize(data?.currentPageSize);
            setTotalPage(data?.totalPage);
          }
        });
    } catch (e) {
      console.log({ e });
    }
  };

  const getTrackingSearch = (a) => {
    setLoading(true);
    try {
      fetch(API_URL + `Tracking/Search?query=${a}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setTracking(data);
        });
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    const payload = {
      type,
      freq,
      driverId: driver,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    getTrackingData(payload);
    dispatch(loadTrackingTypeStart());
    dispatch(loadTrackingFreqStart());
    dispatch(getTrackingDriverStart());
  }, [currentPage, pageSize]);

  useEffect(() => {
    const payload = {
      type,
      freq,
      driverId: driver,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };

    getTrackingData(payload);
  }, [type]);

  useEffect(() => {
    const payload = {
      type,
      freq,
      driverId: driver,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    getTrackingData(payload);
  }, [freq]);

  useEffect(() => {
    const payload = {
      type,
      freq,
      driverId: driver,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    getTrackingData(payload);
  }, [driver]);

  const submitSearchWithKey = (value, event) => {
    const payload = {
      type,
      freq,
      driverId: driver,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };

    if (event?.key === "Enter" && searchValue?.length >= 1) {
      getTrackingSearch(searchValue);
    }
    if (event?.type === "click" && value?.length >= 1) {
      getTrackingSearch(searchValue);
    }
    if (event?.type === "click" && value?.length < 1) {
      getTrackingData(payload);
    }
  };

  const handleFrequnecySelect = (value) => {
    setFreq(value);
  };

  const handleFilterTypeSelect = (value) => {
    setType(value);
  };

  const handleDriverSelect = (value) => {
    setDriver(value);
  };

  const imageCounts = (id, props) => {
    for (let i = 0; i < props; i++) {
      return (
        <Image width={60} height={60} src={API_URL + `img/group/${id}/${i}`} />
      );
    }
  };

  const columns = [
    {
      title: "Status",
      width: 150,
      ellipsis: true,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return (
          <NodeWithTip tip={record.Status.Description}>
            <Tag
              // style={{ color: record.Priority.Color }}
              color={record.Status.Color}
            >
              {record.Status.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Driver",
      width: 170,
      dataIndex: "Driver",
      key: "Driver",
      render(text, record) {
        return (
          <p
            style={{ color: "orange", cursor: "pointer" }}
            onClick={() => showDrawer(record)}
          >
            {record.CurrentDriverName}
          </p>
        );
      },
    },
    {
      title: "Order Num",
      width: 220,
      dataIndex: "OrderNum",
      key: "OrderNum",
    },
    {
      title: "Picked Up",
      width: 140,
      dataIndex: "PickUp",
      key: "PickUp",
      render(text, record) {
        return <p>{record.PickUp?.Address}</p>;
      },
    },
    {
      title: "Dropped",
      width: 150,
      dataIndex: "Drop",
      key: "Drop",
      render(text, record) {
        return <p>{record.DropOff?.Address}</p>;
      },
    },
    {
      title: "Last Updated",
      width: 200,
      dataIndex: "LastUpdated_",
      key: "LastUpdated_",
    },
  ];

  const onChangeData = (page, pageSize) => {
    navigate(`/tracking?page=${page}`);
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    const fetchData = () => {
      fetch(API_URL + `OrderBay/GetOrderItem?orderId=${selectedId}`, {
        headers: {
          authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setSelectedData(data));
    };

    fetchData();
  }, [selectedId]);

  const clearSelect = () => {
    const payload = {
      type: "",
      freq: "",
      driverId: "",
      page,
      pageSize,
    };

    getTrackingData(payload);
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Input.Search
            allowClear
            // disabled={disabled}
            placeholder="Enter search text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(value, event) => submitSearchWithKey(value, event)}
            style={{ width: appDimensions.searchInputWidth }}
          />
          <Select
            allowClear
            defaultValue="Filter By Type"
            onChange={handleFilterTypeSelect}
            onClear={clearSelect}
            style={{
              padding: "10px 0px 10px 10px",
              width: "20%",
            }}
          >
            <Option value="disabled" disabled>
              Filter By Type
            </Option>
            <Option value="">All</Option>
            {trackingType?.map((data) => {
              return <Option value={data.id}>{data.name}</Option>;
            })}
          </Select>
          <Select
            allowClear
            defaultValue="Filter By Frequency"
            onChange={handleFrequnecySelect}
            onClear={clearSelect}
            style={{
              padding: "10px 0px 10px 10px",
              width: "20%",
            }}
          >
            <Option value="disabled" disabled>
              Filter By Frequency
            </Option>
            {trackingFreq?.map((data) => {
              return <Option value={data.id}>{data.name}</Option>;
            })}
          </Select>
          <Select
            allowClear
            defaultValue="Filter By Driver"
            onClear={clearSelect}
            onChange={handleDriverSelect}
            style={{
              padding: "10px 0px 10px 10px",
              width: "20%",
            }}
          >
            <Option value="disabled" disabled>
              Filter By Driver
            </Option>
            {trackingDrivers?.map((data) => {
              return <Option value={data.DriverId}>{data.DriverName}</Option>;
            })}
          </Select>
        </div>
      </div>
      <br />

      {errMsg && <Result title={errMsg} />}

      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        tracking?.length >= 1 &&
        !errMsg && (
          <div
            style={{
              display: "flex",
              height: "90%",
              // flex: 1,
              width: "100%",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            <Table
              bordered
              size="small"
              dataSource={tracking}
              columns={columns}
              rowKey={(row) => row.BlockId}
              pagination={false}
              // scroll={{ y: 500 }}
            />
          </div>
        )
      )}

      <Paginations
        data={trackingData}
        currentPage={isNaN(currentPage) ? page : currentPage}
        onChangeData={onChangeData}
      />

      {/* Modal for Details */}
      <Modal
        title="Tracking Details"
        visible={showModal}
        footer={null}
        onCancel={handleDetailModalClose}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Order Number</a>}
                  description={trackingDetail?.OrderNum}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Order Id</a>}
                  description={trackingDetail?.OrderId}
                />
              </List.Item>
            </List>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Driver Id</a>}
                  description={trackingDetail?.DriverId}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Driver Name</a>}
                  description={trackingDetail?.CurrentDriverName}
                />
              </List.Item>
            </List>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>DropOff Address</a>}
                  description={trackingDetail?.DropOff.Address}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Status</a>}
                  description={
                    <Tag color={trackingDetail?.Status.Color}>
                      {trackingDetail?.Status.Color}
                    </Tag>
                  }
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Last Upadated</a>}
                  description={trackingDetail?.LastUpdated_}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Pickup Address</a>}
                  description={trackingDetail?.PickUp.Address}
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Recipient Address</a>}
                  description={trackingDetail?.Recipient.Address}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Recipient Name</a>}
                  description={trackingDetail?.Recipient.Info}
                />
              </List.Item>
            </List>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Sender Address</a>}
                  description={trackingDetail?.Sender.Address}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Sender Name</a>}
                  description={trackingDetail?.Sender.Info}
                />
              </List.Item>
            </List>
          </div>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={trackingDetail?.TrackingInfo}
          renderItem={(item) => (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div style={{ width: "50%" }}>
                <List.Item>
                  <List.Item.Meta
                    title={<a>Driver Name</a>}
                    description={item.DriverName}
                  />
                </List.Item>
              </div>
              <div style={{ width: "50%" }}>
                <List.Item>
                  <List.Item.Meta
                    title={<a>DropOff Location</a>}
                    description={item.DropOffLocation}
                  />
                </List.Item>
              </div>
              <div style={{ width: "50%" }}>
                <List.Item>
                  <List.Item.Meta
                    title={<a>Is Trip Completed</a>}
                    description={item.IsTripCompleted ? "Yes" : "No"}
                  />
                </List.Item>
              </div>
              <div style={{ width: "50%" }}>
                <List.Item>
                  <List.Item.Meta
                    title={<a>PickUp Location</a>}
                    description={item.PickUpLocation}
                  />
                </List.Item>
              </div>
            </div>
          )}
        />
      </Modal>
      <Drawer
        width={"30%"}
        title="Tracking Detail"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontWeight: "bolder" }}>Order Num</p>
          <Link to={`/order-bay/grouped-by-order/${trackingDetail?.OrderId}`}>
            {trackingDetail?.OrderNum}
          </Link>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontWeight: "bolder" }}>Status</p>
          <p style={{ color: trackingDetail?.Status?.Color }}>
            {trackingDetail?.Status?.Name}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontWeight: "bolder" }}>Shipped From</p>
          <p>{trackingDetail?.PickUp?.Address}</p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontWeight: "bolder" }}>Shipped To</p>
          <p>{trackingDetail?.DropOff?.Address}</p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontWeight: "bolder" }}>Weight</p>
          <p>{trackingDetail?.Weight}</p>
        </div>
        <hr />
        <p style={{ fontWeight: "bolder" }}>Package Images</p>
        <div>
          {imageCounts(
            trackingDetail?.OrderId,
            trackingDetail?.PackageImageCount
          )}
        </div>
        <hr />
        <p style={{ fontWeight: "bolder" }}>Tracking Details</p>
        <div>
          {trackingDetail?.TrackingInfo?.map((data) => {
            return (
              <Steps direction="vertical" current={1}>
                <Step
                  title={
                    <p>
                      <span style={{ fontWeight: "bold", color: "black" }}>
                        Driver Name:{" "}
                      </span>
                      {data?.DriverName}
                    </p>
                  }
                  description={
                    <>
                      <p>
                        <span style={{ fontWeight: "bold", color: "black" }}>
                          Drop Off Location:{" "}
                        </span>{" "}
                        {data?.DropOffLocation}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold", color: "black" }}>
                          Pick Up Location:
                        </span>{" "}
                        {data?.DropOffLocation}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold", color: "black" }}>
                          Is Trip Completed:
                        </span>{" "}
                        {data?.IsTripCompleted ? <Tag>Yes</Tag> : <Tag>No</Tag>}
                      </p>
                    </>
                  }
                />
              </Steps>
              // <div>
              //   <p>Driver Name: </p>
              //   <p>
              //     Trip Completed
              //     {data?.IsTripCompleted ? <Tag>Yes</Tag> : <Tag>No</Tag>}
              //   </p>
              // </div>
            );
          })}
        </div>
      </Drawer>
      <OrderBayModal
        isModalOpen={isModalVisible}
        data={selectedData}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default Tracking;
