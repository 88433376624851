import { DatePicker, Result, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Paginations from "../../components/Pagination";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { getAuditRefStart, getAuditStart } from "../../redux/AuditRedux/action";
import "./audit.css";

function Audits() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [date, setDate] = useState("");
  const { Option } = Select;
  const dispatch = useDispatch();
  const { audits, auditRef, loading, error } = useSelector(
    (state) => state.audits
  );

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  var queryPage = parseInt(query.get("page"));
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryPage);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      date,
      pageSize,
      type: filter,
    };
    dispatch(getAuditStart(payload));
    dispatch(getAuditRefStart());
  }, [currentPage, pageSize]);

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      date,
      pageSize,
      type: filter,
    };
    dispatch(getAuditStart(payload));
  }, [date]);

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      date,
      pageSize,
      type: filter,
    };
    dispatch(getAuditStart(payload));
  }, [filter]);

  function handleChange(value) {
    setFilter(value);
  }

  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [filter, date]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  function onChange(date, dateString) {
    setDate(dateString);
  }

  const handleSelect = (value) => {
    const intValue = Number(value);
    setPageSize(intValue);
    gridApi.paginationSetPageSize(Number(intValue));
  };

  const columns = [
    {
      title: "Name",
      width: 150,
      ellipsis: true,
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Audit Type",
      width: 220,
      dataIndex: "Reference",
      key: "Reference",
    },
    {
      title: "Event",
      width: 140,
      dataIndex: "Event",
      key: "Event",
    },
    {
      title: "Subject",
      width: 170,
      dataIndex: "Target",
      key: "Target",
    },
    {
      title: "Message",
      width: 150,
      dataIndex: "Message",
      key: "Message",
    },
    {
      title: "Time Stamp",
      width: 200,
      dataIndex: "TimeStamp_",
      key: "TimeStamp_",
    },
  ];

  const onChangeData = (page, pageSize) => {
    navigate(`/audits?page=${page}`);
    setPageSize(pageSize);
    setCurrentPage(page);
  };

  const onClear = () => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
      date: "",
      type: "",
    };
    dispatch(getAuditStart(payload));
  };

  return (
    <div className="employee-container">
      <div className="audit-filter">
        <div className="filter-group">
          <div className="filter-sort">
            <Select
              defaultValue="All Audit"
              onChange={handleChange}
              style={{ width: "50%" }}
              allowClear
              onClear={onClear}
            >
              <Option value="disabled" disabled>
                Filter by Role
              </Option>
              {auditRef?.map((ploy) => {
                return <Option value={ploy.id}>{ploy.description}</Option>;
              })}
            </Select>
            <div style={{ width: "10px" }}></div>
          </div>
          <DatePicker onChange={onChange} />
        </div>
      </div>
      <br />

      {error && (
        <div style={{ width: "100%" }}>
          <Result title={error?.response?.data?.message} />
        </div>
      )}

      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        !error && (
          <div
            style={{
              display: "flex",
              height: "90%",
              // flex: 1,
              width: "100%",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            <Table
              bordered
              size="small"
              dataSource={audits?.Data}
              columns={columns}
              rowKey={(row) => row.BlockId}
              pagination={false}
              //scroll={{ y: 500 }}
            />
            <br />
          </div>
        )
      )}
      <Paginations
        data={audits}
        currentPage={isNaN(currentPage) ? page : currentPage}
        onChangeData={onChangeData}
      />
    </div>
  );
}

export default Audits;
