import React from "react";
import { indexArray } from "../global/functions";
import { IUseFetchDataResult } from "./useFetchData";
import { IUsePaginatedDataResult } from "./usePaginatedData";
import usePagination from "./usePagination";

export function usePaginatedDataFromFetchData<
  T,
  FetchExtra extends Record<string, any>
>(
  fetchData: IUseFetchDataResult<T, FetchExtra>,
  getItemId: (item: T) => string
) {
  const paginatedSearchData = usePagination({ data: fetchData.data || [] });
  const fetchDataMap = React.useMemo(
    () =>
      indexArray(fetchData.data || [], {
        indexer: getItemId,
      }),
    [getItemId, fetchData.data]
  );

  const getFetchItemById = React.useCallback(
    (itemId: string) => {
      return fetchDataMap[itemId] || null;
    },
    [fetchDataMap]
  );

  const getFetchItemPageById = React.useCallback((itemId: string) => {
    // TODO: This is currently 0 cause search data is not server-paginated
    return 0;
  }, []);

  const getPageLoading = React.useCallback(() => {
    return !!fetchData.loading;
  }, [fetchData.loading]);

  const getPageError = React.useCallback(() => {
    return fetchData.error?.message;
  }, [fetchData.error]);

  const result: IUsePaginatedDataResult<T, FetchExtra> = {
    page: paginatedSearchData.page,
    pageSize: paginatedSearchData.pageSize,
    total: paginatedSearchData.total,
    isInitialized: true,
    fetchExtra: fetchData.fetchExtra,
    getPageItems: paginatedSearchData.getPageItems,
    getItemPageById: getFetchItemPageById,
    getItemById: getFetchItemById,
    setFetchExtra: fetchData.setFetchExtra,
    setPageSize: paginatedSearchData.setPageSize,
    onNavigate: paginatedSearchData.onNavigate,
    getPageError: getPageError,
    getPageLoading: getPageLoading,
    reloadPageItems: fetchData.reloadEverything,
    reloadEverything: fetchData.reloadEverything,
    clearData: fetchData.clearData,
  };

  return result;
}
