import * as types from "./movebotActionType"


export const loadMovebotPolicyStart = (payload) => ({
    type: types.LOAD_MOVEBOT_POLICY_START,
    payload
})

export const loadMovebotPolicySuccess = (payload) => ({
    type: types.LOAD_MOVEBOT_POLICY_SUCCESS,
    payload: payload
})

export const loadMovebotPolicyError = (error) => ({
    type: types.LOAD_MOVEBOT_POLICY_ERROR,
    payload: error
})

export const loadMovebotRoleStart = () => ({
    type: types.LOAD_MOVEBOT_ROLES_START,
})

export const loadMovebotRoleSuccess = (payload) => ({
    type: types.LOAD_MOVEBOT_ROLES_SUCCESS,
    payload: payload
})

export const loadMovebotRoleError = (error) => ({
    type: types.LOAD_MOVEBOT_ROLES_ERROR,
    payload: error
})