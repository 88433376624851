import { useRequest } from "ahooks";
import { defaultTo } from "lodash";
import React from "react";
import cast from "../global/functions";

const DEFAULT_DEBOUNCE_INTERVAL = 500;

export interface IUseFetchDataProps<T, FetchExtra extends Record<string, any>> {
  manual?: boolean;
  debounceInterval?: number;
  defaultFetchExtra?: FetchExtra;
  fetch: (input: FetchExtra) => Promise<T[]>;
}

export interface IUseFetchDataResult<
  T,
  FetchExtra extends Record<string, any>
> {
  loading?: boolean;
  error?: Error;
  data?: T[];
  fetchExtra: FetchExtra;
  setFetchExtra: (fetchExtra: FetchExtra, reload?: boolean) => void;
  reloadEverything: () => void;
  clearData: () => void;
}

export default function useFetchData<T, FetchExtra extends Record<string, any>>(
  props: IUseFetchDataProps<T, FetchExtra>
) {
  const { defaultFetchExtra, manual, debounceInterval, fetch } = props;
  const [fetchExtra, setFetchExtra] = React.useState<FetchExtra>(
    cast<FetchExtra>(defaultTo(defaultFetchExtra, {}))
  );

  const getData = React.useCallback(async () => {
    const fetchResult = await fetch(fetchExtra);
    return fetchResult;
  }, [fetchExtra, fetch]);

  const { data, loading, error, run, refresh } = useRequest(getData, {
    manual,
    debounceWait: debounceInterval || DEFAULT_DEBOUNCE_INTERVAL,
  });

  const externalSetFetchExtra = React.useCallback(
    (incomingFetchExtra: FetchExtra, reload = false) => {
      setFetchExtra(incomingFetchExtra);

      if (reload) {
        run();
      }
    },
    [run]
  );

  const result: IUseFetchDataResult<T, FetchExtra> = {
    data,
    loading,
    error,
    fetchExtra,
    reloadEverything: run,
    setFetchExtra: externalSetFetchExtra,
    clearData: refresh,
  };

  return result;
}
