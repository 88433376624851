
export const GET_TRACKING_START = "GET_TRACKING_START"
export const GET_TRACKING_SUCCESS = "GET_TRACKING_SUCCESS"
export const GET_TRACKING_ERROR = "GET_TRACKING_ERROR"


export const GET_TRACKING_SEARCH_START = "GET_TRACKING_SEARCH_START"
export const GET_TRACKING_SEARCH_SUCCESS = "GET_TRACKING_SEARCH_SUCCESS"
export const GET_TRACKING_SEARCH_ERROR = "GET_TRACKING_SEARCH_ERROR"

export const GET_TRACKING_DRIVER_START = "GET_TRACKING_DRIVER_START"
export const GET_TRACKING_DRIVER_SUCCESS = "GET_TRACKING_DRIVER_SUCCESS"
export const GET_TRACKING_DRIVER_ERROR = "GET_TRACKING_DRIVER_ERROR"
