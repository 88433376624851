
export const LOAD_EMPLOYEE_START = "LOAD_EMPLOYEE_START"
export const LOAD_EMPLOYEE_SUCCESS = "LOAD_EMPLOYEE_SUCCESS"
export const LOAD_EMPLOYEE_ERROR = "LOAD_EMPLOYEE_ERROR"


export const CREATE_EMPLOYEE_START = "CREATE_EMPLOYEE_START"
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS"
export const CREATE_EMPLOYEE_ERROR = "CREATE_EMPLOYEE_ERROR"

export const FILTER_EMPLOYEE_START = "FILTER_EMPLOYEE_START"
export const FILTER_EMPLOYEE_SUCCESS = "FILTER_EMPLOYEE_SUCCESS"
export const FILTER_EMPLOYEE_ERROR = "FILTER_EMPLOYEE_ERROR"


export const LOAD_QUALIFY_ADMIN_START = "LOAD_QUALIFY_ADMIN_START"
export const LOAD_QUALIFY_ADMIN_SUCCESS = "LOAD_QUALIFY_ADMIN_SUCCESS"
export const LOAD_QUALIFY_ADMIN_ERROR = "LOAD_QUALIFY_ADMIN_ERROR"