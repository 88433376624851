import {
  BranchesOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import TextField from "@material-ui/core/TextField";
import { PhoneOutlined } from "@material-ui/icons";
import {
  Button,
  Card,
  Input,
  Modal,
  Popover,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { API_URL } from "../../globalVariable";
import {
  loadCountryCodeStart,
  loadCountryStateCityStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import {
  loadBranchStart,
  loadPodStart,
  loadSettingTeamStart,
} from "../../redux/SettingsRedux/settingAction";
import "./settings.css";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teams, branch, pod, loading, error } = useSelector(
    (state) => state.settings
  );
  const { Option } = Select;
  const [countryCodes, setCountryCodes] = useState("Select Country Code");
  const [value, setValue] = useState("email");
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [isSupportModalVisible, setIsSupportModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { countryCode } = useSelector((state) => state.resources);

  const [valueSwitch, setValueSwitch] = useState(false);
  const { Title } = Typography;

  const [addContact, setAddContact] = useState([]);

  useEffect(() => {
    dispatch(loadSettingTeamStart());
    dispatch(loadCountryCodeStart());
    dispatch(loadCountryStateCityStart());
    dispatch(loadBranchStart());
    dispatch(loadPodStart(!valueSwitch));
  }, []);

  const changePaymentInPerson = async (checked) => {
    setLoader(true);
    setValueSwitch(!valueSwitch);

    var response = await fetch(
      API_URL +
        `Settings/PODRule?status=${
          !valueSwitch === undefined ? "" : !valueSwitch
        }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );

    var dataResponse = await response.json();

    if (dataResponse) {
      setLoader(false);
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        dispatch(loadPodStart(!valueSwitch));
      });
    } else {
      if (dataResponse.Message) {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        }).then(() => {
          dispatch(loadPodStart(!valueSwitch));
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        }).then(() => {
          dispatch(loadPodStart(!valueSwitch));
        });
      }
    }
  };

  const handleCancel = () => {
    setIsSupportModalVisible(false);
  };

  const addSupportTeam = async () => {
    setLoader(true);
    var data = {
      contactInfo: value === "email" ? email : phoneNumber,
      countryCode: value === "email" ? "" : countryCodes,
    };

    var response = await fetch(API_URL + "Settings/AddSupportTeamContact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      setLoader(false);
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        handleCancel();
        dispatch(loadSettingTeamStart());
      });
    } else {
      if (dataResponse.Message) {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      }
    }
  };

  const deleteSupportContact = (data) => {
    swal({
      text: "You are about to delete a contact",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var response = await fetch(
          API_URL + `Settings/DelSupportTeamContact?contactId=${data}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        var dataResponse = await response.json();

        if (response.ok) {
          setLoader(false);
          return swal({
            text: dataResponse.message,
            icon: "success",
          }).then(() => {
            dispatch(loadSettingTeamStart());
          });
        } else {
          if (dataResponse.Message) {
            setLoader(false);
            return swal({
              text: dataResponse.Message,
              icon: "warning",
            });
          } else {
            setLoader(false);
            return swal({
              text: dataResponse.message,
              icon: "warning",
            });
          }
        }
      } else {
      }
    });
  };

  const options = [
    { label: "Add with Email", value: "email" },
    { label: "Add with Phone Number", value: "number" },
  ];

  const onValueChange = (e) => {
    setValue(e.target.value);
  };

  function handleCountryCodeChange(value) {
    setCountryCodes(value);
  }

  const [branchId, setBranchId] = useState();

  const [detailBranchOpen, setDetailBranchOpen] = useState(false);
  const [deleteBranchOpen, setDeleteBranchOpen] = useState(false);
  const [branchDetail, setBranchDetail] = useState();
  const [reason, setReason] = useState("");

  const handleModalDetailBranchOpen = (data) => {
    setDetailBranchOpen(true);
    setBranchDetail(data);
  };
  const handleModalDetailBranchClose = () => {
    setDetailBranchOpen(false);
  };

  const handleModalDeleteBranchOpen = (id) => {
    setDeleteBranchOpen(true);
    setBranchId(id);
  };
  const handleModalDeleteBranchClose = () => {
    setDeleteBranchOpen(false);
    setBranchId(null);
  };

  const deleteBranch = async () => {
    setLoader(true);
    var datas = {
      id: branchId,
      reason,
    };
    var response = await fetch(API_URL + `Settings/DelBranchLocation`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(datas),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      setLoader(false);
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        handleModalDeleteBranchClose();
        dispatch(loadBranchStart());
      });
    } else {
      if (dataResponse.Message) {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col xs="12" sm="12" md="6" lg="4">
          <Card
            title={
              <div className="support">
                <p>Support Team Contact</p>
                <Popover
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsSupportModalVisible(true)}
                  title="Click to Add Support"
                >
                  <PlusCircleOutlined />
                </Popover>
              </div>
            }
            bordered={true}
            // style={{ width: '350px' }}
            className="settings-card"
          >
            {loading ? (
              <Spin />
            ) : (
              <div className="support-team">
                {teams?.map((data) => {
                  return (
                    <div className="support-contact">
                      <div>
                        <PhoneOutlined
                          style={{ fontSize: "16px", color: "#08c" }}
                        />
                        <p>{data.ContactInfo}</p>
                      </div>

                      <DeleteOutlined
                        className="support-contact-icon"
                        onClick={() =>
                          deleteSupportContact(data.SupportContactId)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </Card>
        </Col>
        <Col xs="12" sm="12" md="6" lg="4">
          <Card
            title={
              <div className="support">
                <p>Payment Option Notice</p>
                <Switch
                  defaultChecked={!valueSwitch}
                  loading={loading}
                  onChange={() => changePaymentInPerson()}
                />
              </div>
            }
            bordered={true}
            // style={{ width: '350px' }}
            className="settings-card"
          >
            {loading ? (
              <Spin />
            ) : (
              <div className="support-team">
                <p>{pod?.message}</p>
              </div>
            )}
          </Card>
        </Col>
        <Col xs="12" sm="12" md="6" lg="4">
          <Card
            title={
              <div className="support">
                <p>Branch</p>
                <Popover
                  style={{ cursor: "pointer" }}
                  // onClick={() => handleModalBranchOpen()}
                  onClick={() => navigate("/add-branch")}
                  title="Click to Add Branch"
                >
                  <PlusCircleOutlined />
                </Popover>
              </div>
            }
            bordered={true}
            // style={{ width: '350px' }}
            className="settings-card"
          >
            {loading ? (
              <Spin />
            ) : (
              <div className="support-team">
                {branch?.map((data) => {
                  return (
                    <div className="support-contact">
                      <div>
                        <BranchesOutlined
                          style={{ fontSize: "16px", color: "#08c" }}
                        />
                        <p>{data.BranchName}</p>
                      </div>
                      <Space>
                        <Tooltip title="Details">
                          <FileDoneOutlined
                            onClick={() => handleModalDetailBranchOpen(data)}
                          />
                        </Tooltip>
                        <Tooltip title="Edit">
                          <EditOutlined
                            onClick={() =>
                              navigate(`/add-branch`, {state: data })
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <DeleteOutlined
                            className="support-contact-icon"
                            onClick={() => handleModalDeleteBranchOpen(data.Id)}
                          />
                        </Tooltip>
                      </Space>
                    </div>
                  );
                })}
              </div>
            )}
          </Card>
        </Col>
      </Row>

      {/* Modal for Support Staff */}
      <Modal
        title="Add Support Contact"
        visible={isSupportModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <form>
          <div className="employee-form-divs">
            <div>
              <Radio.Group
                options={options}
                onChange={onValueChange}
                value={value}
                optionType="button"
              />
            </div>
            <br />
            {value === "email" ? (
              <div>
                <Input
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            ) : (
              <div className="employee-form-div">
                <Select
                  defaultValue="Select Country Code"
                  style={{ width: "50%" }}
                  onChange={handleCountryCodeChange}
                >
                  <Option value="Select Country Code" disabled>
                    Select Country Code
                  </Option>
                  {countryCode?.map((code) => {
                    return <Option value={code}>{code}</Option>;
                  })}
                </Select>
                <Input
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            )}
          </div>
          <br />
          <div className="form-btn">
            <Button type="primary" id="but" onClick={() => addSupportTeam()}>
              {loader ? <Spin /> : "Add Contact"}
            </Button>
          </div>
        </form>
      </Modal>

      <Modal
        title="Branch Detail"
        visible={detailBranchOpen}
        footer={null}
        onCancel={handleModalDetailBranchClose}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={32}>
            <span className="span-address">Branch Name:</span>{" "}
            <Title className="address" level={5}>
              {branchDetail?.BranchName}
            </Title>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <span className="span-address">Branch Address:</span>{" "}
            <Title className="address" level={5}>
              {branchDetail?.Address_}
            </Title>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <span className="span-address">Updated By:</span>{" "}
            <Title className="address" level={5}>
              {branchDetail?.UpdatedBy}
            </Title>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <span className="span-address">Last Updated:</span>{" "}
            <Title className="address" level={5}>
              {branchDetail?.LastUpdated}
            </Title>
          </Col>
        </Row>
        <p>Branch Contact(s)</p>
        {branchDetail?.BranchPhoneNumbers?.length >= 1 &&
          branchDetail?.BranchPhoneNumbers?.map((data, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "rgb(255, 127, 80)",
                  color: "white",
                  padding: "2%",
                  marginBottom: "5px",
                }}
              >
                <p style={{ margin: 0 }}>{i + 1}</p>
                <p style={{ margin: 0 }}>{data.ContactName}</p>
                <p style={{ margin: 0 }}>{data.PhoneNumber}</p>
              </div>
            );
          })}
      </Modal>

      {/* Modal for deleting branch */}

      <div>
        <Modal
          title="Delete Branch"
          visible={deleteBranchOpen}
          footer={null}
          onCancel={handleModalDeleteBranchClose}
        >
          <form className="policy-form">
            <TextField
              id="outlined-multiline-static"
              label="Reason for Deleting Employee"
              placeholder="Reason for Deleting Employee"
              multiline
              rows={4}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              variant="outlined"
              className="delete-textarea"
            />
            <br />
            <div className="form-btn">
              <Button type="primary" id="but" onClick={() => deleteBranch()}>
                {loader ? <Spin /> : "Delete Employee"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default Settings;
