import * as types from "./actionType"



export const getAuditStart = (payload) => ({
    type: types.GET_AUDIT_START,
    payload
})

export const getAuditSuccess = (audits) => ({
    type: types.GET_AUDIT_SUCCESS,
    payload: audits,
})

export const getAuditError = (error) => ({
    type: types.GET_AUDIT_ERROR,
    payload: error
})



export const getAuditRefStart = () => ({
    type: types.GET_AUDIT_REF_START
})

export const getAuditRefSuccess = (auditRef) => ({
    type: types.GET_AUDIT_REF_SUCCESS,
    payload: auditRef,
})

export const getAuditRefError = (error) => ({
    type: types.GET_AUDIT_REF_ERROR,
    payload: error
})

export const filterAuditByTypeStart = (item) => ({
    type: types.FILTER_AUDIT_BY_TYPE_START,
    payload: item
})
export const filterAuditByTypeSuccess = (item) => ({
    type: types.FILTER_AUDIT_BY_TYPE_SUCCESS,
    payload: item,
})

export const filterAuditByTypeError = (error) => ({
    type: types.FILTER_AUDIT_BY_TYPE_ERROR,
    payload: error
})

export const filterAuditByDateStart = (item) => ({
    type: types.FILTER_AUDIT_BY_DATE_START,
    payload: item
})
export const filterAuditByDateSuccess = (date) => ({
    type: types.FILTER_AUDIT_BY_DATE_SUCCESS,
    payload: date,
})

export const filterAuditByDateError = (error) => ({
    type: types.FILTER_AUDIT_BY_DATE_ERROR,
    payload: error
})