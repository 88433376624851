import React from "react";
import OrderBayAPI, {
  ISearchOrdersEndpointInput,
} from "../../api/endpoints/orderBay";
import {
  IOrder,
  PackageStatus,
  ParcelOrderStatus,
} from "../../definitions/orderBay";
import { appOrderBayPaths } from "../../global/paths";
import { GeneralPageModes } from "../../global/types";
import useFetchData from "../../hooks/useFetchData";
import usePaginatedData from "../../hooks/usePaginatedData";
import { usePaginatedDataFromFetchData } from "../../hooks/usePaginatedDataFromFetchData";
import useSectionCurrentPage from "../../hooks/useSectionCurrentPage";

export interface IOrderBayOrdersPageFetchExtra {
  parcelOrderStatus?: ParcelOrderStatus;
  packageStatus?: PackageStatus;
}

export default function useOrderPageData() {
  // Sets mode for order view
  const [orderPageMode, setOrderPageMode] = React.useState(
    GeneralPageModes.Page
  );

  // Get paginated order data
  const orderPageData = usePaginatedData<IOrder, IOrderBayOrdersPageFetchExtra>(
    {
      fetch: OrderBayAPI.getOrdersPagedByOrder,
      getItemId: (item) => item.OrderId,
    }
  );

  // Search orders
  const orderSearchData = useFetchData<IOrder, ISearchOrdersEndpointInput>({
    fetch: OrderBayAPI.searchItemsOrders,
    manual: true,
  });

  // Convert search data to pagination format
  const orderSearchPaginationData = usePaginatedDataFromFetchData<
    IOrder,
    ISearchOrdersEndpointInput
  >(orderSearchData, (item) => item.OrderId);

  // Controls order section page route
  const orderSectionPage = useSectionCurrentPage({
    baseURL: appOrderBayPaths.groupedByOrder,
    hasSelectedItemPage: true,
    // isComponentReady: orderPageData.isInitialized,
    isComponentReady: true,
  });

  const isOnPagedByOrder = window.location.pathname.includes(
    appOrderBayPaths.groupedByOrder
  );

  const orderPageSetFetchExtra = orderPageData.setFetchExtra;
  const onEnterOrderFilter = React.useCallback(
    (data: IOrderBayOrdersPageFetchExtra) => {
      orderPageSetFetchExtra(data, true);
    },
    [orderPageSetFetchExtra]
  );

  const reloadOrders = orderPageData.reloadEverything;
  const gotoPagedByOrderBase = orderSectionPage.gotoBase;
  const orderPageOnCompleteDeleteOrder = React.useCallback(() => {
    gotoPagedByOrderBase();
    reloadOrders(true);
  }, [reloadOrders, gotoPagedByOrderBase]);

  const reloadOrderPageItems = orderPageData.reloadPageItems;
  const orderPageOnCompleteUpdateOrder = React.useCallback(() => {
    reloadOrderPageItems(orderPageData.page);
  }, [orderPageData.page, reloadOrderPageItems]);

  const orderPageSetSearchExtra = orderSearchPaginationData.setFetchExtra;
  const orderPageClearSearchData = orderSearchPaginationData.clearData;
  const onEnterOrderSearchQuery = React.useCallback(
    (input: string) => {
      orderPageSetSearchExtra({ query: input }, input ? true : false);

      if (input === "" && orderPageMode === GeneralPageModes.Search) {
        setOrderPageMode(GeneralPageModes.Page);
        orderPageClearSearchData();
      } else if (input !== "" && orderPageMode === GeneralPageModes.Page) {
        window.setTimeout(() => {
          setOrderPageMode(GeneralPageModes.Search);
        }, 500);
      }
    },
    [
      orderPageMode,
      orderPageSetSearchExtra,
      setOrderPageMode,
      orderPageClearSearchData,
    ]
  );

  return {
    orderPageMode,
    setOrderPageMode,
    orderPageData,
    orderSearchData,
    orderSearchPaginationData,
    orderSectionPage,
    isOnPagedByOrder,
    orderPageOnCompleteDeleteOrder,
    orderPageOnCompleteUpdateOrder,
    onEnterOrderFilter,
    onEnterOrderSearchQuery,
  };
}
