import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import * as types from "./actionType"
import { 
    getTrackingError, 
    getTrackingSuccess, 
    getTrackingSearchError, 
    getTrackingSearchSuccess, 
    getTrackingDriverError, 
    getTrackingDriverSuccess,
} from "./action"
import { getTrackingApi, getTrackingSearchApi, getTrackingDriverApi } from "../Api"



function* onLoadTrackingSearchStartAsync({payload}) {
    try {
        const response = yield call(getTrackingSearchApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(getTrackingSearchSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getTrackingSearchError(error.response.data.message))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadTrackingDriverStartAsync() {
    try {
        const response = yield call(getTrackingDriverApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(getTrackingDriverSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getTrackingDriverError(error.response.data.message))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}



function* onLoadTrackingSearch() {
    yield takeEvery(types.GET_TRACKING_SEARCH_START, onLoadTrackingSearchStartAsync)
}

function* onLoadTrackingDriver() {
    yield takeEvery(types.GET_TRACKING_DRIVER_START, onLoadTrackingDriverStartAsync)
}



export const trackingSaga = [
   // fork(onLoadTracking),
    fork(onLoadTrackingSearch),
    fork(onLoadTrackingDriver)
]
