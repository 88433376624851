import { UploadOutlined } from "@ant-design/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Badge,
  Button,
  Input,
  Modal,
  Result,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import Paginations from "../../components/Pagination";
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { appDimensions } from "../../components/utils/theme";
import { API_URL } from "../../globalVariable";
import {
  loadTicketPriorityStart,
  loadTicketRelatedServiceStart,
  loadTicketStatusStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import {
  loadTicketByPriorityStart,
  loadTicketByStatusStart,
  loadTicketStart,
} from "../../redux/TicketRedux/ticketAction";
import "./tickets.css";

function Tickets() {
  const [ticketOpen, setTicketOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const { tickets, error, loading } = useSelector((state) => state.tickets);
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [modalPriority, setModalPriority] = useState("");
  const [modalRelated, setModalRelated] = useState("");
  const [customService, setCustomService] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [loader, setLoader] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const navigate = useNavigate();
  const [files, setFiles] = useState();
  const ticketUuid = Cookies.get("attachmentId");
  const [imageArr, setImageArr] = useState([]);
  const [imgMessage, setImgMessage] = useState("");
  const unId = Cookies.get("attachmentId");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  var queryType = parseInt(query.get("page"));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryType);
  const [pageSize, setPageSize] = useState(10);
  const { ticketStatus, ticketPriorities, ticketRelatedServices } = useSelector(
    (state) => state.resources
  );

  const handleCancel = () => {
    setTicketOpen(false);
    Cookies.remove("attachmentId");
    setImgMessage(null);
    setImageArr([]);
    setSubject("");
    setMessage("");
    setModalPriority("");
    setModalRelated("");
    setCustomService("");
  };

  const getTicketImages = (setItems, unId, setImgMessage) => {
    fetch(API_URL + `img/group/GetFileNames/${unId}`, {
      method: "GET",
    })
      .then((res) => {
        if (res.statusText === "Unauthorized") {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");

          return window.location.reload();
        }
        return res.json();
      })
      .then((data) => {
        if (data.message) {
          setImgMessage(data.message);
        } else {
          setItems(data);
        }
        setItems(data);
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(loadTicketStart(payload));
    dispatch(loadTicketStatusStart());
    dispatch(loadTicketPriorityStart());
    dispatch(loadTicketRelatedServiceStart());
  }, [currentPage, pageSize]);

  const prior = status.replace(/\s/g, "");

  useEffect(() => {
    const data = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
      priority,
      status: prior,
    };
    dispatch(loadTicketByPriorityStart(data));
  }, [priority]);

  useEffect(() => {
    const data = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
      priority,
      status: prior,
    };
    dispatch(loadTicketByStatusStart(data));
  }, [status]);

  const { Option } = Select;

  const { Title } = Typography;

  const { TextArea } = Input;

  const handleOpen = () => {
    const unique = uuidv4();
    Cookies.set("attachmentId", unique);

    setTicketOpen(true);
  };

  function handlePriorityChange(value) {
    setPriority(value);
  }

  const submitSearchWithKey = (value, event) => {
    return navigate(`/ticket/search/${searchValue}`);
  };

  function handleStatusChange(value) {
    setStatus(value);
  }

  function handleStatusChange(value) {
    setStatus(value);
  }

  function handlePriorityChange(value) {
    setPriority(value);
  }

  function handleRelatedModalChange(value) {
    setModalRelated(value);
  }

  function handlePriorityModalChange(value) {
    setModalPriority(value);
  }

  const handleImage = async (event) => {
    var file = event.target.files[0];
    setFileLoader(true);

    var fileName = file.name;
    setFiles(file);

    let data = new FormData();
    data.append("image", file, fileName);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
      },
    };
    var response = await fetch(API_URL + `img/group/upload?id=${ticketUuid}`, {
      method: "POST",
      body: data,
    });

    var datas = await response.json();
    if (response.ok) {
      return swal({
        text: datas.message,
        icon: "success",
      }).then(() => {
        setFileLoader(false);
        getTicketImages(setImageArr, unId);
      });
    } else {
      if (datas.message) {
        setFileLoader(false);
        return swal({
          text: datas.message,
          icon: "warning",
        });
      } else {
        setFileLoader(false);
        return swal({
          text: datas.Message,
          icon: "warning",
        });
      }
    }
  };

  const submitTicket = async () => {
    const data = {
      subject,
      message,
      priority: modalPriority,
      relatedService: modalRelated,
      customService,
      attachmentId: ticketUuid,
    };

    setLoader(true);

    var response = await fetch(API_URL + "Ticket/Submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        setLoader(false);
        setTicketOpen(false);
        const payload = {
          page,
          pageSize,
        };
        dispatch(loadTicketStart(payload));

        setImgMessage(null);
        setImageArr([]);
        handleCancel();
      });
    } else {
      setLoader(false);
      if (dataResponse.message) {
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      } else {
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };

  const deleteImage = async (data) => {
    var response = await fetch(
      API_URL + `img/group/del?id=${ticketUuid}&fileName=${data}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // "Authorization": `Bearer ${localStorage.getItem("token")}`
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );

    var data = await response.json();

    if (response.ok) {
      return swal({
        text: data.message,
        icon: "success",
      })
        .then(() => {
          getTicketImages(setImageArr, unId, setImgMessage);
        })
        .then(() => {
          setImgMessage("");
        });
    } else {
      if (data.message) {
        return swal({
          text: data.message,
          icon: "warning",
        });
      } else {
        return swal({
          text: data.Message,
          icon: "warning",
        });
      }
    }
  };

  const columns = [
    {
      title: "Status",
      width: 150,
      ellipsis: true,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return (
          <NodeWithTip tip={record?.Status?.Description}>
            <Tag
              // style={{ color: record.Priority.Color }}
              color={record?.Status?.Color}
            >
              {record?.Status?.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Ticket Number",
      width: 220,
      render(text, record) {
        return (
          <Link to={`/tickets/${record?.TicketId}`} state={record}>
            {record?.TicketNumber}
          </Link>
        );
      },
    },
    {
      title: "Priority",
      width: 170,
      dataIndex: "Priority",
      key: "Priority",
      render(text, record) {
        return (
          <Tag color={record?.Priority?.Color}>{record?.Priority?.Name}</Tag>
        );
      },
    },
    {
      title: "Subject",
      width: 340,
      dataIndex: "Subject",
      key: "Subject",
    },
    {
      title: "Requester Name",
      width: 150,
      dataIndex: "RequesterName",
      key: "RequesterName",
    },
    {
      title: "Date Created",
      width: 200,
      dataIndex: "DateCreated",
      key: "DateCreated",
      render(text, record) {
        return <p>{record?.DateCreated_}</p>;
      },
    },
    {
      title: "Last Updated",
      width: 200,
      dataIndex: "LastUpdated",
      key: "LastUpdated",
    },
  ];

  const onChangeData = (page, pageSize) => {
    navigate(`/tickets?page=${page}`);
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="tickets-actions">
        <div className="tickets-actions-div">
          <Input.Search
            allowClear
            // disabled={disabled}
            placeholder="Enter search text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(value, event) => submitSearchWithKey(value, event)}
            style={{ width: appDimensions.searchInputWidth }}
          />
          <div style={{ width: "10px" }}></div>
          <Select
            defaultValue="FilterBy Status"
            className="filt"
            style={{ padding: "10px 0px 10px 10px", width: "50%" }}
            onChange={handleStatusChange}
          >
            <Option value="disabled" disabled>
              FilterBy Status
            </Option>
            <Option value="">All</Option>
            <Option value="New">New</Option>
            <Option value="In Progress">In Progress</Option>
            <Option value="Resolved">Resolved</Option>
          </Select>
          <div style={{ width: "10px" }}></div>
          <Select
            defaultValue="FilterBy Priority"
            style={{ padding: "10px 0px 10px 10px", width: "50%" }}
            onChange={handlePriorityChange}
          >
            <Option value="disabled" disabled>
              FilterBy Priority
            </Option>
            <Option value="">All</Option>
            {ticketPriorities?.map((data) => {
              return (
                <Option key={data?.id} value={data?.name}>
                  {data?.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <Button
          type="primary"
          style={{ background: "#FF7F50", borderColor: "#FF7F50" }}
          onClick={() => handleOpen()}
        >
          Create Support Ticket
        </Button>
      </div>
      <br />
      {error !== null && <Result title={error?.response?.data?.message} />}
      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        tickets?.Data?.length >= 1 &&
        error === null && (
          <div
            style={{
              display: "flex",
              height: "90%",
              // flex: 1,
              width: "100%",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            <Table
              bordered
              size="small"
              dataSource={tickets?.Data}
              columns={columns}
              rowKey={(row) => row.BlockId}
              pagination={false}
              //scroll={{ y: 450 }}
            />
          </div>
        )
      )}

      <br />
      <Paginations
        data={tickets}
        currentPage={isNaN(currentPage) ? page : currentPage}
        onChangeData={onChangeData}
      />

      {/* Modal for Creating Ticket */}
      <Modal
        title="Create Ticket"
        visible={ticketOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <form>
          <div>
            <Title level={5}>Ticket Subject</Title>
            <Input
              placeholder="Ticket  Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              type="text"
            />
          </div>
          <br />
          <div className="employee-form-div">
            <Select
              defaultValue="Select Related Service"
              className="half-width"
              style={{ width: "49%" }}
              onChange={handleRelatedModalChange}
            >
              <Option value="disabled" disabled>
                Select Related Service
              </Option>
              {ticketRelatedServices?.map((data) => {
                return (
                  <Option key={data} value={data.id}>
                    {data.name}
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue="Select Priority"
              style={{ width: "49%" }}
              className="half-width"
              onChange={handlePriorityModalChange}
            >
              <Option value="disabled" disabled>
                Select Priority
              </Option>
              {ticketPriorities?.map((data) => {
                return (
                  <Option key={data?.id} value={data?.name}>
                    {data?.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          {modalRelated === "Others" ? (
            <div>
              <br />
              <Title level={5}>Custom Service</Title>
              <Input
                placeholder="Custom Service"
                value={customService}
                onChange={(e) => setCustomService(e.target.value)}
                type="text"
              />
            </div>
          ) : null}
          <br />
          <ReactQuill
            modules={Tickets.modules}
            formats={Tickets.formats}
            style={{ height: "150px" }}
            placeholder="Ticket Message..."
            value={message}
            onChange={(e) => setMessage(e)}
          />
          <br />
          <br />
          <br />
          <div>
            <div class="upload-btn-wrapper pp">
              {fileLoader ? (
                "File Upload In progress.."
              ) : (
                <Badge count={imageArr?.length}>
                  <>
                    <label>Attachment </label>
                    <UploadOutlined />
                    <input type="file" name="myfile" onChange={handleImage} />
                  </>
                </Badge>
              )}
            </div>
          </div>
          <br />
          <div>
            {imageArr?.length >= 1 &&
              imageArr?.map((data) => {
                return (
                  <div
                    className="arr-img"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>{data}</p>
                    <DeleteIcon
                      style={{ color: "red" }}
                      onClick={() => deleteImage(data)}
                    />
                  </div>
                );
              })}
          </div>
          <br />
          <div className="form-btn">
            <Button type="primary" id="but" onClick={() => submitTicket()}>
              {loader ? <Spin /> : "Create Ticket"}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

Tickets.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"],
    ["code-block"],
  ],
};

Tickets.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "code-block",
];

export default Tickets;
