import { Col, Divider, Row, Space, Typography } from "antd";
import React from "react";
import { IOrderBlock } from "../../definitions/orderBlock";
import OrdersPagedByOrderTable from "../OrderBayPage/order/OrdersPagedByOrderTable";
import OrderBlockActionsButton from "./OrderBlockActionsButton";
import OrderBlockDeliveryDetails from "./OrderBlockDeliveryDetails";
import OrderBlockDetails from "./OrderBlockDetails";
import OrderBlockDriverDetailsContainer from "./OrderBlockDriverDetailsContainer";
import OrderBlockPickupDetails from "./OrderBlockPickupDetails";
import { isOrderBlockPublished, isPublicOrderBlock } from "./utils";

export interface IOrderBlockProps {
  block: IOrderBlock;
  onCompleteUpdate: () => void;
  onCompleteDelete: () => void;
  onSelectUpdateBlock: (blockId: string) => void;
}

const OrderBlock: React.FC<IOrderBlockProps> = (props) => {
  const { block, onCompleteDelete, onCompleteUpdate, onSelectUpdateBlock } =
    props;

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Row>
        <Col span={18}>
          <Typography.Title level={5}>{block.BlockNumber}</Typography.Title>
        </Col>
        <Col span={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <OrderBlockActionsButton
              block={block}
              isPublicBlock={isPublicOrderBlock(block)}
              isPublished={isOrderBlockPublished(block)}
              onCompleteDelete={onCompleteDelete}
              onCompleteUpdate={onCompleteUpdate}
              onSelectUpdateBlock={() => onSelectUpdateBlock(block.BlockId)}
            />
          </div>
        </Col>
      </Row>
      <Divider orientation="left">Block Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderBlockDetails block={block} />
      </div>
      <Divider orientation="left">Driver Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderBlockDriverDetailsContainer block={block} />
      </div>
      <Divider orientation="left">Pickup</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderBlockPickupDetails block={block} />
      </div>
      <Divider orientation="left">Delivery</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderBlockDeliveryDetails block={block} />
      </div>
      <Divider orientation="left">Assigned Orders</Divider>
      <div
        style={{
          width: "100%",
          overflow: "auto",
          marginBottom: "32px",
        }}
      >
        <OrdersPagedByOrderTable
          omitActions
          data={block.AssignedOrders || []}
          onCompleteDelete={onCompleteDelete}
          onCompleteUpdate={onCompleteUpdate}
        />
      </div>
    </Space>
  );
};

export default OrderBlock;
