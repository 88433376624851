import React from "react";
import { Typography, Tag } from "antd";
import { IOrder } from "../../../definitions/orderBay";
import { css } from "@emotion/css";
import OrderItemActionsButton from "./OrderItemActionsButton";

export interface IOrderItemHeaderProps {
  orderItem: IOrder;
  onCompleteUpdate: () => void;
  onCompleteDelete: () => void;
}

const classes = {
  root: css({
    display: "flex",
  }),
  left: css({
    flex: 1,
    marginRight: "32px",
  }),
  button: css({
    fontSize: "24px",
  }),
};

const OrderItemHeader: React.FC<IOrderItemHeaderProps> = (props) => {
  const { orderItem, onCompleteDelete, onCompleteUpdate } = props;

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography.Title level={4}>{orderItem.OrderName}</Typography.Title>
        <Typography.Text
          type="secondary"
          style={{ fontSize: "16px", display: "block" }}
        >
          {orderItem.OrderItemNumber}
        </Typography.Text> 
              <Tag style={{ color: orderItem.Status.Color }}>
                {orderItem.Status.Name}
              </Tag>
              <Typography.Text type="secondary">
                {orderItem.Status.Description}
              </Typography.Text> 
      </div>
      <div>
        <OrderItemActionsButton
          orderId={orderItem.OrderId}
          buttonClassName={classes.button}
          onCompleteDelete={onCompleteDelete}
          onCompleteUpdate={onCompleteUpdate}
        />
      </div>
    </div>
  );
};

export default OrderItemHeader;
