import * as types from "./actionType";

const initialState = {
  statistics: [],
  topBlocks: [],
  topOrders: [],
  loading: false,
  error: null,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATISTICS_START:
    case types.GET_TOP_BLOCKS_START:
    case types.GET_TOP_ORDERS_START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: action.payload,
      };
    case types.GET_TOP_BLOCKS_SUCCESS:
      return {
        ...state,
        loading: false,
        topBlocks: action.payload,
      };
    case types.GET_TOP_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        topOrders: action.payload,
      };
    case types.GET_STATISTICS_ERROR:
    case types.GET_TOP_BLOCKS_ERROR:
    case types.GET_TOP_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
