import { css } from "@emotion/css";
import { Button, Checkbox, Form } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import PersonFormInput, {
  personInitialValues,
  personValidationSchema,
} from "../../../components/form/PersonFormInput";
import { IParcelSenderInfoInput } from "../../../definitions/orderBay";

const defaultValues = {
  ...personInitialValues,
  isParcelLikelyToBePOD: false,
};

const validationSchema = yup.object().shape({
  ...personValidationSchema,
  isParcelLikelyToBePOD: yup.boolean(),
});

const classes = {
  root: css({
    width: "100%",
    maxWidth: "520px",
    margin: "auto",
  }),
};

export interface IOrderBaySenderInfoFormProps {
  loading?: boolean;
  senderInfo?: IParcelSenderInfoInput;
  onNext: (senderInfo: IParcelSenderInfoInput) => void;
}

const OrderBaySenderInfoForm: React.FC<IOrderBaySenderInfoFormProps> = (
  props
) => {
  const { loading, senderInfo, onNext } = props;
  const formik = useFormik({
    validationSchema,
    initialValues: senderInfo || defaultValues,
    onSubmit: onNext,
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classes.root}>
      <PersonFormInput basePath="" formik={formik} loading={loading} />
      <Form.Item>
        <Checkbox
          value={formik.values.isParcelLikelyToBePOD}
          onChange={formik.handleChange}
          name={"isParcelLikelyToBePOD"}
        >
          Is Parcel Likely To Be Payment on Delivery
        </Checkbox>
      </Form.Item>
      <Form.Item style={{ marginTop: "32px" }}>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </form>
  );
};

export default OrderBaySenderInfoForm;
