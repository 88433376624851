import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import Cookies from "js-cookie";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import LabeledNode from "../../components/utils/LabeledNode";
import { API_URL } from "../../globalVariable";
import { getPaymentStart } from "../../redux/PaymentRedux/action";

function PaymentDetail() {
  const location = useLocation();
  const paymentData = location.state;
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getPaymentStart());
  }, []);

  const makeGroupPayment = async () => {
    try {
     var response =  await fetch(
        API_URL + `Payment/GroupPayment?driverId=${paymentData?.DriverId}`,
        {
          method: "post",
          headers: {
            authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      )
        var dataResponse = await response.json();
        if (response.ok) {
          return swal({
            text: dataResponse.message,
            icon: "success",
          })
        } else {
          if (dataResponse.Message) {
            return swal({
              text: dataResponse.Message,
              icon: "warning",
            });
          } else {
           return swal({
              text: dataResponse.message,
              icon: "warning",
            });
          }
        }



    } catch (e) {
      console.log({ e });
    }
  };

  const menuOptionsNode = () => (
    <Menu>
      <Menu.Item onClick={() => makeGroupPayment()}>
        Make Group Payment
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row>
          <Col span={18}>
            <Typography.Title level={5}>
              <Link to={`/order-block/${paymentData?.BlockId}`}>
                {paymentData?.BlockNum}
              </Link>
            </Typography.Title>
          </Col>
          <Col span={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown arrow overlay={menuOptionsNode(paymentData?.BlockId)}>
                <Button
                  icon={<EllipsisOutlined />}
                  // className={buttonClassName}
                ></Button>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <Divider orientation="left">Driver History</Divider>
        <div style={{ maxWidth: "900px", margin: "auto" }}>
          <Row wrap gutter={[16, 32]}>
            <Col span={12}>
              <LabeledNode
                direction="vertical"
                label="Driver Name"
                node={
                  <Space>
                    <Avatar
                      alt="Profile picture of the last person who updated this block"
                      shape="circle"
                      src={
                        API_URL + `img/default/${paymentData?.DriverId}/30/50/p`
                      }
                      icon={<UserOutlined />}
                    />
                    <Typography.Text type="secondary">
                      {paymentData?.DriverName}
                    </Typography.Text>
                  </Space>
                }
              />
            </Col>
            <Col span={12}>
              <LabeledNode
                direction="vertical"
                label="Date Created"
                node={paymentData?.DateCreated_}
              />
            </Col>
          </Row>
        </div>

        <Divider orientation="left">Trip History</Divider>
        <div style={{ maxWidth: "900px", margin: "auto" }}>
          <Row wrap gutter={[16, 32]}>
            <Col span={24}>
              <LabeledNode
                direction="vertical"
                label="Trip Earning"
                node={paymentData?.TripEarning}
              />
            </Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={12}>
              <LabeledNode
                direction="vertical"
                label="Trip Payment Acknowledged At"
                node={paymentData?.TripPaymentAcknowledgedAt}
              />
            </Col>
            <Col span={12}>
              <LabeledNode
                direction="vertical"
                label="Trip Payment Acknowledged By"
                node={paymentData?.TripPaymentAcknowledgedBy}
              />
            </Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={12}>
              <LabeledNode
                direction="vertical"
                label="Trip As Been Paid"
                node={<Tag>{paymentData?.TripAsBeenPaid_}</Tag>}
              />
            </Col>
            <Col span={12}>
              <LabeledNode
                direction="vertical"
                label="Trip Ended At"
                node={paymentData?.TripEndedAt}
              />
            </Col>
          </Row>
        </div>
      </Space>
    </Fragment>
  );
}

export default PaymentDetail;
