import React, { useEffect } from "react";
import { Input, Select, Form, InputNumber, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/css";
import {
  loadPackageSizeStart,
  loadWeightUnitStart,
  loadOrderCategoryStart,
} from "../../../redux/ResourcesRedux/resourcesAction";
import FormItemError from "../../../components/utils/FormItemError";
import { FormikProps } from "formik";
import { IOrderInput } from "../../../definitions/orderBay";
import { IReduxState, IReduxStateResources } from "../../../redux/types";

export const OTHERS_CATEGORY = "Others";

const classes = {
  packageImage: css({
    width: "100%",
    height: "100%",
    maxWidth: "150px",
    maxHeight: "100px",
  }),
  packageSizeImgWrapper: css({
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    height: "100%",
  }),
};

export interface IOrderItemPackageDetailsInputProps {
  loading?: boolean;
  formik: FormikProps<Omit<IOrderInput, "recipientInfo">>;
}

const OrderItemPackageDetailsInput: React.FC<
  IOrderItemPackageDetailsInputProps
> = (props) => {
  const { loading, formik } = props;
  const dispatch = useDispatch();

  const orderItemError = formik.errors;
  const orderItemTouched = formik.touched;

  const { orderCategories, packageSizes, weightUnit } = useSelector<
    IReduxState,
    IReduxStateResources
  >((state) => state.resources);

  useEffect(() => {
    dispatch(loadOrderCategoryStart());
    dispatch(loadPackageSizeStart());
    dispatch(loadWeightUnitStart());
  }, [dispatch]);

  const selectedPackageInfo = packageSizes?.find((data) => {
    return data.id === formik.values.packageEstimatedSize;
  });

  const nameNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Package Name"
      help={
        orderItemTouched.packageName && (
          <FormItemError message={orderItemError.packageName} />
        )
      }
    >
      <Input
        placeholder="Package Name"
        value={formik.values.packageName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="packageName"
        disabled={loading}
      />
    </Form.Item>
  );

  const descriptionNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Description"
      help={
        orderItemTouched.description && (
          <FormItemError message={orderItemError.description} />
        )
      }
    >
      <Input.TextArea
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="description"
        placeholder="Description"
        autoSize={{ minRows: 2 }}
        disabled={loading}
      />
    </Form.Item>
  );

  const categoryNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Category"
      help={
        orderItemTouched.category && (
          <FormItemError message={orderItemError.category} />
        )
      }
    >
      <Select
        placeholder="Select Category"
        value={formik.values.category}
        onChange={(category) => formik.setFieldValue("category", category)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {orderCategories?.map((code) => {
          return <Select.Option value={code}>{code}</Select.Option>;
        })}
      </Select>
    </Form.Item>
  );

  const otherCategoryNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Other Category"
      help={
        orderItemTouched.otherCategory && (
          <FormItemError message={orderItemError.otherCategory} />
        )
      }
    >
      <Input
        placeholder="Other Category"
        value={formik.values.otherCategory}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="otherCategory"
        disabled={loading}
      />
    </Form.Item>
  );

  const estimatedWeightNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Estimated Weight"
      help={
        orderItemTouched.estimatedWeight && (
          <FormItemError message={orderItemError.estimatedWeight} />
        )
      }
    >
      <InputNumber
        placeholder="Estimated Weight"
        value={formik.values.estimatedWeight}
        onChange={(unit) => formik.setFieldValue("estimatedWeight", unit)}
        onBlur={formik.handleBlur}
        style={{ width: "100%" }}
        disabled={loading}
      />
    </Form.Item>
  );

  const estimatedWeightUnitNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Estimated Weight Unit"
      help={
        orderItemTouched.estimatedWeightUnit && (
          <FormItemError message={orderItemError.estimatedWeightUnit} />
        )
      }
    >
      <Select
        placeholder="Estimated Weight Unit"
        value={formik.values.estimatedWeightUnit}
        onChange={(unit) => formik.setFieldValue("estimatedWeightUnit", unit)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {weightUnit?.map((code) => {
          return (
            <Select.Option value={code.id}>
              {code.name} {code.description}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const packageEstimatedSizeNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Package Estimated Size"
      help={
        orderItemTouched.packageEstimatedSize && (
          <FormItemError message={orderItemError.packageEstimatedSize} />
        )
      }
    >
      <Select
        placeholder="Package Estimated Size"
        value={formik.values.packageEstimatedSize}
        onChange={(size) => formik.setFieldValue("packageEstimatedSize", size)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {packageSizes?.map((size) => {
          return (
            <Select.Option value={size.id}>{size.packageSize_}</Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const packageImageNode = (
    <div className={classes.packageSizeImgWrapper}>
      <img
        className={classes.packageImage}
        src={selectedPackageInfo?.imgLink}
        alt={selectedPackageInfo?.id}
      />
    </div>
  );

  return (
    <div>
      {nameNode}
      {descriptionNode}
      <Row gutter={16}>
        <Col span={formik.values.category === OTHERS_CATEGORY ? 12 : 24}>
          {categoryNode}
        </Col>
        {formik.values.category === OTHERS_CATEGORY && (
          <Col span={12}>{otherCategoryNode}</Col>
        )}
      </Row>
      <Row wrap gutter={16}>
        <Col span={12}>{estimatedWeightNode}</Col>
        <Col span={12}>{estimatedWeightUnitNode}</Col>
        <Col span={16}>{packageEstimatedSizeNode}</Col>
        <Col span={8}>{packageImageNode}</Col>
      </Row>
    </div>
  );
};

export default OrderItemPackageDetailsInput;
