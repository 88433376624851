import { IBranch } from "../../definitions/settings";
import { API_URL } from "../../globalVariable";
import { invokeEndpoint } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";

const resourcesURL = `${API_URL}Settings`;
const getOrderFormBranchLocationsURL = `${resourcesURL}/GetOrderFormBranchLocations`;

export interface IGetOrderFormBranchLocationsEndpointInput {
  orgId: string;
}

export type IGetOrderFormBranchLocationsEndpointResult = IBranch[];

async function getOrderFormBranchLocations(
  props: IGetOrderFormBranchLocationsEndpointInput
): Promise<IGetOrderFormBranchLocationsEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  const endpointURL = selectEndpointParam(
    getOrderFormBranchLocationsURL,
    params,
    props
  );
  const result =
    await invokeEndpoint<IGetOrderFormBranchLocationsEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export default class SettingsAPI {
  public static getOrderFormBranchLocations = getOrderFormBranchLocations;
}
