export interface IPaginatedDataResult<T> {
  CurrentPageSize: number;
  TotalPages: number;
  CurrentPage: number;
  TotalSize: number;
  Data: T[];
}

export interface IPaginatedDataInput {
  pageNumber?: number;
  pageSize?: number;
}

export interface IEnum {
  Id: string;
  Name: string;
  Description: string;
  Index: number;
  Color: string;
}

export interface ILowercasedEnum {
  id: string;
  name: string;
  description: string;
  index: number;
  color: string;
}

export interface IAddressInput {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface IAddress {
  Street: string;
  City: string;
  State: string;
  ZipCode: string;
  Country: string;
}

export interface IIdWithReasonInput {
  id: string;
  reason: string;
}

export interface IPersonInput {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  address: IAddressInput;
}

export interface IPackageSize {
  id: string;
  packageSize_: string;
  imgLink: string;
}

export enum GeneralPageModes {
  Page = "page",
  Search = "search",
}
