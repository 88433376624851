import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import CompanyProfile from "./components/CompanyProfile";
import LayOut from "./components/layout";
import OrderSearch from "./components/OrderSearch";
import Profile from "./components/Profile";
import TicketSearch from "./components/TicketSearch";
import UsersList from "./components/UsersList";
import { appLoggedInPaths } from "./global/paths";
import Audits from "./screens/Audits";
import CreateBranch from "./screens/CreateBranch";
import CreateEmployee from "./screens/CreateEmployee";
import Dashboard from "./screens/dashboard";
import DefectDetails from "./screens/DefectDetails";
import Defects from "./screens/Defects";
import EmployeeDetail from "./screens/EmployeeDetail";
import ForgetPasswordPage from "./screens/ForgetPassword";
import LoginPage from "./screens/Login";
import OrderBayCustomerPage from "./screens/OrderBayPage/OrderBayCustomerPage";
import OrderBayPageRoot from "./screens/OrderBayPage/OrderBayPageRoot";
import OrderBlockPageRoot from "./screens/OrderBlock/OrderBlockPageRoot";
import Payment from "./screens/Payment";
import PaymentDetail from "./screens/PaymentDetail";
import Policy from "./screens/PolicyPage";
import Review from "./screens/Review";
import Settings from "./screens/Settings";
import SignupPage from "./screens/Signup";
import TicketDetails from "./screens/TicketDetails";
import Tickets from "./screens/Tickets";
import Tracking from "./screens/tracking";

function App(props) {
  const loggedIn = Cookies.get("accessToken");
  const customerOrderPage = (
    <Route exact path="/customer/:orgId" element={<OrderBayCustomerPage />} />
  );

  if (!loggedIn) {
    return (
      <Router>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<SignupPage />} />
          <Route
            exact
            path="/forget-password"
            element={<ForgetPasswordPage />}
          />
          <Route path="*" element={<Navigate to="/login" />} />
          {customerOrderPage}
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/dashboard" />}></Route>
        <Route
          exact
          path="/dashboard"
          element={
            <LayOut>
              <Dashboard />
            </LayOut>
          }
        />

        <Route
          exact
          path="/defects"
          element={
            <LayOut>
              <Defects />
            </LayOut>
          }
        />

        <Route
          exact
          path="/tracking"
          element={
            <LayOut>
              <Tracking />
            </LayOut>
          }
        />

        <Route
          exact
          path="/reviews"
          element={
            <LayOut>
              <Review />
            </LayOut>
          }
        />

        <Route
          path={`${appLoggedInPaths.orderBay}/*`}
          element={
            <LayOut>
              <OrderBayPageRoot />
            </LayOut>
          }
        />

        <Route
          path={`${appLoggedInPaths.orderBlock}/*`}
          element={
            <LayOut>
              <OrderBlockPageRoot />
            </LayOut>
          }
        />
        <Route
          exact
          path="/defects/:id"
          element={
            <LayOut>
              <DefectDetails />
            </LayOut>
          }
        />
        <Route
          exact
          path="/employee/:id"
          element={
            <LayOut>
              <EmployeeDetail />
            </LayOut>
          }
        />
        <Route
          exact
          path="/create-employee"
          element={
            <LayOut>
              <CreateEmployee />
            </LayOut>
          }
        />
        <Route
          exact
          path="/add-branch"
          element={
            <LayOut>
              <CreateBranch />
            </LayOut>
          }
        />
        <Route
          exact
          path="/payment/:id"
          element={
            <LayOut>
              <PaymentDetail />
            </LayOut>
          }
        />
        <Route
          exact
          path="/tickets"
          element={
            <LayOut>
              <Tickets />
            </LayOut>
          }
        />
        <Route
          exact
          path="/payment"
          element={
            <LayOut>
              <Payment />
            </LayOut>
          }
        />

        <Route
          exact
          path="/audits"
          element={
            <LayOut>
              <Audits />
            </LayOut>
          }
        />
        <Route
          exact
          path="/employee"
          element={
            <LayOut>
              <UsersList />
            </LayOut>
          }
        />
        <Route
          exact
          path="/order/search/:search"
          element={
            <LayOut>
              <OrderSearch />
            </LayOut>
          }
        />
        <Route
          exact
          path="/policy"
          element={
            <LayOut>
              <Policy />
            </LayOut>
          }
        />
        <Route
          exact
          path="/tickets/:id"
          element={
            <LayOut>
              <TicketDetails />
            </LayOut>
          }
        />
        <Route
          exact
          path="/ticket/search/:search"
          element={
            <LayOut>
              <TicketSearch />
            </LayOut>
          }
        />
        <Route
          exact
          path="/companyProfile"
          element={
            <LayOut>
              <CompanyProfile />
            </LayOut>
          }
        />
        <Route
          exact
          path="/userProfile"
          element={
            <LayOut>
              <Profile />
            </LayOut>
          }
        />
        <Route
          exact
          path="/settings"
          element={
            <LayOut>
              <Settings />
            </LayOut>
          }
        />
        {customerOrderPage}
      </Routes>
    </Router>
  );
}

export default App;
