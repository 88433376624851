import React from "react";
import { Select, Space, Typography } from "antd";
import { IBranch } from "../../definitions/settings";
import SelectedBranch from "./SelectedBranch";
import { filterOptionWithLabel, filterSortWithLabel } from "../form/utils";

export interface IBranchSelectInputProps {
  branches: IBranch[];
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLElement>;
}

const BranchSelectInput: React.FC<IBranchSelectInputProps> = (props) => {
  const { branches, value, placeholder, disabled, onChange, onBlur } = props;
  const selectedBranch = React.useMemo(() => {
    return branches.find((branch) => branch.Id === value);
  }, [branches, value]);

  return (
    <Space direction="vertical" size={"middle"} style={{ width: "100%" }}>
      <Select
        showSearch
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        optionLabelProp="label"
        optionFilterProp="label"
        filterOption={filterOptionWithLabel}
        filterSort={filterSortWithLabel}
      >
        {branches.map((data) => {
          return (
            <Select.Option
              key={data.Id}
              value={data.Id}
              label={data.BranchName}
            >
              <Space direction="vertical" size={4}>
                <Typography.Text>{data.BranchName}</Typography.Text>
                <Typography.Text type="secondary">
                  {data.Address_}
                </Typography.Text>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
      {selectedBranch && <SelectedBranch selectedBranch={selectedBranch} />}
    </Space>
  );
};

export default BranchSelectInput;
