import * as types from "./actionType";

const initialState = {
  payments: [],
  driverFilter: [],
  loading: false,
  error: null,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAYMENT_START:
    case types.GET_DRIVER_FILTER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payments: action.payload,
        error: null,
      };
    case types.GET_DRIVER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        driverFilter: action.payload,
        error: null,
      };
    case types.GET_PAYMENT_ERROR:
    case types.GET_DRIVER_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
