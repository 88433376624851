import { EllipsisOutlined } from "@ant-design/icons";
import TextField from "@material-ui/core/TextField";
import BackupIcon from "@material-ui/icons/Backup";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import LabeledNode from "../../components/utils/LabeledNode";
import { API_URL } from "../../globalVariable";
import { loadQualifyAdminStart } from "../../redux/EmployeeRedux/employeeAction";
import { loadCompanyProfileStart } from "../../redux/ProfileRedux/profileAction";
import {
  loadCountryCodeStart,
  loadCountryStateCityStart,
  loadCountryStateStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import "../Profile/profile.css";

function CompanyProfile() {
  const dispatch = useDispatch();
  const { companyProfile, loading } = useSelector((state) => state.profiles);
  const { qualifyAdmins } = useSelector((state) => state.employees);
  const { countryStateCities, getStates } = useSelector(
    (state) => state.resources
  );

  const [cac, setCac] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAcronym, setCompanyAcronym] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [qualifyAdminState, setQualifyAdminState] = useState();
  const [qualifyAdminModal, setQualifyAdminModal] = useState(false);
  const [userCompanyUpdateOpen, setUserCompanyUpdateOpen] = useState(false);
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const [getCities, setGetCities] = useState();

  useEffect(() => {
    dispatch(loadCompanyProfileStart());
    dispatch(loadQualifyAdminStart());
    dispatch(loadCountryStateCityStart());
    dispatch(loadCountryCodeStart());
  }, []);

  useEffect(() => {
    dispatch(loadCountryStateStart(country));
  }, [country]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          API_URL +
            `Resources/GetCountryStateCities?country=${country}&state=${state}`,
          {
            headers: {
              authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        ) 
        .then((data) => setGetCities(data.data));
    };
    fetchData();
  }, [state]);

  var imageFile = () => {
    return API_URL + `img/default/${companyProfile?.CompanyId}/400/400/p`;
  };

  useEffect(() => {
    imageFile();
  }, []);

  if (loading) {
    return <Spin />;
  }

  const handleUserCompanyModalOpen = () => {
    setUserCompanyUpdateOpen(true);
    setCompanyName(companyProfile?.Name);
    setCac(companyProfile?.CAC_Number);
    setCompanyAcronym(companyProfile?.CompanyAcronym);
    setDescription(companyProfile?.Description);
    setStreet(companyProfile?.RawAddress?.Street);
    setCountry(companyProfile?.RawAddress?.Country);
    setZipCode(companyProfile?.RawAddress?.ZipCode);
    setState(companyProfile?.RawAddress?.State);
    setCity(companyProfile?.RawAddress?.City);
    setWebsite(companyProfile?.WebsiteUrl);
  };

  const handleUserCompanyModalClose = () => {
    setUserCompanyUpdateOpen(false);
    setCompanyName("");
    setCac("");
    setCompanyAcronym("");
    setDescription("");
    setStreet("");
    setCountry("");
    setZipCode("");
    setState("");
    setCity("");
    setWebsite("");
  };

  const { Option } = Select;

  function handleCountryChange(value) {
    setCountry(value);
  }
  function handleStateChange(value) {
    setState(value);
  }
  function handleCityChange(value) {
    setCity(value);
  }

  const updateCompanyProfile = async () => {
    const data = {
      name: companyName,
      description,
      caC_Number: cac,
      companyAcronym,
      WebsiteUrl: website,
      primaryAddress: {
        street,
        city,
        state,
        zipCode,
        country,
      },
    };
    setLoader(true);
    var response = await fetch(API_URL + `Profile/UpdateCompanyProfile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json", 
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      setLoader(false);
      return swal({
        text: dataResponse.Message.toLowerCase(),
        icon: "success",
      }).then(() => {
        setLoader(false);
        dispatch(loadCompanyProfileStart());
        handleUserCompanyModalClose();
      });
    } else {
      if (dataResponse.message) {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };
  const updateAdmin = async () => {
    setLoader(true);
    var response = await fetch(
      API_URL +
        `Profile/UpdateCompanyPrimaryAdmin?newPrimaryAdminId=${qualifyAdminState}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json", 
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );

    var data = await response.json();

    if (response.ok) {
      setLoader(false);
      return swal({
        text: data.Message,
        icon: "success",
      }).then(() => {
        return dispatch(loadCompanyProfileStart());
      });
    } else {
      if (data.message) {
        setLoader(false);
        return swal({
          text: data.message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: data.Message,
          icon: "warning",
        });
      }
    }
  };

  const handleAdminChange = (value) => {
    setQualifyAdminState(value);
  };

  const handleImage = async (event) => {
    var file = event.target.files[0];

    var fileName = file.name;

    let data = new FormData();
    data.append("image", file, fileName);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
      },
    };

    var response = await fetch(
      API_URL + `img/upload?id=${companyProfile?.CompanyId}`,
      {
        method: "POST",
        body: data,
        // options
      }
    );

    var datas = await response.json();
    if (response.ok) {
      return swal({
        text: datas.Message,
        icon: "success",
      }).then(() => {
        return window.location.reload();
      });
    } else {
      swal({
        text: datas.Message,
        icon: "warning",
      });
    }
  };

  const deleteImage = () => {
    swal({
      title: "Action",
      text: "Do you really want to delete your company's logo?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        var response = await fetch(
          API_URL + `img/del?id=${companyProfile?.companyId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // "Authorization": `Bearer ${localStorage.getItem("token")}`
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        var data = await response.json();

        if (response.ok) {
          return swal({
            text: data.Message,
            icon: "success",
          }).then(() => {
            return window.location.reload();
          });
        } else {
          return swal({
            text: data.Message,
            icon: "warning",
          });
        }
      } else {
      }
    });
  };

  const handleQualifyModalOpen = () => {
    setQualifyAdminModal(true);
  };

  const handleQualifyModalClose = () => {
    setQualifyAdminModal(false);
  };

  const menuOptionsNode = (
    <Menu>
      <Menu.Item onClick={handleUserCompanyModalOpen}>
        Update Company Profile
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row>
          <Col span={18}></Col>
          <Col span={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown arrow overlay={menuOptionsNode}>
                <Button icon={<EllipsisOutlined />}></Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Divider orientation="left">Company Information</Divider>
        <div style={{ maxWidth: "900px", margin: "auto" }}>
          <Row wrap gutter={[16, 32]}>
            <Col span={6}>
              <div className="profile-section">
                <img src={imageFile()} style={{ maxWidth: "100px", maxHeight: "100px" }}/>
                <div className="profile-image-actions">
                  <div class="upload-btn-wrapper">
                    <BackupIcon />
                    <input type="file" name="myfile" onChange={handleImage} />
                  </div>
                  <DeleteIcon
                    onClick={() => deleteImage()}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Name"
                node={companyProfile?.Name}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="C.A.C Number"
                node={companyProfile?.CAC_Number}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Address"
                node={companyProfile?.Address}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Description"
                node={companyProfile?.Description}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Website"
                node={
                  <a href={companyProfile?.WebsiteUrl} target="_blank">
                    {companyProfile?.WebsiteUrl}
                  </a>
                }
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Primary Admin"
                node={
                  <div>
                    {companyProfile?.PrimaryAdministrator} <span></span>
                    <EditIcon
                      className="edit-qualify"
                      onClick={handleQualifyModalOpen}
                    />
                  </div>
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Date Created"
                node={companyProfile?.DateCreated}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Customer Order Form Link" 
                 node={
                  <a href={companyProfile?.PublicCustomerOrderLink} target="_blank">
                    {companyProfile?.PublicCustomerOrderLink}
                  </a>
                }
              />
            </Col>
          </Row>
        </div>
      </Space>

      {/* Qualify admin modal */}
      <div>
        <Modal
          title="Change Primary Administrator"
          visible={qualifyAdminModal}
          footer={null}
          onCancel={handleQualifyModalClose}
        >
          <form>
            {qualifyAdmins && qualifyAdmins.length > 0 ? (
              <Select
                defaultValue="Select New Admin"
                style={{ width: "100%" }}
                onChange={handleAdminChange}
              >
                <Option value="Select New Admin" disabled>
                  Select New Admin
                </Option>
                {qualifyAdmins?.map((code) => {
                  return (
                    <Option value={code.AcceptableAdminId}>
                      {code.FullName}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <p>{qualifyAdmins?.Message}</p>
            )}
            <br />
            <br />
            <div className="form-btn">
              <Button
                type="primary"
                id="but"
                onClick={() => updateAdmin()}
                disabled={qualifyAdmins?.Message}
              >
                {loader ? <Spin /> : "Update Primary Admin"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      {/* Update company profile modal */}
      <div>
        <Modal
          title="Update Company Profile"
          visible={userCompanyUpdateOpen}
          footer={null}
          onCancel={handleUserCompanyModalClose}
        >
          <form>
            <div className="employee-form-div">
              <Input
                placeholder="Company Name"
                className="half-width"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <Input
                placeholder="Company Cac Number"
                className="half-width"
                value={cac}
                onChange={(e) => setCac(e.target.value)}
              />
            </div>
            <div className="employee-form-div">
              <Input
                placeholder="Company Acronym"
                className="half-width"
                value={companyAcronym}
                onChange={(e) => setCompanyAcronym(e.target.value)}
              />
              <Input
                placeholder="Company Website Url"
                className="half-width"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>

            <div className="employee-form-div">
              <TextField
                id="outlined-multiline-static"
                label="Description"
                placeholder="Description"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                className="delete-textarea"
              />
            </div>
            <div className="employee-form-div">
              <Select
                defaultValue={country}
                style={{ width: "100%" }}
                onChange={handleCountryChange}
              >
                <Option value="Select a Country" disabled>
                  Select a Country
                </Option>
                {countryStateCities?.map((country) => {
                  return <Option value={country}>{country}</Option>;
                })}
              </Select>
            </div>
            <div className="employee-form-div">
              {country.length > 0 ? (
                <Select
                  defaultValue={state}
                  className="half-width"
                  onChange={handleStateChange}
                >
                  <Option value="Select State" disabled>
                    Select State
                  </Option>
                  {getStates?.map((code) => {
                    return <Option value={code}>{code}</Option>;
                  })}
                </Select>
              ) : (
                <Select
                  className="half-width"
                  placeholder="Please select a Country"
                  disabled
                />
              )}
              {state.length > 0 ? (
                <Select
                  defaultValue={city}
                  className="half-width"
                  onChange={handleCityChange}
                >
                  <Option value="Select City" disabled>
                    Select City
                  </Option>
                  {getCities?.map((code) => {
                    return <Option value={code}>{code}</Option>;
                  })}
                </Select>
              ) : (
                <Select
                  className="half-width"
                  placeholder="Please select a state"
                  disabled
                />
              )}
            </div>
            <div className="employee-form-div">
              <Input
                placeholder="Street"
                className="half-width"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
              <Input
                placeholder="Zip Code"
                className="half-width"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>

            <div className="form-btn">
              <Button
                type="primary"
                id="but"
                onClick={() => updateCompanyProfile()}
              >
                {loader ? <Spin /> : "Update Company Profile"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </Fragment>
  );
}

export default CompanyProfile;
