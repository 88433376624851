import { Avatar, Col, Row, Space, Typography } from "antd";
import React from "react";
import { IOrderBlock } from "../../definitions/orderBlock";
import LabeledNode from "../../components/utils/LabeledNode";
import { IEmployee } from "../../definitions/employees";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { UserOutlined } from "@ant-design/icons";
import { nodeOrNull } from "../../components/utils/utils";

export interface IOrderBlockDriverDetailsProps {
  block: IOrderBlock;
  driver?: IEmployee;
  driverPaymentAcknowledgedBy?: IEmployee;
}

const OrderBlockDriverDetails: React.FC<IOrderBlockDriverDetailsProps> = (
  props
) => {
  const { block, driver, driverPaymentAcknowledgedBy } = props;
  const hasDriver = !!driver;
  return (
    <Row wrap gutter={[16, 32]}>
      <Col span={24}>
        <LabeledNode
          nodeIsText={!driver}
          direction="vertical"
          label="Assigned Driver"
          node={
            driver ? (
              <Space size="middle" align="start">
                <Avatar
                  alt="Profile picture of the driver assigned to this block"
                  shape="circle"
                  src={getLoadSingleImagePath(driver.EmployeeId)}
                  size={50}
                  icon={<UserOutlined />}
                />
                <Space direction="vertical" size={0}>
                  <Typography.Text>{driver.FullName}</Typography.Text>
                  <Typography.Text type="secondary">
                    {driver.EmailAddress}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {driver.PhoneNumber}
                  </Typography.Text>
                </Space>
              </Space>
            ) : (
              "N/A"
            )
          }
        />
      </Col>
      {nodeOrNull(
        hasDriver,
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Driver Assigned At"
            node={block.AssignedDriverTimeStamp_}
          />
        </Col>
      )}
      {nodeOrNull(
        hasDriver,
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Has Paid Driver"
            node={block.HasPaidDriver ? "Yes" : "No"}
          />
        </Col>
      )}
      {nodeOrNull(
        hasDriver,
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Driver Paid At"
            node={block.DriverPaymentTimestamp_}
          />
        </Col>
      )}
      {nodeOrNull(
        hasDriver,
        <Col span={24}>
          <LabeledNode
            nodeIsText={!driverPaymentAcknowledgedBy}
            direction="vertical"
            label="Driver Payment Acknowledged By"
            node={
              driverPaymentAcknowledgedBy ? (
                <Space>
                  <Avatar
                    alt="Profile picture of the employee who acknowledged the driver's payment"
                    shape="circle"
                    src={getLoadSingleImagePath(
                      driverPaymentAcknowledgedBy.EmployeeId
                    )}
                    size={50}
                    icon={<UserOutlined />}
                  />
                  <Space direction="vertical" size={4}>
                    <Typography.Text>
                      {driverPaymentAcknowledgedBy.FullName}
                    </Typography.Text>
                    <Typography.Text type="secondary">
                      {driverPaymentAcknowledgedBy.EmailAddress}
                    </Typography.Text>
                    <Typography.Text type="secondary">
                      {driverPaymentAcknowledgedBy.PhoneNumber}
                    </Typography.Text>
                  </Space>
                </Space>
              ) : (
                "N/A"
              )
            }
          />
        </Col>
      )}
      {nodeOrNull(
        hasDriver,
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Total Delivery Trip Info"
            node={block.TotalDeliveryTripInfo}
          />
        </Col>
      )}
      {nodeOrNull(
        hasDriver,
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Delivery Trip Started"
            node={block.DeliveryTripStarted_}
          />
        </Col>
      )}
      {nodeOrNull(
        hasDriver,
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Delivery Trip Ended"
            node={block.DeliveryTripEnded_}
          />
        </Col>
      )}
    </Row>
  );
};

export default OrderBlockDriverDetails;
