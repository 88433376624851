import * as types from "./profileActionType"


export const loadUserProfileStart = () => ({
    type: types.LOAD_USER_PROFILE_START,
})

export const loadUserProfileSuccess = (user) => ({
    type: types.LOAD_USER_PROFILE_SUCCESS,
    payload: user
})

export const loadUserProfileError = (error) => ({
    type: types.LOAD_USER_PROFILE_ERROR,
    payload: error
})


export const loadCompanyProfileStart = () => ({
    type: types.LOAD_COMPANY_PROFILE_START,
})

export const loadCompanyProfileSuccess = (companyInfo) => ({
    type: types.LOAD_COMPANY_PROFILE_SUCCESS,
    payload: companyInfo
})

export const loadCompanyProfileError = (error) => ({
    type: types.LOAD_COMPANY_PROFILE_ERROR,
    payload: error
})
