import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { loadMovebotPolicyStart, loadMovebotRoleStart } from "../../redux/MovebotPolicyAndPermissionRedux/movebotAction"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import "./roleandpermission.css"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Spin} from "antd"


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 700,
    },
}));



function RolesAndPermissions() {
    const dispatch = useDispatch()
    const [role, setRole] = useState("Admin")
    const { permissions, roles, permissionLoading } = useSelector(state => state.movebotActions)

    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#FF8749",
            color: theme.palette.common.white,
            textAlign: 'center'
        },
        body: {
            fontSize: 14,
            textAlign: 'center'
        },
    }))(TableCell);
    
    const StyledTableRow = withStyles((theme) => ({

    }))(TableRow);

    useEffect(() => {
        dispatch(loadMovebotPolicyStart(role))
        dispatch(loadMovebotRoleStart())
    }, [role])



    return (
        <div style={{width: '100%'}}>
            <Grid container spacing={3}>
                {
                    roles?.map(policy => {
                        return <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Paper className={role === policy._RoleType ? "policy-box role-active" : "policy-box"} onClick={() => setRole(policy._RoleType)}>
                                <h3>{policy._RoleType} Policy</h3>
                            </Paper>
                        </Grid>
                    })
                }
            </Grid>
            <br />
            <br />
            <div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell className="left-align">Permissions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            {permissionLoading ? <Spin /> :
                            permissions?.map((row, i) => (
                                <StyledTableRow key={row.PolicyId}>
                                    <StyledTableCell className="left-align">{row._PolicyType}</StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default RolesAndPermissions
