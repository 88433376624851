import { all } from "redux-saga/effects";
import { auditSaga } from "./AuditRedux/auditSaga";
import { employeeSaga } from "./EmployeeRedux/employeeSaga";
import { policySaga } from "./PolicyRedux/policySaga";
import { movebotPolicySaga } from "./MovebotPolicyAndPermissionRedux/movebotSaga";
import { settingSaga } from "./SettingsRedux/settingSaga";
import { profileSaga } from "./ProfileRedux/profileSaga";
import { ticketSaga } from "./TicketRedux/ticketSaga"; 
import { resourceSaga } from "./ResourcesRedux/ResourceSaga"; 
import { defectsSaga } from "./DefectRedux/DefectSaga";
import { trackingSaga } from "./TrackingRedux/TrackingSaga";
import { reviewSaga } from "./ReviewRedux/ReviewSaga";
import { dashboardSaga } from "./DashboardRedux/saga";
import { paymentSaga } from "./PaymentRedux/saga";

export default function* rootSaga() {
  yield all([
    ...auditSaga,
    ...employeeSaga,
    ...policySaga,
    ...movebotPolicySaga,
    ...settingSaga,
    ...profileSaga,
    ...ticketSaga, 
    ...resourceSaga, 
    ...defectsSaga,
    ...trackingSaga,
    ...reviewSaga,
    ...dashboardSaga,
    ...paymentSaga,
  ]);
}
