import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Space } from "antd";
import React from "react";
import { IOrderInput } from "../../../definitions/orderBay";
import useScreenSize from "../../../hooks/useScreenSize";
import OrderItemFormPreview from "./OrderItemFormPreview";

export interface IOrderItemListFormPreviewProps {
  isPreview?: boolean;
  orders: Omit<IOrderInput, "recipientInfo">[];
  onDeleteOrder: (index: number) => void;
  onEditOrder: (index: number) => void;
}

export const getOrderPanelName = (
  index: number,
  item?: Omit<IOrderInput, "recipientInfo">
): string => {
  const panelName = item?.packageName
    ? `Order #${index + 1} - ${item.packageName}`
    : `Order #${index + 1}`;

  return panelName;
};

const OrderItemListFormPreview: React.FC<IOrderItemListFormPreviewProps> = (
  props
) => {
  const { isPreview, orders, onEditOrder, onDeleteOrder } = props;
  const { isSmallScreen } = useScreenSize();
  const renderOrder = (item: Omit<IOrderInput, "recipientInfo">, i: number) => {
    const extraNodes = isPreview ? null : (
      <Space size={"small"}>
        <Button
          icon={<EditOutlined />}
          key="edit-btn"
          onClick={(event) => {
            onEditOrder(i);
            event.stopPropagation();
          }}
        />
        <Button
          icon={<DeleteOutlined />}
          key="delete-btn"
          onClick={(event) => {
            onDeleteOrder(i);
            event.stopPropagation();
          }}
        />
      </Space>
    );

    const header = getOrderPanelName(i, item);
    return (
      <Collapse.Panel header={header} key={header} extra={extraNodes}>
        <OrderItemFormPreview orderItem={item} isSmallScreen={isSmallScreen} />
      </Collapse.Panel>
    );
  };

  return (
    <Collapse expandIconPosition="right">{orders.map(renderOrder)}</Collapse>
  );
};

export default OrderItemListFormPreview;
