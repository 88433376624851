import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Loader from "../../components/Loader";
import { API_URL } from "../../globalVariable";
import { loadMovebotRoleStart } from "../../redux/MovebotPolicyAndPermissionRedux/movebotAction";
import { loadPolicyStart } from "../../redux/PolicyRedux/policyAction";
import {
  loadCountryCodeStart,
  loadCountryStateCityStart,
  loadCountryStateStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import "./create-branch.css";
import { useLocation } from 'react-router-dom';

function CreateBranch(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const branch = location?.state;
  const dispatch = useDispatch();
  var queryType = branch?.Id;
  const [branchState, setBranchState] = useState(
    branch ? branch?.Address?.State : ""
  );
  const [branchCity, setBranchCity] = useState(
    branch ? branch?.Address?.City : ""
  );
  const [branchName, setBranchName] = useState(
    branch ? branch?.BranchName : ""
  );
  const [branchZipcode, setBranchZipcode] = useState(
    branch ? branch?.Address.ZipCode : ""
  );
  const [branchCountry, setBranchCountry] = useState(
    branch ? branch?.Address.Country : ""
  );
  const [branchStreet, setBranchStreet] = useState(
    branch ? branch?.Address.Street : ""
  );
  const [contactName, setContactName] = useState("");
  const [addContact, setAddContact] = useState(
    branch ? branch?.BranchPhoneNumbers : []
  );
  const [countryCodes, setCountryCodes] = useState("Select Country Code");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [getCities, setGetCities] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  function handleCountryChange(value) {
    setBranchCountry(value);
  }

  function handleStateChange(value) {
    setBranchState(value);
  }

  function handleCityChange(value) {
    setBranchCity(value);
  }

  function handleCountryCodeChange(value) {
    setCountryCodes(value);
  }

  useEffect(() => {
    const payload = {
      page,
      pageSize,
    };
    dispatch(loadPolicyStart(payload));
    dispatch(loadCountryStateCityStart());
    dispatch(loadCountryCodeStart());
    dispatch(loadMovebotRoleStart());
  }, []);

  useEffect(() => {
    dispatch(loadCountryStateStart(branchCountry));
  }, [branchCountry]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          API_URL +
            `Resources/GetCountryStateCities?country=${
              branchCountry === undefined ? "" : branchCountry
            }&state=${branchState === undefined ? "" : branchState}`,
          {
            headers: {
              authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        )
        .then((data) => {
          setGetCities(data.data);
        });
    };
    fetchData();
  }, [branchState]);

  const { countryStateCities, countryCode, getStates } = useSelector(
    (state) => state.resources
  );

  const createBranch = async () => {
    setLoader(true);
    var datas = {
      branchName,
      address: {
        street: branchStreet,
        city: branchCity,
        state: branchState,
        zipCode: branchZipcode,
        country: branchCountry,
      },
      branchPhoneNumbers: addContact,
    };

    var response = await fetch(API_URL + "Settings/AddBranchLocation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(datas),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      setLoader(false);
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        setBranchName("");
        setBranchCity("");
        setBranchState("");
        setBranchZipcode("");
        setBranchCountry("");
        setAddContact([]);
      });
    } else {
      if (dataResponse.Message) {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      }
    }
  };

  const addContactFunction = async () => {
    const data = {
      PhoneNumber: phoneNumber,
      CountryCode: countryCodes,
      ContactName: contactName,
    };

    await setAddContact([...addContact, data]);

    setPhoneNumber("");
    setContactName("");
  };

  const deleteContactFunction = (id) => {
    const data = addContact?.filter((data, i) => i !== id);
    setAddContact(data);
  };

  const updateBranch = async () => {
    setLoader(true);
    var datas = {
      branchName,
      address: {
        street: branchStreet,
        city: branchCity,
        state: branchState,
        zipCode: branchZipcode,
        country: branchCountry,
      },
      branchPhoneNumbers: addContact,
    };

    var response = await fetch(
      API_URL + `Settings/UpdateBranchLocation?branchId=${queryType}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // "Authorization": `Bearer ${localStorage.getItem("token")}`
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
        body: JSON.stringify(datas),
      }
    );

    var dataResponse = await response.json();

    if (response.ok) {
      setLoader(false);
      return swal({
        text: dataResponse.message,
        icon: "success",
      });
    } else {
      if (dataResponse.Message) {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      }
    }
  };

  return (
    <Fragment>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row>
          <Col span={18}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={() => navigate(-1)}
                icon={<ArrowLeftOutlined />}
              ></Button>
              <Typography.Title
                style={{ marginLeft: "1%", marginBottom: "0%" }}
                level={5}
              >
                {queryType ? "Edit Branch" : "Create Branch"}
              </Typography.Title>
            </div>
          </Col>
        </Row>
        <div className="create">
          <Row>
            <Col span={24}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Branch Name"
              >
                <Input
                  placeholder="Branch Name"
                  value={branchName}
                  onChange={(e) => setBranchName(e.target.value)}
                  // onBlur={formik.handleBlur}
                  name="branchName"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Country"
              >
                <Select
                  placeholder="Select Country"
                  value={branchCountry}
                  onChange={handleCountryChange}
                  // disabled={loading}
                >
                  {countryStateCities?.map((code) => {
                    return (
                      <Select.Option key={code} value={code}>
                        {code}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select State"
              >
                <Select
                  placeholder="Select State"
                  value={branchState}
                  onChange={handleStateChange}
                >
                  {branchCountry?.length > 0 ? (
                    getStates?.map((code) => {
                      return (
                        <Select.Option key={code} value={code}>
                          {code}
                        </Select.Option>
                      );
                    })
                  ) : (
                    <Select.Option>Please select a country</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select City"
              >
                <Select
                  placeholder="Select City"
                  value={branchCity}
                  onChange={handleCityChange}
                >
                  {branchState?.length > 0 ? (
                    getCities?.map((code) => {
                      return (
                        <Select.Option key={code} value={code}>
                          {code}
                        </Select.Option>
                      );
                    })
                  ) : (
                    <Select.Option>Please select a state</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Street"
              >
                <Input
                  placeholder="Street"
                  value={branchStreet}
                  onChange={(e) => setBranchStreet(e.target.value)}
                  name="street"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Zipcode"
              >
                <Input
                  placeholder="ZipCode"
                  value={branchZipcode}
                  onChange={(e) => setBranchZipcode(e.target.value)}
                  name="zipCode"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Country Code"
              >
                <Select
                  placeholder="Select Country Code"
                  value={countryCodes}
                  onChange={handleCountryCodeChange}
                  // disabled={loading}
                >
                  {countryCode?.map((code) => {
                    return (
                      <Select.Option key={code} value={code}>
                        {code}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Phone Number"
              >
                <Input
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  name="phoneNumber"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Contact Name"
              >
                <Input
                  placeholder="Contact Name"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  // onBlur={formik.handleBlur}
                  name="contactName"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ background: "#FF7F50", color: "white" }}
              onClick={() => addContactFunction()}
              disabled={phoneNumber?.length < 1 && contactName?.length < 1}
            >
              Add Contact
            </Button>
          </div>

          <br />
          {addContact?.length >= 1 &&
            addContact?.map((data, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgb(255, 127, 80)",
                    color: "white",
                    padding: "2%",
                    marginBottom: "5px",
                  }}
                >
                  <p style={{ margin: 0 }}>{data.CountryCode}</p>
                  <p style={{ margin: 0 }}>{data.ContactName}</p>
                  <p style={{ margin: 0 }}>{data.PhoneNumber}</p>
                  <DeleteOutlined onClick={() => deleteContactFunction(i)} />
                </div>
              );
            })}
          <br />

          <Button
            type="primary"
            id="but"
            disabled={addContact?.length < 1}
            onClick={queryType ? () => updateBranch() : () => createBranch()}
          >
            {loader ? (
              <Loader />
            ) : queryType ? (
              "Update Branch"
            ) : (
              "Create Branch"
            )}
          </Button>
        </div>
        <br />
        <br />
      </Space>
    </Fragment>
  );
}

export default CreateBranch;
