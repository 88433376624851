import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import OrderBlockAPI from "../../api/endpoints/orderBlock";
import OrderBlockSelectDriverForm, {
  IOrderBlockSelectDriverFormValues
} from "./OrderBlockSelectDriverForm";

export interface IOrderBlockSelectDriverFormContainerProps {
  blockId: string;
  onCancel: () => void;
  onCompleteUpdate: () => void;
}

const OrderBlockSelectDriverFormContainer: React.FC<
  IOrderBlockSelectDriverFormContainerProps
> = (props) => {
  const { blockId, onCancel, onCompleteUpdate } = props;
  const onSubmit = React.useCallback(
    async (params: IOrderBlockSelectDriverFormValues) => {
      const result = await OrderBlockAPI.publishPrivateBlock({
        ...params,
        blockId,
      });

      message.success(
        result?.Message || result?.message || "Order block published privately"
      );

      onCompleteUpdate();
      onCancel();
    },
    [blockId, onCancel, onCompleteUpdate]
  );

  const { error, loading, run } = useRequest(onSubmit, {
    manual: true,
  });

  return (
    <OrderBlockSelectDriverForm
      submitting={loading}
      error={error?.message}
      onCancel={onCancel}
      onSubmit={run}
    />
  );
};

export default OrderBlockSelectDriverFormContainer;
