import { defaultTo } from "lodash";
import { addressToAddressInput } from "../../../components/form/AddressFormInput";
import { IOrderBlock, IOrderBlockInput } from "../../../definitions/orderBlock";
import { IOrderBlockFormInput } from "./OrderBlockForm";

export function orderBlockToFormInput(block: IOrderBlock) {
  const input: IOrderBlockFormInput = {
    currencyType: block.CurrencyType?.Name,
    description: block.Description,
    deliveryCost: block.DeliveryCost,
    bonusCost: block.BonusCost,
    priority: block.Priority.Name,
    tripTimeType: block.TripTimeType.Name,
    estimatedTripDuration: block.EstimatedTripDuration as unknown as string,
    type: block.Type.Name,
    publicTypeExpirationDate: block.PublicTypeExpirationDate,
    pickUpDateTime: block.BlockPickUpdateTime,
    orders: block.AssignedOrders || [],
    deliveryType: block.DeliveryType.Name.split(" ").join(""),
    deliveryAddressInfo: {
      branchAddressId: block.DeliveryAddress?.BranchAddressId,
      customAddress:
        block.DeliveryAddress?.IsCustomAddress && block.DeliveryAddress?.Address
          ? addressToAddressInput(block.DeliveryAddress?.Address)
          : null,
      supportContactInfo: defaultTo(
        block.DeliveryAddress?.SupportContactInfo,
        []
      ).map((item) => ({
        contactName: item.ContactName,
        phoneNumber: {
          code: item.PhoneNumber.Code,
          number: item.PhoneNumber.Number,
        },
      })),
    },
    pickUpAddressInfo: {
      branchAddressId: block.PickUpAddress?.BranchAddressId,
      customAddress:
        block.PickUpAddress?.IsCustomAddress && block.PickUpAddress?.Address
          ? addressToAddressInput(block.PickUpAddress?.Address)
          : null,
      supportContactInfo: defaultTo(
        block.PickUpAddress?.SupportContactInfo,
        []
      ).map((item) => ({
        contactName: item.ContactName,
        phoneNumber: {
          code: item.PhoneNumber.Code,
          number: item.PhoneNumber.Number,
        },
      })),
    },
  };

  return input;
}

export function orderBlockFormInputToBlockInput(block: IOrderBlockFormInput) {
  const blockInput: IOrderBlockInput = {
    currencyType: block.currencyType,
    description: block.description,
    deliveryCost: block.deliveryCost,
    bonusCost: block.bonusCost,
    priority: block.priority,
    tripTimeType: block.tripTimeType,
    estimatedTripDuration: block.estimatedTripDuration,
    type: block.type,
    publicTypeExpirationDate: block.publicTypeExpirationDate,
    pickUpDateTime: block.pickUpDateTime,
    orderIds: block.orders.map((item) => item.OrderId),
    deliveryType: block.deliveryType,
    deliveryAddressInfo: block.deliveryAddressInfo,
    pickUpAddressInfo: block.pickUpAddressInfo,
  };
  return blockInput;
}
