export const appBasePaths = {
  signup: "/signup",
  forgotPassword: "/forgot-password",
  login: "/login",
  changePassword: "/change-password",
  confirmPhoneNumber: "/confirm-phone-number",
};

export const appLoggedInPaths = {
  dashboard: "/dashboard",
  orderBay: "/order-bay",
  orderBlock: "/order-block",
};

export const appOrderBayPaths = {
  groupedByParcel: `${appLoggedInPaths.orderBay}/grouped-by-parcel`,
  groupedByOrder: `${appLoggedInPaths.orderBay}/grouped-by-order`,
};

export const appAuxiliaryPaths = {
  form: "/form",
  movebot: "https://movebot.ng",
};
