import * as types from "./ticketActionType"


const initialState = {
    tickets: [],
    loading: false,
    error: null
}


export const TicketReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_TICKET_START:
        case types.LOAD_FILTER_BY_PRIORITY_START:
        case types.LOAD_FILTER_BY_STATUS_START:
            return {
                ...state,
                loading: true,
                error: null
            }

        case types.LOAD_FILTER_BY_PRIORITY_SUCCESS:
        case types.LOAD_FILTER_BY_STATUS_SUCCESS:
        case types.LOAD_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                tickets: action.payload,
                error: null
            }
        case types.LOAD_FILTER_BY_PRIORITY_ERROR:
        case types.LOAD_FILTER_BY_STATUS_ERROR:
        case types.LOAD_TICKET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}