

export const LOAD_GROUP_ORDER_BAY_START = "LOAD_GROUP_ORDER_BAY_START"
export const LOAD_GROUP_ORDER_BAY_SUCCESS = "LOAD_GROUP_ORDER_BAY_SUCCESS"
export const LOAD_GROUP_ORDER_BAY_ERROR = "LOAD_GROUP_ORDER_BAY_ERROR"


export const LOAD_UN_GROUP_ORDER_BAY_START = "LOAD_UN_GROUP_ORDER_BAY_START"
export const LOAD_UN_GROUP_ORDER_BAY_SUCCESS = "LOAD_UN_GROUP_ORDER_BAY_SUCCESS"
export const LOAD_UN_GROUP_ORDER_BAY_ERROR = "LOAD_UN_GROUP_ORDER_BAY_ERROR"


export const CREATE_ORDER_BAY_START = "CREATE_ORDER_BAY_START"
export const CREATE_ORDER_BAY_SUCCESS = "CREATE_ORDER_BAY_SUCCESS"
export const CREATE_ORDER_BAY_ERROR = "CREATE_ORDER_BAY_ERROR"

export const FILTER_BY_PARCEL_START = "FILTER_BY_PARCEL_START"
export const FILTER_BY_PARCEL_SUCCESS = "FILTER_BY_PARCEL_SUCCESS"
export const FILTER_BY_PARCEL_ERROR = "FILTER_BY_PARCEL_ERROR"

export const FILTER_BY_PACKAGE_START = "FILTER_BY_PACKAGE_START"
export const FILTER_BY_PACKAGE_SUCCESS = "FILTER_BY_PACKAGE_SUCCESS"
export const FILTER_BY_PACKAGE_ERROR = "FILTER_BY_PACKAGE_ERROR"