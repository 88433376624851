import React from "react";
import intlTelInput, { CountryData } from "intl-tel-input";
import { messages } from "../../definitions/messages";
import { css } from "@emotion/css";
import "intl-tel-input/build/js/utils";
import { uniqueId } from "lodash";
import InlineLoading from "../utils/InlineLoading";
import FormError from "./FormError";
import useCountries from "../../hooks/useCountries";

export interface IPhoneInputProps {
  value?: { code?: string; number?: string };
  placeholder?: string;
  disabled?: boolean;
  numberFormat?: intlTelInputUtils.numberFormat;
  onChangePhoneNumber: (
    val: string,
    code?: CountryData,
    phoneNumber?: string
  ) => void;
  onChangeCountryCode: (code?: CountryData) => void;
  onError: (msg: string) => void;
}

const classes = {
  input: css({
    border: "1px solid #d9d9d9",
    padding: "4px 11px",
    width: "100%",
    borderRadius: "4px",
  }),
  root: css({
    width: "100%",

    "& .iti": {
      width: "100%",
    },
  }),
};

const kIdPrefix = "move-bot-phone-input";
const inputRefsMap: Record<string, intlTelInput.Plugin> = {};

function makeInputRef(id: string, options: intlTelInput.Options = {}) {
  const elem = document.getElementById(id);

  if (!elem) {
    throw new Error("Phone input element missing");
  }

  const ref = intlTelInput(elem, options);
  inputRefsMap[id] = ref;
  return ref;
}

function destroyInputRef(id: string) {
  const ref = inputRefsMap[id];

  if (ref) {
    ref.destroy();
    delete inputRefsMap[id];
  }
}

const PhoneInput: React.FC<IPhoneInputProps> = (props) => {
  const {
    value,
    placeholder,
    disabled,
    numberFormat,
    onChangePhoneNumber: onChange,
    onChangeCountryCode,
    onError,
  } = props;

  const [initError, setInitError] = React.useState<string | null>(null);
  const [refCreated, setRefCreated] = React.useState(false);
  const ref = React.useRef<intlTelInput.Plugin>();
  const [inputId] = React.useState<any>(() => `${kIdPrefix}-${uniqueId()}`);
  const { fetching, error, countries } = useCountries();
  const countryCodes = React.useMemo(() => {
    return countries?.map((country) => country.Code);
  }, [countries]);

  React.useEffect(() => {
    if (refCreated || fetching || error) {
      return;
    }

    try {
      setRefCreated(true);
      ref.current = makeInputRef(inputId, {
        onlyCountries: countryCodes,
      });

      // listen to the telephone input for changes
      document
        .getElementById(inputId)
        ?.addEventListener("countrychange", function (e) {
          const countryCode = ref.current?.getSelectedCountryData();
          onChangeCountryCode(countryCode);
        });
    } catch (error) {
      setInitError(messages.initComponentError);
    }
  }, [
    inputId,
    refCreated,
    fetching,
    error,
    countryCodes,
    onChange,
    onChangeCountryCode,
    onError,
  ]);

  React.useEffect(() => {
    if (refCreated) {
      return () => {
        destroyInputRef(inputId);
      };
    }
  }, [inputId, refCreated]);

  React.useEffect(() => {
    if (refCreated) {
      ref.current?.setNumber(value?.number || "");
      value?.code && ref.current?.setCountry(value?.code || "");
    }
  }, [value?.code, value?.number, refCreated]);

  if (fetching) {
    return <InlineLoading />;
  } else if (error || initError) {
    return <FormError>{error || initError}</FormError>;
  }

  return (
    <div className={classes.root}>
      <input
        id={inputId}
        disabled={disabled}
        type="tel"
        autoComplete="tel"
        className={classes.input}
        // value={value}
        onChange={(evt) => {
          const isValidNumber = ref.current?.isValidNumber();
          const phoneNumber = evt.target.value;
          const code = ref.current?.getSelectedCountryData();
          const nationalNumber = ref.current?.getNumber(
            numberFormat || intlTelInputUtils.numberFormat.NATIONAL
          );
          onChange(phoneNumber, code, nationalNumber);
          onError(isValidNumber ? "" : messages.enterValidPhoneNumber);
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default PhoneInput;
