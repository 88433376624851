import React from 'react'
import "./loader.css"

function Loader() {
    return (
        <div className="loader">
            <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1625050321/loading-buffering_klkmrw.gif" alt="" />
        </div>
    )
}

export default Loader