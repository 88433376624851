import { UploadOutlined } from "@ant-design/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Badge, Button, Descriptions, Image, Tag } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import HTMLRenderer from "react-html-renderer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../components/Loader";
import { API_URL } from "../../globalVariable";
import {
  loadTicketPriorityStart,
  loadTicketRelatedServiceStart,
  loadTicketStatusStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import { loadTicketStart } from "../../redux/TicketRedux/ticketAction";
import "./ticket-detail.css";

function TicketDetails(props) {
  const [ticketDetails, setTicketDetails] = useState([]);
  // const [tickets, setTickets] = useState()
  const [message, setMessage] = useState("");
  const [ticketOpen, setTicketOpen] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [loading, setLoading] = useState(false)
  const ticketUuid = Cookies.get("attachmentId");
  const [imageArr, setImageArr] = useState([]);
  const [imgMessage, setImgMessage] = useState();
  const dispatch = useDispatch();
  const unId = Cookies.get("attachmentId");
  const { id } = useParams();

  useEffect(() => {
    // const payload = {
    //   page,
    //   pageSize
    // }
    dispatch(loadTicketStart());
    dispatch(loadTicketStatusStart());
    dispatch(loadTicketPriorityStart());
    dispatch(loadTicketRelatedServiceStart());
  }, [id]);

  const getTicketImages = (setItems, unId, setImgMessage) => {
    fetch(API_URL + `img/group/GetFileNames/${unId}`, {
      method: "GET",
    })
      .then((res) => {
        if (res.statusText === "Unauthorized") {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");

          return window.location.reload();
        }
        return res.json();
      })
      .then((data) => {
        if (data.message) {
          setImgMessage(data.message);
        } else {
          setItems(data);
        }
        setItems(data);
      })
      .catch((err) => console.log({ err }));
  };

  const imageCount = (id, props) => {
    const list = [];
    for (let i = 0; i < props; i++) {
      return (
        <Image width={60} height={60} src={API_URL + `img/group/${id}/${i}`} />
      );
    }
  };

  const getTicketActivities = () => {
    fetch(API_URL + `Ticket/ActivitiesInfo?ticketId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // var newData = data.sort();
        setTicketDetails(data);
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    const fetchData = () => {
      getTicketActivities();
    };

    fetchData();
  }, [id]);

  const handleImage = async (event) => {
    const unique = uuidv4();
    Cookies.set("attachmentId", unique);

    var file = event.target.files[0];
    setFileLoader(true);

    var fileName = file.name;

    let data = new FormData();
    data.append("image", file, fileName);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
      },
    };
    var response = await fetch(API_URL + `img/group/upload?id=${unique}`, {
      method: "POST",
      body: data,
    });

    var datas = await response.json();
    if (response.ok) {
      return swal({
        text: datas.message,
        icon: "success",
      }).then(() => {
        setFileLoader(false);
        getTicketImages(setImageArr, unique);
      });
    } else {
      if (datas.message) {
        setFileLoader(false);
        return swal({
          text: datas.message,
          icon: "warning",
        });
      } else {
        setFileLoader(false);
        return swal({
          text: datas.Message,
          icon: "warning",
        });
      }
    }
  };

  const deleteImage = async (data) => {
    var response = await fetch(
      API_URL + `img/group/del?id=${ticketUuid}&fileName=${data}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // "Authorization": `Bearer ${localStorage.getItem("token")}`
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );

    var data = await response.json();

    if (response.ok) {
      return swal({
        text: data.message,
        icon: "success",
      }).then(() => {
        getTicketImages(setImageArr, unId, setImgMessage);
      });
    } else {
      if (data.message) {
        return swal({
          text: data.message,
          icon: "warning",
        });
      } else {
        return swal({
          text: data.Message,
          icon: "warning",
        });
      }
    }
  };

  const submitTicket = async () => {
    const unique = uuidv4();

    const data = {
      message,
      ticketId: id,
      attachmentId: ticketUuid,
    };
    setLoader(true);

    var response = await fetch(API_URL + "Ticket/SubmitActivity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse?.message,
        icon: "success",
      }).then(() => {
        setLoader(false);
        setMessage("");
        setImageArr(null);
        setImgMessage(null);
        setTicketOpen(false);
        getTicketActivities();
        Cookies.remove("attachmentId");
      });
    } else {
      if (dataResponse?.message) {
        setLoader(false);
        return swal({
          text: dataResponse?.message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };

  console.log({ ticketDetails });

  return (
    <div style={{ width: "100%" }}>
      <Descriptions title={ticketDetails?.Subject} layout="vertical">
        <Descriptions.Item label="Ticket Number">
          {ticketDetails?.TicketNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Organization Name">
          {ticketDetails?.OrganizationName
            ? ticketDetails?.OrganizationName
            : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={ticketDetails?.Status?.Color}>
            {ticketDetails?.Status?.Name}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Priority">
          <Tag color={ticketDetails?.Priority?.Color}>
            {ticketDetails?.Priority?.Name}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Date Created">
          {ticketDetails?.DateCreated_}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions title="Activities"></Descriptions>

      <div className="detail-container">
        {ticketDetails?.Activities?.map((data, id) => {
          return (
            <div className="detail-ticket" key={id}>
              <div className="detail-ticket-card">
                <div className="card-sub">
                  <Avatar size="30" className="avatar" name={data?.Recipient} />
                  <div style={{ width: "100%" }}>
                    <h1
                      style={{
                        fontSize: "14px",
                        marginBottom: "0%",
                        marginTop: "0%",
                      }}
                    >
                      {data?.Header}
                    </h1>
                    <p style={{ marginBottom: "0%" }}>{data?.Timestamp_}</p>
                    <HTMLRenderer
                      html={data?.Message}
                      components={{
                        p: (props) => (
                          <p
                            style={{ color: "black", fontStyle: "normal" }}
                            {...props}
                          ></p>
                        ),
                      }}
                    />
                    {imageCount(data?.AttachmentId, data?.AttachmentImageCount)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <Descriptions title="Reply"></Descriptions>
      <div style={{ width: "100%" }}>
        <ReactQuill
          modules={TicketDetails.modules}
          formats={TicketDetails.formats}
          style={{ height: "150px", width: "50%" }}
          placeholder="Ticket Message..."
          value={message}
          onChange={(e) => setMessage(e)}
        />
        <br />
        <br />
        <br />
        <br />
        <p>Attachments</p>
        <div>
          <div class="upload-btn-wrapper pp">
            {fileLoader ? (
              "File Upload In progress.."
            ) : (
              <Badge count={imageArr?.length}>
                <>
                  <label>Attachment </label>
                  <UploadOutlined />
                  <input type="file" name="myfile" onChange={handleImage} />
                </>
              </Badge>
            )}
          </div>
        </div>
        <div>
          {imageArr?.length >= 1 &&
            imageArr?.map((data) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <p>{data}</p>
                  <DeleteIcon
                    style={{ color: "red" }}
                    onClick={() => deleteImage(data)}
                  />
                </div>
              );
            })}
        </div>
        <br />
        <div className="form-btn" style={{ width: "50%" }}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            id="but"
            onClick={() => submitTicket()}
          >
            {loader ? <Loader /> : "Send Reply"}
          </Button>
        </div>
        <br />
      </div>
    </div>
  );
}

TicketDetails.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"],
    ["code-block"],
  ],
};

TicketDetails.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "code-block",
];

export default TicketDetails;
