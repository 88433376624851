import axios from "axios";
import { isString } from "lodash";
import Cookies from "js-cookie";
import { API_URL } from "../../globalVariable";
import { invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";
import {
  setEndpointParam,
  selectEndpointParam,
  addEndpointPath,
  setEndpointPath,
} from "../utils";

export const imageSectionURL = `${API_URL}img`;
const uploadImageURL = `${imageSectionURL}/upload`;
const deleteImageURL = `${imageSectionURL}/del`;
const getGroupImageURL = `${imageSectionURL}/group`;
const uploadGroupImageURL = `${getGroupImageURL}/upload`;
const bulkUploadImageURL = `${getGroupImageURL}/bulkUpload`;
const deleteGroupImageURL = `${getGroupImageURL}/del`;
const getFilenamesURL = `${getGroupImageURL}/GetFileNames`;

export function getLoadSingleImagePath(
  id: string,
  width?: number | null,
  height?: number | null,
  dontUseCache: boolean | string = true
) {
  let imageURL = `${imageSectionURL}/${id}`;
  imageURL = addEndpointPath(imageURL, width);
  imageURL = addEndpointPath(imageURL, height);

  if (dontUseCache) {
    imageURL = addEndpointPath(
      imageURL,
      isString(dontUseCache) ? dontUseCache : Date.now().toString()
    );
  }

  return imageURL;
}

export function getLoadGroupImageInIndexPath(
  id: string,
  index: number,
  width?: number,
  height?: number
) {
  let imageURL = `${getGroupImageURL}/${id}/${index}`;
  imageURL = addEndpointPath(imageURL, width);
  imageURL = addEndpointPath(imageURL, height);
  return imageURL;
}

export function getLoadGroupImagePath(
  filename: string,
  width?: number,
  height?: number
) {
  let imageURL = `${getGroupImageURL}/${filename}`;
  imageURL = addEndpointPath(imageURL, width);
  imageURL = addEndpointPath(imageURL, height);
  return imageURL;
}

export function getUploadImagePath(id: string, group?: boolean) {
  const params = new URLSearchParams();
  setEndpointParam(params, "id", id);
  const endpointURL = selectEndpointParam(
    group ? uploadGroupImageURL : uploadImageURL,
    params,
    { id }
  );
  return endpointURL;
}

export interface IUploadImageEndpointInput {
  isGroupImages?: boolean;
  id: string;
  data: File;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

async function uploadImage(
  props: IUploadImageEndpointInput
): Promise<IEndpointMessageResult> {
  const endpointURL = getUploadImagePath(props.id, props.isGroupImages);
  const requestToken = Cookies.get("accessToken");
  const formData = new FormData();
  formData.append("image", props.data);

  if (!requestToken) {
    throw new Error("Please sign in");
  }

  const result = await axios({
    url: endpointURL,
    data: formData,
    headers: {
      Authorization: `Bearer ${requestToken}`,
    },
    method: "POST",
    onUploadProgress: props.onUploadProgress,
  });

  return result.data;
}

export interface IBulkUploadImagesEndpointInput {
  id: string;
  data: File[];
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

async function bulkUploadImages(
  props: IBulkUploadImagesEndpointInput
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "id", props.id);
  const endpointURL = selectEndpointParam(bulkUploadImageURL, params, {
    id: props.id,
  });

  const requestToken = Cookies.get("accessToken");
  const formData = new FormData();
  props.data.forEach((item) => {
    formData.append("image", item);
  });

  if (!requestToken) {
    throw new Error("Please sign in");
  }

  const result = await axios({
    url: endpointURL,
    data: formData,
    headers: {
      Authorization: `Bearer ${requestToken}`,
    },
    method: "POST",
    onUploadProgress: props.onUploadProgress,
  });

  return result.data;
}

export interface IDeleteImagesEndpointInput {
  isGroupImage?: boolean;
  filename?: string;
  id: string;
}

async function deleteImages(
  props: IDeleteImagesEndpointInput
): Promise<IEndpointMessageResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "id", props.id);
  setEndpointParam(
    params,
    "fileName",
    props.filename ? props.filename : props.isGroupImage ? "*" : null
  );

  const endpointURL = selectEndpointParam(
    props.filename || props.isGroupImage ? deleteGroupImageURL : deleteImageURL,
    params,
    props
  );

  const result = await invokeEndpointWithAuth<IEndpointMessageResult>({
    path: endpointURL,
    method: "DELETE",
  });

  return result;
}

export interface IGetFilenamesEndpointInput {
  id: string;
}

async function getFilenames(
  props: IGetFilenamesEndpointInput
): Promise<string[]> {
  let endpointURL = getFilenamesURL;
  endpointURL = setEndpointPath(endpointURL, props.id);
  const result = await invokeEndpointWithAuth<string[]>({
    path: endpointURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export default class ImageAPI {
  public static uploadImage = uploadImage;
  public static deleteImages = deleteImages;
  public static getFilenames = getFilenames;
  public static bulkUploadImages = bulkUploadImages;
}
