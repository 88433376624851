import * as types from "./ticketActionType"
import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import { getTicketApi, getTicketByPriorityApi, getTicketByStatusApi} from "../Api"
import { loadTicketError, loadTicketSuccess,loadTicketByPrioritySuccess,loadTicketByStatusSuccess, loadTicketByPriorityError, loadTicketByStatusError } from "./ticketAction"






function* onTicketStartAsync({payload}) {
    try {
        const response = yield call(getTicketApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadTicketByStatusSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadTicketByStatusError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onTicketByPriorityStartAsync({payload}) {
    try {
        const response = yield call(getTicketByPriorityApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadTicketByPrioritySuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadTicketByPriorityError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onTicketByStatusStartAsync({payload}) {
    try {
        const response = yield call(getTicketByStatusApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadTicketSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadTicketError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}



function* onTicketByStatusLoad() {
    yield takeEvery(types.LOAD_FILTER_BY_STATUS_START, onTicketByStatusStartAsync)
}



function* onTicketByPriorityLoad() {
    yield takeEvery(types.LOAD_FILTER_BY_PRIORITY_START , onTicketByPriorityStartAsync)
}

function* onTicketLoad() {
    yield takeEvery(types.LOAD_TICKET_START, onTicketStartAsync)
}

export const ticketSaga = [
    fork(onTicketLoad),
   fork(onTicketByStatusLoad),
    fork(onTicketByPriorityLoad)
]