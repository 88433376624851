import { useRequest } from "ahooks";
import { Col, Divider, Row, Space, Tag, Typography } from "antd";
import DOMPurify from "dompurify";
import React from "react";
import { getLoadSingleImagePath } from "../../../api/endpoints/image";
import OrderBayAPI from "../../../api/endpoints/orderBay";
import LabeledNode from "../../../components/utils/LabeledNode";
import PageError from "../../../components/utils/PageError";
import PageLoading from "../../../components/utils/PageLoading";
import { appClasses } from "../../../components/utils/theme";

export interface IIParcelTransactionInfoProps {
  parcelId: string;
}

const ParcelTransactionInfo: React.FC<IIParcelTransactionInfoProps> = (
  props
) => {
  const { parcelId } = props;
  const getData = React.useCallback(async () => {
    return await OrderBayAPI.getTransactionInfo({ parcelId });
  }, [parcelId]);

  const { error, data, loading, run } = useRequest(getData);

  if (error) {
    return (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload Trip History", onClick: run }]}
      />
    );
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return (
    <Row wrap gutter={[16, 32]}>
      <Col span={24}>
        <LabeledNode
          direction="vertical"
          node={
            <Space
              direction="vertical"
              split={<Divider style={{ margin: "8px 0px" }} />}
              size={2}
              style={{ width: "100%" }}
            >
              <LabeledNode
                direction="horizontal"
                label="Shipping Cost"
                node={data.ShippingCost_}
                textAlign="right"
              />
              <LabeledNode
                direction="horizontal"
                label="Tax Cost"
                node={data.TaxCost_}
                textAlign="right"
              />
              <LabeledNode
                direction="horizontal"
                label="Insurance Cost"
                node={data.InsuranceCost_}
                textAlign="right"
              />
              <LabeledNode
                direction="horizontal"
                label="Total Cost"
                node={data.TotalCost}
                textAlign="right"
              />
            </Space>
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Estimated Delivery Date"
          node={data.EstimatedDeliveryDate_}
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Is Pay On Delivery"
          node={data.IsPaymentPOD ? "Yes" : "No"}
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Payment Type"
          node={
            <Space direction="vertical" size={2}>
              <Tag style={{ color: data.PaymentType.Color }}>
                {data.PaymentType.Name}
              </Tag>
              <Typography.Text type="secondary">
                {data.PaymentType.Description}
              </Typography.Text>
            </Space>
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Payment Type Info"
          node={data.PaymentTypeInfo}
        />
      </Col>
      {data.PaidOption ? (
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Payment Option"
            node={data.PaidOption}
          />
        </Col>
      ) : null}
      {data.CustomPaidOption ? (
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Custom Payment Option"
            node={data.CustomPaidOption}
          />
        </Col>
      ) : null}
      {data.TransactionRecieptId ? (
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Transaction Receipt"
            node={
              <img
                src={getLoadSingleImagePath(data.TransactionRecieptId)}
                alt="Transaction receipt"
              />
            }
          />
        </Col>
      ) : null}
      <Col span={12}>
        <LabeledNode
          direction="vertical"
          label="Summary"
          node={
            <Typography.Text className={appClasses.paragraph}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.HtmlSummary),
                }}
              />
            </Typography.Text>
          }
        />
      </Col>
    </Row>
  );
};

export default ParcelTransactionInfo;
