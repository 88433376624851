import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import * as types from "./actionType"
import {
    getAuditError,
    getAuditRefSuccess,
    getAuditRefError,
    getAuditSuccess
} from "./action"
import { getAuditApi, getAuditRefApi } from "../Api"



function* onLoadAuditStartAsync({ payload }) {
    try {
        const response = yield call(getAuditApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(getAuditSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch (error) {
        yield put(getAuditError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}



function* onLoadAuditRefStartAsync() {
    try {
        const response = yield call(getAuditRefApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(getAuditRefSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch (error) {
        yield put(getAuditRefError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadAuditRef() {
    yield takeEvery(types.GET_AUDIT_REF_START, onLoadAuditRefStartAsync)
}


function* onLoadAudit() {
    yield takeEvery(types.GET_AUDIT_START, onLoadAuditStartAsync)
}


export const auditSaga = [
    fork(onLoadAudit),
    fork(onLoadAuditRef),
]
