import { createActionFns } from "../utils";
import * as types from "./ResourceActionType";

export const loadStatusTypeStart = () => ({
  type: types.LOAD_STATUS_TYPE_START,
});

export const loadStatusTypeSuccess = (payload) => ({
  type: types.LOAD_STATUS_TYPE_SUCCESS,
  payload,
});

export const loadStatusTypeError = (error) => ({
  type: types.LOAD_STATUS_TYPE_ERROR,
  payload: error,
});

export const loadPackageStatusStart = () => ({
  type: types.LOAD_PACKAGE_STATUS_START,
});

export const loadPackageStatusSuccess = (payload) => ({
  type: types.LOAD_PACKAGE_STATUS_SUCCESS,
  payload,
});

export const loadPackageStatusError = (error) => ({
  type: types.LOAD_PACKAGE_STATUS_ERROR,
  payload: error,
});

export const loadPackageSizeStart = () => ({
  type: types.LOAD_PACKAGE_SIZE_START,
});

export const loadPackageSizeSuccess = (payload) => ({
  type: types.LOAD_PACKAGE_SIZE_SUCCESS,
  payload,
});

export const loadPackageSizeError = (error) => ({
  type: types.LOAD_PACKAGE_SIZE_ERROR,
  payload: error,
});

export const loadPaidOptionStart = () => ({
  type: types.LOAD_PAID_OPTIONS_START,
});

export const loadPaidOptionSuccess = (payload) => ({
  type: types.LOAD_PAID_OPTIONS_SUCCESS,
  payload,
});

export const loadPaidOptionError = (error) => ({
  type: types.LOAD_PAID_OPTIONS_ERROR,
  payload: error,
});

// Currencies
export const loadCurrenciesStart = () => ({
  type: types.LOAD_CURRENCIES_START,
});

export const loadCurrenciesSuccess = (payload) => ({
  type: types.LOAD_CURRENCIES_SUCCESS,
  payload,
});

export const loadCurrenciesError = (error) => ({
  type: types.LOAD_CURRENCIES_ERROR,
  payload: error,
});

export const loadBlockPriorityStart = () => ({
  type: types.LOAD_BLOCK_PRIORITY_START,
});

export const loadBlockPrioritySuccess = (payload) => ({
  type: types.LOAD_BLOCK_PRIORITY_SUCCESS,
  payload,
});

export const loadBlockPriorityError = (error) => ({
  type: types.LOAD_BLOCK_PRIORITY_ERROR,
  payload: error,
});

export const loadBlockTypeStart = () => ({
  type: types.LOAD_BLOCK_TYPE_START,
});

export const loadBlockTypeSuccess = (payload) => ({
  type: types.LOAD_BLOCK_TYPE_SUCCESS,
  payload,
});

export const loadBlockTypeError = (error) => ({
  type: types.LOAD_BLOCK_TYPE_ERROR,
  payload: error,
});

export const loadBlockTimeTypeStart = () => ({
  type: types.LOAD_BLOCK_TIME_TYPE_START,
});

export const loadBlockTimeTypeSuccess = (payload) => ({
  type: types.LOAD_BLOCK_TIME_TYPE_SUCCESS,
  payload,
});

export const loadBlockTimeTypeError = (error) => ({
  type: types.LOAD_BLOCK_TIME_TYPE_ERROR,
  payload: error,
});

export const loadTicketPriorityStart = () => ({
  type: types.LOAD_TICKET_PRIORITY_START,
});

export const loadTicketPrioritySuccess = (payload) => ({
  type: types.LOAD_TICKET_PRIORITY_SUCCESS,
  payload,
});

export const loadTicketPriorityError = (error) => ({
  type: types.LOAD_TICKET_PRIORITY_ERROR,
  payload: error,
});

export const loadWeightUnitStart = () => ({
  type: types.LOAD_WEIGHT_UNIT_START,
});

export const loadWeightUnitSuccess = (payload) => ({
  type: types.LOAD_WEIGHT_UNIT_SUCCESS,
  payload,
});

export const loadWeightUnitError = (error) => ({
  type: types.LOAD_WEIGHT_UNIT_ERROR,
  payload: error,
});

export const loadOrderCategoryStart = () => ({
  type: types.LOAD_ORDER_CATEGORY_START,
});

export const loadOrderCategorySuccess = (payload) => ({
  type: types.LOAD_ORDER_CATEGORY_SUCCESS,
  payload,
});

export const loadOrderCategoryError = (error) => ({
  type: types.LOAD_ORDER_CATEGORY_ERROR,
  payload: error,
});

export const loadTicketStatusStart = () => ({
  type: types.LOAD_TICKET_STATUS_START,
});

export const loadTicketStatusSuccess = (payload) => ({
  type: types.LOAD_TICKET_STATUS_SUCCESS,
  payload,
});

export const loadTicketStatusError = (error) => ({
  type: types.LOAD_TICKET_STATUS_ERROR,
  payload: error,
});

export const loadTicketRelatedServiceStart = () => ({
  type: types.LOAD_TICKET_RELATED_SERVICE_START,
});

export const loadTicketRelatedServiceSuccess = (payload) => ({
  type: types.LOAD_TICKET_RELATED_SERVICE_SUCCESS,
  payload,
});

export const loadTicketRelatedServiceError = (error) => ({
  type: types.LOAD_TICKET_RELATED_SERVICE_ERROR,
  payload: error,
});

export const loadCountryCodeStart = () => ({
  type: types.LOAD_COUNTRY_CODE_START,
});

export const loadCountryCodeSuccess = (payload) => ({
  type: types.LOAD_COUNTRY_CODE_SUCCESS,
  payload,
});

export const loadCountryCodeError = (error) => ({
  type: types.LOAD_COUNTRY_CODE_ERROR,
  payload: error,
});

export const loadLongCountryCodeStart = () => ({
  type: types.LOAD_LONG_COUNTRY_CODE_START,
});

export const loadLongCountryCodeSuccess = (payload) => ({
  type: types.LOAD_LONG_COUNTRY_CODE_SUCCESS,
  payload,
});

export const loadLongCountryCodeError = (error) => ({
  type: types.LOAD_LONG_COUNTRY_CODE_ERROR,
  payload: error,
});

export const loadCountryCodeObjStart = (payload) => ({
  type: types.LOAD_COUNTRY_CODE_OBJ_START,
});

export const loadCountryCodeObjSuccess = (payload) => ({
  type: types.LOAD_COUNTRY_CODE_OBJ_SUCCESS,
  payload,
});

export const loadCountryCodeObjError = (error) => ({
  type: types.LOAD_COUNTRY_CODE_OBJ_ERROR,
  payload: error,
});

export const loadCountryStateCityStart = (payload) => ({
  type: types.LOAD_COUNTRY_STATE_CITIES_START,
  payload,
});

export const loadCountryStateCitySuccess = (payload) => ({
  type: types.LOAD_COUNTRY_STATE_CITIES_SUCCESS,
  payload,
});

export const loadCountryStateCityError = (error) => ({
  type: types.LOAD_COUNTRY_STATE_CITIES_ERROR,
  payload: error,
});

export const loadCountryStateStart = (payload) => ({
  type: types.LOAD_COUNTRY_STATE_START,
  payload: payload,
});

export const loadCountryStateSuccess = (payload) => ({
  type: types.LOAD_COUNTRY_STATE_SUCCESS,
  payload,
});

export const loadCountryStateError = (error) => ({
  type: types.LOAD_COUNTRY_STATE_ERROR,
  payload: error,
});

export const loadDefectTypeStart = (payload) => ({
  type: types.LOAD_DEFECT_TYPE_START,
  payload: payload,
});

export const loadDefectTypeSuccess = (payload) => ({
  type: types.LOAD_DEFECT_TYPE_SUCCESS,
  payload,
});

export const loadDefectTypeError = (error) => ({
  type: types.LOAD_DEFECT_TYPE_ERROR,
  payload: error,
});

export const loadTrackingTypeStart = () => ({
  type: types.LOAD_TRACKING_TYPE_START,
});

export const loadTrackingTypeSuccess = (payload) => ({
  type: types.LOAD_TRACKING_TYPE_SUCCESS,
  payload,
});

export const loadTrackingTypeError = (error) => ({
  type: types.LOAD_TRACKING_TYPE_ERROR,
  payload: error,
});

export const loadTrackingFreqStart = () => ({
  type: types.LOAD_TRACKING_FREQ_START,
});

export const loadTrackingFreqSuccess = (payload) => ({
  type: types.LOAD_TRACKING_FREQ_SUCCESS,
  payload,
});

export const loadTrackingFreqError = (error) => ({
  type: types.LOAD_TRACKING_FREQ_ERROR,
  payload: error,
});

// Block delivery type
export const loadBlockDeliveryTypesStart = () => ({
  type: types.LOAD_BLOCK_DELIVERY_TYPE_START,
});

export const loadBlockDeliveryTypesSuccess = (payload) => ({
  type: types.LOAD_BLOCK_DELIVERY_TYPE_SUCCESS,
  payload,
});

export const loadBlockDeliveryTypesError = (error) => ({
  type: types.LOAD_BLOCK_DELIVERY_TYPE_ERROR,
  payload: error,
});

// Block filter country list
export const orderBlockFilterCountryListActions = createActionFns([
  types.LOAD_BLOCK_FILTER_COUNTRY_LIST_START,
  types.LOAD_BLOCK_FILTER_COUNTRY_LIST_SUCCESS,
  types.LOAD_BLOCK_FILTER_COUNTRY_LIST_ERROR,
]);

// Block filter state list
export const orderBlockFilterStateListActions = createActionFns([
  types.LOAD_BLOCK_FILTER_STATE_LIST_START,
  types.LOAD_BLOCK_FILTER_STATE_LIST_SUCCESS,
  types.LOAD_BLOCK_FILTER_STATE_LIST_ERROR,
]);

// Block filter city list
export const orderBlockFilterCityListActions = createActionFns([
  types.LOAD_BLOCK_FILTER_CITY_LIST_START,
  types.LOAD_BLOCK_FILTER_CITY_LIST_SUCCESS,
  types.LOAD_BLOCK_FILTER_CITY_LIST_ERROR,
]);

// Order block drivers list
export const orderBlockDriversListActions = createActionFns([
  types.LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_START,
  types.LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_SUCCESS,
  types.LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_ERROR,
]);
