import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import HTMLRenderer from "react-html-renderer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import LabeledNode from "../../components/utils/LabeledNode";
import { API_URL } from "../../globalVariable";
import { getDefectsSearchStart } from "../../redux/DefectRedux/action";
import { loadDefectTypeStart } from "../../redux/ResourcesRedux/resourcesAction";

function DefectDetails(props) {
  const { id, pageNumber } = useParams();
  const dispatch = useDispatch();
  const { defectType } = useSelector((state) => state.resources);
  const [defects, setDefects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          API_URL + `DefectOrders/Get?defectId=${id}`,
          {
            headers: {
              authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        if (response.status === 200) {
          setDefects(response.data);
        } else if (response.statusText === "Unauthorized") {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          return window.location.reload();
        }
      } catch (error) {
        console.log(error.response);
        if (error?.response?.status === 401) {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          return (window.location.href = "/login");
        }
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const payload = {
      id,
      pageNumber,
    };
    dispatch(getDefectsSearchStart(payload));
  }, [id]);

  useEffect(() => {
    dispatch(loadDefectTypeStart());
  }, []);

  const submitExecution = async (data, item) => {
    const payload = {
      defectId: data,
      actionType: item,
    };

    try {
      var response = await fetch(API_URL + `DefectOrders/ExecuteAction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
        body: JSON.stringify(payload),
      });

      var dataResponse = await response.json();
      if (response.ok) {
        return swal({
          text: dataResponse.Message,
          icon: "success",
        });
      } else {
        if (dataResponse.message) {
          return swal({
            text: dataResponse.message,
            icon: "warning",
          });
        } else {
          return swal({
            text: dataResponse.Message,
            icon: "warning",
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const menuOptionsNode = (data) => (
    <Menu>
      {defectType &&
        defectType?.map((item) => {
          return (
            <Menu.Item onClick={() => submitExecution(data, item.id)}>
              {item.name}
            </Menu.Item>
          );
        })}
      <Menu.Divider />
    </Menu>
  );

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Row>
        <Col span={18}>
          <Typography.Title level={5}>
            <Tag style={{ color: defects?.TicketStatus?.Color }}>
              {defects?.TicketStatus?.Name}
            </Tag>
            <br />
            {defects?.OrderNum}
          </Typography.Title>
        </Col>
        <Col span={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Dropdown arrow overlay={menuOptionsNode(defects?.DefectId)}>
              <Button
                icon={<EllipsisOutlined />}
                // className={buttonClassName}
              ></Button>
            </Dropdown>
          </div>
        </Col>
      </Row>

      <Divider orientation="left">Order Information</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <Row wrap gutter={[16, 32]}>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Order Number"
              node={
                <Link to={`/order-bay/grouped-by-order/${defects?.OrderId}`}>
                  {defects?.OrderNum}
                </Link>
              }
            />
          </Col>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Block Number"
              node={
                <Link to={`/order-block/${defects?.BlockId}`}>
                  {defects?.BlockNum}
                </Link>
              }
            />
          </Col>
        </Row>
        <br />
        <Row wrap gutter={[16, 32]}>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Ticket Number"
              node={
                <Link to={`/tickets/${defects?.TicketId}`}>
                  {defects?.TicketNum}
                </Link>
              }
            />
          </Col>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Ticket Status"
              node={
                <Space direction="vertical" size={2}>
                  <Tag style={{ color: defects?.TicketStatus?.Color }}>
                    {defects?.TicketStatus?.Name}
                  </Tag>
                  <Typography.Text type="secondary">
                    {defects?.TicketStatus?.Description}
                  </Typography.Text>
                </Space>
              }
            />
          </Col>
        </Row>
        <br />
        <Row wrap gutter={[16, 32]}>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Date Created"
              node={defects?.DateCreated}
            />
          </Col>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Last Updated"
              node={defects?.LastUpdated}
            />
          </Col>
        </Row>
        <br />
        <Row wrap gutter={[16, 32]}>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Current Order Item Status"
              node={
                <Space direction="vertical" size={2}>
                  <Tag
                    style={{ color: defects?.CurrentOrderItemStatus?.Color }}
                  >
                    {defects?.CurrentOrderItemStatus?.Name}
                  </Tag>
                  <Typography.Text type="secondary">
                    {defects?.CurrentOrderItemStatus?.Description}
                  </Typography.Text>
                </Space>
              }
            />
          </Col>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Last Action Executed"
              node={
                <Space direction="vertical" size={2}>
                  <Tag style={{ color: defects?.LastActionExecuted?.Color }}>
                    {defects?.LastActionExecuted?.Name}
                  </Tag>
                  <Typography.Text type="secondary">
                    {defects?.LastActionExecuted?.Description}
                  </Typography.Text>
                </Space>
              }
            />
          </Col>
        </Row>
      </div>

      <Divider orientation="left">Driver Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <Row wrap gutter={[16, 32]}>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Assigned Driver"
              node={
                defects ? (
                  <Space>
                    <Avatar
                      alt="Profile picture of the last person who updated this block"
                      shape="circle"
                      src={getLoadSingleImagePath(defects?.DriverName)}
                      icon={<UserOutlined />}
                    />
                    <Typography.Text type="secondary">
                      {defects?.DriverName}
                    </Typography.Text>
                  </Space>
                ) : (
                  "N/A"
                )
              }
            />
          </Col>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Driver Num"
              node={defects?.DriverNum}
            />
          </Col>
        </Row>
      </div>

      <Divider orientation="left">Issue Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <Row wrap gutter={[16, 32]}>
          <Col span={12}>
            <LabeledNode
              direction="vertical"
              label="Type"
              node={
                <Space direction="vertical" size={2}>
                  <Tag style={{ color: defects?.IssueCategory?.Color }}>
                    {defects?.IssueCategory?.Name}
                  </Tag>
                  <Typography.Text type="secondary">
                    {defects?.IssueCategory?.Description}
                  </Typography.Text>
                </Space>
              }
            />
            <br />
            <LabeledNode
              direction="vertical"
              label="Details"
              node={
                <Space direction="vertical" size={2}>
                  <Typography.Text type="secondary">
                    {defects?.IssueDescription}
                  </Typography.Text>
                </Space>
              }
            />
          </Col>
        </Row>
      </div>

      <Divider orientation="left">Order History</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <Row wrap gutter={[16, 32]}>
          <Col span={100}>
            <LabeledNode
              direction="vertical"
              node={
                defects?.OrderItemHistory ? (
                  <HTMLRenderer
                    html={defects?.OrderItemHistory}
                    components={{
                      br: (props) => (
                        <div style={{ margin: "3%" }} {...props}></div>
                      ),
                    }}
                  />
                ) : null
              }
            />
          </Col>
        </Row>
        <br />
      </div>
    </Space>
  );
}

export default DefectDetails;
