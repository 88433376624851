import { useRequest } from "ahooks";
import { message } from "antd";
import React from "react";
import OrderBlockAPI from "../../../api/endpoints/orderBlock";
import { IOrderBlock } from "../../../definitions/orderBlock";
import OrderBlockAdjustDatesForm, {
  IOrderBlockAdjustDatesFormValues
} from "./OrderBlockAdjustDatesForm";

export interface IOrderBlockAdjustDatesFormContainerProps {
  block: IOrderBlock;
  onCancel: () => void;
  onCompleteUpdate: () => void;
}

const OrderBlockAdjustDatesFormContainer: React.FC<
  IOrderBlockAdjustDatesFormContainerProps
> = (props) => {
  const { block, onCancel, onCompleteUpdate } = props;
  const onSubmit = React.useCallback(
    async (params: IOrderBlockAdjustDatesFormValues) => {
      const result = await OrderBlockAPI.adjustPickupDateTime({
        blockId: block.BlockId,
        pickUpDateTime: params.pickUpDateTime,
        publicTypeExpirationDate: params.publicTypeExpirationDate,
      });

      message.success(
        result?.Message || result?.message || "Order block updated successfully"
      );

      onCompleteUpdate();
      onCancel();
    },
    [block.BlockId, onCancel, onCompleteUpdate]
  );

  const { error, loading, run } = useRequest(onSubmit, {
    manual: true,
  });

  return (
    <OrderBlockAdjustDatesForm
      submitting={loading}
      error={error?.message}
      block={block}
      onCancel={onCancel}
      onSubmit={run}
    />
  );
};

export default OrderBlockAdjustDatesFormContainer;
