import * as types from "./employeeActionType"
import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import { getEmployeesApi, getQualifyAdminApi, getFilterEmployeesApi } from "../Api"
import { loadEmployeeError, loadQualifyAdminSuccess,filterEmployeeSuccess,filterEmployeeError, loadQualifyAdminError, loadEmployeeSuccess } from "./employeeAction"
import { API_URL } from "../../globalVariable"



function* onFilterEmployeeStartAsync({payload}) {
    try {
        const response = yield call(getFilterEmployeesApi, payload)
        if (response.status === 200) {
            const action = response.data.Data
            const dataMap = action?.map(data => {
                return {
                    EmployeeImage: API_URL + `img/default/${data?.EmployeeId}/50/50/p`,
                    ...data
                }
            })
            const allData = {
                currentPage: response.data.CurrentPage,
                currentPageSize: response.data.CurrentPageSize,
                TotalPage: response.data.TotalPages,
                Data: dataMap,
                TotalSize: response.data.TotalSize
            }
            yield delay(500)
            yield put(filterEmployeeSuccess(allData))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(filterEmployeeError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}


function* onLoadEmployeeStartAsync({payload}) {
    try {
        const response = yield call(getEmployeesApi, payload)
        if (response.status === 200) {
            const action = response.data.Data
            const dataMap = action?.map(data => {
                return {
                    EmployeeImage: API_URL + `img/default/${data?.EmployeeId}/50/50/p`,
                    ...data
                }
            })
            const allData = {
                currentPage: response.data.CurrentPage,
                currentPageSize: response.data.CurrentPageSize,
                TotalPage: response.data.TotalPages,
                Data: dataMap,
                TotalSize: response.data.TotalSize
            }
            yield delay(500)
            yield put(loadEmployeeSuccess(allData))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadEmployeeError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadQualifyAdminStartAsync() {
    try {
        const response = yield call(getQualifyAdminApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadQualifyAdminSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadQualifyAdminError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}


function* onLoadAdmin() {
    yield takeEvery(types.LOAD_QUALIFY_ADMIN_START, onLoadQualifyAdminStartAsync)
}

function* onLoadEmployees() {
    yield takeEvery(types.LOAD_EMPLOYEE_START, onLoadEmployeeStartAsync)
}

function* onfilterEmployees() {
    yield takeEvery(types.FILTER_EMPLOYEE_START, onFilterEmployeeStartAsync)
}


export const employeeSaga = [
    fork(onLoadEmployees),
    fork(onLoadAdmin),
    fork(onfilterEmployees),
]