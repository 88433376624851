import * as types from "./actionType";

const initialState = {
  tracking: [],
  trackingDrivers: [],
  loading: false,
  error: null,
};

export const TrackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRACKING_START:
    case types.GET_TRACKING_SEARCH_START:
    case types.GET_TRACKING_DRIVER_START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_TRACKING_SUCCESS:
    case types.GET_TRACKING_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        tracking: action.payload,
      };
    case types.GET_TRACKING_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        trackingDrivers: action.payload,
      };
    case types.GET_TRACKING_ERROR:
    case types.GET_TRACKING_SEARCH_ERROR:
    case types.GET_TRACKING_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
