import React from "react";
import { Divider, Space } from "antd";
import SupportContact, { ISupportContactProps } from "./SupportContact";

export interface ISupportContactListProps {
  contacts: ISupportContactProps[];
}

const SupportContactList: React.FC<ISupportContactListProps> = (props) => {
  const { contacts } = props;
  return (
    <Space
      direction="vertical"
      split={<Divider style={{ margin: "6px 0px" }} />}
      style={{ width: "100%" }}
      size={0}
    >
      {contacts.map((contact, index) => {
        const { contactName, phoneNumber } = contact;
        return (
          <SupportContact
            key={`${index}-${phoneNumber}`}
            contactName={contactName}
            phoneNumber={phoneNumber}
          />
        );
      })}
    </Space>
  );
};

export default SupportContactList;
