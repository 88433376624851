import * as types from "./employeeActionType"


const initialState = {
    employees: [],
    qualifyAdmins: [],
    loading: false,
    error: null
}


export const EmployeeReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_EMPLOYEE_START:
        case types.LOAD_EMPLOYEE_START:
        case types.FILTER_EMPLOYEE_START:
        case types.LOAD_QUALIFY_ADMIN_START:
            return {
                ...state,
                loading: true,
            }
        case types.LOAD_QUALIFY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                qualifyAdmins: action.payload,
            }
        case types.CREATE_EMPLOYEE_SUCCESS:
        case types.FILTER_EMPLOYEE_SUCCESS:
        case types.LOAD_EMPLOYEE_SUCCESS:
            return {
                ...state,
                loading: false,
                employees: action.payload
            }
        case types.LOAD_EMPLOYEE_ERROR:
        case types.FILTER_EMPLOYEE_ERROR:
        case types.CREATE_EMPLOYEE_ERROR:
        case types.LOAD_QUALIFY_ADMIN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}