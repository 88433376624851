import { Avatar, Col, Divider, Row, Space, Tag, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IOrderBlock } from "../../definitions/orderBlock";
import LabeledNode from "../../components/utils/LabeledNode";
import { loadCurrenciesStart } from "../../redux/ResourcesRedux/resourcesAction";
import { IReduxState, IReduxStateResources } from "../../redux/types";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import { UserOutlined } from "@ant-design/icons";

export interface IOrderBlockDetailsProps {
  block: IOrderBlock;
}

const OrderBlockDetails: React.FC<IOrderBlockDetailsProps> = (props) => {
  const { block } = props;
  const dispatch = useDispatch();
  const { currencies } = useSelector<IReduxState, IReduxStateResources>(
    (state) => state.resources
  );

  useEffect(() => {
    dispatch(loadCurrenciesStart());
  }, [dispatch]);

  const selectedCurrency =
    block.CurrencyType &&
    currencies?.find((item) => item.id === block.CurrencyType?.Id);

  const withCurrency = (text: string | number) =>
    selectedCurrency ? `${text} ${selectedCurrency.description}` : text;

  return (
    <Row wrap gutter={[16, 32]}>
      <Col span={24}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Description"
          node={block.Description}
        />
      </Col>
      <Col span={24}>
        <LabeledNode
          direction="vertical"
          node={
            <Space
              direction="vertical"
              split={<Divider style={{ margin: "8px 0px" }} />}
              size={2}
              style={{ width: "100%" }}
            >
              <LabeledNode
                nodeIsText
                direction="horizontal"
                label="Delivery Cost"
                node={block.DeliveryCost_}
                textAlign="right"
              />
              <LabeledNode
                nodeIsText
                direction="horizontal"
                label="Bonus Cost"
                node={block.BonusCost_}
                textAlign="right"
              />
              <LabeledNode
                nodeIsText
                direction="horizontal"
                label="Earning Cost"
                node={withCurrency(block.EarningCost_)}
                textAlign="right"
              />
            </Space>
          }
        />
      </Col>
      {/* <Col span={12}></Col> */}
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Date Created"
          node={block.DateCreated_}
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText={!block.LastModified_}
          direction="vertical"
          label="Last Updated"
          node={
            block.LastModified_ ? (
              <Space>
                <Avatar
                  alt="Profile picture of the last person who updated this block"
                  shape="circle"
                  src={getLoadSingleImagePath(block.UpdatedBy)}
                  icon={<UserOutlined />}
                />
                <Typography.Text type="secondary">
                  Last updated {block.LastModified_}
                </Typography.Text>
              </Space>
            ) : (
              "N/A"
            )
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Estimated Trip Duration"
          node={block.EstimatedTripDuration_}
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Pickup Time"
          node={block.BlockPickUpdateTime_}
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Public Type Expiration Date"
          node={
            block.PublicTypeExpirationDate_
              ? block.PublicTypeExpirationDate_
              : "N/A"
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          direction="vertical"
          label="Status"
          node={
            <Space direction="vertical" size={2}>
              <Tag style={{ color: block.Status.Color }}>
                {block.Status.Name}
              </Tag>
              <Typography.Text type="secondary">
                {block.Status.Description}
              </Typography.Text>
            </Space>
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          direction="vertical"
          label="Priority"
          node={
            <Space direction="vertical" size={2}>
              <Tag style={{ color: block.Priority.Color }}>
                {block.Priority.Name}
              </Tag>
              <Typography.Text type="secondary">
                {block.Priority.Description}
              </Typography.Text>
            </Space>
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          direction="vertical"
          label="Type"
          node={
            <Space direction="vertical" size={2}>
              <Tag style={{ color: block.Type.Color }}>{block.Type.Name}</Tag>
              <Typography.Text type="secondary">
                {block.Type.Description}
              </Typography.Text>
            </Space>
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Delivery Type"
          node={
            <Space direction="vertical" size={2}>
              <Tag style={{ color: block.DeliveryType.Color }}>
                {block.DeliveryType.Name}
              </Tag>
              <Typography.Text type="secondary">
                {block.DeliveryType.Description}
              </Typography.Text>
            </Space>
          }
        />
      </Col>
    </Row>
  );
};

export default OrderBlockDetails;
