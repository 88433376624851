import * as types from "./actionType"



export const getReviewStart = (review) => ({
    type: types.GET_REVIEW_START,
    payload: review
})

export const getReviewSuccess = (review) => ({
    type: types.GET_REVIEW_SUCCESS,
    payload: review,
})

export const getReviewError = (error) => ({
    type: types.GET_REVIEW_ERROR,
    payload: error
})


export const getReviewSearchStart = (payload) => ({
    type: types.GET_REVIEW_SEARCH_START,
    payload
})

export const getReviewSearchSuccess = (review) => ({
    type: types.GET_REVIEW_SEARCH_SUCCESS,
    payload: review,
})

export const getReviewSearchError = (error) => ({
    type: types.GET_REVIEW_SEARCH_ERROR,
    payload: error
})

export const getReviewDriverStart = () => ({
    type: types.GET_REVIEW_DRIVER_START,
})

export const getReviewDriverSuccess = (review) => ({
    type: types.GET_REVIEW_DRIVER_SUCCESS,
    payload: review,
})

export const getReviewDriverError = (error) => ({
    type: types.GET_REVIEW_DRIVER_ERROR,
    payload: error
})