import { Col, Divider, Row, Space, Tag, Typography } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import LabeledNode from "../../components/utils/LabeledNode";
import { API_URL } from "../../globalVariable";

function EmployeeDetail(props) {
  const location = useLocation();
  const employeeData = location.state;
  const [employeeHistory, setEmployeeHistory] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      var response = await axios.get(
        API_URL +
          `Employee/History?employeeId=${
            employeeData?.EmployeeId === undefined
              ? ""
              : employeeData?.EmployeeId
          }`,
        {
          headers: {
            authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response?.status === 200) {
        setEmployeeHistory(response?.data);
      }
    };

    loadData();
  }, [employeeData?.EmployeeId]);

  return (
    <Fragment>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row>
          <Col span={18}>
            <Typography.Title level={5}>Employee Detail</Typography.Title>
          </Col>
        </Row>
        <Divider orientation="left">Employee Information</Divider>
        <div style={{ maxWidth: "900px", margin: "auto" }}>
          <Row wrap gutter={[16, 32]}>
            <Col span={6}>
              <img
                style={{ borderRadius: "10%" }}
                src={
                  API_URL + `img/default/${employeeData?.EmployeeId}/200/200/p`
                }
                alt=""
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Status"
                node={
                  <Space direction="vertical" size={2}>
                    <Tag style={{ color: employeeData?.Status?.Color }}>
                      {employeeData?.Status?.Name}
                    </Tag>
                  </Space>
                }
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Full Name"
                node={employeeData?.FullName}
              />
            </Col>
          </Row>

          <Row wrap gutter={[16, 32]}>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Email Address"
                node={employeeData?.EmailAddress}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Phone Number"
                node={employeeData?.PhoneNumber}
              />
            </Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Driver Number"
                node={employeeData?.DriverNumber}
              />
            </Col>
            <Col span={8}></Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={6}></Col>
            <Col span={18}>
              <LabeledNode
                direction="vertical"
                label="Address"
                node={employeeData?.Address}
              />
            </Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Assigned Role"
                node={employeeData?.AssignedRole}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Assigned Policy"
                node={employeeData?.AssignedGroupPolicy}
              />
            </Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Updated By"
                node={employeeData?.UpdatedBy}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Date Created"
                node={employeeData?.DateCreated_}
              />
            </Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Last Logged In"
                node={<Tag color="red">{employeeData?.LastLoggedIn}</Tag>}
              />
            </Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Last Updated"
                node={employeeData?.LastUpdated}
              />
            </Col>
          </Row>
          <br />
          <Row wrap gutter={[16, 32]}>
            <Col span={6}></Col>
            <Col span={8}>
              <LabeledNode
                direction="vertical"
                label="Assigned Branch"
                node={employeeData?.AssignedBranchInfo}
              />
            </Col>
          </Row>
        </div>
        <Divider orientation="left">Employee History</Divider>
        {employeeHistory?.map((data) => {
          return (
            <div style={{ maxWidth: "900px", margin: "auto" }}>
              <Row wrap gutter={[16, 32]}>
                <Col span={8}>
                  <LabeledNode
                    direction="vertical"
                    label="Status"
                    node={
                      <Space direction="vertical" size={2}>
                        <Tag style={{ color: data?.Status?.Color }}>
                          {data?.Status?.Name}
                        </Tag>
                      </Space>
                    }
                  />
                </Col>
                <Col span={8}>
                  <LabeledNode
                    direction="vertical"
                    label="Updated By"
                    node={data?.UpdatedBy}
                  />
                </Col>
                <Col span={8}>
                  <LabeledNode
                    direction="vertical"
                    label="Timestamp"
                    node={data?.TimeStamp}
                  />
                </Col>
              </Row>
              <br />
              <Row wrap gutter={[16, 32]}>
                <Col span={24}>
                  <LabeledNode
                    direction="vertical"
                    label="Reason"
                    node={data?.Reason}
                  />
                </Col>
              </Row>
              <br />
              <hr />
            </div>
          );
        })}
      </Space>
    </Fragment>
  );
}

export default EmployeeDetail;
