import { css } from "@emotion/css";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography
} from "antd";
import { useFormik } from "formik";
import { isString, noop } from "lodash";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { PartialDeep } from "type-fest";
import * as yup from "yup";
import { optionalAddressValidationSchema } from "../../../components/form/AddressFormInput";
import FormItemError from "../../../components/utils/FormItemError";
import { renderSelectOptionEnum } from "../../../components/utils/SelectOptionEnum";
import { IOrder } from "../../../definitions/orderBay";
import { IOrderBlock, IOrderBlockInput } from "../../../definitions/orderBlock";
import { appFormMessages } from "../../../global/messages";
import {
  loadBlockDeliveryTypesStart,
  loadBlockPriorityStart,
  loadBlockTimeTypeStart,
  loadBlockTypeStart,
  loadCountryCodeStart,
  loadCurrenciesStart
} from "../../../redux/ResourcesRedux/resourcesAction";
import { loadBranchStart } from "../../../redux/SettingsRedux/settingAction";
import {
  IReduxState,
  IReduxStateResources,
  IReduxStateSettings
} from "../../../redux/types";
import OrdersPagedByOrderTable from "../../OrderBayPage/order/OrdersPagedByOrderTable";
import OrderBlockFormAddressInfo from "./OrderBlockFormAddressInfo";
import OrderBlockSelectOrder from "./OrderBlockSelectOrders";

export type IOrderBlockFormInput = Omit<IOrderBlockInput, "orderIds"> & {
  orders: IOrder[];
};

export interface IOrderBlockFormProps {
  loading?: boolean;
  values?: IOrderBlockFormInput;
  block?: IOrderBlock;
  error?: string | null;
  onSubmit: (values: IOrderBlockFormInput) => void;
}

const initialValues: PartialDeep<IOrderBlockFormInput> = {
  currencyType: undefined,
  description: undefined,
  deliveryCost: undefined,
  bonusCost: undefined,
  priority: undefined,
  tripTimeType: undefined,
  estimatedTripDuration: undefined,
  type: undefined,
  publicTypeExpirationDate: undefined,
  pickUpDateTime: undefined,
  orders: [],
  deliveryType: undefined,
  deliveryAddressInfo: {
    branchAddressId: undefined,
    customAddress: undefined,
    supportContactInfo: undefined,
  },
  pickUpAddressInfo: {
    branchAddressId: undefined,
    customAddress: undefined,
    supportContactInfo: undefined,
  },
};

const PUBLIC_ORDER_BLOCK_TYPE = "Public";
const DISPATCH_ORDER_DELIVERY_TYPE = "DispatchingOrders";

const addressYupSchema = yup
  .object()
  .shape({
    ...optionalAddressValidationSchema,
  })
  .nullable();
const supportContactYupSchema = yup
  .object()
  .shape({
    contactName: yup.string().required("Contact name is required"),
    phoneNumber: yup.object().shape({
      number: yup.string().required("Phone number is required"),
      code: yup.string(),
    }),
  })
  .nullable();
const supportContactListYupSchema = yup.array().of(supportContactYupSchema);
const validationSchema = yup.object().shape({
  currencyType: yup.string(),
  description: yup.string().required(appFormMessages.requiredField),
  deliveryCost: yup.string().nullable(),
  bonusCost: yup.string().nullable(),
  priority: yup.string().required(appFormMessages.requiredField),
  tripTimeType: yup.string().required(appFormMessages.requiredField),
  estimatedTripDuration: yup.string().required(appFormMessages.requiredField),
  type: yup.string().required(appFormMessages.requiredField),
  publicTypeExpirationDate: yup.mixed().when("type", {
    is: PUBLIC_ORDER_BLOCK_TYPE,
    then: yup.string().required("Field is required if type is 'public'"),
  }),
  pickUpDateTime: yup.string().required(appFormMessages.requiredField),
  orders: yup
    .array()
    .required(appFormMessages.requiredField)
    .min(1, appFormMessages.requiredField),
  deliveryType: yup.string().required(appFormMessages.requiredField),
  deliveryAddressInfo: yup.object().shape({
    branchAddressId: yup.string().nullable(),
    customAddress: addressYupSchema,
    supportContactInfo: supportContactListYupSchema,
  }),
  pickUpAddressInfo: yup.object().shape({
    branchAddressId: yup.string().nullable(),
    customAddress: addressYupSchema,
    supportContactInfo: supportContactListYupSchema,
  }),
});

function disabledDate(current?: moment.Moment) {
  // Can not select days before today
  return !!(current && current < moment().startOf("day"));
}

const classes = {
  section: css({
    maxWidth: "500px",
    margin: "auto",
  }),
  orderIds: css({
    "& .ant-form-item-label": {
      maxWidth: "500px",
      margin: "auto",
    },

    "& .ant-form-item-explain": {
      maxWidth: "500px",
      margin: "auto",
      marginTop: "16px",
    },

    "& .ant-table": {
      height: "auto !important",
    },

    "& .ant-space": {
      display: "flex",
    },
  }),
  text: css({
    overflow: "auto",
    flex: 1,
    whiteSpace: "normal",
  }),
};

const OrderBlockForm: React.FC<IOrderBlockFormProps> = (props) => {
  const { loading, error, block, onSubmit } = props;

  console.log({ block, v: props.values });

  const formik = useFormik({
    validationSchema,
    onSubmit,
    initialValues: props.values || (initialValues as any),
  });

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadBranchStart());
    dispatch(loadCountryCodeStart());
    dispatch(loadCurrenciesStart());
    dispatch(loadBlockPriorityStart());
    dispatch(loadBlockTypeStart());
    dispatch(loadBlockDeliveryTypesStart());
    dispatch(loadBlockTimeTypeStart());
  }, [dispatch]);

  const {
    currencies,
    blockPriorities,
    blockTypes,
    orderBlockDeliveryTypes,
    blockTime: blockTimes,
  } = useSelector<IReduxState, IReduxStateResources>(
    (state) => state.resources
  );

  const { branch: branches } = useSelector<IReduxState, IReduxStateSettings>(
    (state) => state.settings
  );

  const currencyTypeNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Currency Type"
      help={
        formik.touched.currencyType && (
          <FormItemError message={formik.errors.currencyType} />
        )
      }
    >
      <Select
        placeholder="Currency type"
        value={formik.values.currencyType}
        onChange={(size) => formik.setFieldValue("currencyType", size)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {currencies?.map((type) => {
          return renderSelectOptionEnum(type);
        })}
      </Select>
    </Form.Item>
  );

  const descriptionNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Description"
      help={
        formik.touched.description && (
          <FormItemError message={formik.errors.description} />
        )
      }
    >
      <Input.TextArea
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="description"
        placeholder="Description"
        autoSize={{ minRows: 2 }}
        disabled={loading}
      />
    </Form.Item>
  );

  const selectedCurrency = currencies?.find(
    (item) => item.id === formik.values.currencyType
  );

  const withCurrency = (text: string) =>
    selectedCurrency ? `${text} (${selectedCurrency.description})` : text;

  const deliveryCostNode = (
    <Form.Item
      label="Delivery Cost"
      help={
        formik.touched.deliveryCost && (
          <FormItemError message={formik.errors.deliveryCost} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <InputNumber
        placeholder={withCurrency("Delivery cost")}
        value={formik.values.deliveryCost}
        onChange={(value) => formik.setFieldValue("deliveryCost", value)}
        onBlur={formik.handleBlur}
        name={"deliveryCost"}
        disabled={loading}
        style={{ width: "150px" }}
        min={0}
      />
    </Form.Item>
  );

  const bonusCostNode = (
    <Form.Item
      label="Bonus Cost"
      help={
        formik.touched.bonusCost && (
          <FormItemError message={formik.errors.bonusCost} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <InputNumber
        placeholder={withCurrency("Bonus cost")}
        value={formik.values.bonusCost}
        onChange={(value) => formik.setFieldValue("bonusCost", value)}
        onBlur={formik.handleBlur}
        name={"bonusCost"}
        disabled={loading}
        style={{ width: "150px" }}
        min={0}
      />
    </Form.Item>
  );

  const priorityNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Priority"
      help={
        formik.touched.priority && (
          <FormItemError message={formik.errors.priority} />
        )
      }
    >
      <Select
        placeholder="Priority"
        value={formik.values.priority}
        onChange={(size) => formik.setFieldValue("priority", size)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {blockPriorities?.map(renderSelectOptionEnum)}
      </Select>
    </Form.Item>
  );

  const tripTimeTypeNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Trip Time Type"
      help={
        formik.touched.tripTimeType && (
          <FormItemError message={formik.errors.tripTimeType} />
        )
      }
    >
      <Select
        placeholder="Trip time type"
        value={formik.values.tripTimeType}
        onChange={(size) => formik.setFieldValue("tripTimeType", size)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {blockTimes?.map((size) => {
          return (
            <Select.Option key={size} value={size}>
              {size}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const typeNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Block Type"
      help={
        formik.touched.type && <FormItemError message={formik.errors.type} />
      }
    >
      <Select
        placeholder="Block type"
        value={formik.values.type}
        onChange={(size) => formik.setFieldValue("type", size)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {blockTypes?.map(renderSelectOptionEnum)}
      </Select>
    </Form.Item>
  );

  const deliveryTypeNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Delivery Type"
      help={
        formik.touched.deliveryType && (
          <FormItemError message={formik.errors.deliveryType} />
        )
      }
    >
      <Select
        placeholder="Delivery type"
        value={formik.values.deliveryType}
        onChange={(size) => formik.setFieldValue("deliveryType", size)}
        onBlur={formik.handleBlur}
        disabled={loading}
      >
        {orderBlockDeliveryTypes?.map(renderSelectOptionEnum)}
      </Select>
    </Form.Item>
  );

  const estimatedTripDurationNode = (
    <Form.Item
      required
      label="Estimated Trip Duration"
      help={
        formik.touched.estimatedTripDuration && (
          <FormItemError message={formik.errors.estimatedTripDuration} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <InputNumber
        placeholder="Estimated trip duration"
        value={formik.values.estimatedTripDuration}
        onChange={(value) =>
          formik.setFieldValue("estimatedTripDuration", value)
        }
        onBlur={formik.handleBlur}
        name={"estimatedTripDuration"}
        disabled={loading}
        style={{ width: "150px" }}
      />
    </Form.Item>
  );

  const publicTypeExpirationDateNode = formik.values.type ===
    PUBLIC_ORDER_BLOCK_TYPE && (
    <Form.Item
      required
      label="Public Block Expiration Date"
      help={
        formik.touched.publicTypeExpirationDate && (
          <FormItemError message={formik.errors.publicTypeExpirationDate} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <DatePicker
        format="YYYY-MM-DD"
        disabledDate={disabledDate}
        placeholder="Public block expiration date"
        value={
          formik.values.publicTypeExpirationDate
            ? moment(formik.values.publicTypeExpirationDate)
            : null
        }
        onChange={(date) =>
          formik.setFieldValue("publicTypeExpirationDate", date?.toISOString())
        }
        disabled={loading}
        style={{ width: "200px" }}
      />
    </Form.Item>
  );

  const pickUpDateTimeNode = (
    <Form.Item
      required
      label="Pickup Time"
      help={
        formik.touched.pickUpDateTime && (
          <FormItemError message={formik.errors.pickUpDateTime} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <DatePicker
        format="YYYY-MM-DD hh:mm A"
        disabledDate={disabledDate}
        // disabledTime={disabledDateTime}
        showTime={{ defaultValue: moment("00:00:00", "hh:mm:ss A") }}
        placeholder="Public block expiration date"
        value={
          formik.values.pickUpDateTime
            ? moment(formik.values.pickUpDateTime)
            : null
        }
        onChange={(date) =>
          formik.setFieldValue("pickUpDateTime", date?.toISOString())
        }
        disabled={loading}
        style={{ width: "200px" }}
      />
    </Form.Item>
  );

  const pickupAddressNode = (
    <OrderBlockFormAddressInfo
      loading={loading}
      isCustomAddress={
        !!(
          formik.values.pickUpAddressInfo?.customAddress ||
          block?.PickUpAddress.IsCustomAddress
        )
      }
      branches={branches}
      basePath="pickUpAddressInfo"
      formik={formik}
      selectAddressTypeLabels={{
        formItem: "Pickup Address Type",
        branch: "Pickup from branch",
        custom: "Pickup from address",
      }}
      branchAddressLabels={{
        formItem: "Branch Pickup Address",
        placeholder: "Branch pickup address",
      }}
    />
  );

  const deliveryAddressNode = formik.values.deliveryType &&
    formik.values.deliveryType !== DISPATCH_ORDER_DELIVERY_TYPE && (
      <OrderBlockFormAddressInfo
        loading={loading}
        isCustomAddress={
          !!(
            formik.values.deliveryAddressInfo?.customAddress ||
            block?.DeliveryAddress.IsCustomAddress
          )
        }
        branches={branches}
        basePath="deliveryAddressInfo"
        formik={formik}
        selectAddressTypeLabels={{
          formItem: "Delivery Address Type",
          branch: "Deliver to branch",
          custom: "Deliver to address",
        }}
        branchAddressLabels={{
          formItem: "Branch Delivery Address",
          placeholder: "Branch delivery address",
        }}
      />
    );

  const setFieldValue = formik.setFieldValue;
  const onCommitSelectedOrders = React.useCallback(
    (orders: IOrder[]) => {
      setFieldValue("orders", orders);
    },
    [setFieldValue]
  );

  const selectedOrders = formik.values.orders;
  const selectedOrdersKeys = React.useMemo(
    () => selectedOrders.map((order) => order.OrderId),
    [selectedOrders]
  );

  const onUpdateSelectedOrders = React.useCallback(
    (selectedRows: IOrder[]) => {
      const completeAction = () => {
        setFieldValue("orders", selectedRows);
      };

      if (selectedRows.length === 0) {
        Modal.confirm({
          title: "Are you sure you want to deselect all the selected orders?",
          okText: "Yes",
          cancelText: "No",
          okType: "primary",
          okButtonProps: { danger: true },
          onOk: () => {
            completeAction();
          },
          onCancel() {
            // do nothing
          },
        });
      } else {
        completeAction();
      }
    },
    [setFieldValue]
  );

  const orderIdsNode = (
    <Form.Item
      required
      // label="Order Items"
      help={
        formik.touched.orders &&
        isString(formik.errors.orders) && (
          <FormItemError message={formik.errors.orders} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      className={classes.orderIds}
    >
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        {formik.values.orders.length > 0 && (
          <React.Fragment>
            <Typography.Title level={5}>Selected Orders</Typography.Title>
            <div
              style={{
                width: "100%",
                overflow: "auto",
              }}
            >
              <OrdersPagedByOrderTable
                withCheckbox
                omitActions
                data={formik.values.orders}
                onSelectRows={onUpdateSelectedOrders}
                selectedRowKeys={selectedOrdersKeys}
                onCompleteDelete={noop}
                onCompleteUpdate={noop}
              />
            </div>
            <Divider />
          </React.Fragment>
        )}
        <div
          style={{
            width: "100%",
            overflow: "auto",
          }}
        >
          <OrderBlockSelectOrder
            selectedOrders={formik.values.orders}
            onCommitSelectedOrders={onCommitSelectedOrders}
          />
        </div>
      </Space>
    </Form.Item>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.section}>
        {error && (
          <Form.Item>
            <Alert type="error" message={error} />
          </Form.Item>
        )}
        {descriptionNode}
        {currencyTypeNode}
        <Row wrap gutter={16}>
          <Col span={12}>{deliveryCostNode}</Col>
          <Col span={12}>{bonusCostNode}</Col>
        </Row>
        {priorityNode}
        <Row wrap gutter={16}>
          <Col span={12}>{tripTimeTypeNode}</Col>
          <Col span={12}>{estimatedTripDurationNode}</Col>
        </Row>
        {typeNode}
        {publicTypeExpirationDateNode}
        {pickUpDateTimeNode}
      </div>
      <Divider orientation="left">Orders</Divider>
      {orderIdsNode}
      <Divider orientation="left">Pickup</Divider>
      <div className={classes.section}>{pickupAddressNode}</div>
      <Divider orientation="left">Delivery</Divider>
      <div className={classes.section}>
        {deliveryTypeNode}
        {deliveryAddressNode}
        <Form.Item style={{ marginTop: "32px" }}>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </div>
    </form>
  );
};

export default OrderBlockForm;
