import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message } from "antd";
import React from "react";
import OrderBayAPI from "../../../api/endpoints/orderBay";
import { IEndpointMessageResult } from "../../../api/types";
import ReasonForm from "../../../components/form/ReasonForm";

export interface IOrderItemActionsButtonProps {
  buttonClassName?: string;
  orderId: string;
  onCompleteUpdate: () => void;
  onCompleteDelete: () => void;
}

enum Actions {
  Activate = "activate",
  Deactivate = "deactivate",
  DeleteOrder = "delete",
  AcknowledgePickup = "acknowledge pickup",
  PrepareForAnotherTrip = "prepare for trip",
}

const messageKey = Math.random().toString();

const OrderItemActionsButton: React.FC<IOrderItemActionsButtonProps> = (
  props
) => {
  const { orderId, buttonClassName, onCompleteUpdate, onCompleteDelete } =
    props;
  const [showReasonForm, setReasonForm] = React.useState<{
    action: Actions;
  } | null>();
  const [reasonFormError, setReasonFormError] = React.useState<string>();
  const [reasonFormLoading, setReasonFormLoaing] = React.useState<boolean>();
  const [actionLoading, setActionLoading] = React.useState<boolean>();

  const onCompleteReasonForm = React.useCallback(
    async (reason: string) => {
      if (!showReasonForm) {
        return;
      }

      setReasonFormLoaing(true);
      setReasonFormError("");

      try {
        let response: IEndpointMessageResult | null = null;
        switch (showReasonForm.action) {
          case Actions.Activate:
            response = await OrderBayAPI.activateOrder({ reason, id: orderId });
            break;
          case Actions.Deactivate:
            response = await OrderBayAPI.deactivateOrder({
              reason,
              id: orderId,
            });
            break;
          case Actions.DeleteOrder:
            response = await OrderBayAPI.deleteOrder({ reason, id: orderId });
            break;
          default:
            return; // Reamining actions don't require reason
        }

        message.success(
          response?.Message || response?.message || "Request completed"
        );

        if (showReasonForm.action === Actions.DeleteOrder) {
          onCompleteDelete();
        } else {
          onCompleteUpdate();
        }
      } catch (error: any) {
        let errorMessage = error?.message || "Error completing request";
        setReasonFormError(errorMessage);
        message.error(errorMessage);
      }

      setReasonFormLoaing(false);
    },
    [orderId, showReasonForm, onCompleteUpdate, onCompleteDelete]
  );

  const closeReasonForm = React.useCallback(() => {
    setReasonForm(null);
    setReasonFormError("");
    setReasonFormLoaing(false);
  }, []);

  const reasonFormNode = showReasonForm && (
    <ReasonForm
      useModal
      loading={reasonFormLoading}
      error={reasonFormError}
      onSubmit={onCompleteReasonForm}
      onCancel={closeReasonForm}
      title={
        showReasonForm.action === Actions.Activate
          ? "Activate Order"
          : showReasonForm.action === Actions.Deactivate
          ? "Deactivate Order"
          : "Delete Order"
      }
    />
  );

  const onSelectAction = React.useCallback(
    async (info: { key: string }) => {
      let response: IEndpointMessageResult | null = null;

      function beginMessage(str: string = "Updating order") {
        message.loading({ type: "loading", key: messageKey, content: str });
      }

      function successMessage(inputResponse: IEndpointMessageResult) {
        message.success({
          type: "success",
          key: messageKey,
          content:
            inputResponse?.Message ||
            inputResponse?.message ||
            "Request completed",
        });
      }

      function showErrorMessage(error: string) {
        message.error({
          type: "error",
          key: messageKey,
          content: error,
          duration: 15,
        });
      }

      try {
        switch (info.key) {
          case Actions.Activate:
            setReasonForm({ action: Actions.Activate });
            break;
          case Actions.Deactivate:
            setReasonForm({ action: Actions.Deactivate });
            break;
          case Actions.DeleteOrder:
            setReasonForm({ action: Actions.DeleteOrder });
            break;
          case Actions.AcknowledgePickup: {
            setActionLoading(true);
            beginMessage("Acknowledging order pickup");
            response = await OrderBayAPI.acknowledgeOrderPickupByRecipient({
              orderItemId: orderId,
            });
            successMessage(response);
            break;
          }
          case Actions.PrepareForAnotherTrip: {
            setActionLoading(true);
            beginMessage("Preparing order for another trip");
            response = await OrderBayAPI.prepareOrderForAnotherTrip({
              orderItemId: orderId,
            });
            successMessage(response);
            break;
          }
        }
      } catch (error: any) {
        let errorMessage = error?.message || "Error completing request";
        showErrorMessage(errorMessage);
        setActionLoading(false);
      }
    },
    [orderId]
  );

  const menuOptionsNode = (
    <Menu onClick={onSelectAction}>
      <Menu.Item key={Actions.Activate} disabled={actionLoading}>
        Activate order
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={Actions.Deactivate} disabled={actionLoading}>
        Deactivate order
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={Actions.DeleteOrder} disabled={actionLoading}>
        Delete order
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={Actions.PrepareForAnotherTrip} disabled={actionLoading}>
        Prepare for another trip
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={Actions.AcknowledgePickup} disabled={actionLoading}>
        Acknowledge order pickup
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      {reasonFormNode}
      <Dropdown arrow overlay={menuOptionsNode}>
        <Button
          icon={<EllipsisOutlined />}
          className={buttonClassName}
        ></Button>
      </Dropdown>
    </React.Fragment>
  );
};

export default OrderItemActionsButton;
