import {
  EllipsisOutlined,
  FileDoneOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  List,
  Menu,
  Modal,
  Result,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Paginations from "../../components/Pagination";
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { API_URL } from "../../globalVariable";
import {
  getDriverPaymentStart,
  getPaymentStart,
} from "../../redux/PaymentRedux/action";

const { Option } = Select;

function Payment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState();
  const [paymentInfo, setPaymentInfo] = useState();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  var queryPage = parseInt(query.get("page"));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryPage);
  const [pageSize, setPageSize] = useState(10);

  const handlePaymentModalOpen = (props) => {
    setPaymentModal(true);
    setPaymentDetail(props);
  };

  const handlePaymentModalClose = () => {
    setPaymentModal(false);
  };

  const markAsPaid = async (data) => {
    const payload = {
      driverId: driver,
      type: action,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
   
    swal({
      text: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willSuccess) => {
      if (willSuccess) {
        try {
          fetch(API_URL + `Payment/MarkDeliveryAsPaid?blockId=${data?.BlockId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${Cookies.get('accessToken')}`
            },
        })
            .then(res => res.json())
            .then(data =>  dispatch(getPaymentStart(payload)) )
        } 
        catch (e) {
          console.log({ e });
        }
      } else {
        console.log("No");
      }
    });
  };

  useEffect(() => {
    const loadFunc = async () => {
      try {
        var response = await axios.get(
          API_URL +
            `Payment/GetDriverDetailPaymentInfo?driverId=${paymentDetail?.DriverId}`,
          {
            headers: {
              authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          setPaymentInfo(response?.data);
        }
      } catch (e) {
        console.log({ e });
      }
    };

    if(paymentDetail?.DriverId) {
      loadFunc();
    }

    
  }, [paymentDetail?.DriverId]);

  const { payments, driverFilter, loading, error } = useSelector(
    (state) => state.payments
  );

  const [action, setAction] = useState("");
  const [driver, setDriver] = useState("");

  const handleChange = (value) => {
    setAction(value);
  };

  const handleDriverChange = (value) => {
    setDriver(value);
  };

  useEffect(() => {
    const payload = {
      driverId: driver,
      type: action,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(getPaymentStart(payload));
    dispatch(getDriverPaymentStart());
  }, [currentPage, pageSize]);

  useEffect(() => {
    const payload = {
      driverId: driver,
      type: action,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(getPaymentStart(payload));
  }, [driver]);

  useEffect(() => {
    const payload = {
      driverId: driver,
      type: action,
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(getPaymentStart(payload));
  }, [action]);

  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const makeGroupPayment = async () => {
    try {
      var response = await axios.post(
        API_URL + `Payment/GroupPayment?driverId=${paymentDetail?.DriverId}`,
        {
          headers: {
            authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      console.log({ response });
    } catch (e) {
      console.log({ e });
    }
  };

  const parcelCellRenderer = (props) => {
    // return (
    //   <div>
    //     {props?.data.TripAsBeenPaid ? (
    //       ""
    //     ) : (
    //       <Tooltip title="Approve Payment">
    //         <FileDoneOutlined
    //           onClick={() => markAsPaid(props?.data)}
    //           style={{ marginRight: "5%", cursor: "pointer" }}
    //         />
    //       </Tooltip>
    //     )}
    //     <Tooltip title="Payment Detail">
    //       <ProfileOutlined
    //        // onClick={() => navigate(props?.data?.id)}
    //        // onClick={() => navigate(props?.data?.id, {state: props?.data })}
    //         style={{ marginRight: "5%", cursor: "pointer" }}
    //       />
    //     </Tooltip>
    //   </div>
    // );
  };

  const statusCellRenderer = (props) => {
    return <p>{props.data.TripAsBeenPaid ? "Yes" : "No"}</p>;
  };

  const tripCellRenderer = (props) => {
    return <p>{props.data.TripEarning_}</p>;
  };

  const paidCellRenderer = (props) => {
    return (
      <p>
        {props?.data?.TripAsBeenPaid ? (
          <Tag color={"green"}>{props?.data?.TripAsBeenPaid_}</Tag>
        ) : (
          <Tag color={"red"}>{props?.data?.TripAsBeenPaid_}</Tag>
        )}
      </p>
    );
  };

  const acknowledgeCellRenderer = (props) => {
    return <p>{props.data?.TripPaymentAcknowledgedBy}</p>;
  };

  const frameworkComponents = {
    parcelCellRenderer: parcelCellRenderer,
    statusCellRenderer: statusCellRenderer,
    tripCellRenderer: tripCellRenderer,
    paidCellRenderer: paidCellRenderer,
    acknowledgeCellRenderer: acknowledgeCellRenderer,
  };

  const menuOptionsNode = (data) => (
    <Menu>
      {data.TripAsBeenPaid ? (
        ""
      ) : (
        <Tooltip title="Approve Payment">
          <Menu.Item onClick={() => markAsPaid(data)}>
            Approve Payment
          </Menu.Item>
        </Tooltip>
      )}
      <Tooltip title="Payment Detail">
        <Menu.Item
          onClick={() =>
            navigate(`/payment/${data?.BlockId}`, {state: data })
          }
        >
          Payment Detail
        </Menu.Item>
      </Tooltip>
    </Menu>
  );

  const columns = [
    {
      title: "Block Number",
      width: 150,
      ellipsis: true,
      dataIndex: "BlockNum",
      key: "BlockNum",
      render(text, record) {
        return (
          <Link to={`/order-block/${record?.BlockId}`} state={record}>
            {record?.BlockNum}
          </Link>
        );
      },
    },
    {
      title: "Driver Name",
      width: 150,
      ellipsis: true,
      dataIndex: "DriverName",
      key: "DriverName",
      render(text, record) {
        return (
          <Link to={`/payment/${record?.BlockId}`} state={record}>
            {record?.DriverName}
          </Link>
        );
      },
    },
    {
      title: "Status",
      width: 100,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return (
          <NodeWithTip>
            <Tag>{record.TripAsBeenPaid_}</Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Trip Earning",
      width: 170,
      dataIndex: "TripEarning",
      key: "TripEarning",
      render(text, record) {
        return <p>{record.TripEarning_}</p>;
      },
    },
    {
      title: "Acknowledged By",
      width: 140,
      render(text, record) {
        return <p>{record.TripPaymentAcknowledgedBy}</p>;
      },
    },
    {
      title: "Trip Payment Acknowledged At",
      width: 150,
      dataIndex: "TripPaymentAcknowledgedAt",
      key: "TripPaymentAcknowledgedAt",
    },
    {
      title: "",
      key: "Actions",
      width: 48,
      render: (unused, record) => {
        return (
          <Dropdown arrow overlay={menuOptionsNode(record)}>
            <Button
              icon={<EllipsisOutlined />}
              // className={buttonClassName}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];

  const onChangeData = (page, pageSize) => {
    navigate(`/payment?page=${page}`);
    setPageSize(pageSize);
    setCurrentPage(page);
  };

 // console.log("payment",payments?.Data)

  return (
    <div style={{ width: "100%" }}>
      <div className="employee-actions">
        <div>
          <Select
            style={{ padding: "10px 0px 10px 10px", width: "100%" }}
            defaultValue="Filter by Type"
            onChange={handleChange}
          >
            <Option value="">Filter by Type</Option>
            <Option value="Paid">Paid</Option>
            <Option value="UnPaid">UnPaid</Option>
          </Select>
          <Select
            defaultValue="Filter by Driver"
            onChange={handleDriverChange}
            style={{ padding: "10px 0px 10px 10px", width: "100%" }}
          >
            <Option value="">Filter by Driver</Option>
            {driverFilter?.map((ploy, i) => {
              return (
                <Option key={i} value={ploy.DriverId}>
                  {ploy.DriverName}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>

      {error !== null && <Result title={error?.response?.data?.message} />}
      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        payments?.Data?.length >= 1 &&
        error === null && (
          <div
            style={{
              display: "flex",
              height: "90%",
              // flex: 1,
              width: "100%",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            <Table
              bordered
              size="small"
              dataSource={payments?.Data}
              columns={columns}
              rowKey={(row) => row.BlockId}
              pagination={false}
              //scroll={{ y: 450 }}
            />
          </div>
        )
      )}
      <br />
      <Paginations
        data={payments}
        currentPage={isNaN(currentPage) ? page : currentPage}
        onChangeData={onChangeData}
      />

      {/* Modal for paymentDetail */}
      <Modal
        title="Payment Detail"
        visible={paymentModal}
        footer={null}
        onCancel={handlePaymentModalClose}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Driver Name</a>}
                  description={paymentDetail?.DriverName}
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Trip Earning</a>}
                  description={paymentDetail?.TripEarning}
                />
              </List.Item>
            </List>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Trip As Been Paid</a>}
                  description={
                    paymentDetail?.TripAsBeenPaid ? (
                      <Tag color={"green"}>
                        {paymentDetail?.TripAsBeenPaid_}
                      </Tag>
                    ) : (
                      <Tag color={"red"}>{paymentDetail?.TripAsBeenPaid_}</Tag>
                    )
                  }
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Trip Payment Date</a>}
                  description={paymentDetail?.TripPaymentAcknowledgedAt}
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Total Amount Acknowledged As Paid</a>}
                  description={paymentInfo?.TotalAmountAcknowledgedAsPaid.map(
                    (data) => {
                      return (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {data}
                        </div>
                      );
                    }
                  )}
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Total Amount Earn</a>}
                  description={paymentInfo?.TotalAmountEarn.map((data) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {data}
                      </div>
                    );
                  })}
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Total Amount Yet To Be Acknowledged As Paid</a>}
                  description={paymentInfo?.TotalAmountYetToBeAcknowledgedAsPaid.map(
                    (data) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "30%",
                          }}
                        >
                          <Tag style={{ marginBottom: "3px" }} color={"red"}>
                            {data}
                          </Tag>
                        </div>
                      );
                    }
                  )}
                />
              </List.Item>
            </List>
          </div>
        </div>
        <button onClick={() => makeGroupPayment()}>Make Group Payment</button>
      </Modal>
    </div>
  );
}

export default Payment;
