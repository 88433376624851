import { useResponsive } from "ahooks";

export interface IResponsiveInfo {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export default function useScreenSize() {
  const responsive = useResponsive();
  const isLargeScreen = responsive.xl;
  const isMediumScreen = !isLargeScreen && (responsive.md || responsive.lg);
  const isSmallScreen = !isMediumScreen && (responsive.xs || responsive.sm);
  return { isSmallScreen, isMediumScreen, isLargeScreen };
}
