import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Result, Table, Tag } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import { appLoggedInPaths } from "../../global/paths";
import {
  getStatisticsStart,
  getTopBlocksStart,
  getTopOrdersStart,
} from "../../redux/DashboardRedux/action";
import "./dashboard.css";

function Dashboard() {
  const dispatch = useDispatch();
  const { statistics, topBlocks, topOrders, loading } = useSelector(
    (state) => state.dashboards
  );

  useEffect(() => {
    dispatch(getStatisticsStart());
    dispatch(getTopBlocksStart());
    dispatch(getTopOrdersStart());
  }, []);

  if (loading) {
    return <Loader />;
  }

  const columns = [
    {
      title: "Block Number",
      width: 180,
      dataIndex: "BlockNumber",
      key: "BlockNumber",
      render(text, record) {
        return (
          <Link to={`${appLoggedInPaths.orderBlock}/${record.BlockId}`}>
            {record.BlockNumber}
          </Link>
        );
      },
    },
    {
      title: "Last Updated",
      width: 150,
      dataIndex: "LastUpdated",
      key: "LastUpdated",
    },
    {
      title: "Status",
      width: 200,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return <Tag color={record.Status.Color}>{record.Status.Name}</Tag>;
      },
    },
  ];

  const orderColumn = [
    {
      title: "Order Number",
      width: 180,
      dataIndex: "OrderNum",
      key: "OrderNum",
      render(text, record) {
        return (
          <Link to={`/order-bay/grouped-by-order/${record.OrderId}`}>
            {record.OrderNum}
          </Link>
        );
      },
    },
    {
      title: "Assigned Drivers",
      width: 150,
      dataIndex: "AssignedDrivers",
      key: "AssignedDrivers",
    },
    {
      title: "Status",
      width: 200,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return <Tag color={record.Status.Color}>{record.Status.Name}</Tag>;
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <div className="dashboard">
        <h1>Overview</h1>

        <div className="dashboard-overview">
          <div className="rider">
            {/* <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1624015971/movebot/Group_452_dvwquv.png" /> */}
            <ol>
              <li>
                No man can deliver the goods if his heart is heavier than the
                load. Be happy to deliver the goods.
              </li>
              <li>
                Give every client the best delivery experience. No matter the
                inconvenience.
              </li>
              <li>Deliver quality, on time and safe with MoveBot</li>
            </ol>
          </div>
        </div>

        <div className="grid-layout">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={3}>
              <Paper className="container-box">
                <p>Total Transactions</p>
                <div style={{ marginTop: "2%" }}>
                  <h2 style={{ fontSize: "30px" }}>
                    {statistics?.TotalTransaction}
                  </h2>
                  <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1624017243/movebot/Cash_wq3dau.png" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Paper className="container-box">
                <p>Total Drivers</p>
                <div style={{ marginTop: "2%" }}>
                  <h2 style={{ fontSize: "30px" }}>
                    {statistics?.TotalDrivers}
                  </h2>
                  <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1624017243/movebot/Group_4_ljq6vr.png" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Paper className="container-box">
                <p>Successful delivery</p>
                <div style={{ marginTop: "2%" }}>
                  <h2 style={{ fontSize: "30px" }}>
                    {statistics?.TotalOrdersDelivered}
                  </h2>
                  <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1624017243/movebot/Vector_3_dqtdeu.png" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Paper className="container-box">
                <p>Orders In Transit</p>
                <div style={{ marginTop: "2%" }}>
                  <h2 style={{ fontSize: "30px" }}>
                    {statistics?.BlockOrderInTransit}
                  </h2>
                  <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1624017243/movebot/Group_3_dgonfs.png" />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
              <div className="dashboard-row-div1-mini">
                <p>Top Block Order</p>
              </div>
              <div className="dashboard-row-div1">
              {topBlocks?.length < 1 && <Result title="No Item Available" />}

              {topBlocks?.length >= 1 && (
                <Table
                  bordered
                  size="small"
                  dataSource={topBlocks}
                  columns={columns}
                  rowKey={(row) => row.BlockId}
                  pagination={false}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className="dashboard-row-div1-mini">
              <p>Ongoing Order</p>
            </div>
            <div className="dashboard-row-div1">
            {topOrders?.length < 1 && <Result title="No Item Available" />}
            {topOrders?.length >= 1 && (
              <>
                <Table
                  bordered
                  size="small"
                  dataSource={topOrders}
                  columns={orderColumn}
                  rowKey={(row) => row.BlockId}
                  pagination={false}
                />
              </>
            )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Dashboard;
