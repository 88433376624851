import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import OrderBayAPI from "../../../api/endpoints/orderBay";
import { IEndpointMessageResult } from "../../../api/types";
import ReasonForm from "../../../components/form/ReasonForm";
import { IAcknowledgeParcelInput } from "../../../definitions/orderBay";
import { loadUnGroupOrderBayStart } from "../../../redux/OrderbayRedux/orderbayAction";
import OrderBayAcknowledgeForm from "../form/OrderBayAcknowledgeForm";

export interface IParcelActionsButtonProps {
  buttonClassName?: string;
  canAcknowledgeForm?: boolean;
  isPayOnDelivery: boolean;
  parcelId: string;
  onCompleteDelete: () => void;
}

enum Actions {
  DeleteParcel = "delete",
  AcknowledgeParcel = "acknowledge",
}

const messageKey = Math.random().toString();

const ParcelActionsButton: React.FC<IParcelActionsButtonProps> = (props) => {
  const {
    parcelId,
    buttonClassName,
    canAcknowledgeForm,
    isPayOnDelivery,
    onCompleteDelete,
  } = props;

  const [showReasonForm, setReasonForm] = React.useState<{
    action: Actions;
  } | null>();
  const [showAcknowledgeForm, setAcknowledgeForm] = React.useState(false);
  const [formError, setFormError] = React.useState<string>();
  const [formLoading, setFormLoaing] = React.useState<boolean>();
  const [actionLoading, setActionLoading] = React.useState<boolean>();
  const dispatch = useDispatch();

  const onCompleteReasonForm = React.useCallback(
    async (reason: string) => {
      if (!showReasonForm) {
        return;
      }

      setFormLoaing(true);
      setFormError("");

      try {
        let response: IEndpointMessageResult | null = null;
        switch (showReasonForm.action) {
          case Actions.DeleteParcel:
            response = await OrderBayAPI.deleteParcel({ reason, id: parcelId });
            break;
          default:
            return; // Reamining actions don't require reason
        }

        message.success(
          response?.Message || response?.message || "Request completed"
        );

        if (showReasonForm.action === Actions.DeleteParcel) {
          onCompleteDelete();
        }
      } catch (error: any) {
        let errorMessage = error?.message || "Error completing request";
        setFormError(errorMessage);
        message.error(errorMessage);
      }

      setFormLoaing(false);
    },
    [parcelId, showReasonForm, onCompleteDelete]
  );

  const closeForm = React.useCallback(() => {
    setReasonForm(null);
    setAcknowledgeForm(false);
    setFormError("");
    setFormLoaing(false);
  }, []);

  const onAcknowledgeParcel = async (data: IAcknowledgeParcelInput) => {
    setFormLoaing(true);
    setFormError("");

    try {
      const response = await OrderBayAPI.acknowledgeParcel({
        parcelId: data.parcelId,
        estimatedDeliveryDate: data.estimatedDeliveryDate,
        isPaymentOptionPOD: data.isPaymentOptionPOD,
        paidInfo: data.paidInfo,
        currencyType: data.currencyType,
        cost: data.cost,
        taxPerc: data.taxPerc,
        insuranceCost: data.insuranceCost,
      });

      dispatch(loadUnGroupOrderBayStart());
      message.success(response.message || response.Message);
      closeForm();
    } catch (error: any) {
      const responseMessage = error?.message || "Error acknowledging parcel";
      message.error(responseMessage);
      setFormError(responseMessage);
    }

    setFormLoaing(false);
  };

  const reasonFormNode = showReasonForm && (
    <ReasonForm
      useModal
      loading={formLoading}
      error={formError}
      onSubmit={onCompleteReasonForm}
      onCancel={closeForm}
      title={
        showReasonForm.action === Actions.DeleteParcel
          ? "Delete Parcel"
          : "Action Form"
      }
    />
  );

  const acknowledgeFormNode = showAcknowledgeForm && (
    <OrderBayAcknowledgeForm
      submitting={formLoading}
      error={formError}
      isPayOnDelivery={isPayOnDelivery}
      parcelId={parcelId}
      onSubmit={onAcknowledgeParcel}
      onCancel={closeForm}
    />
  );

  const onSelectAction = React.useCallback(async (info: { key: string }) => {
    function showErrorMessage(error: string) {
      message.error({
        type: "error",
        key: messageKey,
        content: error,
        duration: 15,
      });
    }

    try {
      switch (info.key) {
        case Actions.DeleteParcel:
          setReasonForm({ action: Actions.DeleteParcel });
          break;
        case Actions.AcknowledgeParcel:
          setAcknowledgeForm(true);
          break;
      }
    } catch (error: any) {
      let errorMessage = error?.message || "Error completing request";
      showErrorMessage(errorMessage);
      setActionLoading(false);
    }
  }, []);

  const menuOptionsNode = (
    <Menu onClick={onSelectAction}>
      <Menu.Item
        key={Actions.AcknowledgeParcel}
        disabled={actionLoading || !canAcknowledgeForm}
      >
        Acknowledge parcel
      </Menu.Item>
      <Menu.Divider key="divider-01" />
      <Menu.Item key={Actions.DeleteParcel} disabled={actionLoading}>
        Delete parcel
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      {reasonFormNode}
      {acknowledgeFormNode}
      <Dropdown arrow overlay={menuOptionsNode}>
        <Button
          icon={<EllipsisOutlined />}
          className={buttonClassName}
        ></Button>
      </Dropdown>
    </React.Fragment>
  );
};

export default ParcelActionsButton;
