import React from "react";
import { Skeleton, TableColumnType, Table } from "antd";

const MAX_ROW_COUNT = 7;

export interface ITableSkeletonProps {
  rowCount?: number;
  columns: TableColumnType<any>[];
}

const TableSkeleton: React.FC<ITableSkeletonProps> = (props) => {
  const { columns, rowCount } = props;
  const skeletonColumns = columns.map((column) => {
    return {
      ...column,
      render: () => <Skeleton active paragraph={{ rows: 1 }} title={false} />,
    };
  });

  return (
    <Table
      columns={skeletonColumns}
      dataSource={Array(
        rowCount && rowCount < MAX_ROW_COUNT ? rowCount : MAX_ROW_COUNT
      ).fill(1)}
      pagination={false}
      rowKey={() => Math.random()}
    />
  );
};

export default TableSkeleton;
