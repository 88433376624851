import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Space, Table, TableColumnType, Tag, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import NodeWithTip from "../../../components/utils/NodeWithTip";
import TableSkeleton from "../../../components/utils/TableSkeleton";
import { IParcel } from "../../../definitions/orderBay";
import { appOrderBayPaths } from "../../../global/paths";
import OrdersPagedByOrderTable from "../order/OrdersPagedByOrderTable";
import ParcelActionsButton from "./ParcelActionsButton";

export interface IOrdersPagedByParcelTableProps {
  loading?: boolean;
  data: IParcel[];
  onCompleteUpdateOrder: () => void;
  onCompleteDeleteOrder: () => void;
  onCompleteDeleteParcel: () => void;
}

const OrdersPagedByParcelTable: React.FC<IOrdersPagedByParcelTableProps> = (
  props
) => {
  const {
    data,
    loading,
    onCompleteDeleteOrder,
    onCompleteUpdateOrder,
    onCompleteDeleteParcel,
  } = props;

  // const ref = React.useRef(null);
  // const size = useSize(ref);
  const columns: TableColumnType<IParcel>[] = [
    {
      title: "Parcel Number",
      width: 150,
      ellipsis: true,
      dataIndex: "ParcelNumber",
      key: "ParcelNumber",
      render(text, record) {
        return (
          <Link to={`${appOrderBayPaths.groupedByParcel}/${record.ParcelId}`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: "Parcel Description",
      width: 280,
      dataIndex: "ParcelItemInfo",
      key: "ParcelItemInfo",
    },
    {
      title: "Status",
      width: 170,
      dataIndex: "ParcelStatus",
      key: "ParcelStatus",
      render(text, record) {
        return (
          <NodeWithTip tip={record.ParcelStatus.Description}>
            <Tag
              style={{ color: record.ParcelStatus.Color }}
              // color={record.ParcelStatus.Color}
            >
              {record.ParcelStatus.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Acknowledged",
      width: 140,
      dataIndex: "ParcelAlreadyAcknowledged",
      key: "ParcelAlreadyAcknowledged",
      render: (text, record) => {
        return (
          <Typography.Text>
            {record.ParcelAlreadyAcknowledged ? "Yes" : "No"}
          </Typography.Text>
        );
      },
    },
    {
      title: "Package Count",
      width: 150,
      dataIndex: "PackageCounts",
      key: "PackageCounts",
    },
    {
      title: "Total Estimated Weight",
      width: 200,
      dataIndex: "TotalEstimatedWeight",
      key: "TotalEstimatedWeight",
    },
    {
      title: "Date Created",
      width: 150,
      dataIndex: "ParcelDateCreated_",
      key: "ParcelDateCreated_",
    },
    {
      title: "Updated By",
      width: 150,
      dataIndex: "UpdatedBy",
      key: "UpdatedBy",
    },
    {
      title: "",
      key: "Actions",
      width: 48,
      fixed: "right",
      render: (unused, record) => {
        return (
          <ParcelActionsButton
            parcelId={record.ParcelId}
            onCompleteDelete={onCompleteDeleteParcel}
            // TODO: tell Kingsley to add payOnDelivery to parcel
            isPayOnDelivery={false}
            canAcknowledgeForm={!record.ParcelAlreadyAcknowledged}
          />
        );
      },
    },
  ];

  const expandedRowRender = React.useCallback(
    (parcel) => (
      <Space
        direction="vertical"
        style={{
          overflow: "auto",
          paddingTop: "8px",
          paddingBottom: "16px",
          paddingRight: "8px",
          // width: size?.width,
          width: "100%",
        }}
        className={css({
          "& .ant-table": {
            marginLeft: "0px !important",
          },
        })}
      >
        <Typography.Title level={5} style={{ marginLeft: "0px" }}>
          Orders
        </Typography.Title>
        <OrdersPagedByOrderTable
          data={parcel.OrdersInfo}
          onCompleteDelete={onCompleteDeleteOrder}
          onCompleteUpdate={onCompleteUpdateOrder}
        />
      </Space>
    ),
    [
      onCompleteDeleteOrder,
      onCompleteUpdateOrder,
      // size?.width
    ]
  );

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <div
    // ref={ref}
    >
      <Table
        bordered
        size="small"
        dataSource={data}
        columns={columns}
        rowKey={(row) => row.ParcelId}
        pagination={false}
        expandable={{
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
    </div>
  );
};

export default OrdersPagedByParcelTable;
