import * as types from "./policyActionType"
import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import { getPolicyApi, getFilterPolicyApi } from "../Api"
import { loadPolicyError, loadFilterPolicyError,loadFilterPolicySuccess, loadPolicySuccess } from "./policyAction"






function* onLoadPolicyStartAsync({payload}) {
    try {
        const response = yield call(getPolicyApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadPolicySuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadPolicyError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadFilterPolicyStartAsync({payload}) {
    try {
        const response = yield call(getFilterPolicyApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadFilterPolicySuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadFilterPolicyError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadPolicies() {
    yield takeEvery(types.LOAD_POLICY_START, onLoadPolicyStartAsync)
}

function* onLoadFilterPolicies() {
    yield takeEvery(types.LOAD_FILTER_POLICY_START, onLoadFilterPolicyStartAsync)
}



export const policySaga = [
    fork(onLoadPolicies),
    fork(onLoadFilterPolicies)
]