import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Result, Table, Tag } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Paginations from "../../components/Pagination";
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { appDimensions } from "../../components/utils/theme";
import { API_URL } from "../../globalVariable";
import {
  getDefectsSearchStart,
  getDefectsStart,
} from "../../redux/DefectRedux/action";
import { loadDefectTypeStart } from "../../redux/ResourcesRedux/resourcesAction";
import "./defects.css";

function Defects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(null);
  const { defects, loading, error } = useSelector((state) => state.defects);
  const { defectType } = useSelector((state) => state.resources);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  var queryType = query.get("search");
  var queryPage = parseInt(query.get("page"));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryPage);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(getDefectsStart(payload));
    dispatch(loadDefectTypeStart());
  }, [currentPage, pageSize]);



  const submitExecution = (data, item, description) => {

    const payload = {
      defectId: data,
      actionType: item,
    };

    swal({
      text: `${description}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          var response = await fetch(API_URL + `DefectOrders/ExecuteAction`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
            body: JSON.stringify(payload),
          });

          var dataResponse = await response.json();
          if (response.ok) {
            return swal({
              text: dataResponse.message,
              icon: "success",
            }).then(() => {
              const payload = {
                page: isNaN(currentPage) ? page : currentPage,
                pageSize,
              };
              dispatch(getDefectsStart(payload));
            });
          } else {
            if (dataResponse.message) {
              return swal({
                text: dataResponse.message,
                icon: "warning",
              });
            } else {
              return swal({
                text: dataResponse.Message,
                icon: "warning",
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
      } else {
      }
    });
  };

  const menuOptionsNode = (data) => (
    <Menu>
      {defectType &&
        defectType?.map((item) => {
          return (
            <Menu.Item onClick={() => submitExecution(data, item.id, item?.description)}>
              {item.name}
            </Menu.Item>
          );
        })}
      <Menu.Divider />
    </Menu>
  );

  const submitSearchWithKey = (value, event) => {
    const payload = {
      page,
      pageSize,
    };

    if (event?.key === "Enter" && searchValue?.length >= 1) {
      dispatch(getDefectsSearchStart(searchValue));
      return navigate(`/defects?search=${searchValue}`);
    }
    if (event?.type === "click" && value?.length >= 1) {
      dispatch(getDefectsSearchStart(searchValue));
      return navigate(`/defects?search=${searchValue}`);
    }
    if (event?.type === "click" && value?.length < 1) {
      dispatch(getDefectsStart(payload));
      return navigate(`/defects`);
    }
  };

  const columns = [
    {
      title: "Ticket Status",
      width: 150,
      ellipsis: true,
      dataIndex: "TicketStatus",
      key: "TicketStatus",
      render(text, record) {
        return (
          <NodeWithTip tip={record.TicketStatus.Description}>
            <Tag
              // style={{ color: record.Priority.Color }}
              color={record.TicketStatus.Color}
            >
              {record.TicketStatus.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },

    {
      title: "Defect Type",
      width: 220,
      dataIndex: "DefectType",
      key: "DefectType",
      render(text, record) {
        return (
          <Tag color={record.IssueCategory.Color}>
            {record.IssueCategory.Name}
          </Tag>
        );
      },
    },

    {
      title: "Driver Num",
      width: 120,
      dataIndex: "DriverNum",
      key: "DriverNum",
      render(text, record) {
        return (
          <Link to={{ pathname: `/defects/${record.DefectId}`, state: record }}>
            {record.DriverNum}
          </Link>
        );
      },
    },{
      title: "Driver Name",
      width: 150,
      dataIndex: "DriverName",
      key: "DriverName",
    } ,
    {
      title: "Order Num",
      width: 200,
      dataIndex: "OrderNum",
      key: "OrderNum",
    } ,
    
    {
      title: "Current Order Item Status",
      width: 200,
      ellipsis: true,
      dataIndex: "CurrentOrderItemStatus",
      key: "CurrentOrderItemStatus",
      render(text, record) {
        return (
          <NodeWithTip tip={record?.CurrentOrderItemStatus.Description}>
            <Tag
              // style={{ color: record.Priority.Color }}
              color={record?.CurrentOrderItemStatus.Color}
            >
              {record?.CurrentOrderItemStatus.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Updated By",
      width: 150,
      dataIndex: "UpdatedBy",
      key: "UpdatedBy",
    },
    {
      title: "Last Updated",
      width: 200,
      dataIndex: "LastUpdated",
      key: "LastUpdated",
    },
    {
      title: "Last Action Executed",
      width: 150,
      ellipsis: true,
      dataIndex: "LastActionExecuted",
      key: "LastActionExecuted",
      render(text, record) {
        return (
          <NodeWithTip tip={record.LastActionExecuted.Description}>
            <Tag
              // style={{ color: record.Priority.Color }}
              color={record.LastActionExecuted.Color}
            >
              {record.LastActionExecuted.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "",
      key: "Actions",
      width: 48,
      fixed: "right",
      render: (unused, record) => {
        return (
          <Dropdown arrow overlay={menuOptionsNode(record.DefectId)}>
            <Button
              icon={<EllipsisOutlined />}
              // className={buttonClassName}
            ></Button>
          </Dropdown>
        );
      },
    },
  ];

  const onChangeData = (page, pageSize) => {
    navigate(`/defects?page=${page}`);
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div style={{ width: "100%" }}>
      <Input.Search
        allowClear
        // disabled={disabled}
        placeholder="Enter search text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onSearch={(value, event) => submitSearchWithKey(value, event)}
        style={{ width: appDimensions.searchInputWidth }}
      />
      <br />
      <br />
      {error?.length > 1 || defects?.length < 1 ? (
        <Result title={error} />
      ) : null}

      {queryType === null ? (
        error === null &&
        defects.Data?.length >= 1 && (
          <>
            {loading ? (
              <TableSkeleton columns={columns} />
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "90%",
                  // flex: 1,
                  width: "100%",
                  flexDirection: "column",
                  overflowX: "auto",
                }}
              >
                <Table
                  bordered
                  size="small"
                  dataSource={defects?.Data}
                  columns={columns}
                  rowKey={(row) => row.BlockId}
                  pagination={false}
                  //scroll={{ y: 500 }}
                />

                <br />
              </div>
            )}
            <Paginations
              data={defects}
              currentPage={isNaN(currentPage) ? page : currentPage}
              onChangeData={onChangeData}
            />
          </>
        )
      ) : (
        <>
          {error === null &&
            defects?.length >= 1 &&
            (loading ? (
              <TableSkeleton />
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "90%",
                  // flex: 1,
                  width: "100%",
                  flexDirection: "column",
                  overflowX: "auto",
                }}
              >
                <Table
                  bordered
                  size="small"
                  dataSource={defects}
                  columns={columns}
                  rowKey={(row) => row.BlockId}
                  pagination={false}
                  // scroll={{ y: 500 }}
                />
              </div>
            ))}
        </>
      )}
    </div>
  );
}

export default Defects;
