import React from "react";
import { Space, Button, Form, Alert } from "antd";
import OrderBlockInputPreview from "./OrderBlockInputPreview";
import { IOrderBlockFormInput } from "./OrderBlockForm";
import { appFormControlClasses } from "../../../components/utils/theme";

export interface IOrderBlockFormPreviewProps {
  submitting?: boolean;
  error?: string | null;
  block: IOrderBlockFormInput;
  onSubmit: () => void;
  onBack: () => void;
}

const OrderBlockFormPreview: React.FC<IOrderBlockFormPreviewProps> = (
  props
) => {
  const { submitting, block, error, onSubmit, onBack } = props;

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      {error && (
        <div style={{ maxWidth: "900px", margin: "auto" }}>
          <Form.Item>
            <Alert type="error" message={error} />
          </Form.Item>
        </div>
      )}
      <OrderBlockInputPreview input={block} />
      <Form.Item style={{ marginTop: "32px" }}>
        <div
          className={appFormControlClasses.controlRoot}
          style={{ maxWidth: "900px", margin: "auto" }}
        >
          <div
            className={appFormControlClasses.spaceEvenContainer}
            style={{ marginRight: "16px" }}
          >
            <Button htmlType="button" disabled={submitting} onClick={onBack}>
              Back
            </Button>
          </div>
          <Button
            type="primary"
            disabled={submitting}
            htmlType="submit"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </Form.Item>
    </Space>
  );
};

export default OrderBlockFormPreview;
