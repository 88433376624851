import * as types from "./ResourceActionType";

const initiatState = {
  statusTypes: [],
  packageSizes: [],
  defectType: [],
  blockTime: [],
  loading: false,
  error: null,
  packageStatus: [],
  paidOptions: [],
  currencies: [],
  blockPriorities: [],
  blockTypes: [],
  ticketPriorities: [],
  weightUnit: [],
  orderCategories: [],
  ticketStatus: [],
  ticketRelatedServices: [],
  countryCode: [],
  longCountryCode: [],
  countryCodeObj: [],
  countryStateCities: [],
  getStates: [],
  trackingType: [],
  trackingFreq: [],
  orderBlockDeliveryTypes: [],
  orderBlockFilterCountryList: [],
  orderBlockFilterStateList: [],
  orderBlockFilterCityList: [],

  // Order blocks available drivers list
  orderBlocksAvailableDriversListLoading: false,
  orderBlocksAvailableDriversListError: null,
  orderBlocksAvailableDriversList: [],
};

export const ResourcesReducers = (state = initiatState, action) => {
  switch (action.type) {
    case types.LOAD_STATUS_TYPE_START:
    case types.LOAD_PACKAGE_STATUS_START:
    case types.LOAD_PACKAGE_SIZE_START:
    case types.LOAD_PAID_OPTIONS_START:
    case types.LOAD_CURRENCIES_START:
    case types.LOAD_BLOCK_PRIORITY_START:
    case types.LOAD_BLOCK_TYPE_START:
    case types.LOAD_BLOCK_TIME_TYPE_START:
    case types.LOAD_TICKET_PRIORITY_START:
    case types.LOAD_WEIGHT_UNIT_START:
    case types.LOAD_ORDER_CATEGORY_START:
    case types.LOAD_TICKET_STATUS_START:
    case types.LOAD_TICKET_RELATED_SERVICE_START:
    case types.LOAD_COUNTRY_CODE_START:
    case types.LOAD_LONG_COUNTRY_CODE_START:
    case types.LOAD_COUNTRY_CODE_OBJ_START:
    case types.LOAD_COUNTRY_STATE_START:
    case types.LOAD_COUNTRY_STATE_CITIES_START:
    case types.LOAD_DEFECT_TYPE_START:
    case types.LOAD_TRACKING_TYPE_START:
    case types.LOAD_TRACKING_FREQ_START:
    case types.LOAD_BLOCK_DELIVERY_TYPE_START:
    case types.LOAD_BLOCK_FILTER_COUNTRY_LIST_START:
    case types.LOAD_BLOCK_FILTER_STATE_LIST_START:
    case types.LOAD_BLOCK_FILTER_CITY_LIST_START:
      return {
        ...state,
        loading: true,
      };

    // Success cases
    case types.LOAD_TRACKING_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        trackingType: action.payload,
      };
    case types.LOAD_TRACKING_FREQ_SUCCESS:
      return {
        ...state,
        loading: false,
        trackingFreq: action.payload,
      };
    case types.LOAD_DEFECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        defectType: action.payload,
      };
    case types.LOAD_BLOCK_TIME_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        blockTime: action.payload,
      };
    case types.LOAD_COUNTRY_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        getStates: action.payload,
      };
    case types.LOAD_STATUS_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        statusTypes: action.payload,
      };
    case types.LOAD_PACKAGE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        packageStatus: action.payload,
      };
    case types.LOAD_PACKAGE_SIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        packageSizes: action.payload,
      };
    case types.LOAD_PAID_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        paidOptions: action.payload,
      };
    case types.LOAD_CURRENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action.payload,
      };
    case types.LOAD_BLOCK_PRIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        blockPriorities: action.payload,
      };
    case types.LOAD_BLOCK_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        blockTypes: action.payload,
      };
    case types.LOAD_TICKET_PRIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketPriorities: action.payload,
      };
    case types.LOAD_WEIGHT_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        weightUnit: action.payload,
      };
    case types.LOAD_ORDER_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        orderCategories: action.payload,
      };
    case types.LOAD_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketStatus: action.payload,
      };
    case types.LOAD_TICKET_RELATED_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketRelatedServices: action.payload,
      };
    case types.LOAD_COUNTRY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        countryCode: action.payload,
      };
    case types.LOAD_LONG_COUNTRY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        longCountryCode: action.payload,
      };
    case types.LOAD_COUNTRY_CODE_OBJ_SUCCESS:
      return {
        ...state,
        loading: false,
        countryCodeObj: action.payload,
      };
    case types.LOAD_COUNTRY_STATE_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countryStateCities: action.payload,
      };
    case types.LOAD_BLOCK_DELIVERY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        orderBlockDeliveryTypes: action.payload,
      };
    case types.LOAD_BLOCK_FILTER_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderBlockFilterCountryList: action.payload,
      };
    case types.LOAD_BLOCK_FILTER_STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderBlockFilterStateList: action.payload,
      };
    case types.LOAD_BLOCK_FILTER_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderBlockFilterCityList: action.payload,
      };

    // Error cases
    case types.LOAD_PACKAGE_STATUS_ERROR:
    case types.LOAD_DEFECT_TYPE_ERROR:
    case types.LOAD_STATUS_TYPE_ERROR:
    case types.LOAD_PACKAGE_SIZE_ERROR:
    case types.LOAD_COUNTRY_STATE_ERROR:
    case types.LOAD_PAID_OPTIONS_ERROR:
    case types.LOAD_CURRENCIES_ERROR:
    case types.LOAD_BLOCK_PRIORITY_ERROR:
    case types.LOAD_BLOCK_TYPE_ERROR:
    case types.LOAD_TICKET_PRIORITY_ERROR:
    case types.LOAD_WEIGHT_UNIT_ERROR:
    case types.LOAD_ORDER_CATEGORY_ERROR:
    case types.LOAD_TICKET_STATUS_ERROR:
    case types.LOAD_TICKET_RELATED_SERVICE_ERROR:
    case types.LOAD_COUNTRY_CODE_ERROR:
    case types.LOAD_LONG_COUNTRY_CODE_ERROR:
    case types.LOAD_COUNTRY_CODE_OBJ_ERROR:
    case types.LOAD_COUNTRY_STATE_CITIES_ERROR:
    case types.LOAD_TRACKING_TYPE_ERROR:
    case types.LOAD_TRACKING_FREQ_ERROR:
    case types.LOAD_BLOCK_TIME_TYPE_ERROR:
    case types.LOAD_BLOCK_DELIVERY_TYPE_ERROR:
    case types.LOAD_BLOCK_FILTER_COUNTRY_LIST_ERROR:
    case types.LOAD_BLOCK_FILTER_STATE_LIST_ERROR:
    case types.LOAD_BLOCK_FILTER_CITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Order blocks available drivers list
    case types.LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_START:
      return {
        ...state,
        orderBlocksAvailableDriversListLoading: true,
        orderBlocksAvailableDriversListError: null,
      };

    case types.LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_SUCCESS:
      return {
        ...state,
        orderBlocksAvailableDriversListLoading: false,
        orderBlocksAvailableDriversList: action.payload,
      };

    case types.LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_ERROR:
      return {
        ...state,
        orderBlocksAvailableDriversListLoading: false,
        orderBlocksAvailableDriversListError: action.payload,
      };
    default:
      return state;
  }
};
