import { ShopOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Avatar } from "antd";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { getLoadSingleImagePath } from "../../api/endpoints/image";
import OrderBayAPI from "../../api/endpoints/orderBay";
import { IParcelInput } from "../../definitions/orderBay";
import { appAuxiliaryPaths } from "../../global/paths";
import OrderBayForm from "./form/OrderBayForm";

const OrderBayCustomerPage: React.FC<{}> = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const onCloseForm = () => {
    window.location.assign(appAuxiliaryPaths.movebot);
  };

  const onSubmit = async (values: IParcelInput) => {
    if (!orgId) {
      throw new Error("OrgId is required");
    }

    return await OrderBayAPI.submitCustomerOrder({ orgId, parcel: values });
  };

  if (!orgId) {
    return <Navigate to={appAuxiliaryPaths.movebot} />;
  }

  const orgSrc = getLoadSingleImagePath(orgId, null, null);
  return (
    <div
      className={css({
        padding: "1rem",
      })}
    >
      <OrderBayForm
        onCloseForm={onCloseForm}
        submitFn={onSubmit}
        title="Customer Parcel"
        icon={
          <Avatar
            key={orgSrc}
            icon={<ShopOutlined />}
            src={orgSrc}
            alt={`Company's profile picture`}
            shape="square"
          />
        }
        orgId={orgId}
      />
    </div>
  );
};

export default OrderBayCustomerPage;
