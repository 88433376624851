import React from "react";

export const filterOptionWithLabel = (
  input: string,
  option: { label?: React.ReactNode } | undefined
) => {
  if (option && typeof option.label === "string") {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return false;
};

export const filterSortWithLabel = (
  optionA: { label?: React.ReactNode },
  optionB: { label?: React.ReactNode }
) => {
  if (typeof optionA.label === "string" && typeof optionB.label === "string") {
    return optionA.label
      .toLowerCase()
      .localeCompare(optionB.label.toLowerCase());
  }

  return 0;
};
