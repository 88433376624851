import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import * as types from "./actionType"
import { 
   getPaymentSuccess, getDriverPaymentError, getDriverPaymentSuccess, getPaymentError
} from "./action"
import {getPaymentsApi, getDriverPaymentsApi } from "../Api"



function* onLoadDriverFilterStartAsync() {
    try {
        const response = yield call(getDriverPaymentsApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(getDriverPaymentSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getDriverPaymentError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadPaymentStartAsync({payload}) {
    try {
        const response = yield call(getPaymentsApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(getPaymentSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getPaymentError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onDriverFilter() {
    yield takeEvery(types.GET_DRIVER_FILTER_START, onLoadDriverFilterStartAsync)
}

function* onPayment() {
    yield takeEvery(types.GET_PAYMENT_START, onLoadPaymentStartAsync)
}


export const paymentSaga = [
    fork(onPayment),
    fork(onDriverFilter),
]
