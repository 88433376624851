import * as types from "./policyActionType"


export const loadPolicyStart = (payload) => ({
    type: types.LOAD_POLICY_START,
    payload
})

export const loadPolicySuccess = (policy) => ({
    type: types.LOAD_POLICY_SUCCESS,
    payload: policy
})

export const loadPolicyError = (error) => ({
    type: types.LOAD_POLICY_ERROR,
    payload: error
})


export const loadFilterPolicyStart = (payload) => ({
    type: types.LOAD_FILTER_POLICY_START,
    payload
})

export const loadFilterPolicySuccess = (policy) => ({
    type: types.LOAD_FILTER_POLICY_SUCCESS,
    payload: policy
})

export const loadFilterPolicyError = (error) => ({
    type: types.LOAD_FILTER_POLICY_ERROR,
    payload: error
})
