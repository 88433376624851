import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import RolesAndPermissions from "../../components/RolesAndPermissions";
import "../employee/employee.css";
import Policy from "../Policy";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function PolicyPage() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="employee-container" style={{ width: "100%" }}>
      <div>
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // centered
          >
            <Tab label="Group Policy" />
            <Tab label="Roles and Permissions" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Policy />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RolesAndPermissions />
          </TabPanel>
        </Paper>
      </div>
    </div>
  );
}

export default PolicyPage;
