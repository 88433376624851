import * as yup from "yup";
import React from "react";
import { useFormik } from "formik";
import PersonFormInput, {
  personInitialValues,
  personValidationSchema,
} from "../../../components/form/PersonFormInput";
import { IPersonInput } from "../../../global/types";
import { Modal } from "antd";

export interface IRecipientForm {
  loading?: boolean;
  recipient?: IPersonInput;
  title: string;
  onSubmit: (recipient: IPersonInput) => void;
  onClose: () => void;
}

const RecipientForm: React.FC<IRecipientForm> = (props) => {
  const { loading, title, onSubmit, onClose } = props;
  const formik = useFormik({
    onSubmit,
    validationSchema: yup.object().shape(personValidationSchema).required(),
    initialValues: props.recipient || personInitialValues,
  });

  return (
    <Modal visible title={title} onCancel={onClose} onOk={formik.submitForm}>
      <form onSubmit={formik.handleSubmit}>
        <PersonFormInput basePath="" formik={formik} loading={loading} />
      </form>
    </Modal>
  );
};

export default RecipientForm;
