import React, { useState, useEffect } from 'react'
import "./createpolicy.css"
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux"
import Multiselect from 'multiselect-react-dropdown';
import { API_URL } from '../../globalVariable';
import swal from "sweetalert"
import Loader from "../Loader"
import Cookies from "js-cookie"
import { Button, Select, Input, Spin } from 'antd';
import { loadMovebotRoleStart, loadMovebotPolicyStart } from "../../redux/MovebotPolicyAndPermissionRedux/movebotAction"
import { useDispatch, useSelector } from "react-redux"
import { loadPolicyStart } from '../../redux/PolicyRedux/policyAction'


function CreatePolicy({ handleClose }) {
    const [groupName, setGroupName] = useState()
    const dispatch = useDispatch()
    const { roles, permissions } = useSelector(state => state.movebotActions)
    const { policies, loading } = useSelector(state => state.policies)
    const [description, setDescription] = useState()
    const [assignPolicy, setAssignPolicy] = useState()
    const [role, setRole] = useState()
    const [select, setSelect] = useState([])
    const [loader, setLoader] = useState(false)
    const [groupPolicy, setGroupPolicy] = useState()


    const { TextArea } = Input;


    const onSelect = (selectedList) => {
        var ab = selectedList?.map(item => item.PolicyId)
        setAssignPolicy({ ...assignPolicy, ab })
        setSelect(selectedList)
    }

    const onRemove = (selectedList) => {
        var ab = selectedList?.filter(item => item.PolicyId)
        setAssignPolicy({ ...assignPolicy, ab })
        setSelect(selectedList)
    }

    useEffect(() => {
       // dispatch(loadPolicyStart())
         dispatch(loadMovebotRoleStart())
        //dispatch(loadMovebotPolicyStart())
    }, [])

    useEffect(() => {
        dispatch(loadMovebotPolicyStart(role))
    }, [role])

    const createPolicy = async () => {
        const data = {
            groupName,
            description,
            roleType: role,
            assignedGroupPolicy: assignPolicy.ab
        }
        setLoader(true)
        var response = await fetch(API_URL + "GroupPolicy/Create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Authorization": `Bearer ${localStorage.getItem("token")}`
                "Authorization": `Bearer ${Cookies.get('accessToken')}`
            },
            body: JSON.stringify(data)
        })

        var dataResponse = await response.json()

        if (response.ok) {
            return (
                swal({
                    text: dataResponse.message,
                    icon: "success"
                })
                    .then(() => {
                        setLoader(false)
                        setGroupName("")
                        setDescription("")
                        setRole("")
                        setSelect("")
                    })
                    .then(() => handleClose())
            )
        }
        else {
            if (dataResponse.message) {
                return (
                    swal({
                        text: dataResponse.message,
                        icon: "warning"
                    })
                        .then(() => {
                            setLoader(false)
                            setGroupName("")
                            setDescription("")
                            setRole("")
                            setSelect("")
                        })
                )
            }
            else {
                return (
                    swal({
                        text: dataResponse.Message,
                        icon: "warning"
                    })
                        .then(() => {
                            setLoader(false)
                            setGroupName("")
                            setDescription("")
                            setRole("")
                            setSelect("")
                        })
                )
            }
        }
    }

    const { Option } = Select;

    function handleChange(value) {
        setRole(value)
        setAssignPolicy(null)
        setSelect([])
    }

    function onChangeEvent({ target: { value } }) {
        setDescription(value)
    }

    return (
        <div>
            <form className="policy-form">
                <Input placeholder="Group Name" style={{ width: '100%' }} value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                <br />
                <TextArea
                    value={description}
                    onChange={onChangeEvent}
                    placeholder="Description"
                    autoSize={{ minRows: 3, maxRows: 10 }}
                />
                <br />
                <div>
                    <Select defaultValue="Select Roles" style={{ width: '100%' }} onChange={handleChange}>
                        <Option value="disabled" disabled>Select Roles</Option>
                        {
                            roles?.map(policy => {
                                return <Option value={policy._RoleType}>{policy._RoleType}</Option>
                            })
                        }
                    </Select>
                </div>
                <div className="policy-multi-select">
                    {
                        Array.isArray(permissions) && permissions?.length > 0 ?
                            (
                                <Multiselect
                                    options={permissions}
                                    selectedValues={select}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    displayValue="_PolicyType"
                                    className="employee-select"
                                />
                            )
                            :
                            <Select placeholder="Select Role First" disabled style={{width: '100%'}}/>
                    }
                </div>
                <br />
                <div className="form-btn">
                    <Button type="primary" id="but" onClick={() => createPolicy()}>
                        {loader ? <Spin /> : "Create Policy"}
                    </Button>
                </div>
            </form>
        </div>
    )
}


export default CreatePolicy
