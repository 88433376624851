import { Col, Row } from "antd";
import React from "react";
import { IOrderBlock } from "../../definitions/orderBlock";
import LabeledNode from "../../components/utils/LabeledNode";
import SelectedBranchWithLoadBranches from "../../components/branch/SelectedBranchWithLoadBranches";
import SupportContactList from "../../components/branch/SupportContactList";

export interface IOrderBlockPickupDetailsProps {
  block: IOrderBlock;
}

const OrderBlockPickupDetails: React.FC<IOrderBlockPickupDetailsProps> = (
  props
) => {
  const { block } = props;

  return (
    <Row wrap gutter={[16, 32]}>
      <Col span={12}>
        <LabeledNode
          direction="vertical"
          label="Branch Pickup Address"
          labelFontSize={16}
          node={
            block.PickUpAddress.BranchAddressId ? (
              <SelectedBranchWithLoadBranches
                branchId={block.PickUpAddress.BranchAddressId}
              />
            ) : (
              "N/A"
            )
          }
        />
      </Col>
      <Col span={12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Custom Pickup Address"
          labelFontSize={16}
          node={
            block.PickUpAddress.IsCustomAddress
              ? block.PickUpAddress.Address_
              : "N/A"
          }
        />
      </Col>
      {block.PickUpAddress.IsCustomAddress ? (
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Pickup Support Contact"
            labelFontSize={16}
            node={
              block.PickUpAddress.SupportContactInfo ? (
                <SupportContactList
                  contacts={block.PickUpAddress.SupportContactInfo.map(
                    (item) => ({
                      contactName: item.ContactName,
                      phoneNumber: item.PhoneNumber.GetStyled,
                    })
                  )}
                />
              ) : (
                "N/A"
              )
            }
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default OrderBlockPickupDetails;
