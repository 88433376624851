import { IOrderBlock } from "../../definitions/orderBlock";

export const ORDER_BLOCK_PUBLIC_TYPE = "public"; 

export function isPublicOrderBlock(block: IOrderBlock) {
  return block.Type.Name.toLowerCase() === ORDER_BLOCK_PUBLIC_TYPE;
}

//0-New, 2-Unpublished
export function isOrderBlockPublished(block: IOrderBlock) {
  return !(block.Status.Index == 0 || block.Status.Index == 2);
}
