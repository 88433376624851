import { Row, Col } from "antd";
import React from "react";

const RowList = (props) => {
  const { gutter, span, children } = props;

  return (
    <Row gutter={gutter || [16, 32]}>
      {React.Children.toArray(children)
        .filter((node) => !!node)
        .map((node, i) => (
          <Col key={i} span={span || 8}>
            {node}
          </Col>
        ))}
    </Row>
  );
};

export default RowList;
