import React from 'react'
import "./signup.css"

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


function Signup() {
    return (
        <div className="signup-component">

            <div className="signup-div1">
                <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1623772465/Rectangle_453_zrsfyc.png" />
            </div>

            <div className="signup-div2">
                <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1624214382/A_PNG_senvtp.png" />

                <div>
                    <h1>Sign Up</h1>
                    <form>
                        <div className="signup-form-div">
                            <TextField id="outlined-basic" label="First Name" variant="outlined" className="" />
                            <TextField id="outlined-basic" label="LastName" variant="outlined" className="" />
                        </div>

                        <div  className="signup-form-div2">
                            <TextField id="outlined-basic" label="Email Address" variant="outlined" className="" />
                        </div>

                        <div  className="signup-form-div">
                            <TextField id="outlined-basic" label="Phone Number" variant="outlined" className="" />
                            <TextField id="outlined-basic" label="Street" variant="outlined" className="" />
                        </div>

                        <div  className="signup-form-div">
                            <TextField id="outlined-basic" label="City" variant="outlined" className="" />
                            <TextField id="outlined-basic" label="State" variant="outlined" className="" />
                        </div>

                        <div  className="signup-form-div">
                            <TextField id="outlined-basic" label="Zip Code" variant="outlined" className="" />
                            <TextField id="outlined-basic" label="Country" variant="outlined" className="" />
                        </div>

                        <div  className="signup-form-div">
                            <TextField id="outlined-basic" label="Website URL" variant="outlined" className="" />
                            <TextField id="outlined-basic" label="Facebook URL" variant="outlined" className="" />
                        </div>

                        <div  className="signup-form-div">
                            <TextField id="outlined-basic" label="Youtube URL" variant="outlined" className="" />
                            <TextField id="outlined-basic" label="Instagram URL" variant="outlined" className="" />
                        </div>

                        <div className="signup-button">
                            <Button variant="contained" color="primary" >
                                Sign Up
                            </Button>
                        </div>

                        <div className="already-acc">
                            <p>Already have an account? <span>Sign In</span></p>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Signup
