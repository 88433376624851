import { css } from "@emotion/css";
import { Button, Input, Select, Space } from "antd";
import React from "react";
import { appDimensions } from "../../components/utils/theme";
import {
  PackageStatus,
  ParcelOrderStatus,
  ParcelStatus,
} from "../../definitions/orderBay";
import { IOrderBayOrdersPageFetchExtra } from "./useOrderPageData";
import { IOrderBayParcelsPageFetchExtra } from "./useParcelPageData";

export interface IOrderBayPageControlsProps
  extends IOrderBayOrdersPageFetchExtra,
    IOrderBayParcelsPageFetchExtra {
  disabled?: boolean;
  isPagedByParcel?: boolean;
  isPagedByOrder?: boolean;
  isSearchOrder?: boolean;
  onCreateParcel: () => void;
  onEnterSearchQuery: (query: string) => void;
  onEnterOrderFilter: (input: IOrderBayOrdersPageFetchExtra) => void;
  onEnterParcelFilter: (input: IOrderBayParcelsPageFetchExtra) => void;
}

const classes = {
  root: css({
    display: "flex",
    alignItems: "flex-start",
    "& .ant-input-affix-wrapper": {
      minHeight: "32px",
    },
  }),
  leftControls: css({
    flex: 1,
  }),
};

const OrderBayPageControls: React.FC<IOrderBayPageControlsProps> = (props) => {
  const {
    disabled,
    isPagedByOrder,
    isPagedByParcel,
    packageStatus,
    isSearchOrder,
    parcelOrderStatus,
    status,
    onCreateParcel,
    onEnterOrderFilter,
    onEnterParcelFilter,
    onEnterSearchQuery,
  } = props;

  const orderStatusInputNode = isPagedByOrder && (
    <Select
      allowClear
      disabled={disabled || isSearchOrder}
      value={parcelOrderStatus}
      placeholder="Select parcel order status"
      onChange={(value) =>
        onEnterOrderFilter({
          packageStatus,
          parcelOrderStatus: value as ParcelOrderStatus,
        })
      }
      style={{ width: appDimensions.searchInputWidth }}
    >
      <Select.Option value={ParcelOrderStatus.Available}>
        {ParcelOrderStatus.Available}
      </Select.Option>
      <Select.Option value={ParcelOrderStatus.OnHold}>
        {ParcelOrderStatus.OnHold}
      </Select.Option>
    </Select>
  );

  const orderParcelStatusInputNode = isPagedByOrder && (
    <Select
      allowClear
      disabled={disabled || isSearchOrder}
      value={packageStatus}
      placeholder="Select parcel status"
      onChange={(value) =>
        onEnterOrderFilter({
          parcelOrderStatus,
          packageStatus: value as PackageStatus,
        })
      }
      style={{ width: appDimensions.searchInputWidth }}
    >
      <Select.Option value={PackageStatus.AsArrived}>
        {PackageStatus.AsArrived}
      </Select.Option>
      <Select.Option value={PackageStatus.Completed}>
        {PackageStatus.Completed}
      </Select.Option>
      <Select.Option value={PackageStatus.Delivered}>
        {PackageStatus.Delivered}
      </Select.Option>
      <Select.Option value={PackageStatus.Draft}>
        {PackageStatus.Draft}
      </Select.Option>
      <Select.Option value={PackageStatus.InProcess}>
        {PackageStatus.InProcess}
      </Select.Option>
      <Select.Option value={PackageStatus.InTransit}>
        {PackageStatus.InTransit}
      </Select.Option>
      <Select.Option value={PackageStatus.Issues}>
        {PackageStatus.Issues}
      </Select.Option>
      <Select.Option value={PackageStatus.ReadyForBlock}>
        {PackageStatus.ReadyForBlock}
      </Select.Option>
      <Select.Option value={PackageStatus.Staged}>
        {PackageStatus.Staged}
      </Select.Option>
      <Select.Option value={PackageStatus.Terminated}>
        {PackageStatus.Terminated}
      </Select.Option>
    </Select>
  );

  const parcelStatusInputNode = isPagedByParcel && (
    <Select
      allowClear
      disabled={disabled || isSearchOrder}
      value={status}
      placeholder="Select parcel status"
      onChange={(value) =>
        onEnterParcelFilter({
          status: value as ParcelStatus,
        })
      }
      style={{ width: appDimensions.searchInputWidth }}
    >
      <Select.Option value={ParcelStatus.Draft}>
        {ParcelStatus.Draft}
      </Select.Option>
      <Select.Option value={ParcelStatus.Acknowledged}>
        {ParcelStatus.Acknowledged}
      </Select.Option>
      <Select.Option value={ParcelStatus.InProgress}>In Progress</Select.Option>
      <Select.Option value={ParcelStatus.NeedsAttension}>
        Needs Attension
      </Select.Option>
      <Select.Option value={ParcelStatus.Completed}>
        {ParcelStatus.Completed}
      </Select.Option>
      <Select.Option value={ParcelStatus.Terminated}>
        {ParcelStatus.Terminated}
      </Select.Option>
      <Select.Option value={ParcelStatus.OnHold}>On Hold</Select.Option>
    </Select>
  );

  const searchNode = (
    <Input.Search
      allowClear
      disabled={disabled}
      placeholder="Enter search text"
      onSearch={onEnterSearchQuery}
      style={{ width: appDimensions.searchInputWidth }}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.leftControls}>
        <Space size="middle">
          {orderStatusInputNode}
          {orderParcelStatusInputNode}
          {parcelStatusInputNode}
          {searchNode}
        </Space>
      </div>
      <div>
        <Button type="primary" onClick={() => onCreateParcel()}>
          Create Order
        </Button>
      </div>
    </div>
  );
};

export default OrderBayPageControls;
