import { EllipsisOutlined } from "@ant-design/icons";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Col, Dropdown, Menu, Modal, Result, Row, Select, Spin, Switch, Table, Tag, Tooltip, Typography } from 'antd';
import Cookies from "js-cookie";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import CreatePolicy from '../../components/CreatePolicy';
import { API_URL } from '../../globalVariable';
import { loadMovebotPolicyStart, loadMovebotRoleStart } from "../../redux/MovebotPolicyAndPermissionRedux/movebotAction";
import { loadFilterPolicyStart, loadPolicyStart } from '../../redux/PolicyRedux/policyAction';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Multiselect from 'multiselect-react-dropdown';

import { useLocation, useNavigate } from "react-router-dom";
import Paginations from '../../components/Pagination';
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";




const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#FF8749",
        color: theme.palette.common.white,
        textAlign: 'center'
    },
    body: {
        fontSize: 14,
        textAlign: 'center'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    // root: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    // },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    typography: {
        padding: theme.spacing(2),
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    grid: {
        display: "flex",
        flexDirection: "column-reverse"
    }
}));




function Policy() {
    const dispatch = useDispatch()
    const [gridApi, setGridApi] = useState(null);
    const pagination = true;
    const paginationPageSize = 10
    const { policies, loading, error } = useSelector(state => state.policies)
    const { roles, permissions } = useSelector(state => state.movebotActions)
    const [filter, setFilter] = useState("NotRequired")
    const [roleOpen, setRoleOpen] = useState(false);
    const navigate = useNavigate()
    const [groupName, setGroupName] = useState()
    const [description, setDescription] = useState()
    const [assignPolicy, setAssignPolicy] = useState()
    const [selectedPolicy, setSelectedPolicy] = useState()
    const [reason, setReason] = useState()
    const [detailOpen, setDetailOpen] = useState(false);
    const [detail, setDetail] = useState()
    const [loader, setLoader] = useState(false)
    const [statusId, setStatusId] = useState()
    const [updatePolicyStatus, setUpdatePolicyStatus] = useState(false)
    const [statusValue, setStatusValue] = useState(false)
    const [select, setSelect] = useState([])
    const classes = useStyles();
    const [policyId, setPolicyId] = useState("")
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [getRole, setGetRole] = useState("")

    const [anchorEl, setAnchorEl] = useState(null);

    const { Title, Paragraph } = Typography;

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    var queryPage = parseInt(query.get('page'))

    const [page, setPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(queryPage)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    }, [filter]);

    const handleSelect = (value) => {
        const intValue = Number(value)
        setPageSize(intValue)
        gridApi.paginationSetPageSize(Number(intValue));
    }


    const handleUpdateStatusModalOpen = (id) => {
        setUpdatePolicyStatus(true)
        setStatusId(id)
        setStatusValue(statusValue)
    }
    const handleUpdateStatusModalClose = () => {
        setUpdatePolicyStatus(false)
    }

    const onStatusChange = (checked) => {
        setStatusValue(checked)
    }


    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    };

    const handleDetailModalOpen = (pol) => {
        setDetailOpen(true);
        setDetail(pol)
    };

    const handleDetailModalClose = () => {
        setDetailOpen(false);
        setDetail()
    };

    const handleEditModalOpen = (pol) => {
        setEditModalOpen(true);
        setPolicyId(pol.GroupPolicyId)
        setGroupName(pol.GroupName)
        setDescription(pol.Description)
        setGetRole(pol.RoleInfo._RoleType)
        setSelect(pol.AssignedPolicy)
        var pp = []
        pol.AssignedPolicy?.map(as => pp.push(as))
        setAssignPolicy(pp)
    };

    useEffect(() => {
        dispatch(loadMovebotPolicyStart(getRole))
    }, [getRole])

    useEffect(() => {
        const payload = {
            page: isNaN(currentPage) ? page : currentPage,
            pageSize,
            roleType: filter
        }
        dispatch(loadFilterPolicyStart(payload))
    }, [filter])



    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setPolicyId("")
        setGroupName()
        setDescription()
        setGetRole()
        setSelect()
    };

    const handleChange = (value) => {
        setRoleOpen(value)
    }

    const handleClick = (event, policy) => {
        setAnchorEl(event.currentTarget);
        setSelectedPolicy(policy.row)

    };

    const onSelect = (selectedList) => {
        setAssignPolicy(selectedList)
    }

    const onRemove = (selectedList) => {
        setAssignPolicy(selectedList)
    }


    const updatePolicy = async () => {
        const data = {
            roleType: getRole,
            groupName,
            description,
            assignedGroupPolicy: assignPolicy?.map(a => a.PolicyId)

        }
        setLoader(true)
        var response = await fetch(API_URL + `GroupPolicy/Update?groupPolicyId=${policyId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Authorization": `Bearer ${localStorage.getItem("token")}`
                "Authorization": `Bearer ${Cookies.get('accessToken')}`
            },
            body: JSON.stringify(data)
        })
        var dataResponse = await response.json()
        if (response.ok) {
            setLoader(false)
            return (
                swal({
                    text: dataResponse.message,
                    icon: "success"
                })
                    .then(() => {
                        setGroupName("")
                        setDescription("")
                        setSelect("")

                        const payload = {
                            page,
                            pageSize
                        }
                        dispatch(loadPolicyStart(payload))
                    })
                    .then(() => {
                        handleEditModalClose()
                    })
            )
        }
        else {
            if (dataResponse.message) {
                setLoader(false)
                return (
                    swal({
                        text: dataResponse.message,
                        icon: "warning"
                    })
                )
            }
            else {
                setLoader(false)
                return (
                    swal({
                        text: dataResponse.Message,
                        icon: "warning"
                    })
                )
            }
        }
    }

    const updateStatus = async () => {

        const datas = {
            id: statusId,
            reason: reason,
            value: statusValue
        }
        setLoader(true)
        var response = await fetch(API_URL + `GroupPolicy/Status`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Authorization": `Bearer ${localStorage.getItem("token")}`
                "Authorization": `Bearer ${Cookies.get('accessToken')}`
            },
            body: JSON.stringify(datas)
        })
        var data = await response.json()
        if (response.ok) {
            return (
                swal({
                    text: data.message,
                    icon: "success"
                })
                    .then(() => {
                        setLoader(false)
                        setReason("")
                        const payload = {
                            page,
                            pageSize
                        }
                        dispatch(loadPolicyStart(payload))
                        handleUpdateStatusModalClose()
                    })
            )
        }
        else {
            setLoader(false)
            if (data.message) {
                return (
                    swal({
                        text: data.message,
                        icon: "warning"
                    })
                )
            }
            else {
                return (
                    swal({
                        text: data.Message,
                        icon: "warning"
                    })
                )
            }
        }

    }

    const deleteModal = (id) => {
        handleDeleteModalOpen()
        setDeleteId(id)
    }

    const deletePolicy = async () => {
        const data = {
            reason,
            id: deleteId
        }
        setLoader(true)
        var response = await fetch(API_URL + "GroupPolicy/Delete", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Authorization": `Bearer ${localStorage.getItem("token")}`
                "Authorization": `Bearer ${Cookies.get('accessToken')}`
            },
            body: JSON.stringify(data)
        })
        var dataResponse = await response.json()

        if (response.ok) {
            return swal({
                text: dataResponse.message,
                icon: 'success'
            })
                .then(() => {
                    setDeleteId("")
                    setReason("")
                    setLoader(false)
                    const payload = {
                        page,
                        pageSize
                    }
                    dispatch(loadPolicyStart(payload))

                })
                .then(() => {
                    handleDeleteModalClose()
                })
        }
        else {
            setDeleteId("")
            setReason("")
            setLoader(false)
            if (dataResponse.message) {
                return swal({
                    text: dataResponse.message,
                    icon: 'warning'
                })
            }
            else {
                return swal({
                    text: dataResponse.Message,
                    icon: 'warning'
                })
            }
        }

    }


    const handleRoleClose = () => {
        setRoleOpen(false);
        const payload = {
            page: isNaN(currentPage) ? page : currentPage,
            pageSize
        }
        dispatch(loadPolicyStart(payload))

    };


    useEffect(() => {
        const payload = {
            page: isNaN(currentPage) ? page : currentPage,
            pageSize
        }
        dispatch(loadPolicyStart(payload))
        dispatch(loadMovebotRoleStart())
        dispatch(loadMovebotPolicyStart())
    }, [currentPage, pageSize])


    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    }, []);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };



    const { Option } = Select;

    function handleFilterChange(value) {
        setFilter(value)
    }



    const handleRoleOpen = () => {
        setRoleOpen(true);
    };


    const menuOptionsNode = (data) => (
        <Menu>
            <Tooltip title="Edit">
                <Menu.Item onClick={() => handleEditModalOpen(data)}>
                    Edit Policy
                </Menu.Item>
            </Tooltip>
            <Menu.Divider />
            <Tooltip title="Update">
                <Menu.Item onClick={() => handleUpdateStatusModalOpen(data.GroupPolicyId)}>
                    Update Policy
                </Menu.Item>
            </Tooltip>
            <Menu.Divider />
            <Menu.Divider />
            <Tooltip title="Delete">
                <Menu.Item onClick={() => deleteModal(data?.GroupPolicyId)}>
                    Delete Policy
                </Menu.Item>
            </Tooltip>
            <Menu.Divider />

        </Menu>
    );


    const columns = [
        {
            title: "Status",
            width: 150,
            ellipsis: true,
            dataIndex: "Status",
            key: "Status",
            render(text, record) {
                return (
                    <NodeWithTip>
                        <Tag color={record?.Status?.Color}>
                            {record?.Status?.Name}
                        </Tag>
                    </NodeWithTip>
                );
            },
        },
        {
            title: "Group Name",
            width: 170,
            dataIndex: "GroupName",
            key: "GroupName",
            render(text, record) {
                return (
                    <p style={{ color: 'rgba(255, 127, 80, 1)' }} onClick={() => handleDetailModalOpen(record)}>{record.GroupName}</p>
                );
            },
        },
        {
            title: "Group Role",
            width: 150,
            dataIndex: "GroupRole",
            key: "GroupRole",
            render(text, record) {
                return (
                    <p>{record.RoleInfo._RoleType}</p>
                );
            },
        },

        {
            title: "Description",
            width: 350,
            dataIndex: "Description",
            key: "Description",
        },
        {
            title: "Last Updated",
            width: 150,
            dataIndex: "LastUpdated",
            key: "LastUpdated",
        },
        {
            title: "Updated By",
            width: 200,
            dataIndex: "UpdatedBy",
            key: "UpdatedBy",
        },
        {
            title: "",
            key: "Actions",
            width: 48,
            render: (unused, record) => {
                return (
                    <Dropdown arrow overlay={menuOptionsNode(record)}>
                        <Button
                            icon={<EllipsisOutlined />}
                        // className={buttonClassName}
                        ></Button>
                    </Dropdown>
                );
            },
        },
    ];


    // if (loading) {
    //     return <TableSkeleton columns={columns} />
    // }


    const onChangeData = (page, pageSize) => {
        navigate(`/policy?page=${page}`)
        setPageSize(pageSize)
        setCurrentPage(page)
    }

    return (
        <div style={{ width: '100%' }}>
            <div className="policy-filtered">
                <br/>
                <div className="filter-group">
                    <div className="filter-sort">
                        <Select defaultValue="Filter by Role" onChange={handleFilterChange} className="filtd">
                            <Option value="NotRequired">
                                Filter by Role
                            </Option>
                            {
                                roles?.map(ploy => {
                                    return <Option value={ploy._RoleType}>{ploy._RoleType} Policy</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div className="">
                        <div>
                            <Button type="primary" id="but" onClick={handleRoleOpen}>Create Group Policy</Button>
                            {/* <button  >Create Group Policy</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <br />

            {
                error !== null && <Result title={error?.response?.data?.message} />
            }
            {
                loading ? <TableSkeleton columns={columns} />
                    :
                    error === null && (
                        <div
                            style={{
                                display: "flex",
                                height: '90%',
                                // flex: 1,
                                width: "100%",
                                flexDirection: "column",
                                overflowX: "auto",
                            }}
                        >
                            <Table
                                bordered
                                size="small"
                                dataSource={policies?.Data}
                                columns={columns}
                                rowKey={(row) => row.BlockId}
                                // className="dds"
                                pagination={false}
                            //scroll={{ y: 400 }}
                            />

                        </div>
                    )


            }
            <br />
            <Paginations data={policies} currentPage={isNaN(currentPage) ? page : currentPage} onChangeData={onChangeData} />


            {/* Modal for policy edit */}

            <div>
                <Modal
                    title="Edit Policy"
                    visible={editModalOpen}
                    footer={null}
                    onCancel={handleEditModalClose}
                >
                    {
                        policies.Data?.map(policy => {
                            return policy.GroupPolicyId === policyId && (

                                <form className="policy-form">
                                    <TextField id="outlined-basic" label="Group Name" value={groupName} onChange={(e) => setGroupName(e.target.value)} variant="outlined" />
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Description"
                                        placeholder="Description"
                                        multiline
                                        rows={4}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        variant="outlined"
                                        className="form-textarea"
                                    />
                                    <div className="policy-multi-select">
                                        {
                                            Array.isArray(permissions) && permissions?.length > 0 ?
                                                (
                                                    <Multiselect
                                                        options={permissions}
                                                        selectedValues={select}
                                                        onSelect={onSelect}
                                                        onRemove={onRemove}
                                                        displayValue="_PolicyType"
                                                        className="employee-select"
                                                        placeholder="Select Assigned Policy"
                                                    />
                                                )
                                                :
                                                null
                                        }
                                    </div>

                                    <div className="form-btn">
                                        <Button type="primary" id="but" onClick={() => updatePolicy()}>
                                            {loader ? <Spin /> : "Update Policy"}
                                        </Button>
                                    </div>
                                </form>
                            )
                        })
                    }
                </Modal>
            </div>


            {/* Modal for policy details */}
            <div>
                <Modal
                    title="Policy Details"
                    visible={detailOpen}
                    footer={null}
                    onCancel={handleDetailModalClose}
                >
                    <div>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={8}>
                                <span className="span-address">Group Name:</span> <Paragraph className="address" >{detail?.GroupName}</Paragraph>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <span className="span-address">Description:</span> <Paragraph className="address">{detail?.Description}</Paragraph>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <span className="span-address">Updated By:</span> <Paragraph className="address">{detail?.UpdatedBy}</Paragraph>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <span className="span-address">Status:</span> <Paragraph className="address"><Tag color={detail?.Status?.Color}>{detail?.Status?.Name}</Tag></Paragraph>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <span className="span-address">Date Created:</span> <Paragraph className="address">{detail?.DateCreated_}</Paragraph>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <span className="span-address">Last Updated:</span> <Paragraph className="address">{detail?.LastUpdated}</Paragraph>
                            </Col>
                        </Row>
                        <br />
                        <div>
                            <p>Assigned Roles</p>
                            <ul>
                                {detail?.AssignedPolicy?.map((pol, i) => (
                                    <li>{pol._PolicyType}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Modal>
            </div>



            {/* Modal for deleting Policy */}

            <div>
                <Modal
                    title="Delete Policy"
                    visible={deleteModalOpen}
                    footer={null}
                    onCancel={handleDeleteModalClose}
                >
                    <form className="policy-form">

                        <TextField
                            id="outlined-multiline-static"
                            label="Reason for Deleting"
                            placeholder="Reason for Deleting"
                            multiline
                            rows={4}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            variant="outlined"
                            className="form-textarea"
                        />

                        <div className="form-btn">
                            <Button type="primary" id="but" onClick={() => deletePolicy()}>
                                {loader ? <Spin /> : "Delete Policy"}
                            </Button>
                        </div>
                    </form>
                </Modal>
            </div>

            {/* Modal for Updating Policy Status */}
            <div>
                <Modal
                    title="Update Policy Status"
                    visible={updatePolicyStatus}
                    footer={null}
                    onCancel={handleUpdateStatusModalClose}
                >
                    <form className="policy-form">

                        <TextField
                            id="outlined-multiline-static"
                            label="Reason for Deactivating Order"
                            placeholder="Reason for Deactivating Order"
                            multiline
                            rows={4}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            variant="outlined"
                            className="delete-textarea"
                        />
                        <br />
                        <div>
                            Change Status:
                            <Switch style={{ marginLeft: '2%' }} checked={statusValue} onChange={onStatusChange} />
                        </div>
                        <br />
                        <div className="form-btn">
                            <Button type="primary" id="but" onClick={() => updateStatus()}>
                                {loader ? <Spin /> : "Update Status"}
                            </Button>
                        </div>
                    </form>
                </Modal>
            </div>



            {/* Modal for creating Policy */}

            <Modal
                title="Create Policy"
                visible={roleOpen}
                footer={null}
                onCancel={handleRoleClose}
            >
                <CreatePolicy handleClose={handleRoleClose} />
            </Modal>
        </div>
    )
}

export default Policy
