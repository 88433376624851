import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { defaultTo, isString } from "lodash";
import React from "react";
import BranchSelectInput from "../../../components/branch/BranchSelectInput";
import AddressFormInput from "../../../components/form/AddressFormInput";
import FormItemError from "../../../components/utils/FormItemError";
import {
  IOrderBlockAddressInput,
  ISupportContactInput
} from "../../../definitions/orderBlock";
import { IBranch } from "../../../definitions/settings";
import { IOrderBlockFormInput } from "./OrderBlockForm";
import SupportContactInfoInput from "./SupportContactInfoInput";

export interface IOrderBlockFormProps {
  loading?: boolean;
  isCustomAddress?: boolean;
  branches: IBranch[];
  basePath: keyof IOrderBlockFormInput;
  formik: FormikProps<IOrderBlockFormInput>;
  selectAddressTypeLabels: {
    formItem: string;
    branch: string;
    custom: string;
  };
  branchAddressLabels: {
    formItem: string;
    placeholder: string;
  };
}

enum OrderBlockAddressType {
  Custom,
  Branch,
}

const OrderBlockFormAddressInfo: React.FC<IOrderBlockFormProps> = (props) => {
  const {
    loading,
    formik,
    isCustomAddress,
    branches,
    selectAddressTypeLabels,
    basePath,
    branchAddressLabels,
  } = props;

  const values = defaultTo(
    formik.values[basePath],
    {}
  ) as Partial<IOrderBlockAddressInput>;
  const touched = defaultTo(
    formik.touched[basePath],
    {}
  ) as FormikTouched<IOrderBlockAddressInput>;
  const errors = defaultTo(
    formik.errors[basePath],
    {}
  ) as FormikErrors<IOrderBlockAddressInput>;

  const [addressType, setAddressType] = React.useState(
    isCustomAddress
      ? OrderBlockAddressType.Custom
      : OrderBlockAddressType.Branch
  );

  const branchFieldName = `${basePath}.branchAddressId`;
  const branchFieldHelpers = formik.getFieldHelpers(branchFieldName);
  const supportContactBasePath = `${basePath}.supportContactInfo`;
  const supportContactList = defaultTo(values.supportContactInfo, []);
  const supportContactTouched = defaultTo(
    touched.supportContactInfo,
    []
  ) as any;
  const supportContactErrors = defaultTo(errors.supportContactInfo, []) as any;
  const customAddressFieldName = `${basePath}.customAddress`;

  const selectTypeNode = (
    <Form.Item
      required
      label={selectAddressTypeLabels.formItem}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Select
        placeholder="Select address type"
        value={addressType}
        onChange={(val) => {
          setAddressType(val as OrderBlockAddressType);
          if (val === OrderBlockAddressType.Custom) {
            formik.setFieldValue(branchFieldName, undefined);
          } else if (val === OrderBlockAddressType.Branch) {
            formik.setFieldValue(customAddressFieldName, undefined);
            formik.setFieldValue(supportContactBasePath, undefined);
          }
        }}
        disabled={loading}
      >
        <Select.Option
          key={OrderBlockAddressType.Branch}
          value={OrderBlockAddressType.Branch}
        >
          {selectAddressTypeLabels.branch}
        </Select.Option>
        <Select.Option
          key={OrderBlockAddressType.Custom}
          value={OrderBlockAddressType.Custom}
        >
          {selectAddressTypeLabels.custom}
        </Select.Option>
      </Select>
    </Form.Item>
  );

  const customAddressNode = addressType === OrderBlockAddressType.Custom && (
    <Form.Item
      // label={customAddressLabels.formItem}
      help={
        touched.customAddress &&
        isString(errors.customAddress) && (
          <FormItemError message={errors.customAddress} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <AddressFormInput
        basePath={customAddressFieldName}
        formik={formik}
        loading={loading}
      />
    </Form.Item>
  );

  const branchPickUpAddressNode = addressType ===
    OrderBlockAddressType.Branch && (
    <Form.Item
      label={branchAddressLabels.formItem}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        touched.branchAddressId && (
          <FormItemError message={errors.branchAddressId} />
        )
      }
    >
      <BranchSelectInput
        branches={branches}
        disabled={loading}
        onChange={branchFieldHelpers.setValue}
        placeholder={branchAddressLabels.placeholder}
        value={values.branchAddressId}
      />
    </Form.Item>
  );

  const supportContactListNode = addressType ===
    OrderBlockAddressType.Custom && (
    <Form.Item
      required
      label="Support Contacts"
      help={
        touched.supportContactInfo &&
        isString(errors.supportContactInfo) && (
          <FormItemError message={errors.supportContactInfo} />
        )
      }
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      {supportContactList.map((contact, index) => {
        const itemTouched = supportContactTouched[index];
        const itemErrors = supportContactErrors[index];
        const itemBasePath = `${supportContactBasePath}[${index}]`;
        return (
          <Form.Item
            key={index}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            help={
              itemTouched &&
              isString(itemErrors) && <FormItemError message={itemErrors} />
            }
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: 1, marginRight: "12px" }}>
                <SupportContactInfoInput
                  disabled={loading}
                  basePath={itemBasePath}
                  onError={(err) => formik.setFieldError(itemBasePath, err)}
                  setFieldValue={formik.setFieldValue}
                  values={contact}
                  errors={itemTouched && itemErrors}
                />
              </div>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  const newSupportContact = [...supportContactList];
                  newSupportContact.splice(index, 1);
                  formik.setFieldValue(
                    supportContactBasePath,
                    newSupportContact
                  );
                }}
              />
            </div>
          </Form.Item>
        );
      })}
      <Button
        onClick={() => {
          const newSupportContact: ISupportContactInput[] = [
            ...supportContactList,
            {
              contactName: "",
              phoneNumber: {
                code: "NG",
                number: "",
              },
            },
          ];
          formik.setFieldValue(supportContactBasePath, newSupportContact);
        }}
      >
        Add Support Contact
      </Button>
    </Form.Item>
  );

  return (
    <div>
      {selectTypeNode}
      {branchPickUpAddressNode}
      {customAddressNode}
      {supportContactListNode}
    </div>
  );
};

export default OrderBlockFormAddressInfo;
