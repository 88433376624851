import { css } from "@emotion/css";
import { Button, Input, Modal as ModalComponent, Space } from "antd";
import React from "react";
import { appDimensions } from "../../../components/utils/theme";
import { IFilterOrderItemsInput } from "../../../definitions/orderBlock";
import OrderBlockFilterOrderItemsInput from "./OrderBlockFilterOrderItemsInput";

const Modal = ModalComponent as React.FC<
  React.ComponentProps<typeof ModalComponent> & { children: React.ReactNode }
>;

export interface IOrderBlockSelectOrdersControlsProps {
  disabled?: boolean;
  isFilterMode?: boolean;
  filter: Partial<IFilterOrderItemsInput>;
  query: string;
  onEnterSearchQuery: (query: string) => void;
  onEnterOrderFilter: (input: Partial<IFilterOrderItemsInput>) => void;
  onClearFilterMode: () => void;
}

const classes = {
  root: css({
    display: "flex",
    alignItems: "flex-start",
    "& .ant-input-affix-wrapper": {
      minHeight: "32px",
    },
  }),
  leftControls: css({
    flex: 1,
  }),
};

const OrderBlockSelectOrdersControls: React.FC<
  IOrderBlockSelectOrdersControlsProps
> = (props) => {
  const {
    disabled,
    isFilterMode,
    filter,
    onClearFilterMode,
    onEnterOrderFilter,
    onEnterSearchQuery,
  } = props;

  const [showFilter, setFilterVisibility] = React.useState(false);
  const [filterInput, setFilterInput] =
    React.useState<Partial<IFilterOrderItemsInput>>(filter);

  const internalOnClearFilter = () => {
    setFilterInput({});
    onClearFilterMode();
  };

  const orderStatusInputNode = showFilter && (
    <Modal
      visible
      closable
      title="Filter Orders"
      okText="Apply Filters"
      onOk={() => {
        onEnterOrderFilter(filterInput);
        setFilterVisibility(false);
      }}
      onCancel={() => setFilterVisibility(false)}
    >
      <OrderBlockFilterOrderItemsInput
        disabled={disabled}
        input={filterInput}
        setInput={setFilterInput}
      />
    </Modal>
  );

  const searchNode = (
    <Input.Search
      allowClear
      disabled={disabled}
      placeholder="Search orders"
      onSearch={onEnterSearchQuery}
      style={{ width: appDimensions.searchInputWidth }}
    />
  );

  return (
    <div className={classes.root}>
      {orderStatusInputNode}
      <div className={classes.leftControls}>{searchNode}</div>
      <Space>
        <Button onClick={() => setFilterVisibility(true)}>Show Filter</Button>
        {isFilterMode && (
          <Button onClick={internalOnClearFilter}>Clear Filter</Button>
        )}
      </Space>
    </div>
  );
};

export default OrderBlockSelectOrdersControls;
