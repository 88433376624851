import { useRequest } from "ahooks";
import { List } from "antd";
import React from "react";
import OrderBayAPI from "../../../api/endpoints/orderBay";
import PageError from "../../../components/utils/PageError";
import PageLoading from "../../../components/utils/PageLoading";
import { appClasses } from "../../../components/utils/theme";
import OrderItemTripHistoryItem from "./OrderItemTripHistoryItem";

export interface IOrderItemTripHistoryProps {
  orderItemId: string;
}

const OrderItemTripHistory: React.FC<IOrderItemTripHistoryProps> = (props) => {
  const { orderItemId } = props;
  const getData = React.useCallback(async () => {
    return await OrderBayAPI.getTripHistory({ orderItemId });
  }, [orderItemId]);

  const { error, data, loading, run } = useRequest(getData);

  if (error) {
    return (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload Trip History", onClick: run }]}
      />
    );
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return (
    <List
      split
      loading={loading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => {
        return (
          <List.Item>
            <OrderItemTripHistoryItem item={item} />
          </List.Item>
        );
      }}
      className={appClasses.list}
    />
  );
};

export default OrderItemTripHistory;
