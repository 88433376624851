import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown as DropdownComponent, Menu, message } from "antd";
import React from "react";
import OrderBlockAPI from "../../api/endpoints/orderBlock";
import { IEndpointMessageResult } from "../../api/types";
import ReasonForm from "../../components/form/ReasonForm";
import { IOrderBlock } from "../../definitions/orderBlock";
import OrderBlockAdjustDatesFormContainer from "./form/OrderBlockAdjustDatesFormContainer";
import OrderBlockSelectDriverFormContainer from "./OrderBlockSelectDriverFormContainer";

const Dropdown = DropdownComponent as React.FC<
  React.ComponentProps<typeof DropdownComponent> & { children: React.ReactNode }
>;

export interface IOrderBlockActionsButtonProps {
  isPublicBlock?: boolean;
  isPublished?: boolean;
  buttonClassName?: string;
  block: IOrderBlock;
  onCompleteUpdate: () => void;
  onCompleteDelete: () => void;
  onSelectUpdateBlock: () => void;
}

enum Actions {
  PublishPublicBlock = "Publich public block",
  PublishPrivateBlock = "Publish private block",
  UnpublishOrderBlock = "Unpublish order block",
  DeleteOrderBlock = "Delete block",
  UpdateBlock = "Update block",
  AdjustDates = "Adjust dates",
}

const OrderBlockActionsButton: React.FC<IOrderBlockActionsButtonProps> = (
  props
) => {
  const {
    isPublicBlock,
    isPublished,
    block,
    buttonClassName,
    onCompleteUpdate,
    onCompleteDelete,
    onSelectUpdateBlock,
  } = props;

  const [showSelectDriverForm, setSelectDriverForm] = React.useState(false);
  const [showAdjustDateForm, setAdjustDateForm] = React.useState(false);
  const [showReasonForm, setReasonForm] = React.useState<{
    action: Actions;
  } | null>();
  const [reasonFormError, setReasonFormError] = React.useState<string>();
  const [reasonFormLoading, setReasonFormLoaing] = React.useState<boolean>();
  const [actionLoading, setActionLoading] = React.useState<boolean>();

  const closeSelectDriverForm = React.useCallback(() => {
    setSelectDriverForm(false);
  }, []);

  const closeAdjustDateForm = React.useCallback(() => {
    setAdjustDateForm(false);
  }, []);

  const onCompleteReasonForm = React.useCallback(
    async (reason: string) => {
      if (!showReasonForm) {
        return;
      }

      setReasonFormLoaing(true);
      setReasonFormError("");
      try {
        let response: IEndpointMessageResult | null = null;
        switch (showReasonForm.action) {
          case Actions.UnpublishOrderBlock:
            response = await OrderBlockAPI.unpublishOrderBlock({
              reason,
              id: block.BlockId,
            });
            onCompleteUpdate();
            break;
          case Actions.DeleteOrderBlock:
            response = await OrderBlockAPI.deleteBlock({
              reason,
              id: block.BlockId,
            });
            onCompleteDelete();
            break;
          default:
            return; // Reamining actions don't require reason
        }

        message.success(
          response?.Message || response?.message || "Request completed"
        );
      } catch (error: any) {
        let errorMessage = error?.message || "Error completing request";
        setReasonFormError(errorMessage);
        message.error(errorMessage);
      }

      setReasonFormLoaing(false);
    },
    [block.BlockId, showReasonForm, onCompleteUpdate, onCompleteDelete]
  );

  const closeReasonForm = React.useCallback(() => {
    setReasonForm(null);
    setReasonFormError("");
    setReasonFormLoaing(false);
  }, []);

  const reasonFormNode = showReasonForm && (
    <ReasonForm
      useModal
      loading={reasonFormLoading}
      error={reasonFormError}
      onSubmit={onCompleteReasonForm}
      onCancel={closeReasonForm}
      title={
        showReasonForm.action === Actions.UnpublishOrderBlock
          ? "Unpublish Order Block"
          : showReasonForm.action === Actions.DeleteOrderBlock
          ? "Delete Order Block"
          : ""
      }
    />
  );

  const selectDriverFormNode = showSelectDriverForm && (
    <OrderBlockSelectDriverFormContainer
      blockId={block.BlockId}
      onCancel={closeSelectDriverForm}
      onCompleteUpdate={onCompleteUpdate}
    />
  );

  const adjustDateFormNode = showAdjustDateForm && (
    <OrderBlockAdjustDatesFormContainer
      block={block}
      onCancel={closeAdjustDateForm}
      onCompleteUpdate={onCompleteUpdate}
    />
  );

  const onSelectAction = React.useCallback(
    async (info: { key: string }) => {
      const messageKey = Math.random().toString();
      let response: IEndpointMessageResult | null = null;

      function beginMessage(str: string = "Updating order block") {
        message.loading({ key: messageKey, content: str, type: "loading" });
      }

      function successMessage(inputResponse: IEndpointMessageResult) {
        message.success({
          type: "success",
          key: messageKey,
          content:
            inputResponse?.Message ||
            inputResponse?.message ||
            "Request completed",
        });
      }

      try {
        switch (info.key) {
          case Actions.PublishPrivateBlock:
            setSelectDriverForm(true);
            break;
          case Actions.UnpublishOrderBlock:
            setReasonForm({ action: Actions.UnpublishOrderBlock });
            break;
          case Actions.DeleteOrderBlock:
            setReasonForm({ action: Actions.DeleteOrderBlock });
            break;
          case Actions.PublishPublicBlock: {
            setActionLoading(true);
            beginMessage("Publishing public block");
            response = await OrderBlockAPI.publishPublicBlock({
              blockId: block.BlockId,
            });
            successMessage(response);
            onCompleteUpdate();
            break;
          }
          case Actions.UpdateBlock: {
            onSelectUpdateBlock();
            break;
          }
          case Actions.AdjustDates: {
            setAdjustDateForm(true);
            break;
          }
        }

        setActionLoading(false);
      } catch (error: any) {
        let errorMessage = error?.message || "Error completing request";
        message.error(errorMessage);
        setActionLoading(false);
      }
    },
    [block.BlockId, onSelectUpdateBlock, onCompleteUpdate]
  );

  const menuOptionsNode = (
    <Menu onClick={onSelectAction} style={{ minWidth: "200px" }}>
      <Menu.Item
        key={Actions.PublishPublicBlock}
        disabled={actionLoading || !isPublicBlock || isPublished}
      >
        Publish public block
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={Actions.PublishPrivateBlock}
        disabled={actionLoading || isPublicBlock || isPublished}
      >
        Publish private block
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={Actions.UnpublishOrderBlock}
        disabled={actionLoading || !isPublished}
      >
        Unpublish order block
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={Actions.AdjustDates} disabled={actionLoading}>
        Adjust dates
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={Actions.UpdateBlock} disabled={actionLoading}>
        Update block
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={Actions.DeleteOrderBlock} disabled={actionLoading}>
        Delete block
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      {reasonFormNode}
      {selectDriverFormNode}
      {adjustDateFormNode}
      <Dropdown arrow overlay={menuOptionsNode}>
        <Button
          icon={<EllipsisOutlined />}
          className={buttonClassName}
        ></Button>
      </Dropdown>
    </React.Fragment>
  );
};

export default OrderBlockActionsButton;
