import { Table, TableColumnType, Tag, Typography } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import React from "react";
import { Link } from "react-router-dom";
import NodeWithTip from "../../../components/utils/NodeWithTip";
import TableSkeleton from "../../../components/utils/TableSkeleton";
import { IOrder } from "../../../definitions/orderBay";
import { appOrderBayPaths } from "../../../global/paths";
import OrderItemActionsButton from "./OrderItemActionsButton";

export interface IOrdersPagedByOrderTableProps {
  loading?: boolean;
  withCheckbox?: boolean;
  hideSelectAll?: boolean;
  selectedRowKeys?: string[];
  omitActions?: boolean;
  data: IOrder[];
  onCompleteUpdate: () => void;
  onCompleteDelete: () => void;
  onSelectRows?: (selectedRows: IOrder[], selectedAll: boolean) => void;
}

const OrdersPagedByOrderTable: React.FC<IOrdersPagedByOrderTableProps> = (
  props
) => {
  const {
    data,
    loading,
    hideSelectAll,
    withCheckbox,
    selectedRowKeys,
    omitActions,
    onSelectRows,
    onCompleteDelete,
    onCompleteUpdate,
  } = props;

  const columns: TableColumnType<IOrder>[] = [
    {
      title: "Order Number",
      ellipsis: true,
      width: 150,
      dataIndex: "OrderItemNumber",
      key: "OrderItemNumber",
      render(text, record) {
        return (
          <Link to={`${appOrderBayPaths.groupedByOrder}/${record.OrderId}`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: "Order Name",
      width: 150,
      dataIndex: "OrderName",
      key: "OrderName",
    },
    {
      title: "Category",
      width: 150,
      dataIndex: "OrderCategory",
      key: "OrderCategory",
    },
    {
      title: "Order Status",
      width: 150,
      dataIndex: "OrderItemStatus",
      key: "OrderItemStatus",
      render(text, record) {
        return (
          <NodeWithTip tip={record.OrderItemStatus.Description}>
            <Tag
              style={{ color: record.OrderItemStatus.Color }}
              // color={record.OrderItemStatus.Color}
            >
              {record.OrderItemStatus.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Status",
      width: 160,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return (
          <NodeWithTip tip={record.Status.Description}>
            <Tag style={{ color: record.Status.Color }}>
              {record.Status.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "From Location",
      width: 220,
      dataIndex: "From Location",
      key: "From_Sender",
      render(text, record) {
        return record.From_Sender.Address;
      },
    },
    {
      title: "Current Location",
      width: 220,
      dataIndex: "CurrentItemLocation",
      key: "CurrentItemLocation",
    },
    {
      title: "To Location",
      width: 220,
      dataIndex: "To Location",
      key: "To_Receiver",
      render(text, record) {
        return record.To_Receiver.Address;
      },
    },
    {
      title: "Estimated Size",
      width: 160,
      dataIndex: "PackageEstimatedSize",
      key: "PackageEstimatedSize",
      render(text, record) {
        return (
          <NodeWithTip tip={record.PackageEstimatedSize.Description}>
            <Tag
              style={{ color: record.PackageEstimatedSize.Color }}
              // color={record.PackageEstimatedSize.Color}
            >
              {record.PackageEstimatedSize.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Estimated Weight",
      width: 180,
      dataIndex: "EstimatedWeight",
      key: "EstimatedWeight",
      render(text, record) {
        return (
          <Typography.Text>
            {record.EstimatedWeight} {record.EstimatedWeightUnit.Name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Updated By",
      width: 150,
      dataIndex: "LastUpdated_",
      key: "LastUpdated_",
    },
  ];

  if (!omitActions) {
    columns.push({
      title: "",
      key: "Actions",
      width: 50,
      fixed: "right",
      render: (unused, record) => {
        return (
          <OrderItemActionsButton
            orderId={record.OrderId}
            onCompleteDelete={onCompleteDelete}
            onCompleteUpdate={onCompleteUpdate}
          />
        );
      },
    });
  }

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  let rowSelection: TableRowSelection<IOrder> | undefined = undefined;

  if (withCheckbox) {
    rowSelection = {
      hideSelectAll,
      selectedRowKeys,
      type: "checkbox",
      onChange: (selectedRowKeys: React.Key[], selectedRows: IOrder[]) => {
        onSelectRows &&
          onSelectRows(selectedRows, selectedRows.length === data.length);
      },
    };
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={data}
      columns={columns}
      rowKey={(row) => row.OrderId}
      pagination={false}
      rowSelection={rowSelection}
    />
  );
};

export default OrdersPagedByOrderTable;
