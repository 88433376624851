import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Select, Space, Checkbox } from "antd";
import { IFilterOrderItemsInput } from "../../../definitions/orderBlock";
import {
  loadOrderCategoryStart,
  loadPackageSizeStart,
  orderBlockFilterCountryListActions,
  orderBlockFilterStateListActions,
  orderBlockFilterCityListActions,
} from "../../../redux/ResourcesRedux/resourcesAction";
import { IReduxState, IReduxStateResources } from "../../../redux/types";
import moment from "moment";

export interface IOrderBlockFilterOrderItemsInputProps {
  disabled?: boolean;
  input: Partial<IFilterOrderItemsInput>;
  setInput: (input: Partial<IFilterOrderItemsInput>) => void;
}

const OrderBlockFilterOrderItemsInput: React.FC<
  IOrderBlockFilterOrderItemsInputProps
> = (props) => {
  const { input, disabled, setInput } = props;
  const dispatch = useDispatch();

  const {
    orderCategories,
    packageSizes,
    orderBlockFilterCountryList,
    orderBlockFilterStateList,
    orderBlockFilterCityList,
  } = useSelector<IReduxState, IReduxStateResources>(
    (state) => state.resources
  );

  useEffect(() => {
    dispatch(loadOrderCategoryStart());
    dispatch(loadPackageSizeStart());
    dispatch(orderBlockFilterCountryListActions.startAction());
    dispatch(orderBlockFilterStateListActions.startAction());
    dispatch(orderBlockFilterCityListActions.startAction());
  }, [dispatch]);

  const categoryNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Category"
    >
      <Select
        allowClear
        placeholder="Select category"
        value={input.category}
        onChange={(category) => setInput({ ...input, category })}
        disabled={disabled}
      >
        {orderCategories?.map((code) => {
          return (
            <Select.Option key={code} value={code}>
              {code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const packageEstimatedSizeNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Package Estimated Size"
    >
      <Select
        allowClear
        placeholder="Select package estimated size"
        value={input.parcelSize}
        onChange={(parcelSize) => setInput({ ...input, parcelSize })}
        disabled={disabled}
      >
        {packageSizes?.map((size) => {
          return (
            <Select.Option key={size.id} value={size.id}>
              {size.packageSize_}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const cityNode = (
    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="City">
      <Select
        allowClear
        placeholder="Select city"
        value={input.deliveryCity}
        onChange={(deliveryCity) => setInput({ ...input, deliveryCity })}
        disabled={disabled}
      >
        {orderBlockFilterCityList?.map((item) => {
          return (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const stateNode = (
    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="State">
      <Select
        allowClear
        placeholder="Select state"
        value={input.deliveryState}
        onChange={(deliveryState) =>
          setInput({ ...input, deliveryState, deliveryCity: undefined })
        }
        disabled={disabled}
      >
        {orderBlockFilterStateList?.map((item) => {
          return (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const countryNode = (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Country"
    >
      <Select
        allowClear
        placeholder="Select country"
        value={input.deliveryCountry}
        onChange={(deliveryCountry) =>
          setInput({
            ...input,
            deliveryCountry,
            deliveryState: undefined,
            deliveryCity: undefined,
          })
        }
        disabled={disabled}
      >
        {orderBlockFilterCountryList?.map((item) => {
          return (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const dateCreatedNode = (
    <Form.Item
      label="Date Created"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <DatePicker
        format="YYYY-MM-DD"
        disabledDate={function disabledDate(current?: moment.Moment) {
          // Can not select days past today
          return !!(current && current > moment().endOf("day"));
        }}
        placeholder="Public block expiration date"
        value={input.dateCreated ? moment(input.dateCreated) : null}
        onChange={(date) =>
          setInput({ ...input, dateCreated: date?.toISOString() })
        }
        disabled={disabled}
        style={{ width: "200px" }}
      />
    </Form.Item>
  );

  const deliveryDateNode = (
    <Form.Item
      label="Delivery Date"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <DatePicker.RangePicker
        format="YYYY-MM-DD"
        allowEmpty={[true, true]}
        value={[
          input.from_EstDeliveryDate
            ? moment(input.from_EstDeliveryDate)
            : null,
          input.to_EstDeliveryDate ? moment(input.to_EstDeliveryDate) : null,
        ]}
        onChange={(dates, dateStrings) =>
          setInput({
            ...input,
            from_EstDeliveryDate: dateStrings[0],
            to_EstDeliveryDate: dateStrings[1],
          })
        }
        disabled={disabled}
      />
    </Form.Item>
  );

  const checkListNode = (
    <Form.Item>
      <Space direction="vertical">
        <Checkbox
          onChange={(value) =>
            setInput({ ...input, fragile: !!value.target.checked })
          }
          checked={input.fragile}
        >
          Fragile
        </Checkbox>
        <Checkbox
          onChange={(value) =>
            setInput({ ...input, perishable: !!value.target.checked })
          }
          checked={input.perishable}
        >
          Perishable
        </Checkbox>
        <Checkbox
          onChange={(value) =>
            setInput({ ...input, canSpill: !!value.target.checked })
          }
          checked={input.canSpill}
        >
          Can Spill
        </Checkbox>
        <Checkbox
          onChange={(value) =>
            setInput({ ...input, hazardious: !!value.target.checked })
          }
          checked={input.hazardious}
        >
          Hazardious
        </Checkbox>
        <Checkbox
          onChange={(value) =>
            setInput({ ...input, explosive: !!value.target.checked })
          }
          checked={input.explosive}
        >
          Explosive
        </Checkbox>
        <Checkbox
          onChange={(value) =>
            setInput({ ...input, flammable: !!value.target.checked })
          }
          checked={input.flammable}
        >
          Flammable
        </Checkbox>
      </Space>
    </Form.Item>
  );

  return (
    <div>
      {categoryNode}
      {packageEstimatedSizeNode}
      {dateCreatedNode}
      {deliveryDateNode}
      {countryNode}
      {stateNode}
      {cityNode}
      {checkListNode}
    </div>
  );
};

export default OrderBlockFilterOrderItemsInput;
