import { Divider, Space } from "antd";
import React from "react";
import { IOrderInput } from "../../../definitions/orderBay";
import OrderItemCheckList from "../order/OrderItemCheckList";
import OrderItemDeliveryCheckList from "../order/OrderItemDeliveryCheckList";
import OrderItemPackageDetails from "../order/OrderItemPackageDetails";

export interface IOrderItemFormPreviewProps {
  orderItem: Omit<IOrderInput, "recipientInfo">;
  isSmallScreen?: boolean;
}

const OrderItemFormPreview: React.FC<IOrderItemFormPreviewProps> = (props) => {
  const { orderItem, isSmallScreen } = props;
  return (
    <Space direction="vertical" size="middle">
      <Divider orientation="left">Package Details</Divider>
      <OrderItemPackageDetails {...orderItem} isSmallScreen={isSmallScreen} />
      <Divider orientation="left">CheckList</Divider>
      <OrderItemCheckList
        {...orderItem.checkList}
        isSmallScreen={isSmallScreen}
      />
      <Divider orientation="left">Delivery CheckList</Divider>
      <OrderItemDeliveryCheckList
        {...orderItem}
        isSmallScreen={isSmallScreen}
      />
    </Space>
  );
};

export default OrderItemFormPreview;
