import { Divider, Modal } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { IOrderInput } from "../../../definitions/orderBay";
import { appFormMessages } from "../../../global/messages";
import OrderItemCheckListInput from "./OrderItemCheckListInput";
import OrderItemDeliveryCheckListInput from "./OrderItemDeliveryCheckListInput";
import OrderItemDriverNoteInput from "./OrderItemDriverNoteInput";
import OrderItemPackageDetailsInput, {
  OTHERS_CATEGORY,
} from "./OrderItemPackageDetailsInput";

export interface IOrderItemFormProps {
  loading?: boolean;
  order?: Omit<IOrderInput, "recipientInfo">;
  orgId?: string;
  title: string;
  onSubmit: (order: Omit<IOrderInput, "recipientInfo">) => void;
  onCancel: () => void;
}

const orderInitialValues: Omit<IOrderInput, "recipientInfo"> = {
  category: "",
  otherCategory: "",
  estimatedWeight: 0,
  estimatedWeightUnit: "lb",
  packageEstimatedSize: "",
  packageName: "",
  description: "",
  note: "",
  checkList: {
    fragile: false,
    perishable: false,
    contentCanSpill: false,
    hazardious: false,
    explosive: false,
    flammable: false,
  },
  deliveryCheckListInfo: {
    canDispatcher_DropPackageWithNeighbor: false,
    canDispatcher_HidePackageInASecureArea: false,
    canDispatcher_ReturnPackageToNearestBranchIfNotAround: true,
    returnPackageToNearestBranchId: null,
  },
  recipientPickingUpAtNearestBranch: false,
  recipientPickingUpAtNearestBranchId: null,
};

const validationSchema = yup.object().shape({
  category: yup.string().required(appFormMessages.requiredField),
  otherCategory: yup.string().when("category", {
    is: OTHERS_CATEGORY,
    then: yup.string().required(appFormMessages.requiredField),
  }),
  estimatedWeight: yup
    .number()
    .required(appFormMessages.requiredField)
    .moreThan(0, "Weight should me more than 0"),
  estimatedWeightUnit: yup.string().required(appFormMessages.requiredField),
  packageEstimatedSize: yup.string().required(appFormMessages.requiredField),
  packageName: yup.string().required(appFormMessages.requiredField),
  description: yup.string().nullable(),
  note: yup.string().nullable(),
  checkList: yup.object().shape({
    fragile: yup.boolean(),
    perishable: yup.boolean(),
    contentCanSpill: yup.boolean(),
    hazardious: yup.boolean(),
    explosive: yup.boolean(),
    flammable: yup.boolean(),
  }),
  recipientPickingUpAtNearestBranch: yup
    .boolean()
    .required(appFormMessages.requiredField),
  recipientPickingUpAtNearestBranchId: yup
    .string()
    .when("recipientPickingUpAtNearestBranch", {
      is: true,
      then: yup
        .string()
        .nullable()
        .uuid()
        .required(appFormMessages.requiredField),
    })
    .nullable(),
  deliveryCheckListInfo: yup.object().shape({
    canDispatcher_DropPackageWithNeighbor: yup.boolean(),
    canDispatcher_HidePackageInASecureArea: yup.boolean(),
    canDispatcher_ReturnPackageToNearestBranchIfNotAround: yup.boolean(),
    returnPackageToNearestBranchId: yup
      .string()
      .when("canDispatcher_ReturnPackageToNearestBranchIfNotAround", {
        is: true,
        then: yup
          .string()
          .nullable()
          .uuid()
          .required(appFormMessages.requiredField),
      })
      .nullable(),
  }),
});

const OrderItemForm: React.FC<IOrderItemFormProps> = (props) => {
  const { loading, title, onSubmit, onCancel, orgId } = props;
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: props.order || orderInitialValues,
  });

  return (
    <Modal
      destroyOnClose
      visible
      title={title}
      onCancel={onCancel}
      onOk={formik.submitForm}
    >
      <form onSubmit={formik.handleSubmit}>
        <Divider orientation="left">Package Details</Divider>
        <OrderItemPackageDetailsInput formik={formik} loading={loading} />
        <Divider orientation="left">CheckList</Divider>
        <OrderItemCheckListInput formik={formik} loading={loading} />
        <Divider orientation="left">Delivery CheckList</Divider>
        <OrderItemDeliveryCheckListInput
          formik={formik}
          loading={loading}
          orgId={orgId}
        />
        <OrderItemDriverNoteInput formik={formik} loading={loading} />
      </form>
    </Modal>
  );
};

export default OrderItemForm;
