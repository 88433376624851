import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Button, Collapse, Form, Space, Typography } from "antd";
import { isNumber } from "lodash";
import React from "react";
import { appFormControlClasses } from "../../../components/utils/theme";
import OrderItemsByRecipient, {
  IRecipientWithOrdersEntry,
} from "./OrderItemsByRecipient";
import RecipientForm from "./RecipientForm";

/**
 * OrderItemsByRecipient serves as the entry component for the order creation flow.
 * For the order creation flow, users will first enter the recipient,
 * the for each recipient entered, they can add orders to them. On submit,
 * we will restructure the data into the format the API accepts, which requires
 * a recipient per order.
 */

export interface IOrderItemsByRecipientListProps {
  loading?: boolean;
  orgId?: string;
  isPreview?: boolean;
  entries: IRecipientWithOrdersEntry[];
  onNext: (entries: IRecipientWithOrdersEntry[]) => void;
  onPrev: (entries: IRecipientWithOrdersEntry[]) => void;
}

const classes = {
  root: css({
    width: "100%",
    maxWidth: "720px",
    margin: "auto",

    "& .ant-collapse-header": {
      alignItems: "center !important",
    },
  }),
  collapseBtns: css({
    "& .ant-space-item": {
      alignItems: "center",
      justifyContent: "center",
      display: "flex !important",
    },
  }),
};

const OrderItemsByRecipientList: React.FC<IOrderItemsByRecipientListProps> = (
  props
) => {
  const { isPreview, loading, onNext, onPrev, orgId } = props;
  const [entries, setEntries] = React.useState<IRecipientWithOrdersEntry[]>(
    props.entries || []
  );

  const [editingIndex, setEditingIndex] = React.useState<number | null>(null);
  const [showNewRecipientForm, setShowNewRecipientForm] = React.useState(false);
  const closeForm = React.useCallback(() => {
    setEditingIndex(null);
    setShowNewRecipientForm(false);
  }, []);

  const formNode = (showNewRecipientForm || isNumber(editingIndex)) && (
    <RecipientForm
      loading={loading}
      onSubmit={(recipient) => {
        if (isNumber(editingIndex)) {
          const newEntries = [...entries];
          newEntries[editingIndex] = { ...newEntries[editingIndex], recipient };
          setEntries(newEntries);
        } else {
          setEntries([...entries, { recipient, orders: [] }]);
        }

        closeForm();
      }}
      title={
        isNumber(editingIndex)
          ? entries[editingIndex].recipient.name
          : "New Recipient"
      }
      onClose={closeForm}
      recipient={
        isNumber(editingIndex) ? entries[editingIndex]?.recipient : undefined
      }
    />
  );

  const renderEntry = (item: IRecipientWithOrdersEntry, i: number) => {
    const extraNodes = isPreview ? null : (
      <Space size={"small"} className={classes.collapseBtns} align="center">
        <Button
          icon={<EditOutlined />}
          key="edit-btn"
          onClick={(event) => {
            setEditingIndex(i);
            event.stopPropagation();
          }}
        />
        <Button
          icon={<DeleteOutlined />}
          key="delete-btn"
          onClick={(event) => {
            const newEntries = [...entries];
            newEntries.splice(i, 1);
            setEntries(newEntries);
            event.stopPropagation();
          }}
        />
      </Space>
    );

    const header = `${item.recipient.name}  `;
    return (
      <Collapse.Panel
        header={
          <Space direction="vertical" size={4}>
            <Typography.Text>{header}</Typography.Text>
            <Typography.Text type="secondary">
              {item.orders.length} order(s) —{" "}
              {isPreview ? "Open to view orders" : "Open to add orders"}
            </Typography.Text>
          </Space>
        }
        key={header}
        extra={extraNodes}
      >
        <OrderItemsByRecipient
          isPreview={isPreview}
          entry={item}
          loading={loading}
          onUpdate={(entry) => {
            const newEntries = [...entries];
            newEntries[i] = entry;
            setEntries(newEntries);
          }}
          orgId={orgId}
        />
      </Collapse.Panel>
    );
  };

  const secondaryControlsNode = !isPreview && (
    <Form.Item>
      <Button
        onClick={() => {
          setShowNewRecipientForm(true);
        }}
      >
        Add Recipient
      </Button>
    </Form.Item>
  );

  const primaryControlsNode = !isPreview && (
    <Form.Item
      style={{ marginTop: "32px", marginBottom: "32px", display: "flex" }}
    >
      <div className={appFormControlClasses.controlRoot}>
        <div
          className={appFormControlClasses.spaceEvenContainer}
          style={{ marginRight: "16px" }}
        >
          <Button onClick={() => onPrev(entries)}>Prev</Button>
        </div>
        <Button
          type="primary"
          onClick={() => onNext(entries)}
          disabled={entries.length === 0}
        >
          Next
        </Button>
      </div>
    </Form.Item>
  );

  return (
    <div className={classes.root}>
      {formNode}
      <Form.Item
        label="Recipients"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Collapse expandIconPosition="right">
          {entries.map(renderEntry)}
        </Collapse>
      </Form.Item>
      {secondaryControlsNode}
      {primaryControlsNode}
    </div>
  );
};

export default OrderItemsByRecipientList;
