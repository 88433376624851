
export const GET_STATISTICS_START = "GET_STATISTICS_START"
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS"
export const GET_STATISTICS_ERROR = "GET_STATISTICS_ERROR"


export const GET_TOP_BLOCKS_START = "GET_TOP_BLOCKS_START"
export const GET_TOP_BLOCKS_SUCCESS = "GET_TOP_BLOCKS_SUCCESS"
export const GET_TOP_BLOCKS_ERROR = "GET_TOP_BLOCKS_ERROR"

export const GET_TOP_ORDERS_START = "GET_TOP_ORDERS_START"
export const GET_TOP_ORDERS_SUCCESS = "GET_TOP_ORDERS_SUCCESS"
export const GET_TOP_ORDERS_ERROR = "GET_TOP_ORDERS_ERROR"