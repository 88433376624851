
export const GET_DEFECTS_START = "GET_DEFECTS_START"
export const GET_DEFECTS_SUCCESS = "GET_DEFECTS_SUCCESS"
export const GET_DEFECTS_ERROR = "GET_DEFECTS_ERROR"

export const GET_DEFECTS_BY_ID_START = "GET_DEFECTS_BY_ID_START"
export const GET_DEFECTS_BY_ID_SUCCESS = "GET_DEFECTS_BY_ID_SUCCESS"
export const GET_DEFECTS_BY_ID_ERROR = "GET_DEFECTS_BY_ID_ERROR"


export const GET_DEFECTS_SEARCH_START = "GET_DEFECTS_SEARCH_START"
export const GET_DEFECTS_SEARCH_SUCCESS = "GET_DEFECTS_SEARCH_SUCCESS"
export const GET_DEFECTS_SEARCH_ERROR = "GET_DEFECTS_SEARCH_ERROR"

export const GET_DEFECTS_EXECUTION_START = "GET_DEFECTS_EXECUTION_START"
export const GET_DEFECTS_EXECUTION_SUCCESS = "GET_DEFECTS_EXECUTION_SUCCESS"
export const GET_DEFECTS_EXECUTION_ERROR = "GET_DEFECTS_EXECUTION_ERROR"
