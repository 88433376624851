import React, { useState, useEffect } from "react";
import "./employeesignup.css";

import TextField from "@material-ui/core/TextField";
import { API_URL } from "../../globalVariable";
import Multiselect from "multiselect-react-dropdown";
import swal from "sweetalert";
import { connect } from "react-redux";
import Loader from "../Loader";
import Cookies from "js-cookie";

import { Input, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCountryStateCityStart,
  loadTicketStatusStart,
  loadTicketRelatedServiceStart,
  loadCountryCodeObjStart,
  loadCountryCodeStart,
  loadLongCountryCodeStart,
  loadCountryStateStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import { loadPolicyStart } from "../../redux/PolicyRedux/policyAction";
import {
  loadMovebotRoleStart,
  loadMovebotPolicyStart,
} from "../../redux/MovebotPolicyAndPermissionRedux/movebotAction";
import axios from "axios";

function EmployeeSignUp({ change }) {
  const [select, setSelect] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countryCodes, setCountryCodes] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState();
  const [roleId, setRoleId] = useState("");
  const [getCities, setGetCities] = useState("");

  const [policy, setPolicy] = useState();
  const [getCountries, setGetCountries] = useState();
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const { roles, permissions } = useSelector((state) => state.movebotActions);

  const {

    countryStateCities,
    countryCode,
    getStates,
  } = useSelector((state) => state.resources);

  const { policies, loading } = useSelector((state) => state.policies);

  const { Option } = Select;

  useEffect(() => {
    dispatch(loadPolicyStart());
    dispatch(loadCountryStateCityStart());
    dispatch(loadCountryCodeStart());
    dispatch(loadMovebotRoleStart());
  }, []);

  useEffect(() => {
    dispatch(loadCountryStateStart(country));
  }, [country]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          API_URL +
            `Resources/GetCountryStateCities?country=${country}&state=${state}`,
          {
            headers: {
              authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        )
        // .then(res => res.json())
        .then((data) => setGetCities(data.data));
    };
    fetchData();
  }, [state]);

  function handleCountryChange(value) {
    setCountry(value);
  }

  function handleStateChange(value) {
    setState(value);
  }

  function handleCityChange(value) {
    setCity(value);
  }
  function handleCountryCodeChange(value) {
    setCountryCodes(value);
  }
  function handleRoleChange(value) {
    setRole(value);
  }
  function handlePermissionChange(value) {
    setPolicy(value);
  }

  const onSelect = (selectedList) => {
    setSelect(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelect(selectedList);
  };

  const createUser = async () => {
    setLoader(true);
    var data = {
      firstName,
      lastName,
      emailAddress: email,
      phoneNumber,
      countryCode: countryCodes,
      address: {
        street,
        city,
        state,
        zipCode,
        country,
      },
      assignedGroupPolicyId: policy,
    };

    var response = await fetch(API_URL + "Employee/Create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.Message,
        icon: "success",
      })
        .then(() => {
          setFirstName("");
          setLoader(false);
          setLastName("");
          setEmail("");
          setPhoneNumber("");
          setRole("");
          setCountryCodes("");
          setStreet("");
          setCity("");
          setState("");
          setCountry("");
          setZipCode("");
          setPolicy("");
        })
        .then(() => {
          change();
        });
    } else {
      if (dataResponse.Message) {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      }
    }
  };


  return (
    <div>
      <form>
        <div className="employee-form-div">
          <div className="half-width">
            <label>First Name</label>
            <br />
            <Input
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="half-width">
            <label>Last Name</label>
            <Input
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className="employee-form-div">
          <div style={{ width: "100%" }}>
            <label>Email Address</label>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="employee-form-div">
          <div style={{ width: "100%" }}>
            <label>Select Country</label>
            <br/>
            <Select
              defaultValue="Select a Country"
              onChange={handleCountryChange}
              style={{ width: "100%" }}
            >
              <Option value="Select a Country" disabled>
                Select a Country
              </Option>
              {countryStateCities?.map((country) => {
                return <Option value={country}>{country}</Option>;
              })}
            </Select>
          </div>
        </div>
        <div className="employee-form-div">
          <div className="half-width">
            <label>Select State</label>
            {country.length > 0 ? (
              <Select defaultValue="Select State" style={{ width: "98%" }} onChange={handleStateChange}>
                <Option value="Select State" disabled>
                  Select State
                </Option>
                {getStates?.map((code) => {
                  return <Option value={code}>{code}</Option>;
                })}
              </Select>
            ) : (
              <Select placeholder="Please select a Country" style={{ width: "98%" }} disabled />
            )}
          </div>
          <div className="half-width">
            <label>Select City</label>
            {state.length > 0 ? (
              <Select defaultValue="Select City" style={{ width: "98%" }} onChange={handleCityChange}>
                <Option value="Select City" disabled>
                  Select City
                </Option>
                {getCities?.map((code) => {
                  return <Option value={code}>{code}</Option>;
                })}
              </Select>
            ) : (
              <Select placeholder="Please select a state" style={{ width: "98%" }} disabled />
            )}
          </div>
        </div>
        <div className="employee-form-div">
          <div className="half-width">
            <label>Select Street</label>
            <Input
              placeholder="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </div>
          <div className="half-width">
            <label>Select Zipcode</label>
            <Input
              placeholder="ZipCode"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>
        </div>

        <div className="employee-form-div">
          <div className="half-width">
            <label>Select Country Code</label>
            <Select
              defaultValue="Select Country Code"
              onChange={handleCountryCodeChange}
              style={{ width: "98%" }}
              
            >
              <Option value="Select Country Code" disabled>
                Select Country Code
              </Option>
              {countryCode?.map((code) => {
                return <Option value={code}>{code}</Option>;
              })}
            </Select>
          </div>
          <div className="half-width">
            <label>Phone Number</label>
            <Input
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="employee-form-div">
          <div style={{ width: "100%" }}>
            <label>Select Role</label>
            <br />
            <Select defaultValue="Select Roles" style={{ width: "98%" }} onChange={handleRoleChange}>
              <Option value="Select Roles" disabled>
                Select Roles
              </Option>
              {roles?.map((policy) => {
                return (
                  <Option value={policy._RoleType}>{policy._RoleType}</Option>
                );
              })}
            </Select>
          </div>
          <div style={{ width: "100%" }}>
            <label>Select Permission</label>
            <br />
            <Select
              defaultValue="Select Permission"
              style={{ width: "98%" }}
              onChange={handlePermissionChange}
            >
              <Option value="Permission" disabled>
                Select Permission
              </Option>
              {policies?.map((per) => {
                return per.RoleInfo._RoleType === role ? (
                  <Option value={per.GroupPolicyId}>{per.GroupName}</Option>
                ) : null;
              })}
            </Select>
          </div>
        </div>
        <br />
        <div className="form-btn">
          <Button type="primary" id="but" onClick={() => createUser()}>
            {loader ? <Loader /> : "Create User"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EmployeeSignUp;
