import * as types from "./actionType"



export const getTrackingStart = (payload) => ({
    type: types.GET_TRACKING_START,
    payload
})

export const getTrackingSuccess = (tracking) => ({
    type: types.GET_TRACKING_SUCCESS,
    payload: tracking,
})

export const getTrackingError = (error) => ({
    type: types.GET_TRACKING_ERROR,
    payload: error
})


export const getTrackingSearchStart = (payload) => ({
    type: types.GET_TRACKING_SEARCH_START,
    payload
})

export const getTrackingSearchSuccess = (tracking) => ({
    type: types.GET_TRACKING_SEARCH_SUCCESS,
    payload: tracking,
})

export const getTrackingSearchError = (error) => ({
    type: types.GET_TRACKING_SEARCH_ERROR,
    payload: error
})

export const getTrackingDriverStart = (payload) => ({
    type: types.GET_TRACKING_DRIVER_START,
    payload
})

export const getTrackingDriverSuccess = (tracking) => ({
    type: types.GET_TRACKING_DRIVER_SUCCESS,
    payload: tracking,
})

export const getTrackingDriverError = (error) => ({
    type: types.GET_TRACKING_DRIVER_ERROR,
    payload: error
})