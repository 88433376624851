import * as types from "./orderbayActionType"



export const loadGroupOrderBayStart = () => ({
    type: types.LOAD_GROUP_ORDER_BAY_START
})

export const loadGroupOrderBaySuccess = (payload) => ({
    type: types.LOAD_GROUP_ORDER_BAY_SUCCESS,
    payload
})

export const loadGroupOrderBayError = (error) => ({
    type: types.LOAD_GROUP_ORDER_BAY_ERROR,
    payload: error
})

export const loadUnGroupOrderBayStart = () => ({
    type: types.LOAD_UN_GROUP_ORDER_BAY_START
})

export const loadUnGroupOrderBaySuccess = (payload) => ({
    type: types.LOAD_UN_GROUP_ORDER_BAY_SUCCESS,
    payload
})

export const loadUnGroupOrderBayError = (error) => ({
    type: types.LOAD_UN_GROUP_ORDER_BAY_ERROR,
    payload: error
})


export const createOrderBayStart = (payload) => ({
    type: types.CREATE_ORDER_BAY_START,
    payload
})

export const createOrderBaySuccess = (payload) => ({
    type: types.CREATE_ORDER_BAY_SUCCESS,
    payload
})

export const createOrderBayError = (error) => ({
    type: types.CREATE_ORDER_BAY_ERROR,
    payload: error
})


export const filterByParcelStart = (payload) => ({
    type: types.FILTER_BY_PARCEL_START,
    payload
})

export const filterByParcelSuccess = (payload) => ({
    type: types.FILTER_BY_PARCEL_SUCCESS,
    payload
})

export const filterByParcelError = (error) => ({
    type: types.FILTER_BY_PARCEL_ERROR,
    payload: error
})

export const filterByPackageStart = (payload) => ({
    type: types.FILTER_BY_PACKAGE_START,
    payload
})

export const filterByPackageSuccess = (payload) => ({
    type: types.FILTER_BY_PACKAGE_SUCCESS,
    payload
})

export const filterByPackageError = (error) => ({
    type: types.FILTER_BY_PACKAGE_ERROR,
    payload: error
})