import { Space, Switch, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabeledNode from "../../../components/utils/LabeledNode";
import { loadBranchStart } from "../../../redux/SettingsRedux/settingAction";
import { IReduxState, IReduxStateSettings } from "../../../redux/types";

export interface IOrderItemDeliveryCheckListProps {
  deliveryCheckListInfo: {
    returnPackageToNearestBranchId: string | null;
    canDispatcher_DropPackageWithNeighbor: boolean;
    canDispatcher_HidePackageInASecureArea: boolean;
    canDispatcher_ReturnPackageToNearestBranchIfNotAround: boolean;
  };
  recipientPickingUpAtNearestBranch: boolean;
  recipientPickingUpAtNearestBranchId: string | null;
  note?: string;
  isSmallScreen?: boolean;
}

const OrderItemDeliveryCheckList: React.FC<IOrderItemDeliveryCheckListProps> = (
  props
) => {
  const {
    deliveryCheckListInfo,
    recipientPickingUpAtNearestBranch,
    recipientPickingUpAtNearestBranchId,
    note,
  } = props;

  const dispatch = useDispatch();
  const { branch: branches } = useSelector<IReduxState, IReduxStateSettings>(
    (state) => state.settings
  );

  useEffect(() => {
    dispatch(loadBranchStart());
  }, [dispatch]);

  const dropOffBranch = recipientPickingUpAtNearestBranchId
    ? branches.find(
        (branch) => branch.Id === recipientPickingUpAtNearestBranchId
      )
    : null;

  const returnPackageNearestBranch =
    deliveryCheckListInfo?.returnPackageToNearestBranchId
      ? branches.find(
          (branch) =>
            branch.Id === deliveryCheckListInfo.returnPackageToNearestBranchId
        )
      : null;

  return (
    <Space direction="vertical" size={24}>
      <LabeledNode
        nodeIsText
        direction="vertical"
        label="Recipient Picking Up At Nearest Branch"
        node={recipientPickingUpAtNearestBranch ? "Yes" : "No"}
      />
      {recipientPickingUpAtNearestBranch && (
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Recipient Picking Up At Nearest Branch"
          node={dropOffBranch?.BranchName}
        />
      )}
      {!recipientPickingUpAtNearestBranch && (
        <Space direction="vertical" size={24}>
          <Space direction="vertical">
            <Space>
              <Switch
                disabled
                defaultChecked={
                  deliveryCheckListInfo?.canDispatcher_DropPackageWithNeighbor
                }
              />
              <Typography.Text>
                Can Dispatcher Drop Package With Neighbor
              </Typography.Text>
            </Space>
            <Space>
              <Switch
                disabled
                defaultChecked={
                  deliveryCheckListInfo?.canDispatcher_HidePackageInASecureArea
                }
              />
              <Typography.Text>
                Can Dispatcher Hide Package In A Secure Area
              </Typography.Text>
            </Space>
            <Space>
              <Switch
                disabled
                defaultChecked={
                  deliveryCheckListInfo?.canDispatcher_ReturnPackageToNearestBranchIfNotAround
                }
              />
              <Typography.Text>
                Can Dispatcher Return Package To Nearest Branch If Not Around
              </Typography.Text>
            </Space>
          </Space>
          {deliveryCheckListInfo?.canDispatcher_ReturnPackageToNearestBranchIfNotAround && (
            <LabeledNode
              nodeIsText
              direction="vertical"
              label="Return Package To Nearest Branch"
              node={
                returnPackageNearestBranch?.BranchName || "Branch not found"
              }
            />
          )}
        </Space>
      )}
      <LabeledNode
        nodeIsText
        direction="vertical"
        label="Note To Driver"
        node={note}
      />
    </Space>
  );
};

export default OrderItemDeliveryCheckList;
