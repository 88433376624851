import { ArrowLeftOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import {
  Button,
  message,
  Space,
  Steps as StepsComponent,
  Typography,
} from "antd";
import React from "react";
import { useNavigate } from "react-router";
import OrderBlockAPI from "../../../api/endpoints/orderBlock";
import { IEndpointMessageResult } from "../../../api/types";
import { IOrderBlock, IOrderBlockInput } from "../../../definitions/orderBlock";
import { appLoggedInPaths } from "../../../global/paths";
import OrderBlockForm, { IOrderBlockFormInput } from "./OrderBlockForm";
import OrderBlockFormPreview from "./OrderBlockFormPreview";
import { orderBlockToFormInput } from "./utils";

const Steps = StepsComponent as React.FC<
  React.ComponentProps<typeof StepsComponent> & { children: React.ReactNode }
>;

enum OrderBlockFormSteps {
  Form,
  Preview,
}

const classes = {
  root: css({
    width: "100%",
  }),
  error: css({
    width: "70%",
    maxWidth: "720px",
    margin: "auto",
  }),
};

export interface IOrderBlockFormRootProps {
  existingBlock?: IOrderBlock;
  onCloseForm: () => void;
  onCompleteSubmit: () => void;
}

const OrderBlockFormRoot: React.FC<IOrderBlockFormRootProps> = (props) => {
  const { existingBlock, onCloseForm, onCompleteSubmit } = props;
  const [currentStep, setStep] = React.useState(OrderBlockFormSteps.Form);
  const [block, setBlock] = React.useState<IOrderBlockFormInput | undefined>(
    () => existingBlock && orderBlockToFormInput(existingBlock)
  );
  const [error, setError] = React.useState<string | null>();
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();

  // Internal functions and callbacks
  const onSubmitBlock = React.useCallback(async () => {
    setSubmitting(true);
    setError(null);

    if (!block) {
      return;
    }

    try {
      let response: IEndpointMessageResult | null = null;
      const blockInput: IOrderBlockInput = {
        currencyType: block.currencyType,
        description: block.description,
        deliveryCost: block.deliveryCost,
        bonusCost: block.bonusCost,
        priority: block.priority,
        tripTimeType: block.tripTimeType,
        estimatedTripDuration: block.estimatedTripDuration,
        type: block.type,
        publicTypeExpirationDate: block.publicTypeExpirationDate,
        pickUpDateTime: block.pickUpDateTime,
        orderIds: block.orders.map((item) => item.OrderId),
        deliveryType: block.deliveryType,
        deliveryAddressInfo: block.deliveryAddressInfo,
        pickUpAddressInfo: block.pickUpAddressInfo,
      };

      if (existingBlock) {
        response = await OrderBlockAPI.updateBlock({
          blockId: existingBlock.BlockId,
          data: blockInput,
        });
      } else {
        response = await OrderBlockAPI.createOrderBlock(blockInput);
      }

      message.success(
        response.Message ||
          response.message ||
          "Order block submitted successfully"
      );
      onCompleteSubmit();
      navigate(appLoggedInPaths.orderBlock);
    } catch (error: any) {
      const responseMessage = error?.message || "Error submitting order block";
      message.error(responseMessage, 7);
      setError(responseMessage);
    }

    setSubmitting(false);
  }, [block, existingBlock, onCompleteSubmit]);

  // Begin rendering
  let contentNode = null;
  const onNextForm = React.useCallback((input: IOrderBlockFormInput) => {
    setBlock(input);
    setStep(OrderBlockFormSteps.Preview);
  }, []);

  const onBackPreview = React.useCallback(() => {
    setStep(OrderBlockFormSteps.Form);
  }, []);

  if (currentStep === OrderBlockFormSteps.Form) {
    contentNode = (
      <OrderBlockForm
        loading={submitting}
        values={block}
        block={existingBlock}
        onSubmit={onNextForm}
      />
    );
  } else if (currentStep === OrderBlockFormSteps.Preview) {
    contentNode = (
      <OrderBlockFormPreview
        block={block!}
        onBack={onBackPreview}
        onSubmit={onSubmitBlock}
        submitting={submitting}
        error={error}
      />
    );
  }

  return (
    <Space direction="vertical" size={32} className={classes.root}>
      <Space size="large" align="center">
        <Button icon={<ArrowLeftOutlined />} onClick={onCloseForm}></Button>
        <Typography.Title level={5} style={{ marginBottom: "0" }}>
          Order Block Form
        </Typography.Title>
      </Space>
      <Steps current={currentStep}>
        <StepsComponent.Step title="Order Block" />
        <StepsComponent.Step title="Preview Order Block" />
      </Steps>
      {contentNode}
    </Space>
  );
};

export default OrderBlockFormRoot;
