import React from "react";
import PageError from "../../../components/utils/PageError";
import PagePagination from "../../../components/utils/PagePaination";
import { appClasses } from "../../../components/utils/theme";
import { IParcel } from "../../../definitions/orderBay";
import { IUsePaginatedDataResult } from "../../../hooks/usePaginatedData";
import OrdersPagedByParcelTable from "./OrdersPagedByParcelTable";

export interface IOrdersPagedByParcelPageProps
  extends IUsePaginatedDataResult<IParcel, any> {
  onCompleteUpdateOrder: () => void;
  onCompleteDeleteOrder: () => void;
  onCompleteDeleteParcel: () => void;
}

const OrdersPagedByParcelPage: React.FC<IOrdersPagedByParcelPageProps> = (
  props
) => {
  const {
    page,
    isInitialized,
    getPageItems,
    reloadEverything,
    getPageError,
    getPageLoading,
    onCompleteDeleteOrder,
    onCompleteUpdateOrder,
    onCompleteDeleteParcel,
  } = props;

  let contentNode: React.ReactNode = null;
  const pageError = getPageError(page);

  if (pageError) {
    contentNode = (
      <PageError
        messageText={pageError}
        actions={[{ text: "Reload", onClick: () => reloadEverything(false) }]}
      />
    );
  } else if (isInitialized) {
    const pageData = getPageItems(page);
    contentNode = (
      <OrdersPagedByParcelTable
        loading={getPageLoading(page)}
        data={pageData}
        onCompleteDeleteOrder={onCompleteDeleteOrder}
        onCompleteUpdateOrder={onCompleteUpdateOrder}
        onCompleteDeleteParcel={onCompleteDeleteParcel}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageContent}>{contentNode}</div>
      {!pageError && (
        <div className={appClasses.pageBottomControls}>
          <PagePagination {...props} />
        </div>
      )}
    </div>
  );
};

export default OrdersPagedByParcelPage;
