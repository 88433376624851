import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router";

export interface IUseSectionCurrentPageProps {
  hasCreateForm?: boolean;
  hasUpdateForm?: boolean;
  hasSelectedItemPage?: boolean;
  disableAutoRouteToBaseOnInvalidCreatePath?: boolean;
  disableAutoRouteToBaseOnInvalidUpdatePath?: boolean;
  disableAutoRouteToBaseOnInvalidSelectedPath?: boolean;
  baseURL: string;
  isComponentReady: boolean;
}

export interface IUseSectionCurrentPageResult {
  isCreateForm: boolean;
  selectedItemId?: string;
  updateItemId?: string;
  gotoCreateForm: () => void;
  gotoBase: () => void;
  gotoUpdateForm: (id: string) => void;
  gotoSelectedItem: (id: string) => void;
}

interface IPath01RouteMatch {
  path01: string;
}

const FORM_SUBPATH = "form";

// For navigation and for knowing page route state
export default function useSectionCurrentPage(
  props: IUseSectionCurrentPageProps
): IUseSectionCurrentPageResult {
  const {
    disableAutoRouteToBaseOnInvalidCreatePath,
    disableAutoRouteToBaseOnInvalidSelectedPath,
    disableAutoRouteToBaseOnInvalidUpdatePath,
    hasCreateForm,
    hasUpdateForm,
    hasSelectedItemPage,
    baseURL,
    isComponentReady: areItemsLoaded,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const path01RouteMatch = matchPath(`${baseURL}/:path01`, location.pathname);
  const path02RouteMatch = matchPath(
    `${baseURL}/form/:path01`,
    location.pathname
  );

  const updateItemId = path02RouteMatch?.params.path01;
  const isCreateForm = path01RouteMatch?.params.path01 === FORM_SUBPATH;
  const selectedItemId = !isCreateForm
    ? path01RouteMatch?.params.path01
    : undefined;

  console.log({
    location,
    baseURL,
    path01RouteMatch,
    path02RouteMatch,
    updateItemId,
    isCreateForm,
    selectedItemId,
  });

  React.useEffect(() => {
    if (
      !disableAutoRouteToBaseOnInvalidUpdatePath &&
      updateItemId &&
      (!hasUpdateForm || !areItemsLoaded)
    ) {
      navigate(baseURL);
    }
  }, [
    disableAutoRouteToBaseOnInvalidUpdatePath,
    updateItemId,
    areItemsLoaded,
    hasUpdateForm,
    history,
    baseURL,
  ]);

  React.useEffect(() => {
    if (
      !disableAutoRouteToBaseOnInvalidCreatePath &&
      isCreateForm &&
      !hasCreateForm
    ) {
      navigate(baseURL);
    }
  }, [
    disableAutoRouteToBaseOnInvalidCreatePath,
    isCreateForm,
    hasCreateForm,
    history,
    baseURL,
  ]);

  React.useEffect(() => {
    if (
      !disableAutoRouteToBaseOnInvalidSelectedPath &&
      selectedItemId &&
      (!hasSelectedItemPage || !areItemsLoaded)
    ) {
      navigate(baseURL);
      return;
    }
  }, [
    disableAutoRouteToBaseOnInvalidSelectedPath,
    selectedItemId,
    hasSelectedItemPage,
    history,
    baseURL,
    areItemsLoaded,
  ]);

  const gotoCreateForm = React.useCallback(() => {
    navigate(baseURL + "/form");
  }, [history, baseURL]);

  const gotoUpdateForm = React.useCallback(
    (id: string) => {
      navigate(baseURL + "/form/" + id);
    },
    [history, baseURL]
  );

  const gotoSelectedItem = React.useCallback(
    (id: string) => {
      navigate(baseURL + "/" + id);
    },
    [history, baseURL]
  );

  const gotoBase = React.useCallback(() => {
    navigate(baseURL);
  }, [history, baseURL]);

  return {
    isCreateForm,
    selectedItemId,
    updateItemId,
    gotoCreateForm,
    gotoUpdateForm,
    gotoBase,
    gotoSelectedItem,
  };
}
