import {
    takeEvery,
    put,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import * as types from "./actionType"
import { 
    getDefectsError, 
    getDefectsSuccess, 
    getDefectsSearchError, 
    getDefectsSearchSuccess,
    getDefectsByIdError, 
} from "./action"
import { getDefectsApi, getDefectsApiById, getDefectsSearchApi } from "../Api"



function* onLoadDefectsStartAsync({payload}) {
    try {
        const response = yield call(getDefectsApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(getDefectsSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getDefectsError(error.response.data.message))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}


function* onLoadDefectsByIdStartAsync({payload}) {
    try {
        const response = yield call(getDefectsApiById, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(getDefectsByIdSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getDefectsByIdError(error.response))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}


function* onLoadDefectsSearchStartAsync({payload}) {
    try {
        const response = yield call(getDefectsSearchApi, payload)
      
        if (response.status === 200) {
            yield delay(500)
            yield put(getDefectsSearchSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(getDefectsSearchError(error.response.data.message))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadDefects() {
    yield takeEvery(types.GET_DEFECTS_START, onLoadDefectsStartAsync)
}

function* onLoadDefectsById() {
    yield takeEvery(types.GET_DEFECTS_BY_ID_START, onLoadDefectsByIdStartAsync)
}

function* onLoadDefectsSearch() {
    yield takeEvery(types.GET_DEFECTS_SEARCH_START, onLoadDefectsSearchStartAsync)
}



export const defectsSaga = [
    fork(onLoadDefects),
    fork(onLoadDefectsSearch),
    fork(onLoadDefectsById)
]
