import * as types from "./actionType"



export const getDefectsStart = (payload) => ({
    type: types.GET_DEFECTS_START,
    payload
})

export const getDefectsSuccess = (defects) => ({
    type: types.GET_DEFECTS_SUCCESS,
    payload: defects,
})

export const getDefectsError = (error) => ({
    type: types.GET_DEFECTS_ERROR,
    payload: error
})

export const getDefectsByIdStart = (payload) => ({
    type: types.GET_DEFECTS_BY_ID_START,
    payload
})

export const getDefectsByIdSuccess = (defects) => ({
    type: types.GET_DEFECTS_BY_ID_SUCCESS,
    payload: defects,
})

export const getDefectsByIdError = (error) => ({
    type: types.GET_DEFECTS_BY_ID_ERROR,
    payload: error
})


export const getDefectsSearchStart = (payload) => ({
    type: types.GET_DEFECTS_SEARCH_START,
    payload
})

export const getDefectsSearchSuccess = (defects) => ({
    type: types.GET_DEFECTS_SEARCH_SUCCESS,
    payload: defects,
})

export const getDefectsSearchError = (error) => ({
    type: types.GET_DEFECTS_SEARCH_ERROR,
    payload: error
})

export const getDefectsExecutionStart = (payload) => ({
    type: types.GET_DEFECTS_EXECUTION_START,
    payload
})

export const getDefectsExecutionSuccess = (defects) => ({
    type: types.GET_DEFECTS_EXECUTION_SUCCESS,
    payload: defects,
})

export const getDefectsExecutionError = (error) => ({
    type: types.GET_DEFECTS_EXECUTION_ERROR,
    payload: error
})