import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  Form,
  Typography,
  Space,
  Alert,
  Modal as ModalComponent,
} from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { appFormMessages } from "../../global/messages";
import FormItemError from "../../components/utils/FormItemError";
import { IReduxState, IReduxStateResources } from "../../redux/types";
import { orderBlockDriversListActions } from "../../redux/ResourcesRedux/resourcesAction";

const Modal = ModalComponent as React.FC<
  React.ComponentProps<typeof ModalComponent> & { children: React.ReactNode }
>;

const validationSchema = yup.object().shape({
  driverId: yup.string().required(appFormMessages.requiredField),
});

export interface IOrderBlockSelectDriverFormValues {
  driverId: string;
}

const getInitialValues = () => {
  const initialValues: Partial<IOrderBlockSelectDriverFormValues> = {
    driverId: undefined,
  };

  return initialValues;
};

const formItemLabelCol = {
  span: 24,
};

const formItemWrapperCol = { span: 24 };

export interface IOrderBlockSelectDriverFormProps {
  error?: string | null;
  submitting?: boolean;
  onSubmit: (data: IOrderBlockSelectDriverFormValues) => void;
  onCancel: () => void;
}

const OrderBlockSelectDriverForm: React.FC<IOrderBlockSelectDriverFormProps> = (
  props
) => {
  const { error, submitting, onSubmit, onCancel } = props;

  const dispatch = useDispatch();
  const formik = useFormik({
    validationSchema,
    initialValues: getInitialValues() as any,
    onSubmit: onSubmit,
  });

  const { orderBlocksAvailableDriversList } = useSelector<
    IReduxState,
    IReduxStateResources
  >((state) => state.resources);

  useEffect(() => {
    dispatch(orderBlockDriversListActions.startAction());
  }, [dispatch]);

  const driverNode = (
    <Form.Item
      required
      label="Driver"
      help={
        formik.touched.driverId && (
          <FormItemError message={formik.errors.driverId} />
        )
      }
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
    >
      <Select
        placeholder="Select driver"
        value={formik.values.driverId}
        onChange={(value) => formik.setFieldValue("driverId", value)}
        onBlur={formik.handleBlur}
        disabled={submitting}
      >
        {orderBlocksAvailableDriversList?.map((driver) => {
          return (
            <Select.Option key={driver.UserId} value={driver.UserId}>
              <Space direction="vertical" size={0}>
                <Typography.Text>{driver.Name}</Typography.Text>
                <Space split={<Typography.Text>&#xB7;</Typography.Text>}>
                  <Typography.Text type="secondary">
                    {driver.Status.Name}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {driver.Availability.Name}
                  </Typography.Text>
                </Space>
              </Space>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <Modal
      visible
      title="Select Order Block Driver"
      onCancel={onCancel}
      onOk={formik.submitForm}
    >
      <form onSubmit={formik.handleSubmit}>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        {driverNode}
      </form>
    </Modal>
  );
};

export default OrderBlockSelectDriverForm;
