import { ArrowLeftOutlined, SmileOutlined } from "@ant-design/icons";
import { Result, Table, Tag } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { API_URL } from "../../globalVariable";
import { loadTicketStart } from "../../redux/TicketRedux/ticketAction";
import Loader from "../Loader";

function TicketSearch(props) {
  const navigate = useNavigate();
  const [searchTickets, setSearchTickets] = useState([]);
  const [ticketMessage, setTicketMessage] = useState("");
  const { search } = useParams();
  const dispatch = useDispatch();
  const { tickets, loading } = useSelector((state) => state.tickets);

  useEffect(() => {
    dispatch(loadTicketStart());
  }, []);

  const getTicketSearch = (setSearch, setTicket, searchValue) => {
    fetch(API_URL + `Ticket/Search?query=${searchValue}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    })
      .then((res) => {
        if (res.statusText === "Unauthorized") {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");

          return window.location.reload();
        }
        return res.json();
      })
      .then((data) => {
        if (data.Message) {
          setTicket(data.Message);
        } else {
          var s = [];
          data?.forEach((d, i) => {
            var id = i + 1;
            s.push({ id, ...d });
          });
          setSearch(s);
        }
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await getTicketSearch(setSearchTickets, setTicketMessage, search);
    };

    fetchData();
  }, []);

  const ticketRoute = (id) => {
    navigate(`/tickets/${id}`);
  };

  const columns = [
    {
      title: "Status",
      width: 150,
      ellipsis: true,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return (
          <NodeWithTip tip={record.Status.Description}>
            <Tag
              // style={{ color: record.Priority.Color }}
              color={record.Status.Color}
            >
              {record.Status.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Ticket Number",
      width: 220,
      render(text, record) {
        return (
          <Link to={`/tickets/${record.TicketId}`}>{record.TicketNumber}</Link>
        );
      },
    },
    {
      title: "Priority",
      width: 170,
      dataIndex: "Priority",
      key: "Priority",
    },
    {
      title: "Subject",
      width: 340,
      dataIndex: "Subject",
      key: "Subject",
    },
    {
      title: "Requester Name",
      width: 150,
      dataIndex: "RequesterName",
      key: "RequesterName",
    },
    {
      title: "Date Created",
      width: 200,
      dataIndex: "DateCreated",
      key: "DateCreated",
    },
    {
      title: "Last Updated",
      width: 200,
      dataIndex: "LastUpdated",
      key: "LastUpdated",
    },
  ];

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <div>
      <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> Back
      </div>
      <br />
      <div>
        {ticketMessage.length < 1 ? (
          <Table
            bordered
            size="small"
            dataSource={searchTickets}
            columns={columns}
            rowKey={(row) => row.BlockId}
          />
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Result icon={<SmileOutlined />} title={ticketMessage} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default TicketSearch;
