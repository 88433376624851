import * as types from "./movebotActionType"
import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import { getMovebotPolicyApi, getMovebotRolesApi } from "../Api"
import { loadMovebotPolicyError, loadMovebotPolicySuccess, loadMovebotRoleError, loadMovebotRoleSuccess } from "./movebotAction"






function* onLoadPolicyStartAsync({payload}) {
    try {
        const response = yield call(getMovebotPolicyApi, payload)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadMovebotPolicySuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadMovebotPolicyError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}


function* onLoadRolesStartAsync() {
    try {
        const response = yield call(getMovebotRolesApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadMovebotRoleSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadMovebotRoleError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}


function* onLoadRoles() {
    yield takeEvery(types.LOAD_MOVEBOT_ROLES_START, onLoadRolesStartAsync)
}


function* onLoadPolicy() {
    yield takeEvery(types.LOAD_MOVEBOT_POLICY_START, onLoadPolicyStartAsync)
}


export const movebotPolicySaga = [
    fork(onLoadPolicy),
    fork(onLoadRoles)
]