import { Col, Divider, Row, Space } from "antd";
import React from "react";
import LabeledNode from "../utils/LabeledNode";

export interface IAddressProps {
  street: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
}

const Address: React.FC<IAddressProps> = (props) => {
  const { street, country, state, city, zipCode } = props;

  return (
    <Row wrap gutter={[16, 16]}>
      <Col span={24}>
        <Space
          direction="vertical"
          split={<Divider style={{ margin: "8px 0px" }} />}
          size={2}
          style={{ width: "100%" }}
        >
          <LabeledNode
            nodeIsText
            labelFontSize={13}
            direction="horizontal"
            label="Street Address"
            node={street}
            textAlign="right"
            labelSpan={8}
            nodeSpan={16}
          />
          <LabeledNode
            nodeIsText
            labelFontSize={13}
            direction="horizontal"
            label="Country"
            node={country}
            textAlign="right"
            labelSpan={8}
            nodeSpan={16}
          />
          <LabeledNode
            nodeIsText
            labelFontSize={13}
            direction="horizontal"
            label="State"
            node={state}
            textAlign="right"
            labelSpan={8}
            nodeSpan={16}
          />
          <LabeledNode
            nodeIsText
            labelFontSize={13}
            direction="horizontal"
            label="City"
            node={city}
            textAlign="right"
            labelSpan={8}
            nodeSpan={16}
          />
          <LabeledNode
            nodeIsText
            labelFontSize={13}
            direction="horizontal"
            label="Zip Code"
            node={zipCode}
            textAlign="right"
            labelSpan={8}
            nodeSpan={16}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default Address;
