import React from "react";
import { FormikErrors } from "formik";
import { Form, Input } from "antd";
import { ISupportContactInput } from "../../../definitions/orderBlock";
import FormItemError from "../../../components/utils/FormItemError";
import PhoneInput from "../../../components/form/PhoneInput";

export interface ISupportContactInfoInputProps {
  disabled?: boolean;
  errors?: FormikErrors<ISupportContactInput>;
  basePath: string;
  values: Partial<ISupportContactInput>;
  setFieldValue: (field: string, value: any) => void;
  onError: (error: string) => void;
}

const SupportContactInfoInput: React.FC<ISupportContactInfoInputProps> = (
  props
) => {
  const { disabled, values, errors, basePath, setFieldValue, onError } = props;
  const contactNameField = `${basePath}.contactName`;
  const phoneNumberCodeField = `${basePath}.phoneNumber.code`;
  const phoneNumberNumberField = `${basePath}.phoneNumber.number`;

  const onChangePhoneNumber = React.useCallback(
    (phoneNumber) => setFieldValue(phoneNumberNumberField, phoneNumber),
    [phoneNumberNumberField, setFieldValue]
  );
  const onChangeCountryCode = React.useCallback(
    (code) => setFieldValue(phoneNumberCodeField, code?.iso2),
    [phoneNumberCodeField, setFieldValue]
  );

  const supportContactNameNode = (
    <Form.Item
      // label="Support Contact Name"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        errors?.contactName && <FormItemError message={errors?.contactName} />
      }
      style={{ marginBottom: "8px" }}
    >
      <Input
        placeholder="Support contact name"
        value={values?.contactName}
        onChange={(evt) => setFieldValue(contactNameField, evt.target.value)}
        disabled={disabled}
      />
    </Form.Item>
  );

  const supportContactPhoneNode = (
    <Form.Item
      // label="Support Contact Phone Number"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        errors?.phoneNumber?.number && (
          <FormItemError message={errors?.phoneNumber?.number} />
        )
      }
    >
      <PhoneInput
        value={{
          code: values.phoneNumber?.code,
          number: values.phoneNumber?.number,
        }}
        onChangePhoneNumber={onChangePhoneNumber}
        onChangeCountryCode={onChangeCountryCode}
        onError={onError}
        placeholder="Enter support contact phone number"
        disabled={disabled}
      />
    </Form.Item>
  );

  return (
    <div>
      {supportContactNameNode}
      {supportContactPhoneNode}
    </div>
  );
};

export default SupportContactInfoInput;
