import * as types from "./settingActionType"


const initialState = {
    payments: {},
    teams: [],
    pod: null,
    branch: [],
    loading: false,
    error: null
}


export const SettingReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_SETTINGS_PAYMENT_START:
        case types.LOAD_SETTINGS_TEAM_START:
        case types.LOAD_BRANCH_START:
        case types.LOAD_POD_START:
            return {
                ...state,
                loading: true,
            }
        case types.LOAD_POD_SUCCESS:
            return {
                ...state,
                loading: false,
                pod: action.payload
            }
        case types.LOAD_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                branch: action.payload
            }
        case types.LOAD_SETTINGS_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload
            }
        case types.LOAD_SETTINGS_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                teams: action.payload
            }
        case types.LOAD_SETTINGS_PAYMENT_ERROR:
        case types.LOAD_BRANCH_ERROR:
        case types.LOAD_POD_ERROR:
        case types.LOAD_SETTINGS_TEAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}