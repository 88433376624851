import React from "react";
import { IParcel } from "../../../definitions/orderBay";
import LabeledNode from "../../../components/utils/LabeledNode";
import { Col, Row, Space, Tag, Typography } from "antd";

export interface IParcelDetailsProps {
  parcel: IParcel;
}

const ParcelDetails: React.FC<IParcelDetailsProps> = (props) => {
  const { parcel } = props;

  return (
    <div>
      <Row wrap gutter={[32, 32]}>
        <Col span={24}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Parcel Item Info"
            node={parcel.ParcelItemInfo}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Package Counts"
            node={parcel.PackageCounts}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Total Estimated Weight"
            node={parcel.TotalEstimatedWeight}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Parcel Status"
            node={
              <Space direction="vertical" size={2}>
                <Tag style={{ color: parcel.ParcelStatus.Color }}>
                  {parcel.ParcelStatus.Name}
                </Tag>
                <Typography.Text type="secondary">
                  {parcel.ParcelStatus.Description}
                </Typography.Text>
              </Space>
            }
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Parcel Acknowledged"
            node={parcel.ParcelAlreadyAcknowledged ? "Yes" : "No"}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Updated By"
            node={parcel.UpdatedBy}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ParcelDetails;
