import { API_URL } from "../globalVariable";
import Cookies from "js-cookie";
import axios from "axios";

export const getOrderBlockApi = async () =>
  await axios.get(API_URL + `OrderBlock/Page?page_number=1&page_size=100`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getOrderItemApi = async () =>
  await axios.get(
    API_URL + `OrderBlock/OrderItemPage?page_number=1&page_size=100`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getAuditApi = async (payload) => {
 return await axios.get(
    API_URL +
      `Audit/Page?page_number=${payload?.page}&page_size=${payload?.pageSize}&type=${payload?.type}&date=${payload?.date}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );
};

export const getAuditRefApi = async () =>
  await axios.get(API_URL + `Resources/GetAuditReferenceTypes`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getEmployeesApi = async (payload) =>
  await axios.get(
    API_URL +
      `Employee/Page?page_number=${payload?.page === undefined ? '' : payload?.page}&page_size=${payload?.pageSize === undefined ? '' : payload?.pageSize}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getFilterEmployeesApi = async (payload) =>
  await axios.get(API_URL + `Employee/Page?page_number=${payload?.page === undefined ? '' : payload?.page}&page_size=${payload?.pageSize === undefined ? '' : payload?.pageSize}&roleType=${payload?.filter === undefined ? '' : payload?.filter}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getPolicyApi = async (payload) =>
  await axios.get(API_URL +`GroupPolicy/Page?page_number=${payload?.page === undefined ? '' : payload?.page}&page_size=${payload?.pageSize === undefined ? '' : payload?.pageSize}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getFilterPolicyApi = async (payload) =>
  await axios.get(
    API_URL +
      `GroupPolicy/Page?page_number=${payload?.page}&page_size=${payload?.pageSize}&roleType=${payload?.roleType === undefined ? '' : payload?.roleType}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getMovebotRolesApi = async () =>
  await axios.get(API_URL + `GroupPolicy/GetRoles`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getMovebotPolicyApi = async (role) =>
  await axios.get(API_URL + `GroupPolicy/GetRolePermissions?roleType=${role === undefined ? '' : role}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

  
export const getPodApi = async (payload) =>
  await axios.get(API_URL + `Settings/PODRule?status=${payload}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getSupportTeamApi = async () =>
  await axios.get(API_URL + `Settings/GetSupportTeamContact`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getSettingPaymentApi = async () =>
  await axios.get(API_URL + `Settings/GetCustomerPaymentOption`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getUserInfoApi = async () =>
  await axios.get(API_URL + `Profile/Get`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getCompanyInfoApi = async () =>
  await axios.get(API_URL + `Profile/GetCompany`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTicketApi = async (payload) =>
  await axios.get(
    API_URL +
      `Ticket/Page?page_number=${payload?.page}&page_size=${payload?.pageSize === undefined ? '' : payload?.pageSize}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );
export const getTicketByPriorityApi = async (payload) =>
  await axios.get(
    API_URL +
      `Ticket/Page?page_number=${payload?.page}&page_size=${payload?.pageSize === undefined ? '' : payload?.pageSize}&status=${payload.status === undefined ? '' : payload.status}&priority=${payload.priority === undefined ? '' : payload.priority}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );
export const getTicketByStatusApi = async (payload) =>
  await axios.get(
    API_URL +
      `Ticket/Page?page_number=${payload?.page}&page_size=${payload?.pageSize === undefined ? '' : payload?.pageSize}&status=${payload.status}&priority=${payload.priority}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getGroupOrderbayApi = async () =>
  await axios.get(
    API_URL + `OrderBay/PagedByParcel?page_number=1&page_size=100`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getUnGroupOrderbayApi = async () =>
  await axios.get(
    API_URL + `OrderBay/PagedByOrder?page_number=1&page_size=100`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getFilterByParcelApi = async (payload) =>
  await axios.get(
    API_URL +
      `OrderBay/PagedByOrder?page_number=1&page_size=100&parcelOrderStatus=${payload.parcelOrderStatus}&packageStatus=${payload.packageStatus}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getFilterByPackageApi = async (payload) =>
  await axios.get(
    API_URL +
      `OrderBay/PagedByOrder?page_number=1&page_size=100&parcelOrderStatus=${payload.parcelOrderStatus}&packageStatus=${payload.packageStatus}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getQualifyAdminApi = async () =>
  await axios.get(API_URL + `Profile/GetQualifiedAdminList`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });
export const getBranchApi = async () =>
  await axios.get(API_URL + `Settings/GetBranchLocations`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });
export const getStatusTypeApi = async () =>
  await axios.get(API_URL + `Resources/GetAccountStatusTypes`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getPackageSizesApi = async () =>
  await axios.get(API_URL + `Resources/GetPackageSizes`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getCurrenciesApi = async () =>
  await axios.get(API_URL + `Resources/GetCurrencyType`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getPaidOptionsApi = async () =>
  await axios.get(API_URL + `Resources/GetPaidOptions`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getBlockPriorityApi = async () =>
  await axios.get(API_URL + `Resources/GetBlockPriorityTypes`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getBlockTypeApi = async () =>
  await axios.get(API_URL + `Resources/GetBlockType`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTicketPriorityApi = async () =>
  await axios.get(API_URL + `Resources/GetTicketPriority`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getPackageStatusApi = async () =>
  await axios.get(API_URL + `Resources/GetPackageStatusType`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getWeightUnitApi = async () =>
  await axios.get(API_URL + `Resources/GetWeightUnit`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getOrderCategoriesApi = async () =>
  await axios.get(API_URL + `Resources/GetOrderCategories`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTicketStatusApi = async () =>
  await axios.get(API_URL + `Resources/GetTicketStatus`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTicketRelatedServicesApi = async () =>
  await axios.get(API_URL + `Resources/GetTicketRelatedServices`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getCountryCodeApi = async () =>
  await axios.get(API_URL + `Resources/GetCountryCode`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getLongCountryCodeApi = async () =>
  await axios.get(API_URL + `Resources/GetLongCountryCode`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getBlockTimeTypeApi = async () =>
  await axios.get(API_URL + `Resources/GetBlockTripTimeType`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getCountryCodeObjApi = async () =>
  await axios.get(API_URL + `Resources/GetCountryCodeObj`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getCountryStateCitiesApi = async () =>
  await axios.get(API_URL + `Resources/GetCountryStateCities`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getCountryStateApi = async (payload) =>
  await axios.get(
    API_URL + `Resources/GetCountryStateCities?country=${payload}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const createOrderBayApi = async (payload) =>
  await axios.post(API_URL + `OrderBay/SubmitOrder`, payload, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getUserApi = async () =>
  await axios.get(API_URL + `Authentication/ProfileInfo`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getUserCompanyApi = async () =>
  await axios.get(API_URL + `Profile/GetCompany`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const filterBlockApi = async (payload) =>
  await axios.get(
    API_URL +
      `OrderBlock/Page?page_number=1&page_size=100&priority=${payload.priority}&type=${payload.type}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getOrderBlockSearchApi = async (payload) =>
  await axios.get(API_URL + `OrderBlock/SearchBlockOrders?query=${payload}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getOrderItemsSearchApi = async (payload) =>
  await axios.get(
    API_URL + `OrderBlock/FilterSearchOrderItems?query=${payload}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getOrderBlockDriverApi = async () =>
  await axios.get(API_URL + `OrderBlock/GetDrivers`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getOrderBlockFilterApi = async () =>
  await axios.get(API_URL + `OrderBlock​/FilterSearchOrderItems`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getOrderBlockFilterByCityApi = async () =>
  await axios.get(API_URL + `OrderBlock/GetFilterCityList`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getOrderBlockFilterByCountryApi = async () =>
  await axios.get(API_URL + `OrderBlock/GetFilterCountryList`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getOrderBlockFilterByStateApi = async () =>
  await axios.get(API_URL + `OrderBlock/GetFilterStateList`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const filterMultipleOrderApi = async (payload) =>
  await axios.post(API_URL + `OrderBlock/FilterOrderItems`, payload, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getDefectsApi = async (payload) =>
  await axios.get(
    API_URL +
      `DefectOrders/Page?page_number=${payload?.page}&page_size=${payload?.pageSize}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

  export const getDefectsApiById = async (payload) =>
  await axios.get(
    API_URL +
      `DefectOrders/Get?defectId=${payload}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getDefectsSearchApi = async (payload) =>
  await axios.get(API_URL + `DefectOrders/Search?query=${payload}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getDefectTypeApi = async () =>
  await axios.get(API_URL + `Resources/GetDefectActionType`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });


export const getTrackingTypeApi = async () =>
  await axios.get(API_URL + `Resources/GetTrackingType`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTrackingFrequencyApi = async () =>
  await axios.get(API_URL + `Resources/GetDateFreqType`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTrackingSearchApi = async (payload) =>
  await axios.get(API_URL + `Tracking/Search?query=${payload}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTrackingDriverApi = async () =>
  await axios.get(API_URL + `Tracking/GetDriverFilters`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getReviewApi = async (payload) => {
  if (payload === undefined) {
    return await axios.get(
      API_URL +
        `Reviews/Page?page_number=${payload?.page}&page_size=${payload?.pageSize}`,
      {
        headers: {
          authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );
  } else if (payload?.driverId === undefined) {
    return await axios.get(
      API_URL +
        `Reviews/Page?page_number=${payload?.page}&page_size=${payload?.pageSize}`,
      {
        headers: {
          authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );
  } else {
    return await axios.get(
      API_URL +
        `Reviews/Page?page_number=${payload?.page}&page_size=${payload?.pageSize}&driverId=${payload?.driverId}`,
      {
        headers: {
          authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );
  }
};


export const getReviewSearchApi = async (payload) =>
  await axios.get(API_URL + `Reviews/Search?query=${payload}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getReviewDriverApi = async () =>
  await axios.get(API_URL + `Reviews/GetDriversFilters`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getStatisticApi = async () =>
  await axios.get(API_URL + `Dashboard/GetStatistics`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTopBlocksApi = async () =>
  await axios.get(API_URL + `Dashboard/GetTop15BlockOrders`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });

export const getTopOrdersApi = async () =>
  await axios.get(API_URL + `Dashboard/GetTop15OngingOrders`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });


export const getPaymentsApi = async (payload) =>
  await axios.get(
    API_URL +
      `Payment/Page?page_number=${payload?.page}&page_size=${payload?.pageSize}&driverId=${payload.driverId === undefined ? '' : payload.driverId}&type=${payload.type === undefined ? '' : payload.type}`,
    {
      headers: {
        authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    }
  );

export const getDriverPaymentsApi = async () =>
  await axios.get(API_URL + `Payment/GetDriverFilter`, {
    headers: {
      authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });
