import { useRequest } from "ahooks";
import React from "react";
import OrderBlockAPI from "../../api/endpoints/orderBlock";
import PageError from "../../components/utils/PageError";
import PageLoading from "../../components/utils/PageLoading";
import OrderBlock, { IOrderBlockProps } from "./OrderBlock";

export interface IOrderBlockContainerProps
  extends Omit<IOrderBlockProps, "block"> {
  blockId: string;
}

const OrderBlockContainer: React.FC<IOrderBlockContainerProps> = (props) => {
  const { blockId } = props;
  const getBlock = React.useCallback(async () => {
    return await OrderBlockAPI.getBlock({ blockId });
  }, [blockId]);

  const { loading, error, data, run } = useRequest(getBlock);

  if (error) {
    return (
      <PageError
        messageText={error.message}
        actions={[{ text: "Reload Order Block", onClick: run }]}
      />
    );
  } else if (loading || !data) {
    return <PageLoading messageText="Loading order block..." />;
  }

  return <OrderBlock {...props} block={data} />;
};

export default OrderBlockContainer;
