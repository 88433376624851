import { css } from "@emotion/css";
import { Select, Space, Typography } from "antd";
import React from "react";
import { ILowercasedEnum } from "../../global/types";

export interface ISelecteOptionEnumProps {
  item: ILowercasedEnum;
}

const classes = {
  text: css({
    overflow: "auto",
    flex: 1,
    whiteSpace: "normal",
  }),
};

export function renderSelectOptionEnum(item: ILowercasedEnum) {
  return (
    <Select.Option key={item.id} value={item.id}>
      <Space wrap direction="vertical" size={2}>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text type="secondary" className={classes.text}>
          {item.description}
        </Typography.Text>
      </Space>
    </Select.Option>
  );
}

const SelectOptionEnum: React.FC<ISelecteOptionEnumProps> = (props) => {
  const { item } = props;
  return renderSelectOptionEnum(item);
};

export default SelectOptionEnum;
