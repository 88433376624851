import React from "react";
import PageError from "../../../components/utils/PageError";
import PagePagination from "../../../components/utils/PagePaination";
import { appClasses } from "../../../components/utils/theme";
import { IOrder } from "../../../definitions/orderBay";
import { IUsePaginatedDataResult } from "../../../hooks/usePaginatedData";
import OrdersPagedByOrderTable from "./OrdersPagedByOrderTable";

export interface IOrdersPagedByOrderPageProps
  extends IUsePaginatedDataResult<IOrder, any> {
  onCompleteUpdate: () => void;
  onCompleteDelete: () => void;
  selectedRowKeys?: string[];
  omitActions?: boolean;
  hideSelectAll?: boolean;
  withCheckbox?: boolean;
  onSelectRows?: (selectedRows: IOrder[], selectedAll: boolean) => void;
}

const OrdersPagedByOrderPage: React.FC<IOrdersPagedByOrderPageProps> = (
  props
) => {
  const {
    page,
    isInitialized,
    withCheckbox,
    omitActions,
    hideSelectAll,
    selectedRowKeys,
    onSelectRows,
    getPageItems,
    reloadEverything,
    getPageError,
    getPageLoading,
    onCompleteDelete,
    onCompleteUpdate,
  } = props;

  let contentNode: React.ReactNode = null;
  const pageError = getPageError(page);

  if (pageError) {
    contentNode = (
      <PageError
        messageText={pageError}
        actions={[{ text: "Reload", onClick: () => reloadEverything() }]}
      />
    );
  } else if (isInitialized) {
    const pageData = getPageItems(page);
    contentNode = (
      <OrdersPagedByOrderTable
        withCheckbox={withCheckbox}
        omitActions={omitActions}
        hideSelectAll={hideSelectAll}
        loading={getPageLoading(page)}
        data={pageData}
        selectedRowKeys={selectedRowKeys}
        onSelectRows={onSelectRows}
        onCompleteDelete={onCompleteDelete}
        onCompleteUpdate={onCompleteUpdate}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageContent}>{contentNode}</div>
      {!pageError && (
        <div className={appClasses.pageBottomControls}>
          <PagePagination {...props} />
        </div>
      )}
    </div>
  );
};

export default OrdersPagedByOrderPage;
