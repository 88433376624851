
export const LOAD_SETTINGS_PAYMENT_START = "LOAD_SETTINGS_PAYMENT_START"
export const LOAD_SETTINGS_PAYMENT_SUCCESS = "LOAD_SETTINGS_PAYMENT_SUCCESS"
export const LOAD_SETTINGS_PAYMENT_ERROR = "LOAD_SETTINGS_PAYMENT_ERROR"


export const LOAD_SETTINGS_TEAM_START = "LOAD_SETTINGS_TEAM_START"
export const LOAD_SETTINGS_TEAM_SUCCESS = "LOAD_SETTINGS_TEAM_SUCCESS"
export const LOAD_SETTINGS_TEAM_ERROR = "LOAD_SETTINGS_TEAM_ERROR"

export const LOAD_BRANCH_START = "LOAD_BRANCH_START"
export const LOAD_BRANCH_SUCCESS = "LOAD_BRANCH_SUCCESS"
export const LOAD_BRANCH_ERROR = "LOAD_BRANCH_ERROR"

export const LOAD_POD_START = "LOAD_POD_START"
export const LOAD_POD_SUCCESS = "LOAD_POD_SUCCESS"
export const LOAD_POD_ERROR = "LOAD_POD_ERROR"