import { Col, Divider, Row, Space, Tag } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { noop } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Address from "../../../components/form/Address";
import LabeledNode from "../../../components/utils/LabeledNode";
import { loadCurrenciesStart } from "../../../redux/ResourcesRedux/resourcesAction";
import { loadBranchStart } from "../../../redux/SettingsRedux/settingAction";
import { IReduxState, IReduxStateResources } from "../../../redux/types";
import OrdersPagedByOrderTable from "../../OrderBayPage/order/OrdersPagedByOrderTable";
import { IOrderBlockFormInput } from "./OrderBlockForm";
import SelectedBranchWithLoadBranches from "../../../components/branch/SelectedBranchWithLoadBranches";
import SupportContactList from "../../../components/branch/SupportContactList";

export interface IOrderBlockInputPreviewProps {
  input: IOrderBlockFormInput;
}

const OrderBlockInputPreview: React.FC<IOrderBlockInputPreviewProps> = (
  props
) => {
  const { input } = props;
  const dispatch = useDispatch();
  const { currencies } = useSelector<IReduxState, IReduxStateResources>(
    (state) => state.resources
  );

  useEffect(() => {
    dispatch(loadCurrenciesStart());
    dispatch(loadBranchStart());
  }, [dispatch]);

  const selectedCurrency = currencies?.find(
    (item) => item.id === input.currencyType
  );

  const withCurrency = (text: string | number) =>
    selectedCurrency ? `${text} ${selectedCurrency.description}` : text;

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Divider orientation="left">Block Details</Divider>
      <Row wrap gutter={[16, 32]} style={{ maxWidth: "900px", margin: "auto" }}>
        <Col span={24}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Description"
            node={input.description}
          />
        </Col>
        <Col span={24}>
          <LabeledNode
            direction="vertical"
            label=""
            node={
              <Space
                direction="vertical"
                split={<Divider style={{ margin: "8px 0px" }} />}
                size={2}
                style={{ width: "100%" }}
              >
                <LabeledNode
                  nodeIsText
                  direction="horizontal"
                  label="Delivery Cost"
                  node={withCurrency(input.deliveryCost)}
                  textAlign="right"
                />
                <LabeledNode
                  nodeIsText
                  direction="horizontal"
                  label="Bonus Cost"
                  node={withCurrency(input.bonusCost)}
                  textAlign="right"
                />
              </Space>
            }
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Priority"
            node={<Tag>{input.priority}</Tag>}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Estimated Trip Duration"
            node={input.estimatedTripDuration + " " + input.tripTimeType}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Type"
            node={<Tag>{input.type}</Tag>}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Public Type Expiration Date"
            node={
              input.publicTypeExpirationDate
                ? moment(input.publicTypeExpirationDate).toISOString()
                : "N/A"
            }
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Pickup Date and Time"
            node={moment(input.pickUpDateTime).toISOString()}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Delivery Type"
            node={<Tag>{input.deliveryType}</Tag>}
          />
        </Col>
      </Row>

      <Divider orientation="left">Pickup Address</Divider>
      <Row wrap gutter={[16, 32]} style={{ maxWidth: "900px", margin: "auto" }}>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Branch Pickup Address"
            labelFontSize={16}
            node={
              input.pickUpAddressInfo.branchAddressId ? (
                <SelectedBranchWithLoadBranches
                  branchId={input.pickUpAddressInfo.branchAddressId}
                />
              ) : (
                "N/A"
              )
            }
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Custom Pickup Address"
            labelFontSize={16}
            node={
              input.pickUpAddressInfo.customAddress ? (
                <Address
                  city={input.pickUpAddressInfo.customAddress.city}
                  country={input.pickUpAddressInfo.customAddress.country}
                  state={input.pickUpAddressInfo.customAddress.state}
                  street={input.pickUpAddressInfo.customAddress.street}
                  zipCode={input.pickUpAddressInfo.customAddress.zipCode}
                />
              ) : (
                "N/A"
              )
            }
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Pickup Support Contact"
            labelFontSize={16}
            node={
              input.pickUpAddressInfo.supportContactInfo ? (
                <SupportContactList
                  contacts={input.pickUpAddressInfo.supportContactInfo.map(
                    (item) => ({
                      contactName: item.contactName,
                      phoneNumber: item.phoneNumber.number,
                    })
                  )}
                />
              ) : (
                "N/A"
              )
            }
          />
        </Col>
      </Row>
      <Divider orientation="left">Delivery Address</Divider>
      <Row wrap gutter={[16, 32]} style={{ maxWidth: "900px", margin: "auto" }}>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Branch Delivery Address"
            labelFontSize={16}
            node={
              input.deliveryAddressInfo.branchAddressId ? (
                <SelectedBranchWithLoadBranches
                  branchId={input.deliveryAddressInfo.branchAddressId}
                />
              ) : (
                "N/A"
              )
            }
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Custom Delivery Address"
            labelFontSize={16}
            node={
              input.deliveryAddressInfo.customAddress ? (
                <Address
                  city={input.deliveryAddressInfo.customAddress.city}
                  country={input.deliveryAddressInfo.customAddress.country}
                  state={input.deliveryAddressInfo.customAddress.state}
                  street={input.deliveryAddressInfo.customAddress.state}
                  zipCode={input.deliveryAddressInfo.customAddress.zipCode}
                />
              ) : (
                "N/A"
              )
            }
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            direction="vertical"
            label="Delivery Support Contact"
            labelFontSize={16}
            node={
              input.deliveryAddressInfo.supportContactInfo ? (
                <SupportContactList
                  contacts={input.deliveryAddressInfo.supportContactInfo.map(
                    (item) => ({
                      contactName: item.contactName,
                      phoneNumber: item.phoneNumber.number,
                    })
                  )}
                />
              ) : (
                "N/A"
              )
            }
          />
        </Col>
      </Row>

      <Divider orientation="left">Assigned Orders</Divider>
      <div
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <OrdersPagedByOrderTable
          omitActions
          data={input.orders}
          onCompleteDelete={noop}
          onCompleteUpdate={noop}
        />
      </div>
    </Space>
  );
};

export default OrderBlockInputPreview;
