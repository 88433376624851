import { ArrowLeftOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Alert, Button, message, Space, Steps, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import OrderBayAPI from "../../../api/endpoints/orderBay";
import { IEndpointMessageResult } from "../../../api/types";
import {
  IAcknowledgeParcelInput,
  IOrderInput,
  IParcel,
  IParcelInput,
  IParcelSenderInfoInput,
} from "../../../definitions/orderBay";
import { appLoggedInPaths } from "../../../global/paths";
import { IPersonInput } from "../../../global/types";
import { loadUnGroupOrderBayStart } from "../../../redux/OrderbayRedux/orderbayAction";
import OrderBayAcknowledgeForm from "./OrderBayAcknowledgeForm";
import OrderBayFormPreview from "./OrderBayFormPreview";
import OrderBaySenderInfoForm from "./OrderBaySenderInfoForm";
import { IRecipientWithOrdersEntry } from "./OrderItemsByRecipient";
import OrderItemsByRecipientList from "./OrderItemsByRecipientList";

const steps = {
  sender: 1,
  orderItems: 2,
  preview: 3,
};

const classes = {
  root: css({
    width: "100%",
  }),
  error: css({
    maxWidth: "720px",
    margin: "auto",
  }),
};

export interface IOrderBayFormProps {
  title?: string;
  icon?: React.ReactNode;
  orgId?: string;
  onCloseForm: () => void;
  submitFn?: (order: IParcelInput) => Promise<IEndpointMessageResult>;
}

function recipientWithOrdersToOrdersInput(
  items: Array<IRecipientWithOrdersEntry>
) {
  const orders: IOrderInput[] = [];
  items.forEach((item) => {
    item.orders.forEach((inputOrder) => {
      const order: IOrderInput = {
        ...inputOrder,
        recipientInfo: item.recipient,
      };
      orders.push(order);
    });
  });

  return orders;
}

const OrderBayForm: React.FC<IOrderBayFormProps> = (props) => {
  const { onCloseForm, submitFn, title, icon, orgId } = props;
  const [currentStep, setStep] = React.useState(steps.sender);
  const [senderInfo, setSenderDetails] = React.useState<
    IParcelSenderInfoInput | undefined
  >();
  const [entries, setEntries] = React.useState<
    Array<IRecipientWithOrdersEntry>
  >([]);
  const [error, setError] = React.useState<string | null>();
  const [parcel, setParcel] = React.useState<IParcel>();
  const [submitting, setSubmitting] = React.useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Internal functions and callbacks
  const clearForm = () => {
    setStep(steps.sender);
    setSenderDetails(undefined);
    setEntries([]);
    setParcel(undefined);
    setError(null);
    setSubmitting(false);
    setShowAcknowledgement(false);
  };

  const onSubmitParcel = async () => {
    setSubmitting(true);
    setError(null);
    if (!senderInfo) {
      setError("Sender info missing");
      return;
    }

    if (!entries || entries.length === 0) {
      setError("Orders and recipient missing");
      return;
    }

    const apiSenderInfo: IPersonInput & { isParcelLikelyToBePOD?: boolean } = {
      ...senderInfo,
    };
    const isParcelLikelyToBePOD = senderInfo.isParcelLikelyToBePOD;
    const orderItems = recipientWithOrdersToOrdersInput(entries);
    delete apiSenderInfo.isParcelLikelyToBePOD;
    try {
      const input: IParcelInput = {
        orderItems,
        isParcelLikelyToBePOD,
        senderInfo: apiSenderInfo,
      };

      if (submitFn) {
        const response = await submitFn(input);
        message.success(
          response.message ||
            response.Message ||
            "Parcel submitted successfully"
        );
        clearForm();
      } else {
        const response = await OrderBayAPI.submitOrders({
          orderItems,
          isParcelLikelyToBePOD,
          senderInfo: apiSenderInfo,
        });

        setParcel(response);
        setShowAcknowledgement(true);
        dispatch(loadUnGroupOrderBayStart());
        message.success("Parcel submitted successfully");
      }
    } catch (error: any) {
      const responseMessage = error?.message || "Error submitting parcel";
      message.error(responseMessage);
      setError(responseMessage);
    }

    setSubmitting(false);
  };

  const onAcknowledgeOrder = async (data: IAcknowledgeParcelInput) => {
    setSubmitting(true);
    setError(null);
    try {
      const response = await OrderBayAPI.acknowledgeParcel({
        parcelId: data.parcelId,
        estimatedDeliveryDate: data.estimatedDeliveryDate,
        isPaymentOptionPOD: data.isPaymentOptionPOD,
        paidInfo: data.paidInfo,
        currencyType: data.currencyType,
        cost: data.cost,
        taxPerc: data.taxPerc,
        insuranceCost: data.insuranceCost,
      });

      dispatch(loadUnGroupOrderBayStart());
      setShowAcknowledgement(false);
      message.success(
        response.message ||
          response.Message ||
          "Parcel acknowledged successfully"
      );
      navigate(appLoggedInPaths.orderBay);
    } catch (error: any) {
      const responseMessage = error?.message || "Error acknowledging parcel";
      message.error(responseMessage);
      setError(responseMessage);
    }

    setSubmitting(false);
  };

  // Begin rendering
  let contentNode = null;
  if (showAcknowledgement) {
    contentNode = (
      <OrderBayAcknowledgeForm
        submitting={submitting}
        error={error}
        isPayOnDelivery={senderInfo!.isParcelLikelyToBePOD}
        parcelId={parcel!.ParcelId}
        onSubmit={onAcknowledgeOrder}
        onCancel={onCloseForm}
      />
    );
  } else {
    if (currentStep === steps.sender) {
      contentNode = (
        <OrderBaySenderInfoForm
          senderInfo={senderInfo}
          onNext={(data) => {
            setSenderDetails(data);
            setStep(steps.orderItems);
          }}
        />
      );
    } else if (currentStep === steps.orderItems) {
      contentNode = (
        <OrderItemsByRecipientList
          entries={entries}
          onNext={(data) => {
            setEntries(data);
            setStep(steps.preview);
          }}
          onPrev={(data) => {
            if (data) {
              setEntries(data);
            }

            setStep(steps.sender);
          }}
          orgId={orgId}
        />
      );
    } else if (currentStep === steps.preview) {
      contentNode = (
        <OrderBayFormPreview
          submitting={submitting}
          senderInfo={senderInfo!}
          entries={entries}
          onSubmit={onSubmitParcel}
          onBack={() => {
            setStep(steps.orderItems);
          }}
        />
      );
    }
  }

  return (
    <Space direction="vertical" size={32} className={classes.root}>
      <Space size="large" align="center">
        {icon || (
          <Button icon={<ArrowLeftOutlined />} onClick={onCloseForm}></Button>
        )}
        <Typography.Title level={5} style={{ marginBottom: "0" }}>
          {title || "Order Bay Form"}
        </Typography.Title>
      </Space>
      <Steps current={currentStep}>
        <Steps.Step title="Sender Details" />
        <Steps.Step title="Order Items" />
        <Steps.Step title="Preview Orders" />
      </Steps>
      {error && !showAcknowledgement && (
        <div className={classes.error}>
          <Alert message={error} type="error" />
        </div>
      )}
      {contentNode}
    </Space>
  );
};

export default OrderBayForm;
