import React from "react";
import OrderBayAPI, {
  ISearchOrdersEndpointInput,
} from "../../api/endpoints/orderBay";
import { IParcel, ParcelStatus } from "../../definitions/orderBay";
import { appOrderBayPaths } from "../../global/paths";
import { GeneralPageModes } from "../../global/types";
import useFetchData from "../../hooks/useFetchData";
import usePaginatedData from "../../hooks/usePaginatedData";
import { usePaginatedDataFromFetchData } from "../../hooks/usePaginatedDataFromFetchData";
import useSectionCurrentPage from "../../hooks/useSectionCurrentPage";

export interface IOrderBayParcelsPageFetchExtra {
  status?: ParcelStatus;
}

export default function useParcelPageData() {
  // Sets mode for parcel view
  const [parcelPageMode, setParcelPageMode] = React.useState(
    GeneralPageModes.Page
  );

  // Get paginated parcel data
  const parcelPageData = usePaginatedData<
    IParcel,
    IOrderBayParcelsPageFetchExtra
  >({
    fetch: OrderBayAPI.getOrdersPagedByParcel,
    getItemId: (item) => item.ParcelId,
  });

  // Search parcels
  const parcelSearchData = useFetchData<IParcel, ISearchOrdersEndpointInput>({
    fetch: OrderBayAPI.searchOrders,
    manual: true,
  });

  // Convert search data to pagination format
  const parcelSearchPaginationData = usePaginatedDataFromFetchData<
    IParcel,
    ISearchOrdersEndpointInput
  >(parcelSearchData, (item) => item.ParcelId);

  // Controls parcel section page route
  const parcelSectionPage = useSectionCurrentPage({
    baseURL: appOrderBayPaths.groupedByParcel,
    hasSelectedItemPage: true,
    isComponentReady: parcelPageData.isInitialized,
  });

  const isOnPagedByParcel = window.location.pathname.includes(
    appOrderBayPaths.groupedByParcel
  );

  const parcelPageSetFetchExtra = parcelPageData.setFetchExtra;
  const onEnterParcelFilter = React.useCallback(
    (data: IOrderBayParcelsPageFetchExtra) => {
      parcelPageSetFetchExtra(data, true);
    },
    [parcelPageSetFetchExtra]
  );

  const parcelPageSetSearchExtra = parcelSearchPaginationData.setFetchExtra;
  const parcelPageClearSearchData = parcelSearchPaginationData.clearData;
  const onEnterParcelSearchQuery = React.useCallback(
    (input: string) => {
      parcelPageSetSearchExtra({ query: input }, input ? true : false);

      if (input === "" && parcelPageMode === GeneralPageModes.Search) {
        setParcelPageMode(GeneralPageModes.Page);
        parcelPageClearSearchData();
      } else if (input !== "" && parcelPageMode === GeneralPageModes.Page) {
        window.setTimeout(() => {
          setParcelPageMode(GeneralPageModes.Search);
        }, 500);
      }
    },
    [
      parcelPageMode,
      parcelPageSetSearchExtra,
      setParcelPageMode,
      parcelPageClearSearchData,
    ]
  );

  // Parcel page data variables and functions
  const reloadParcelPageItems = parcelPageData.reloadPageItems;
  const reloadParcelPageData = parcelPageData.reloadEverything;

  // Parcel search data variables and functions
  const reloadParcelSearchData = parcelSearchData.reloadEverything;

  // Parcel section page variables and functions
  const gotoParcelBase = parcelSectionPage.gotoBase;

  const parcelPageOnCompleteUpdateOrder = React.useCallback(() => {
    reloadParcelPageItems(parcelPageData.page);
  }, [parcelPageData.page, reloadParcelPageItems]);

  const onCompleteDeleteParcel = React.useCallback(() => {
    gotoParcelBase();
    reloadParcelPageData(true);
    reloadParcelSearchData();
  }, [reloadParcelPageData, reloadParcelSearchData, gotoParcelBase]);

  return {
    parcelPageMode,
    setParcelPageMode,
    parcelPageData,
    parcelSearchData,
    parcelSearchPaginationData,
    parcelSectionPage,
    isOnPagedByParcel,
    onEnterParcelSearchQuery,
    onEnterParcelFilter,
    parcelPageOnCompleteUpdateOrder,
    onCompleteDeleteParcel,
  };
}
