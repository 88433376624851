


export const LOAD_TICKET_START = "LOAD_TICKET_START"
export const LOAD_TICKET_SUCCESS = "LOAD_TICKET_SUCCESS"
export const LOAD_TICKET_ERROR = "LOAD_TICKET_ERROR"

export const LOAD_FILTER_BY_PRIORITY_START = "LOAD_FILTER_BY_PRIORITY_START"
export const LOAD_FILTER_BY_PRIORITY_SUCCESS = "LOAD_FILTER_BY_PRIORITY_SUCCESS"
export const LOAD_FILTER_BY_PRIORITY_ERROR = "LOAD_FILTER_BY_PRIORITY_ERROR"

export const LOAD_FILTER_BY_STATUS_START = "LOAD_FILTER_BY_STATUS_START"
export const LOAD_FILTER_BY_STATUS_SUCCESS = "LOAD_FILTER_BY_STATUS_SUCCESS"
export const LOAD_FILTER_BY_STATUS_ERROR = "LOAD_FILTER_BY_STATUS_ERROR"