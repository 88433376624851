import { css } from "@emotion/css";
import { Tabs } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
import { appClasses } from "../../components/utils/theme";
import { appLoggedInPaths, appOrderBayPaths } from "../../global/paths";
import { GeneralPageModes } from "../../global/types";
import useSectionCurrentPage from "../../hooks/useSectionCurrentPage";
import OrderBayForm from "./form/OrderBayForm";
import OrderItemContainer from "./order/OrderItemContainer";
import OrdersPagedByOrderPage from "./order/OrdersPagedbyOrderPage";
import OrderBayPageControls from "./OrderBayPageControls";
import OrdersPagedByParcelPage from "./parcel/OrdersPagedbyParcelPage";
import Parcel from "./parcel/Parcel";
import useOrderPageData from "./useOrderPageData";
import useParcelPageData from "./useParcelPageData";

enum OrderBayTabs {
  PagedByParcel = "PagedByParcel",
  PagedByOrder = "PagedByOrder",
}

const classes = {
  tabs: css({
    flex: 1,

    "& .ant-tabs-content": {
      height: "100%",
      maxHeight: "100%",
    },

    "& .ant-tabs-nav": { marginBottom: "0" },
  }),
};

const OrderBayPageRoot: React.FC<{}> = () => {
  const {
    parcelPageMode,
    parcelPageData,
    parcelSearchPaginationData,
    parcelSectionPage,
    isOnPagedByParcel,
    onEnterParcelFilter,
    onEnterParcelSearchQuery,
    parcelPageOnCompleteUpdateOrder,
    onCompleteDeleteParcel,
  } = useParcelPageData();

  const {
    orderPageMode,
    orderPageData,
    orderSearchPaginationData,
    orderSectionPage,
    isOnPagedByOrder,
    onEnterOrderFilter,
    orderPageOnCompleteDeleteOrder,
    orderPageOnCompleteUpdateOrder,
    onEnterOrderSearchQuery,
  } = useOrderPageData();

  // Controls root section page route
  const rootSectionPage = useSectionCurrentPage({
    baseURL: appLoggedInPaths.orderBay,
    hasCreateForm: true,
    isComponentReady: true,
    disableAutoRouteToBaseOnInvalidCreatePath: true,
    disableAutoRouteToBaseOnInvalidSelectedPath: true,
    disableAutoRouteToBaseOnInvalidUpdatePath: true,
  });

  const onEnterSearchQuery = React.useCallback(
    (input: string) => {
      if (isOnPagedByOrder) {
        onEnterOrderSearchQuery(input);
      } else {
        onEnterParcelSearchQuery(input);
      }
    },
    [onEnterOrderSearchQuery, onEnterParcelSearchQuery]
  );

  if (
    !isOnPagedByParcel &&
    !isOnPagedByOrder &&
    !rootSectionPage.isCreateForm
  ) {
    return <Navigate to={appOrderBayPaths.groupedByParcel} />;
  }

  if (rootSectionPage.isCreateForm) {
    return <OrderBayForm onCloseForm={parcelSectionPage.gotoBase} />;
  }

  // Render selected parcel
  if (parcelSectionPage.selectedItemId) {
    const selectedParcel =
      parcelPageMode === GeneralPageModes.Page
        ? parcelPageData.getItemById(parcelSectionPage.selectedItemId)
        : parcelSearchPaginationData.getItemById(
            parcelSectionPage.selectedItemId
          );

    if (selectedParcel) {
      return (
        <Parcel
          parcel={selectedParcel}
          onCompleteDeleteOrder={parcelPageOnCompleteUpdateOrder}
          onCompleteUpdateOrder={parcelPageOnCompleteUpdateOrder}
          onCompleteDeleteParcel={onCompleteDeleteParcel}
        />
      );
    } else {
      return <Navigate to={appOrderBayPaths.groupedByParcel} />;
    }
  }

  // Render selected order
  if (orderSectionPage.selectedItemId) {
    return (
      <OrderItemContainer
        orderItemId={orderSectionPage.selectedItemId}
        onCompleteDelete={orderPageOnCompleteDeleteOrder}
        onCompleteUpdate={orderPageOnCompleteUpdateOrder}
      />
    );
  }

  return (
    <div className={appClasses.pageRoot}>
      <div className={appClasses.pageControls} style={{ marginBottom: "16px" }}>
        <OrderBayPageControls
          packageStatus={orderPageData.fetchExtra.packageStatus}
          parcelOrderStatus={orderPageData.fetchExtra.parcelOrderStatus}
          status={parcelPageData.fetchExtra.status}
          disabled={
            !orderPageData.isInitialized || !parcelPageData.isInitialized
          }
          isPagedByOrder={isOnPagedByOrder}
          isPagedByParcel={isOnPagedByParcel}
          isSearchOrder={orderPageMode === GeneralPageModes.Search}
          onEnterOrderFilter={onEnterOrderFilter}
          onEnterParcelFilter={onEnterParcelFilter}
          onEnterSearchQuery={onEnterSearchQuery}
          onCreateParcel={rootSectionPage.gotoCreateForm}
        />
      </div>
      <div className={appClasses.pageContent}>
        <Tabs
          className={classes.tabs}
          defaultActiveKey={
            isOnPagedByParcel
              ? OrderBayTabs.PagedByParcel
              : OrderBayTabs.PagedByOrder
          }
          onChange={(activeKey) => {
            switch (activeKey) {
              case OrderBayTabs.PagedByParcel:
                parcelSectionPage.gotoBase();
                break;
              case OrderBayTabs.PagedByOrder:
                orderSectionPage.gotoBase();
                break;
            }
          }}
        >
          <Tabs.TabPane tab="Paged by Parcel" key={OrderBayTabs.PagedByParcel}>
            <OrdersPagedByParcelPage
              {...(parcelPageMode === GeneralPageModes.Page
                ? parcelPageData
                : parcelSearchPaginationData)}
              onCompleteDeleteOrder={orderPageOnCompleteDeleteOrder}
              onCompleteUpdateOrder={orderPageOnCompleteUpdateOrder}
              onCompleteDeleteParcel={onCompleteDeleteParcel}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Paged by Order" key={OrderBayTabs.PagedByOrder}>
            <OrdersPagedByOrderPage
              {...(orderPageMode === GeneralPageModes.Page
                ? orderPageData
                : orderSearchPaginationData)}
              onCompleteDelete={orderPageOnCompleteDeleteOrder}
              onCompleteUpdate={orderPageOnCompleteUpdateOrder}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default OrderBayPageRoot;
