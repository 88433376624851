import { IAddress, IAddressInput, IEnum, IPersonInput } from "../global/types";

export enum ParcelOrderStatus {
  Available = "Available",
  OnHold = "OnHold",
}

export enum ParcelStatus {
  Draft = "Draft",
  Acknowledged = "Acknowledged",
  InProgress = "InProgress",
  NeedsAttension = "NeedsAttension",
  Completed = "Completed",
  Terminated = "Terminated",
  OnHold = "OnHold",
}

export enum PackageStatus {
  Draft = "Draft",
  ReadyForBlock = "ReadyForBlock",
  InProcess = "InProcess",
  Issues = "Issues",
  Staged = "Staged",
  InTransit = "InTransit",
  Terminated = "Terminated",
  Completed = "Completed",
  Delivered = "Delivered",
  AsArrived = "AsArrived",
}

export interface ISenderOrRecipient {
  FullName: string;
  EmailAddress: string;
  PhoneNumber: string;
  Address: string;
  Address_: IAddress;
  CountryCode: string;
  PhoneNumber_: string;
  EmailAddress_: string;
}

export interface IOrder {
  OrderId: string;
  OrderItemNumber: string;
  OrderItemStatus: IEnum;
  Status: IEnum;
  PackageEstimatedSize: IEnum;
  EstimatedWeightUnit: IEnum;
  OrderName: string;
  OrderDescription: string;
  OrderCategory: string;
  MsgNote: string;
  CurrentItemLocation: string;
  CheckListInfo: {
    Fragile: boolean;
    Perishable: boolean;
    ContentCanSpill: boolean;
    Hazardious: boolean;
    Explosive: boolean;
    Flammable: boolean;
  };
  DeliveryCheckListInfo: {
    CanDispatcher_DropPackageWithNeighbor: boolean;
    CanDispatcher_HidePackageInASecureArea: boolean;
    CanDispatcher_ReturnPackageToNearestBranchIfNotAround: boolean;
    ReturnPackageToNearestBranchId: string;
  };
  RecipientPickingUpAtNearestBranch: boolean;
  RecipientPickingUpAtNearestBranchId: string;
  EstimatedWeight: number;
  From_Sender: ISenderOrRecipient;
  To_Receiver: ISenderOrRecipient;
  OrderImageCounts: number;
  LastUpdated: string;
  LastUpdated_: string;
  TripHistory: string;
}

export interface IParcel {
  ParcelId: string;
  ParcelNumber: string;
  ParcelItemInfo: string;
  PackageCounts: string;
  TotalEstimatedWeight: string;
  ParcelDateCreated: string;
  ParcelDateCreated_: string;
  ParcelStatus: IEnum;
  ParcelAlreadyAcknowledged: boolean;
  UpdatedBy: string;
  OrganizationId: string;
  OrdersInfo: IOrder[];
}

export interface IParcelTransactionInfo {
  ParcelId: string;
  EstimatedDeliveryDate_: string;
  EstimatedDeliveryDate: string;
  ShippingCost: number;
  TaxPer: number;
  IsPaymentPOD: boolean;
  PaymentType: IEnum;
  PaidOption: string;
  CustomPaidOption: string;
  PaymentTypeInfo: string;
  ShippingCost_: string;
  TaxCost_: string;
  InsuranceCost_: string;
  InsuranceCost: number;
  Currency: IEnum;
  TransactionRecieptId: string;
  TotalCost: string;
  Summary: string;
  HtmlSummary: string;
}

export interface IOrderTripHistory {
  Status: IEnum;
  FromLocation: string;
  ToLocation: string;
  CurrentActiveTrip: boolean;
  AssignedDriverNum: string;
  AssignedDriverName: string;
  TripInfo: string;
  LastUpdated: string;
  TripStartedAt: string;
  TripEndedAt: string;
  TotalTripDuration: string;
}

export interface IRecipientInput {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  address: IAddressInput;
}

export interface IParcelSenderInfoInput extends IPersonInput {
  isParcelLikelyToBePOD: boolean;
}

export interface IOrderInput {
  category: string;
  otherCategory: string;
  estimatedWeight: number;
  estimatedWeightUnit: string;
  packageEstimatedSize: string;
  packageName: string;
  description: string;
  note: string;
  checkList: {
    fragile: boolean;
    perishable: boolean;
    contentCanSpill: boolean;
    hazardious: boolean;
    explosive: boolean;
    flammable: boolean;
  };
  deliveryCheckListInfo: {
    canDispatcher_DropPackageWithNeighbor: boolean;
    canDispatcher_HidePackageInASecureArea: boolean;
    canDispatcher_ReturnPackageToNearestBranchIfNotAround: boolean;
    returnPackageToNearestBranchId: string | null;
  };
  recipientPickingUpAtNearestBranch: boolean;
  recipientPickingUpAtNearestBranchId: string | null;
  recipientInfo: IPersonInput;
}

export interface IParcelInput {
  senderInfo: IPersonInput;
  orderItems: IOrderInput[];
  isParcelLikelyToBePOD: boolean;
}

export interface IAcknowledgeParcelInput {
  parcelId: string;
  estimatedDeliveryDate: string;
  isPaymentOptionPOD: boolean;
  paidInfo: {
    paidCustomOption: string;
    paidOption: string;
    recieptAttachmentId: string | null;
  } | null;
  currencyType: string;
  cost: number;
  taxPerc: number;
  insuranceCost: number;
}
