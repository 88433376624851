import { css } from "@emotion/css";
import { Button, Form } from "antd";
import { isNumber } from "lodash";
import React from "react";
import { IOrderInput } from "../../../definitions/orderBay";
import OrderItemForm from "./OrderItemForm";
import OrderItemListFormPreview, {
  getOrderPanelName,
} from "./OrderItemListPreview";

const classes = {
  root: css({
    maxWidth: "720px",
    margin: "auto",
  }),
};

export interface IOrderItemListFormProps {
  loading?: boolean;
  isPreview?: boolean;
  orgId?: string;
  orders: Omit<IOrderInput, "recipientInfo">[];
  onAddOrder: (order: Omit<IOrderInput, "recipientInfo">) => void;
  onUpdateOrder: (
    index: number,
    order: Omit<IOrderInput, "recipientInfo">
  ) => void;
  onDeleteOrder: (index: number) => void;
}

const OrderItemListForm: React.FC<IOrderItemListFormProps> = (props) => {
  const {
    isPreview,
    loading,
    orders,
    orgId,
    onAddOrder,
    onDeleteOrder,
    onUpdateOrder,
  } = props;

  const [editingIndex, setEditingIndex] = React.useState<number | null>(null);
  const [showNewOrderForm, setShowNewOrderForm] = React.useState(false);
  const closeForm = React.useCallback(() => {
    setEditingIndex(null);
    setShowNewOrderForm(false);
  }, []);

  const formNode = (showNewOrderForm || isNumber(editingIndex)) && (
    <OrderItemForm
      loading={loading}
      order={isNumber(editingIndex) ? orders[editingIndex] : undefined}
      onSubmit={(inputOrder) => {
        if (isNumber(editingIndex)) {
          onUpdateOrder(editingIndex, inputOrder);
        } else {
          onAddOrder(inputOrder);
        }

        closeForm();
      }}
      onCancel={closeForm}
      title={getOrderPanelName(orders.length)}
      orgId={orgId}
    />
  );

  const controlsNode = !isPreview && (
    <Form.Item>
      <Button
        onClick={() => {
          setShowNewOrderForm(true);
        }}
      >
        Add Order
      </Button>
    </Form.Item>
  );

  return (
    <div className={classes.root}>
      {formNode}
      <Form.Item
        label="Order Items"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <OrderItemListFormPreview
          isPreview={isPreview}
          orders={orders}
          onEditOrder={setEditingIndex}
          onDeleteOrder={onDeleteOrder}
        />
      </Form.Item>
      {controlsNode}
    </div>
  );
};

export default OrderItemListForm;
