import * as types from "./policyActionType"


const initialState = {
    policies: [],
    loading: false,
    error: null
}


export const PolicyReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_POLICY_START:
        case types.LOAD_FILTER_POLICY_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case types.LOAD_POLICY_SUCCESS:
        case types.LOAD_FILTER_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                policies: action.payload,
                error: null,
            }
        case types.LOAD_POLICY_ERROR:
        case types.LOAD_FILTER_POLICY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}