import { ICountryInfo } from "../../definitions/countries";
import { API_URL } from "../../globalVariable";
import { invokeEndpoint, invokeEndpointWithAuth } from "../invokeREST";
import { selectEndpointParam, setEndpointParam } from "../utils";

const resourcesURL = `${API_URL}Resources`;
const getCountryStateCitiesURL = `${resourcesURL}/GetCountryStateCities`;
const getBlockTripTimeTypeURL = `${resourcesURL}/GetBlockTripTimeType`;
const getBlockDeliveryTypeURL = `${resourcesURL}/GetBlockDelivery`;
const getCountryCodesObjURL = `${resourcesURL}/GetCountryCodeObj`;

export interface IGetCountryStateCitiesEndpointInput {
  country: string;
  state?: string;
}

export type IGetCountryStateCitiesEndpointResult = string[];

async function getCountryStateCities(
  props: IGetCountryStateCitiesEndpointInput
): Promise<IGetCountryStateCitiesEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "country", props.country);
  setEndpointParam(params, "state", props.state);
  const endpointURL = selectEndpointParam(
    getCountryStateCitiesURL,
    params,
    props
  );
  const result = await invokeEndpoint<IGetCountryStateCitiesEndpointResult>({
    path: endpointURL,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export type IGetBlockTripTimeTypeEndpointResult = string[];

async function getBlockTripTimeTypes(): Promise<IGetBlockTripTimeTypeEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetBlockTripTimeTypeEndpointResult>({
      path: getBlockTripTimeTypeURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export type IGetBlockDeliveryTypesEndpointResult = string[];

async function getBlockDeliveryTypes(): Promise<IGetBlockDeliveryTypesEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IGetBlockDeliveryTypesEndpointResult>({
      path: getBlockDeliveryTypeURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export interface IGetCountryCodesObjProps {
  query?: string;
}

export type IGetCountryCodesObjResult = Array<ICountryInfo>;

async function getCountryCodesObj(
  props: IGetCountryCodesObjProps = {}
): Promise<IGetCountryCodesObjResult> {
  const params = new URLSearchParams();

  if (props.query) {
    params.set("query", props.query);
  }

  const fullPath = props.query
    ? `${getCountryCodesObjURL}?${params.toString()}`
    : getCountryCodesObjURL;

  const result = await invokeEndpointWithAuth<IGetCountryCodesObjResult>({
    path: fullPath,
    method: "GET",
    defaultValue: [],
  });

  return result;
}

export default class ResourcesAPI {
  public static getCountryStateCities = getCountryStateCities;
  public static getBlockTripTimeTypes = getBlockTripTimeTypes;
  public static getBlockDeliveryTypes = getBlockDeliveryTypes;
  public static getCountryCodesObj = getCountryCodesObj;
}
