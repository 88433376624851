import * as types from "./actionType";

const initialState = {
  review: [],
  reviewDrivers: [],
  loading: false,
  error: null,
};

export const ReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REVIEW_START:
    case types.GET_REVIEW_SEARCH_START:
    case types.GET_REVIEW_DRIVER_START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_REVIEW_SUCCESS:
    case types.GET_REVIEW_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        review: action.payload,
      };
    case types.GET_REVIEW_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
       reviewDrivers: action.payload,
      };
    case types.GET_REVIEW_ERROR:
    case types.GET_REVIEW_SEARCH_ERROR:
    case types.GET_REVIEW_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
