import * as types from "./profileActionType"
import {
    take,
    takeEvery,
    takeLatest,
    put,
    all,
    delay,
    fork,
    call
} from "redux-saga/effects"
import Cookies from "js-cookie"
import {  getUserInfoApi ,  getCompanyInfoApi  } from "../Api"
import { loadUserProfileError,loadCompanyProfileError, loadUserProfileSuccess, loadCompanyProfileSuccess } from "./profileAction"






function* onLoadUserProfileStartAsync() {
    try {
        const response = yield call( getUserInfoApi )
        if (response.status === 200) {
            yield delay(500)
            yield put(loadUserProfileSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadUserProfileError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}

function* onLoadCompanyProfileStartAsync() {
    try {
        const response = yield call(getCompanyInfoApi)
        if (response.status === 200) {
            yield delay(500)
            yield put(loadCompanyProfileSuccess(response.data))
        }
        else if (response.statusText === "Unauthorized") {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.reload()
        }
    }
    catch(error) {
        yield put(loadCompanyProfileError(error))
        if (error?.response?.status === 401) {
            Cookies.remove("accessToken")
            Cookies.remove("refreshToken")
            return window.location.href = "/login";
        }
    }
}


function* onLoadUserProfile() {
    yield takeEvery(types.LOAD_USER_PROFILE_START, onLoadUserProfileStartAsync)
}

function* onLoadCompanyProfile() {
    yield takeEvery(types.LOAD_COMPANY_PROFILE_START, onLoadCompanyProfileStartAsync)
}



export const profileSaga = [
    fork(onLoadUserProfile),
    fork(onLoadCompanyProfile)
]