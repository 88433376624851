import {
  take,
  takeEvery,
  takeLatest,
  put,
  all,
  delay,
  fork,
  call,
} from "redux-saga/effects";
import Cookies from "js-cookie";
import * as types from "./actionType";
import {
  getReviewError,
  getReviewSuccess,
  getReviewSearchError,
  getReviewSearchSuccess,
  getReviewDriverError,
  getReviewDriverSuccess,
} from "./action";
import { getReviewApi, getReviewSearchApi, getReviewDriverApi } from "../Api";

function* onLoadReviewStartAsync({payload}) {
  try {
    const response = yield call(getReviewApi, payload);
    if (response.status === 200) {
      yield delay(500);
      yield put(getReviewSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("accessToken")
      Cookies.remove("refreshToken")
      return window.location.href = "/login";
  }
    if (error.response.data.Message) {
      yield put(getReviewError(error.response.data.Message));
    } else {
      yield put(getReviewError(error.response.data.message));
    }
  }
}

function* onLoadReviewSearchStartAsync({ payload }) {
  try {
    const response = yield call(getReviewSearchApi, payload);
    if (response.status === 200) {
      yield delay(500);
      yield put(getReviewSearchSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("accessToken")
      Cookies.remove("refreshToken")
      return window.location.href = "/login";
  }
    if (error.response.data.Message) {
        yield put(getReviewSearchError(error.response.data.Message));
      } else {
        yield put(getReviewSearchError(error.response.data.message));
      }
  }
}

function* onLoadReviewDriverStartAsync() {
  try {
    const response = yield call(getReviewDriverApi);
    if (response.status === 200) {
      yield delay(500);
      yield put(getReviewDriverSuccess(response.data));
    } else if (response.statusText === "Unauthorized") {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return window.location.reload();
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("accessToken")
      Cookies.remove("refreshToken")
      return window.location.href = "/login";
  }
    if(error.response.data.Message) {
      yield put(getReviewDriverError(error.response.data.Message));
  }
  else {
      yield put(getReviewDriverError(error.response.data.message));
  }
  
  }
}

function* onLoadReview() {
  yield takeEvery(types.GET_REVIEW_START, onLoadReviewStartAsync);
}

function* onLoadReviewSearch() {
  yield takeEvery(types.GET_REVIEW_SEARCH_START, onLoadReviewSearchStartAsync);
}

function* onLoadReviewDriver() {
  yield takeEvery(types.GET_REVIEW_DRIVER_START, onLoadReviewDriverStartAsync);
}

export const reviewSaga = [
  fork(onLoadReview),
  fork(onLoadReviewSearch),
  fork(onLoadReviewDriver),
];
