import React from "react";
import { Navigate } from "react-router-dom";
import OrderBlockAPI, {
  ISearchOrderBlocksEnpointInput,
} from "../../api/endpoints/orderBlock";
import {
  IOrderBlock,
  OrderBlockPriority,
  OrderBlockStatus,
  OrderBlockType,
} from "../../definitions/orderBlock";
import { appLoggedInPaths } from "../../global/paths";
import { GeneralPageModes } from "../../global/types";
import useFetchData from "../../hooks/useFetchData";
import usePaginatedData from "../../hooks/usePaginatedData";
import { usePaginatedDataFromFetchData } from "../../hooks/usePaginatedDataFromFetchData";
import useSectionCurrentPage from "../../hooks/useSectionCurrentPage";
import OrderBlockFormRoot from "./form/OrderBlockFormRoot";
import OrderBlockContainer from "./OrderBlockContainer";
import OrderBlockPage from "./OrderBlockPage";

export interface IOrderBlockPageFetchExtra {
  type?: OrderBlockType;
  priority?: OrderBlockPriority;
  status?: OrderBlockStatus;
}

const OrderBlockPageRoot: React.FC<{}> = () => {
  const [pageMode, setPageMode] = React.useState(GeneralPageModes.Page);

  // Get paginated order block data
  const blockPageData = usePaginatedData<
    IOrderBlock,
    IOrderBlockPageFetchExtra
  >({
    fetch: OrderBlockAPI.getOrderBlocks,
    getItemId: (item) => item.BlockId,
  });

  // Search order blocks
  const blockSearchData = useFetchData<
    IOrderBlock,
    ISearchOrderBlocksEnpointInput
  >({
    fetch: OrderBlockAPI.searchOrderBlocks,
    manual: true,
  });

  // Convert search data to pagination format
  const blockSearchPaginationData = usePaginatedDataFromFetchData<
    IOrderBlock,
    ISearchOrderBlocksEnpointInput
  >(blockSearchData, (item) => item.BlockId);

  // Controls root section page route
  const rootSectionPage = useSectionCurrentPage({
    baseURL: appLoggedInPaths.orderBlock,
    hasCreateForm: true,
    hasUpdateForm: true,
    hasSelectedItemPage: true,
    isComponentReady: true,
  });

  const setFetchExtra = blockPageData.setFetchExtra;
  const onEnterBlockFilter = React.useCallback(
    (data: IOrderBlockPageFetchExtra) => {
      setFetchExtra(data, true);
    },
    [setFetchExtra]
  );

  const searchModeSetSearchExtra = blockSearchPaginationData.setFetchExtra;
  const clearSearchData = blockSearchPaginationData.clearData;
  const onEnterSearchQuery = React.useCallback(
    (input: string) => {
      searchModeSetSearchExtra({ query: input }, input ? true : false);

      if (input === "" && pageMode === GeneralPageModes.Search) {
        setPageMode(GeneralPageModes.Page);
        clearSearchData();
      } else if (input !== "" && pageMode === GeneralPageModes.Page) {
        window.setTimeout(() => {
          setPageMode(GeneralPageModes.Search);
        }, 500);
      }
    },
    [pageMode, searchModeSetSearchExtra, setPageMode, clearSearchData]
  );

  const blockPageReloadBlocks = blockPageData.reloadEverything;
  const blockSearchReloadBlocks = blockPageData.reloadEverything;
  const gotoBase = rootSectionPage.gotoBase;
  const onCompleteDeleteOrCreateBlock = React.useCallback(() => {
    gotoBase();
    blockPageReloadBlocks(true);
    blockSearchReloadBlocks(true);
  }, [blockPageReloadBlocks, blockSearchReloadBlocks, gotoBase]);

  const reloadPageItems = blockPageData.reloadPageItems;
  const onCompleteUpdateBlock = React.useCallback(() => {
    reloadPageItems(blockPageData.page);
  }, [blockPageData.page, reloadPageItems]);

  if (rootSectionPage.isCreateForm) {
    return (
      <OrderBlockFormRoot
        onCloseForm={rootSectionPage.gotoBase}
        onCompleteSubmit={onCompleteDeleteOrCreateBlock}
      />
    );
  }

  if (rootSectionPage.updateItemId) {
    const selectedBlock =
      pageMode === GeneralPageModes.Page
        ? blockPageData.getItemById(rootSectionPage.updateItemId)
        : blockSearchPaginationData.getItemById(rootSectionPage.updateItemId);

    if (selectedBlock) {
      return (
        <OrderBlockFormRoot
          existingBlock={selectedBlock}
          onCloseForm={rootSectionPage.gotoBase}
          onCompleteSubmit={onCompleteUpdateBlock}
        />
      );
    } else {
      return <Navigate to={appLoggedInPaths.orderBlock} />;
    }
  }

  // Render selected order block
  if (rootSectionPage.selectedItemId) {
    return (
      <OrderBlockContainer
        blockId={rootSectionPage.selectedItemId}
        onCompleteDelete={onCompleteDeleteOrCreateBlock}
        onCompleteUpdate={onCompleteUpdateBlock}
        onSelectUpdateBlock={rootSectionPage.gotoUpdateForm}
      />
    );
  }

  return (
    <OrderBlockPage
      controls={{
        ...blockPageData.fetchExtra,
        onEnterBlockFilter,
        disabled: !blockPageData.isInitialized,
        onCreateBlockOrder: rootSectionPage.gotoCreateForm,
        onEnterSearchQuery: onEnterSearchQuery,
      }}
      pageData={
        pageMode === GeneralPageModes.Page
          ? blockPageData
          : blockSearchPaginationData
      }
      onCompleteDeleteBlock={onCompleteDeleteOrCreateBlock}
      onCompleteUpdateBlock={onCompleteUpdateBlock}
      onSelectUpdateBlock={rootSectionPage.gotoUpdateForm}
    />
  );
};

export default OrderBlockPageRoot;
