import React from "react";
import { Space, Divider } from "antd";
import OrderItemPackageDetails from "./OrderItemPackageDetails";
import OrderItemCheckList from "./OrderItemCheckList";
import OrderItemDeliveryCheckList from "./OrderItemDeliveryCheckList";
import OrderItemSenderOrRecipientDetails from "./OrderItemRecipientDetails";
import OrderItemTripHistory from "./OrderItemTripHistory";
import { IOrder } from "../../../definitions/orderBay";
import ImageProviderAndUpload from "../../../components/utils/ImageProviderAndUpload";
import { appDimensions } from "../../../components/utils/theme";
import OrderItemHeader from "./OrderItemHeader";
import LabeledNode from "../../../components/utils/LabeledNode";

export interface IOrderItemProps {
  orderItem: IOrder;
  onCompleteUpdate: () => void;
  onCompleteDelete: () => void;
}

const OrderItem: React.FC<IOrderItemProps> = (props) => {
  const { orderItem } = props;
  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <OrderItemHeader {...props} />
      <Divider orientation="left">Package Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderItemPackageDetails
          category={orderItem.OrderCategory}
          description={orderItem.OrderDescription}
          estimatedWeight={orderItem.EstimatedWeight}
          estimatedWeightUnit={orderItem.EstimatedWeightUnit}
          otherCategory={orderItem.OrderCategory}
          packageEstimatedSize={orderItem.PackageEstimatedSize}
          packageName={orderItem.OrderName}
          lastUpdatedAt={orderItem.LastUpdated_}
        />
      </div>
      <Divider orientation="left">CheckList</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderItemCheckList
          contentCanSpill={orderItem.CheckListInfo.ContentCanSpill}
          explosive={orderItem.CheckListInfo.Explosive}
          flammable={orderItem.CheckListInfo.Flammable}
          fragile={orderItem.CheckListInfo.Fragile}
          hazardious={orderItem.CheckListInfo.Hazardious}
          perishable={orderItem.CheckListInfo.Perishable}
        />
      </div>
      <Divider orientation="left">Sender Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderItemSenderOrRecipientDetails
          isSender
          city={orderItem.From_Sender.Address_.City}
          country={orderItem.From_Sender.Address_.Country}
          countryCode={orderItem.From_Sender.CountryCode}
          emailAddress={orderItem.From_Sender.EmailAddress}
          name={orderItem.From_Sender.FullName}
          phoneNumber={orderItem.From_Sender.PhoneNumber}
          state={orderItem.From_Sender.Address_.State}
          street={orderItem.From_Sender.Address_.Street}
          zipCode={orderItem.From_Sender.Address_.ZipCode}
          formattedAddress={orderItem.From_Sender.Address}
        />
      </div>
      <Divider orientation="left">Recipient Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderItemSenderOrRecipientDetails
          city={orderItem.To_Receiver.Address_.City}
          country={orderItem.To_Receiver.Address_.Country}
          countryCode={orderItem.To_Receiver.CountryCode}
          emailAddress={orderItem.To_Receiver.EmailAddress}
          name={orderItem.To_Receiver.FullName}
          phoneNumber={orderItem.To_Receiver.PhoneNumber}
          state={orderItem.To_Receiver.Address_.State}
          street={orderItem.To_Receiver.Address_.Street}
          zipCode={orderItem.To_Receiver.Address_.ZipCode}
          formattedAddress={orderItem.To_Receiver.Address}
        />
      </div>
      <Divider orientation="left">Delivery CheckList</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderItemDeliveryCheckList
          deliveryCheckListInfo={{
            canDispatcher_DropPackageWithNeighbor:
              orderItem.DeliveryCheckListInfo
                ?.CanDispatcher_DropPackageWithNeighbor,
            canDispatcher_HidePackageInASecureArea:
              orderItem.DeliveryCheckListInfo
                ?.CanDispatcher_HidePackageInASecureArea,
            canDispatcher_ReturnPackageToNearestBranchIfNotAround:
              orderItem.DeliveryCheckListInfo
                ?.CanDispatcher_ReturnPackageToNearestBranchIfNotAround,
            returnPackageToNearestBranchId:
              orderItem.DeliveryCheckListInfo?.ReturnPackageToNearestBranchId,
          }}
          recipientPickingUpAtNearestBranch={
            orderItem.RecipientPickingUpAtNearestBranch
          }
          recipientPickingUpAtNearestBranchId={
            orderItem.RecipientPickingUpAtNearestBranchId
          }
          note={orderItem.MsgNote}
        />
      </div>
      <Divider orientation="left">Trip History</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <LabeledNode
          direction="vertical"
          label="Order Item Current Location"
          node={orderItem.CurrentItemLocation}
        />
      </div>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <OrderItemTripHistory orderItemId={orderItem.OrderId} />
      </div>
      <Divider orientation="left">Attachments</Divider>
      <div style={{ maxWidth: "900px", margin: "auto", marginBottom: "32px" }}>
        <ImageProviderAndUpload
          isGroupImages
          allowDelete
          id={orderItem.OrderId}
          imageAltBase={`${orderItem.OrderName}`}
          height={appDimensions.imageWidth}
          width={appDimensions.imageWidth}
        />
      </div>
    </Space>
  );
};

export default OrderItem;
