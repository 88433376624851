import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadBranchStart } from "../../redux/SettingsRedux/settingAction";
import { IReduxState, IReduxStateSettings } from "../../redux/types";
import SelectedBranch from "./SelectedBranch";

export interface ISelectedBranchWithLoadBranchesProps {
  branchId: string;
}

const SelectedBranchWithLoadBranches: React.FC<
  ISelectedBranchWithLoadBranchesProps
> = (props) => {
  const { branchId } = props;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadBranchStart());
  }, [dispatch]);
  const { branch: branches } = useSelector<IReduxState, IReduxStateSettings>(
    (state) => state.settings
  );
  const branch = React.useMemo(() => {
    return branches.find((branch) => branch.Id === branchId);
  }, [branches, branchId]);

  if (!branch) {
    return null;
  }

  return <SelectedBranch selectedBranch={branch} />;
};

export default SelectedBranchWithLoadBranches;
