import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import Loader from "../../components/Loader";
import { API_URL } from "../../globalVariable";
import { loadMovebotRoleStart } from "../../redux/MovebotPolicyAndPermissionRedux/movebotAction";
import { loadPolicyStart } from "../../redux/PolicyRedux/policyAction";
import {
  loadCountryCodeStart,
  loadCountryStateCityStart,
  loadCountryStateStart,
} from "../../redux/ResourcesRedux/resourcesAction";
import { loadBranchStart } from "../../redux/SettingsRedux/settingAction";
import "./create-employee.css";

function CreateEmployee(props) {
  const location = useLocation();
  const employee = location.state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var queryType = employee?.EmployeeId;

  const [firstName, setFirstName] = useState(
    employee?.EmployeeRawData?.FirstName
      ? employee?.EmployeeRawData?.FirstName
      : ""
  );
  const [lastName, setLastName] = useState(
    employee?.EmployeeRawData?.LastName
      ? employee?.EmployeeRawData?.LastName
      : ""
  );
  const [email, setEmail] = useState(
    employee?.EmployeeRawData?.EmailAddress
      ? employee?.EmployeeRawData?.EmailAddress
      : ""
  );
  const [street, setStreet] = useState(
    employee?.EmployeeRawData?.Address.Street
      ? employee?.EmployeeRawData?.Address.Street
      : ""
  );
  const [city, setCity] = useState(
    employee?.EmployeeRawData?.Address.City
      ? employee?.EmployeeRawData?.Address.City
      : ""
  );
  const [zipCode, setZipCode] = useState(
    employee?.EmployeeRawData?.Address.ZipCode
      ? employee?.EmployeeRawData?.Address.ZipCode
      : ""
  );
  const [state, setState] = useState(
    employee?.EmployeeRawData?.Address.State
      ? employee?.EmployeeRawData?.Address.State
      : ""
  );
  const [country, setCountry] = useState(
    employee?.EmployeeRawData?.Address.Country
      ? employee?.EmployeeRawData?.Address.Country
      : ""
  );
  const [countryCodes, setCountryCodes] = useState(
    employee?.EmployeeRawData?.PhoneNumber?.Code
      ? employee?.EmployeeRawData?.PhoneNumber?.Code
      : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    employee?.EmployeeRawData?.PhoneNumber?.Number
      ? employee?.EmployeeRawData?.PhoneNumber?.Number
      : ""
  );
  const [role, setRole] = useState(employee?.AssignedRole);

  const [getCities, setGetCities] = useState([]);
  const [policy, setPolicy] = useState(employee?.AssignedGroupPolicy);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const { roles } = useSelector((state) => state.movebotActions);

  const { policies } = useSelector((state) => state.policies);
  const { branch } = useSelector((state) => state.settings);

  const editedRoleId = roles?.find(
    (data) => data._RoleType === employee?.AssignedRole
  )?.RoleId;

  const [assignedRoleId, setAssignRoleId] = useState("");
  const [assignedGroupPolicyId, setAssignGroupPolicyId] = useState(
    employee?.EmployeeRawData?.AssignedGroupPolicyId
  );
  const [branchName, setBranchName] = useState(employee?.AssignedBranchInfo);
  const [branchId, setBranchId] = useState(
    employee?.EmployeeRawData?.AssignBranchId
  );

  console.log({ branchId, branchName });
  useEffect(() => {
    const fetchD = () => {
      setAssignRoleId(editedRoleId);
    };
    fetchD;
  }, [editedRoleId]);

  function handleCountryChange(value) {
    setCountry(value);
  }
  function handleBranchChange(value) {
    const branchData = branch?.find((data) => data.Id === value);
    setBranchName(branchData?.BranchName);
    setBranchId(branchData?.Id);
  }

  function handleStateChange(value) {
    setState(value);
  }

  function handleCityChange(value) {
    setCity(value);
  }
  function handleCountryCodeChange(value) {
    setCountryCodes(value);
  }

  useEffect(() => {
    const payload = {
      page,
      pageSize,
    };
    dispatch(loadPolicyStart(payload));
    dispatch(loadCountryStateCityStart());
    dispatch(loadCountryCodeStart());
    dispatch(loadMovebotRoleStart());
    dispatch(loadBranchStart());
  }, []);

  useEffect(() => {
    dispatch(loadCountryStateStart(country));
  }, [country]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          API_URL +
            `Resources/GetCountryStateCities?country=${
              country === undefined ? "" : country
            }&state=${state === undefined ? "" : state}`,
          {
            headers: {
              authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        )
        .then((data) => {
          setGetCities(data.data);
        });
    };
    fetchData();
  }, [state]);

  const { countryStateCities, countryCode, getStates } = useSelector(
    (state) => state.resources
  );

  function handleRoleChange(value) {
    setRole(value);
    setAssignRoleId(value);
  }

  function handlePermissionChange(value) {
    setPolicy(value);
    setAssignGroupPolicyId(value);
  }

  const createUser = async () => {
    setLoader(true);
    var data = {
      firstName,
      lastName,
      emailAddress: email,
      phoneNumber: {
        Number: phoneNumber,
        code: countryCodes,
      },
      address: {
        street,
        city,
        state,
        zipCode,
        country,
      },
      assignedGroupPolicyId: assignedGroupPolicyId,
      assignBranchId: branchId,
    };

    var response = await fetch(API_URL + "Employee/Create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.message,
        icon: "success",
      })
        .then(() => {
          setFirstName("");
          setLoader(false);
          setLastName("");
          setEmail("");
          setPhoneNumber("");
          setRole("");
          setCountryCodes("");
          setStreet("");
          setCity("");
          setState("");
          setCountry("");
          setZipCode("");
          setPolicy("");
          setBranchName("");
          setBranchId("");
        })
        .then(() => {});
    } else {
      if (dataResponse.Message) {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      }
    }
  };

  const updateEmployee = async () => {
    const data = {
      firstName,
      lastName,
      emailAddress: email,
      phoneNumber: {
        Number: phoneNumber,
        code: countryCodes,
      },
      addres: {
        street,
        city,
        state,
        zipCode,
        country,
      },
      assignedRoleId,
      assignedGroupPolicyId,
      assignBranchId: branchId,
    };

    setLoader(true);

    var response = await fetch(
      API_URL + `Employee/Update?employeeId=${queryType}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.message,
        icon: "success",
      }).then(() => {
        setLoader(false);
      });
    } else {
      if (dataResponse.message) {
        setLoader(false);
        return swal({
          text: dataResponse.message,
          icon: "warning",
        });
      } else {
        setLoader(false);
        return swal({
          text: dataResponse.Message,
          icon: "warning",
        });
      }
    }
  };

  return (
    <Fragment>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row>
          <Col span={18}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={() => navigate(-1)}
                icon={<ArrowLeftOutlined />}
              ></Button>
              <Typography.Title
                style={{ marginLeft: "1%", marginBottom: "0%" }}
                level={5}
              >
                {queryType ? "Edit Employee" : "Create Employee"}
              </Typography.Title>
            </div>
          </Col>
        </Row>
        <div className="create">
          <Row>
            <Col span={24}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="First Name"
              >
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  // onBlur={formik.handleBlur}
                  name="firstName"
                  // disabled={loading}
                />
              </Form.Item>

              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Last Name"
              >
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  // onBlur={formik.handleBlur}
                  name="lastName"
                  // disabled={loading}
                />
              </Form.Item>

              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Email Address"
              >
                <Input
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // onBlur={formik.handleBlur}
                  name="email"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Country Code"
              >
                <Select
                  placeholder="Select Country Code"
                  value={countryCodes}
                  onChange={handleCountryCodeChange}
                  // disabled={loading}
                >
                  {countryCode?.map((code) => {
                    return (
                      <Select.Option key={code} value={code}>
                        {code}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Phone Number"
              >
                <Input
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  name="phoneNumber"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Country"
              >
                <Select
                  placeholder="Select Country"
                  value={country}
                  onChange={handleCountryChange}
                  // disabled={loading}
                >
                  {countryStateCities?.map((code) => {
                    return (
                      <Select.Option key={code} value={code}>
                        {code}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select State"
              >
                <Select
                  placeholder="Select State"
                  value={state}
                  onChange={handleStateChange}
                  // disabled={loading}
                >
                  {country?.length > 0 ? (
                    getStates?.map((code) => {
                      return (
                        <Select.Option key={code} value={code}>
                          {code}
                        </Select.Option>
                      );
                    })
                  ) : (
                    <Select.Option>Select State</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Street"
              >
                <Input
                  placeholder="Street"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  name="street"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select City"
              >
                <Select
                  placeholder="Select City"
                  value={city}
                  onChange={handleCityChange}
                  // disabled={loading}
                >
                  {state?.length > 0 ? (
                    getCities?.map((code) => {
                      return (
                        <Select.Option key={code} value={code}>
                          {code}
                        </Select.Option>
                      );
                    })
                  ) : (
                    <Select.Option>Select City</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Zipcode"
              >
                <Input
                  placeholder="ZipCode"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  name="zipCode"
                  // disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Role"
              >
                <Select value={role} onChange={handleRoleChange}>
                  {roles?.map((code) => {
                    return (
                      <Select.Option key={code} value={code._RoleType}>
                        {code._RoleType}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Permission"
              >
                <Select value={policy} onChange={handlePermissionChange}>
                  {policies?.Data?.map((code) => {
                    return code.RoleInfo._RoleType === role ? (
                      <Select.Option
                        key={code._RoleType}
                        value={code.GroupPolicyId}
                      >
                        {code.GroupName}
                      </Select.Option>
                    ) : null;
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                required
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Select Branch"
              >
                <Select value={branchName} onChange={handleBranchChange}>
                  {branch?.map((code) => {
                    return (
                      <Select.Option key={code?.Id} value={code?.Id}>
                        {code?.BranchName + " " + `(${code?.Address_})`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={16}></Col>
          </Row>

          <Button
            type="primary"
            id="but"
            onClick={queryType ? () => updateEmployee() : () => createUser()}
          >
            {loader ? (
              <Loader />
            ) : queryType ? (
              "Update Employee"
            ) : (
              "Create User"
            )}
          </Button>
        </div>
        <br />
        <br />
      </Space>
    </Fragment>
  );
}

export default CreateEmployee;
