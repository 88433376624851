import React, { useState, useEffect } from 'react'

import "./login.css"

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from "../../components/Loader"
import { API_URL } from "../../globalVariable"

import Cookies from "js-cookie"
import { resolveOnChange } from 'antd/lib/input/Input';

function Login(props) {

    const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState()
    const [password, setPassword] = useState()
    const [loader, setLoader] = useState(false)
    const [errMsg, setErrMsg] = useState("")



    const loginUser = () => {
        setLoader(true)
        const data = {
            emailOrPhoneNumber,
            password
        }

        fetch(API_URL + "Authentication/Login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                const { Token, RefreshToken } = data

                if (Token) {
                    // localStorage.setItem("token", data.Token);
                    Cookies.set('accessToken', Token)
                    Cookies.set('refreshToken', RefreshToken)
                    setLoader(false)
                    return window.location.href = "/dashboard"
                }
                else {
                    setErrMsg(data.message)
                    setLoader(false)
                }

            })
            .then(() => {
                setTimeout(() => {
                    setErrMsg("")
                    setLoader(false)
                }, 3000)
            })
            .catch(err => {
                console.log({ err })
                setLoader(false)
            })

    }



    return (
        <div className="signin-component">
            <div className="signins-div1">
                <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1623772465/Rectangle_453_zrsfyc.png" />
            </div>

            <div className="signnin-div2">
                <p className={errMsg?.length <= 0 ? "none" : "err-msg"}>{errMsg}</p>
                <div className="login-component">
                    <img src="https://res.cloudinary.com/doouwbecx/image/upload/v1624214382/A_PNG_senvtp.png" />
                    <h2>Login to access Workspace</h2>
                    <form className="" noValidate autoComplete="off">
                        <div>
                            <TextField id="outlined-basic" label="Email Or PhoneNumber" value={emailOrPhoneNumber} onChange={(e) => setEmailOrPhoneNumber(e.target.value)} variant="outlined" className="" />
                        </div>

                        <div>
                            <TextField type="password" id="outlined-basic" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} variant="outlined" className="" />
                        </div>
                        <div className="forget-btn">
                            <p onClick={() => window.location.href = "/forget-password"}>Forgot Password?</p>
                            
                        </div>

                        <Button variant="contained" color="primary" className="login-button" onClick={() => loginUser()}>
                            {loader ? <Loader /> : "Login to Workspace"}
                        </Button>
                        <br/>
                        <br/>

                        <a href='https://movebot.ng/Contact' target="_blank">No account? Talk to us</a>
                    </form>
                </div>
            </div>

        </div>
    )
}



export default Login
