export const messages = {
  // generic
  loading: "Loading",

  // error
  initComponentError: "Error initializing component, please reload the page",

  // form
  requiredField: "Field is required",

  // user error messages
  enterValidPhoneNumber: "Please enter a valid phone number",
};
