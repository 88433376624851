import {
  Divider,
  Image,
  Input,
  List,
  Modal,
  Result,
  Select,
  Table,
} from "antd";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Paginations from "../../components/Pagination";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { appDimensions } from "../../components/utils/theme";
import { API_URL } from "../../globalVariable";
import {
  getReviewDriverStart,
  getReviewSearchStart,
  getReviewStart,
} from "../../redux/ReviewRedux/action";
import "./review.css";

const { Option } = Select;

function Review() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { review, reviewDrivers, error, loading } = useSelector(
    (state) => state.reviews
  );
  const [visible, setVisible] = useState(false);
  const [visibleRecipient, setVisibleRecipient] = useState(false);
  const [driverId, setDriverId] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  const [searchValue, setSearchValue] = useState();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  var queryType = query.get("search");

  var queryPage = parseInt(query.get("page"));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryPage);
  const [pageSize, setPageSize] = useState(10);

  const [detailModal, setDetailModal] = useState(false);
  const [details, setDetails] = useState();
  const handleDetailModalOpens = (props) => {
    setDetails(props);
    setDetailModal(true);
  };

  const handleDetailModalClose = () => {
    setDetailModal(false);
    setDetails(null);
  };

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };
    dispatch(getReviewStart(payload));
    dispatch(getReviewDriverStart());
  }, [currentPage, pageSize]);

  useEffect(() => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
      driverId,
    };
    dispatch(getReviewStart(payload));
  }, [driverId]);

  const handleDriverSelect = (value) => {
    setDriverId(value);
  };

  const columns = [
    {
      title: "Sender Name",
      width: 150,
      ellipsis: true,
      dataIndex: "SenderName",
      key: "SenderName",
      render(text, record) {
        return (
          <p
            style={{ color: "orange", cursor: "pointer" }}
            onClick={() => handleDetailModalOpens(record)}
          >
            {record?.SenderName}
          </p>
        );
      },
    },
    {
      title: "Order Num",
      width: 220,
      dataIndex: "OrderNum",
      key: "OrderNum",
      render(text, record) {
        return (
          <Link to={`/order-bay/grouped-by-order/${record?.OrderId}`}>
            {record?.OrderNum}
          </Link>
        );
      },
    },
    {
      title: "Block Num",
      width: 170,
      dataIndex: "BlockNum",
      key: "BlockNum",
      render(text, record) {
        return (
          <Link to={`/order-block/${record?.BlockId}`}>{record?.BlockNum}</Link>
        );
      },
    },
    {
      title: "Overall Rating ",
      width: 140,
      dataIndex: "OverallRating",
      key: "OverallRating",
      render(text, record) {
        return <Rate disabled value={record?.OverAllRating} />;
      },
    },
  ];

  const submitSearchWithKey = (value, event) => {
    const payload = {
      page: isNaN(currentPage) ? page : currentPage,
      pageSize,
    };

    if (event?.key === "Enter" && searchValue?.length >= 1) {
      dispatch(getReviewSearchStart(searchValue));
      return navigate(`/reviews?search=${searchValue}`);
    }
    if (event?.type === "click" && value?.length >= 1) {
      dispatch(getReviewSearchStart(searchValue));
      return navigate(`/reviews?search=${searchValue}`);
    }
    if (event?.type === "click" && value?.length < 1) {
      dispatch(getReviewStart(payload));
      return navigate(`/reviews`);
    }
  };

  const imageCount = (id, props) => {
    const list = [];
    for (let i = 0; i < props; i++) {
      list?.push(<img src="" alt={i} />);
    }

    const previewImg = [];
    for (let i = 0; i < props; i++) {
      previewImg.push(<Image src={API_URL + `img/group/${id}/${i}`} />);
    }

    var ads = list?.map((ad, i) => {
      return (
        <>
          <Image
            preview={{ visible: false }}
            width={60}
            height={60}
            style={{ cursor: "pointer" }}
            src={API_URL + `img/group/${id}/${i}`}
            alt={i}
            onClick={() => setVisible(true)}
          />
          <div style={{ display: "none" }}>
            <Image.PreviewGroup
              preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
            >
              {previewImg.map((img) => {
                return img;
              })}
            </Image.PreviewGroup>
          </div>
        </>
      );
    });
    return ads;
  };

  const imageCountRecipient = (id, props) => {
    const list = [];
    for (let i = 0; i < props; i++) {
      list?.push(<img src="" alt={i} />);
    }

    const previewImg = [];
    for (let i = 0; i < props; i++) {
      previewImg.push(<Image src={API_URL + `img/group/${id}/${i}`} />);
    }

    var ads = list?.map((ad, i) => {
      return (
        <>
          <Image
            preview={{ visible: false }}
            width={60}
            height={60}
            style={{ cursor: "pointer" }}
            src={API_URL + `img/group/${id}/${i}`}
            alt={i}
            onClick={() => setVisibleRecipient(true)}
          />
          <div style={{ display: "none" }}>
            <Image.PreviewGroup
              preview={{
                visible,
                onVisibleChange: (vis) => setVisibleRecipient(vis),
              }}
            >
              {previewImg.map((img) => {
                return img;
              })}
            </Image.PreviewGroup>
          </div>
        </>
      );
    });
    return ads;
  };

  const onChangeData = (page, pageSize) => {
    navigate(`/reviews?page=${page}`);
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
        <Input.Search
          allowClear
          // disabled={disabled}
          placeholder="Enter search text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(value, event) => submitSearchWithKey(value, event)}
          style={{ width: appDimensions.searchInputWidth }}
        />
        <Select
          allowClear
          defaultValue="Filter By Driver"
          style={{ padding: "10px 0px 10px 10px", width: "50%" }}
          onChange={handleDriverSelect}
          className="filt"
        >
          <Option value="disabled" disabled>
            Filter By Driver
          </Option>
          {reviewDrivers?.map((data) => {
            return <Option value={data.DriverId}>{data.DriverName}</Option>;
          })}
        </Select>
      </div>
      <br />
      {error?.length > 1 && <Result title={error} />}

      {queryType === null ? (
        error === null &&
        review?.Data?.length >= 1 && (
          <>
            {loading ? (
              <TableSkeleton columns={columns} />
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "90%",
                  // flex: 1,
                  width: "100%",
                  flexDirection: "column",
                  overflowX: "auto",
                }}
              >
                <Table
                  bordered
                  size="small"
                  dataSource={review?.Data}
                  columns={columns}
                  rowKey={(row) => row.BlockId}
                  pagination={false}
                />
                <br />
              </div>
            )}

            <Paginations
              data={review}
              currentPage={isNaN(currentPage) ? page : currentPage}
              onChangeData={onChangeData}
            />
          </>
        )
      ) : (
        <>
          {error === null && review?.length >= 1 && (
            <div
              style={{
                display: "flex",
                height: "90%",
                // flex: 1,
                width: "100%",
                flexDirection: "column",
                overflowX: "auto",
              }}
            >
              <Table
                bordered
                size="small"
                dataSource={review}
                columns={columns}
                rowKey={(row) => row.BlockId}
                pagination={false}
                //scroll={{ y: 500 }}
              />
            </div>
          )}
        </>
      )}

      {/* Modal for Details */}
      <Modal
        title={
          <div>
            <p style={{ marginBottom: "0%" }}>Review Details</p>
            <Rate disabled value={details?.OverAllRating} />
          </div>
        }
        visible={detailModal}
        footer={null}
        onCancel={handleDetailModalClose}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Block Number</a>}
                  description={
                    <Link to={`/order-block/${details?.BlockId}`}>
                      {details?.BlockNum}
                    </Link>
                  }
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Order Number</a>}
                  description={
                    <Link
                      to={`/order-bay/grouped-by-order/${details?.OrderId}`}
                    >
                      {details?.OrderNum}
                    </Link>
                  }
                />
              </List.Item>
            </List>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Sender Name</a>}
                  description={details?.SenderName}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Sender Rating</a>}
                  description={
                    <Rate
                      disabled
                      value={
                        details?.SenderReviewInfo?.Rating
                          ? details?.SenderReviewInfo?.Rating
                          : 0
                      }
                    />
                  }
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={<a>Time Stamp</a>}
                description={details?.SenderReviewInfo?.Timestamp}
              />
            </List.Item>
          </List>
        </div>

        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          {imageCount(
            details?.SenderReviewInfo?.AttachmentImageId,
            details?.SenderReviewInfo?.AttachmentImageCount
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Sender Comment</a>}
                  description={
                    details?.SenderReviewInfo?.Comment
                      ? details?.SenderReviewInfo?.Comment
                      : "N/A"
                  }
                />
              </List.Item>
            </List>
          </div>
        </div>
        <Divider />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Receiver Name</a>}
                  description={details?.RecipientReviewInfo?.ReviewName}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Receiver Rating</a>}
                  description={
                    <Rate
                      disabled
                      value={
                        details?.RecipientReviewInfo?.Rating
                          ? details?.RecipientReviewInfo?.Rating
                          : 0
                      }
                    />
                  }
                />
              </List.Item>
            </List>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Driver Name</a>}
                  description={details?.RecipientReviewInfo?.DriverName}
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Driver Number</a>}
                  description={details?.RecipientReviewInfo?.DriverNumber}
                />
              </List.Item>
            </List>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          {imageCountRecipient(
            details?.RecipientReviewInfo?.AttachmentImageId,
            details?.RecipientReviewInfo?.AttachmentImageCount
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Receiver Comment</a>}
                  description={
                    details?.RecipientReviewInfo?.Comment
                      ? details?.RecipientReviewInfo?.Comment
                      : "N/A"
                  }
                />
              </List.Item>
            </List>
          </div>
          <div style={{ width: "50%" }}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={<a>Time Stamp</a>}
                  description={details?.RecipientReviewInfo?.Timestamp}
                />
              </List.Item>
            </List>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Review;
