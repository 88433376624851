import { Space } from "antd";
import React from "react";
import Address, { IAddressProps } from "../../../components/form/Address";
import LabeledNode from "../../../components/utils/LabeledNode";
import RowList from "../../../components/utils/RowList";
import useScreenSize from "../../../hooks/useScreenSize";

export interface IOrderItemSenderOrRecipientDetailsProps extends IAddressProps {
  isSender?: boolean;
  formattedAddress?: string;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
}

const OrderItemSenderOrRecipientDetails: React.FC<
  IOrderItemSenderOrRecipientDetailsProps
> = (props) => {
  const { isSender, name, emailAddress, phoneNumber, formattedAddress } = props;
  const { isSmallScreen } = useScreenSize();
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <RowList span={isSmallScreen ? 24 : 12}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label={isSender ? "Sender's Name" : "Reciever's Name"}
          node={name}
        />
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Email Address"
          node={emailAddress}
        />
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Phone Number"
          node={phoneNumber}
        />
      </RowList>
      {formattedAddress ? (
        <LabeledNode
          nodeIsText
          direction="vertical"
          label={isSender ? "Sender's Address" : "Receiver's Address"}
          labelFontSize={16}
          node={formattedAddress}
        />
      ) : (
        <Address {...props} />
      )}
    </Space>
  );
};

export default OrderItemSenderOrRecipientDetails;
