import { useRequest } from "ahooks";
import React from "react";
import EmployeeAPI from "../../api/endpoints/employee";
import PageError from "../../components/utils/PageError";
import PageLoading from "../../components/utils/PageLoading";
import { IOrderBlock } from "../../definitions/orderBlock";
import OrderBlockDriverDetails from "./OrderBlockDriverDetails";

export interface IOrderBlockDriverDetailsContainerProps {
  block: IOrderBlock;
}

const OrderBlockDriverDetailsContainer: React.FC<
  IOrderBlockDriverDetailsContainerProps
> = (props) => {
  const { block } = props;
  const fetchDriver = React.useCallback(async () => {
    if (!block.AssignedDriverId) {
      return undefined;
    }

    return await EmployeeAPI.getEmployeeInfo({
      employeeId: block.AssignedDriverId,
    });
  }, [block]);

  const fetchDriverPaymentAcknowledger = React.useCallback(async () => {
    if (!block.DriverPaymentAcknowledgedBy) {
      return undefined;
    }

    return await EmployeeAPI.getEmployeeInfo({
      employeeId: block.DriverPaymentAcknowledgedBy,
    });
  }, [block]);

  const fetchDriverResult = useRequest(fetchDriver);
  const fetchDriverPaymentAcknowledgerResult = useRequest(
    fetchDriverPaymentAcknowledger
  );

  if (fetchDriverResult.error || fetchDriverPaymentAcknowledgerResult.error) {
    return (
      <PageError
        messageText={
          fetchDriverResult.error?.message ||
          fetchDriverPaymentAcknowledgerResult.error?.message
        }
        actions={[
          {
            text: "Reload",
            onClick: fetchDriverResult.error
              ? fetchDriverResult.run
              : fetchDriverPaymentAcknowledgerResult.run,
          },
        ]}
      />
    );
  }

  if (
    fetchDriverResult.loading ||
    fetchDriverPaymentAcknowledgerResult.loading
  ) {
    return <PageLoading />;
  }

  return (
    <OrderBlockDriverDetails
      block={block}
      driver={fetchDriverResult.data}
      driverPaymentAcknowledgedBy={fetchDriverPaymentAcknowledgerResult.data}
    />
  );
};

export default OrderBlockDriverDetailsContainer;
