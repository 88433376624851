import {
  AlertOutlined,
  AuditOutlined,
  DollarOutlined,
  ExceptionOutlined,
  FileExcelOutlined,
  FlagOutlined,
  LogoutOutlined,
  ProjectOutlined,
  SettingOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/css";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Avatar, Dropdown, Empty, Menu, Space, Typography } from "antd";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import { appLoggedInPaths } from "../../global/paths";
import { API_URL } from "../../globalVariable";
import { loadUserProfileStart } from "../../redux/ProfileRedux/profileAction";
import "./layout.css";

const classes = {
  header: css({ background: "white !important" }),
  headerContent: css({
    width: "100%",
    height: "64px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgb(240, 240, 240)",
    padding: "0px 24px",
  }),
  headerContentTitle: css({ marginBottom: "0px !important" }),
  headerContentExtra: css({
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  }),
  root: css({
    display: "flex",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }),
  body: css({
    display: "flex",
    flex: 1,
    height: "100%",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  }),
  innerBody: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    overflow: "auto",
  }),
  innerBody2: css({ display: "flex", padding: "32px 24px", height: "100%" }),
  menu: css({
    width: "100%",
    backgroundColor: "inherit !important",
    color: "white !important",

    "& .ant-menu-item a": {
      color: "white",
    },

    "& .ant-menu-item-selected a": {
      color: "rgba(255, 127, 80, 1)",
    },
  }),
  navOuterWrapper: css({
    height: "100%",
    backgroundColor: "rgba(15, 113, 115, 1) !important",
    color: "white !important",
    display: "flex",
    flexDirection: "column",
    width: "220px",
  }),
  navInnerWrapper: css({
    flex: 1,
  }),
  logoWrapper: css({
    height: "64px",
    borderBottom: "1px solid rgb(240, 240, 240)",
    borderRight: "1px solid rgb(240, 240, 240)",
  }),
};

const LayOut = ({ children }) => {
  const logOut = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    return window.location.reload();
  };

  const path = window.location.pathname;
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.profiles);

  useEffect(() => {
    dispatch(loadUserProfileStart());
  }, [dispatch]);

  const menu = (
    <Menu className="notification">
      <Empty />
    </Menu>
  );

  const logOutFunction = () => {
    swal({
      title: "Action",
      text: "Do you really want to log out?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        logOut();
      } else {
      }
    });
  };

  const userMenuActions = (
    <Menu style={{ width: "160px" }}>
      <Menu.Item key="/userProfile" icon={<UserOutlined />}>
        <NavLink to="/userProfile">
          <span>Profile</span>
        </NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="/logOut"
        icon={<LogoutOutlined />}
        onClick={() => logOutFunction()}
      >
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  const menuNode = (
    <Menu defaultSelectedKeys={[path]} className={classes.menu}>
      <Menu.Item key="/dashboard" icon={<DashboardIcon />}>
        <NavLink to="/dashboard">
          <span>Dashboard</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key={appLoggedInPaths.orderBay} icon={<ExceptionOutlined />}>
        <NavLink to={appLoggedInPaths.orderBay}>
          <span>Order Bay</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/orderBlock" icon={<ExceptionOutlined />}>
        <NavLink to={appLoggedInPaths.orderBlock}>
          <span>Order Block</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/defects" icon={<FileExcelOutlined />}>
        <NavLink to="/defects">
          <span>Defects</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/tracking" icon={<WomanOutlined />}>
        <NavLink to="/tracking">
          <span>Tracking</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/reviews" icon={<FlagOutlined />}>
        <NavLink to="/reviews">
          <span>Review</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/payment" icon={<DollarOutlined />}>
        <NavLink to="/payment">
          <span>Payment</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/employee" icon={<UsergroupAddOutlined />}>
        <NavLink to="/employee">
          <span>Employee</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/tickets" icon={<ProjectOutlined />}>
        <NavLink to="/tickets">
          <span>Tickets</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/policy" icon={<SolutionOutlined />}>
        <NavLink to="/policy">
          <span>Group Policy</span>
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="/marketPlace" icon={<ShopOutlined />}>
                <NavLink to="/marketPlace">
                    <span>Market Place</span>
                </NavLink>
            </Menu.Item> */}
      <Menu.Item key="/audits" icon={<AuditOutlined />}>
        <NavLink to="/audits">
          <span>Audit</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/userProfile" icon={<UserOutlined />}>
        <NavLink to="/userProfile">
          <span> User Profile</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/CompanyProfile" icon={<UserOutlined />}>
        <NavLink to="/companyProfile">
          <span>Company Profile</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="/settings" icon={<SettingOutlined />}>
        <NavLink to="/settings">
          <span>Settings</span>
        </NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="/logOut"
        icon={<LogoutOutlined />}
        onClick={() => logOutFunction()}
      >
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  const headerNode = (
    <div className={classes.headerContent}>
      <Typography.Title level={4} className={classes.headerContentTitle}>
        {path === "/dashboard"
          ? "Dashboard"
          : path === "/marketPlace"
          ? "Market Place"
          : path === "/audits"
          ? "Audits"
          : path === "/employee"
          ? "Employees"
          : path === "/tracking"
          ? "Tracking"
          : path === "/reviews"
          ? "Reviews"
          : path.includes("/defects")
          ? "Defects"
          : path.includes("/payment")
          ? "Payment"
          : path === "/userProfile"
          ? "User Profile"
          : path === "/companyProfile"
          ? "Company Profile"
          : path === "/drivers"
          ? "Drivers"
          : path.includes(appLoggedInPaths.orderBay)
          ? "Order Bay"
          : path.includes(appLoggedInPaths.orderBlock)
          ? "Order Block"
          : path === "/policy"
          ? "Group Policy"
          : path.includes("/ticket/search")
          ? "Ticket Search"
          : path.includes("/order/search")
          ? "Order Search"
          : path.includes("/tickets")
          ? "Tickets"
          : path === "/settings"
          ? "Settings"
          : ""}
      </Typography.Title>
      <div className={classes.headerContentExtra}>
        <Space size="large" align="center">
          <Dropdown overlay={menu} trigger={["click"]}>
            <AlertOutlined />
          </Dropdown>
          <Typography.Text>
            Hello,{" "}
            <Typography.Text strong>{userProfile?.FullName}</Typography.Text>
          </Typography.Text>
          <Dropdown overlay={userMenuActions} trigger={["click"]}>
            <Avatar
              icon={<UserOutlined />}
              src={API_URL + `img/default/${userProfile?.EmployeeId}/50/50/p`}
              alt="Your avatar"
              shape="circle"
              style={{ cursor: "pointer" }}
            />
          </Dropdown>
        </Space>
      </div>
    </div>
  );

  const node = (
    <div className={classes.root}>
      <div className={classes.navOuterWrapper}>
        <div className={classes.logoWrapper} />
        <div className={classes.navInnerWrapper}>{menuNode}</div>
      </div>
      <div className={classes.body}>
        {headerNode}
        <div className={classes.innerBody}>
          <div className={classes.innerBody2}>{children}</div>
        </div>
      </div>
    </div>
  );

  return node;
};

export default LayOut;
