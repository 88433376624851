import { Alert, Form, Radio, Select, Space, Switch } from "antd";
import { FormikProps } from "formik";
import { invert } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useRequest } from "ahooks";
import SettingsAPI from "../../../api/endpoints/settings";
import FormItemError from "../../../components/utils/FormItemError";
import { IOrderInput } from "../../../definitions/orderBay";
import { loadBranchStart } from "../../../redux/SettingsRedux/settingAction";
import { IReduxState, IReduxStateSettings } from "../../../redux/types";

const optionsToKeyMap = {
  canDispatcher_DropPackageWithNeighbor: 1,
  canDispatcher_HidePackageInASecureArea: 2,
  canDispatcher_ReturnPackageToNearestBranchIfNotAround: 3,
};

const reverseOptionsToKeyMap = invert(optionsToKeyMap);

export interface IOrderItemDeliveryCheckListInputProps {
  loading?: boolean;
  orgId?: string;
  formik: FormikProps<Omit<IOrderInput, "recipientInfo">>;
}

const OrderItemDeliveryCheckListInput: React.FC<
  IOrderItemDeliveryCheckListInputProps
> = (props) => {
  const { loading, formik, orgId } = props;
  const dispatch = useDispatch();
  const getBranchesByOrgId = React.useCallback(async () => {
    if (orgId) {
      return await SettingsAPI.getOrderFormBranchLocations({ orgId });
    }
  }, [orgId]);

  const customerPageBranchesByOrderId = useRequest(getBranchesByOrgId);
  const { branch } = useSelector<IReduxState, IReduxStateSettings>(
    (state) => state.settings
  );

  useEffect(() => {
    if (!orgId) {
      dispatch(loadBranchStart());
    }
  }, [dispatch]);

  const branches = customerPageBranchesByOrderId.data || branch || [];
  const {
    deliveryCheckListInfo,
    recipientPickingUpAtNearestBranch,
    recipientPickingUpAtNearestBranchId,
  } = formik.values;

  const checkListError = formik.values.deliveryCheckListInfo;
  const checkListTouched = formik.touched.deliveryCheckListInfo;
  const pickupAtNearestBranchNode = (
    <Form.Item label="Recipient Picking Up At Nearest Branch" labelAlign="left">
      <Switch
        checked={recipientPickingUpAtNearestBranch}
        onChange={(choice) => {
          formik.setFieldValue("recipientPickingUpAtNearestBranch", choice);

          if (choice) {
            formik.setFieldValue("deliveryCheckListInfo", {
              returnPackageToNearestBranchId: null,
            });
          } else {
            formik.setFieldValue("recipientPickingUpAtNearestBranchId", null);
            formik.setFieldValue("deliveryCheckListInfo", {
              canDispatcher_ReturnPackageToNearestBranchIfNotAround: true,
              returnPackageToNearestBranchId: null,
            });
          }
        }}
        disabled={loading}
      />
    </Form.Item>
  );

  const nearestBranchNode = recipientPickingUpAtNearestBranch && (
    <Form.Item
      label="Package Nearest Branch"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={
        formik.touched.recipientPickingUpAtNearestBranchId && (
          <FormItemError
            message={formik.errors.recipientPickingUpAtNearestBranchId}
          />
        )
      }
    >
      <Select
        placeholder="Package Nearest Branch"
        // @ts-ignore
        value={recipientPickingUpAtNearestBranchId}
        onChange={(branch) =>
          formik.setFieldValue("recipientPickingUpAtNearestBranchId", branch)
        }
        onBlur={formik.handleBlur}
        disabled={loading}
        loading={customerPageBranchesByOrderId.loading}
      >
        {branches?.map((data) => {
          return (
            <Select.Option value={data.Id}>{data.BranchName}</Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const checkListNode = (
    <Form.Item>
      <Radio.Group
        onChange={(evt) =>
          formik.setFieldValue("deliveryCheckListInfo", {
            [reverseOptionsToKeyMap[evt.target.value]]: true,
          })
        }
        value={
          deliveryCheckListInfo.canDispatcher_DropPackageWithNeighbor
            ? optionsToKeyMap.canDispatcher_DropPackageWithNeighbor
            : deliveryCheckListInfo.canDispatcher_HidePackageInASecureArea
            ? optionsToKeyMap.canDispatcher_HidePackageInASecureArea
            : deliveryCheckListInfo.canDispatcher_ReturnPackageToNearestBranchIfNotAround
            ? optionsToKeyMap.canDispatcher_ReturnPackageToNearestBranchIfNotAround
            : undefined
        }
        disabled={loading}
      >
        <Space direction="vertical">
          <Radio
            value={optionsToKeyMap.canDispatcher_DropPackageWithNeighbor}
            disabled={loading}
          >
            Can Dispatcher Drop Package With Neighbor
          </Radio>
          <Radio
            value={optionsToKeyMap.canDispatcher_HidePackageInASecureArea}
            disabled={loading}
          >
            Can Dispatcher Hide Package In A Secure Area
          </Radio>
          <Radio
            value={
              optionsToKeyMap.canDispatcher_ReturnPackageToNearestBranchIfNotAround
            }
            disabled={loading}
          >
            Can Dispatcher Return Package To Nearest Branch If Not Around
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );

  const returnToNearestBranchNode =
    deliveryCheckListInfo.canDispatcher_ReturnPackageToNearestBranchIfNotAround && (
      <Form.Item
        label="Recipient Nearest Branch"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        help={
          checkListTouched?.returnPackageToNearestBranchId &&
          checkListError?.returnPackageToNearestBranchId && (
            <FormItemError
              message={checkListError?.returnPackageToNearestBranchId}
            />
          )
        }
      >
        <Select
          placeholder="Recipient Nearest Branch"
          // value is string | null, so ts-ignore is okay
          // @ts-ignore
          value={deliveryCheckListInfo.returnPackageToNearestBranchId}
          onChange={(branch) =>
            formik.setFieldValue(
              "deliveryCheckListInfo.returnPackageToNearestBranchId",
              branch
            )
          }
          onBlur={formik.handleBlur}
          disabled={loading}
          loading={customerPageBranchesByOrderId.loading}
        >
          {branches?.map((data) => {
            return (
              <Select.Option value={data.Id}>{data.BranchName}</Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    );

  return (
    <div>
      {customerPageBranchesByOrderId.error && (
        <Alert
          message={customerPageBranchesByOrderId.error}
          type="error"
          style={{ marginBottom: "16px" }}
        />
      )}
      <div>
        {pickupAtNearestBranchNode}
        {nearestBranchNode}
      </div>
      {!recipientPickingUpAtNearestBranch && (
        <div>
          {checkListNode}
          {returnToNearestBranchNode}
        </div>
      )}
    </div>
  );
};

export default OrderItemDeliveryCheckListInput;
