export const LOAD_STATUS_TYPE_START = "LOAD_STATUS_TYPE_START";
export const LOAD_STATUS_TYPE_SUCCESS = "LOAD_STATUS_TYPE_SUCCESS";
export const LOAD_STATUS_TYPE_ERROR = "LOAD_STATUS_TYPE_ERROR";

export const LOAD_PACKAGE_SIZE_START = "LOAD_PACKAGE_SIZE_START";
export const LOAD_PACKAGE_SIZE_SUCCESS = "LOAD_PACKAGE_SIZE_SUCCESS";
export const LOAD_PACKAGE_SIZE_ERROR = "LOAD_PACKAGE_SIZE_ERROR";

export const LOAD_PAID_OPTIONS_START = "LOAD_PAID_OPTIONS_START";
export const LOAD_PAID_OPTIONS_SUCCESS = "LOAD_PAID_OPTIONS_SUCCESS";
export const LOAD_PAID_OPTIONS_ERROR = "LOAD_PAID_OPTIONS_ERROR";

export const LOAD_PACKAGE_STATUS_START = "LOAD_PACKAGE_STATUS_START";
export const LOAD_PACKAGE_STATUS_SUCCESS = "LOAD_PACKAGE_STATUS_SUCCESS";
export const LOAD_PACKAGE_STATUS_ERROR = "LOAD_PACKAGE_STATUS_ERROR";

export const LOAD_CURRENCIES_START = "LOAD_CURRENCIES_START";
export const LOAD_CURRENCIES_SUCCESS = "LOAD_CURRENCIES_SUCCESS";
export const LOAD_CURRENCIES_ERROR = "LOAD_CURRENCIES_ERROR";

export const LOAD_BLOCK_PRIORITY_START = "LOAD_BLOCK_PRIORITY_START";
export const LOAD_BLOCK_PRIORITY_SUCCESS = "LOAD_BLOCK_PRIORITY_SUCCESS";
export const LOAD_BLOCK_PRIORITY_ERROR = "LOAD_BLOCK_PRIORITY_ERROR";

export const LOAD_BLOCK_TYPE_START = "LOAD_BLOCK_TYPE_START";
export const LOAD_BLOCK_TYPE_SUCCESS = "LOAD_BLOCK_TYPE_SUCCESS";
export const LOAD_BLOCK_TYPE_ERROR = "LOAD_BLOCK_TYPE_ERROR";

export const LOAD_BLOCK_TIME_TYPE_START = "LOAD_BLOCK_TIME_TYPE_START";
export const LOAD_BLOCK_TIME_TYPE_SUCCESS = "LOAD_BLOCK_TIME_TYPE_SUCCESS";
export const LOAD_BLOCK_TIME_TYPE_ERROR = "LOAD_BLOCK_TIME_TYPE_ERROR";

export const LOAD_TICKET_PRIORITY_START = "LOAD_TICKET_PRIORITY_START";
export const LOAD_TICKET_PRIORITY_SUCCESS = "LOAD_TICKET_PRIORITY_SUCCESS";
export const LOAD_TICKET_PRIORITY_ERROR = "LOAD_TICKET_PRIORITY_ERROR";

export const LOAD_WEIGHT_UNIT_START = "LOAD_WEIGHT_UNIT_START";
export const LOAD_WEIGHT_UNIT_SUCCESS = "LOAD_WEIGHT_UNIT_SUCCESS";
export const LOAD_WEIGHT_UNIT_ERROR = "LOAD_WEIGHT_UNIT_ERROR";

export const LOAD_ORDER_CATEGORY_START = "LOAD_ORDER_CATEGORY_START";
export const LOAD_ORDER_CATEGORY_SUCCESS = "LOAD_ORDER_CATEGORY_SUCCESS";
export const LOAD_ORDER_CATEGORY_ERROR = "LOAD_ORDER_CATEGORY_ERROR";

export const LOAD_TICKET_STATUS_START = "LOAD_TICKET_STATUS_START";
export const LOAD_TICKET_STATUS_SUCCESS = "LOAD_TICKET_STATUS_SUCCESS";
export const LOAD_TICKET_STATUS_ERROR = "LOAD_TICKET_STATUS_ERROR";

export const LOAD_TICKET_RELATED_SERVICE_START =
  "LOAD_TICKET_RELATED_SERVICE_START";
export const LOAD_TICKET_RELATED_SERVICE_SUCCESS =
  "LOAD_TICKET_RELATED_SERVICE_SUCCESS";
export const LOAD_TICKET_RELATED_SERVICE_ERROR =
  "LOAD_TICKET_RELATED_SERVICE_ERROR";

export const LOAD_COUNTRY_CODE_START = "LOAD_COUNTRY_CODE_START";
export const LOAD_COUNTRY_CODE_SUCCESS = "LOAD_COUNTRY_CODE_SUCCESS";
export const LOAD_COUNTRY_CODE_ERROR = "LOAD_COUNTRY_CODE_ERROR";

export const LOAD_LONG_COUNTRY_CODE_START = "LOAD_LONG_COUNTRY_CODE_START";
export const LOAD_LONG_COUNTRY_CODE_SUCCESS = "LOAD_LONG_COUNTRY_CODE_SUCCESS";
export const LOAD_LONG_COUNTRY_CODE_ERROR = "LOAD_LONG_COUNTRY_CODE_ERROR";

export const LOAD_COUNTRY_CODE_OBJ_START = "LOAD_COUNTRY_CODE_OBJ_START";
export const LOAD_COUNTRY_CODE_OBJ_SUCCESS = "LOAD_COUNTRY_CODE_OBJ_SUCCESS";
export const LOAD_COUNTRY_CODE_OBJ_ERROR = "LOAD_COUNTRY_CODE_OBJ_ERROR";

export const LOAD_COUNTRY_STATE_CITIES_START =
  "LOAD_COUNTRY_STATE_CITIES_START";
export const LOAD_COUNTRY_STATE_CITIES_SUCCESS =
  "LOAD_COUNTRY_STATE_CITIES_SUCCESS";
export const LOAD_COUNTRY_STATE_CITIES_ERROR =
  "LOAD_COUNTRY_STATE_CITIES_ERROR";

export const LOAD_COUNTRY_STATE_START = "LOAD_COUNTRY_STATE_START";
export const LOAD_COUNTRY_STATE_SUCCESS = "LOAD_COUNTRY_STATE_SUCCESS";
export const LOAD_COUNTRY_STATE_ERROR = "LOAD_COUNTRY_STATE_ERROR";

export const LOAD_DEFECT_TYPE_START = "LOAD_DEFECT_TYPE_START";
export const LOAD_DEFECT_TYPE_SUCCESS = "LOAD_DEFECT_TYPE_SUCCESS";
export const LOAD_DEFECT_TYPE_ERROR = "LOAD_DEFECT_TYPE_ERROR";

export const LOAD_TRACKING_TYPE_START = "LOAD_TRACKING_TYPE_START";
export const LOAD_TRACKING_TYPE_SUCCESS = "LOAD_TRACKING_TYPE_SUCCESS";
export const LOAD_TRACKING_TYPE_ERROR = "LOAD_TRACKING_TYPE_ERROR";

export const LOAD_TRACKING_FREQ_START = "LOAD_TRACKING_FREQ_START";
export const LOAD_TRACKING_FREQ_SUCCESS = "LOAD_TRACKING_FREQ_SUCCESS";
export const LOAD_TRACKING_FREQ_ERROR = "LOAD_TRACKING_FREQ_ERROR";

export const LOAD_BLOCK_DELIVERY_TYPE_START = "LOAD_BLOCK_DELIVERY_TYPE_START";
export const LOAD_BLOCK_DELIVERY_TYPE_SUCCESS =
  "LOAD_BLOCK_DELIVERY_TYPE_SUCCESS";
export const LOAD_BLOCK_DELIVERY_TYPE_ERROR = "LOAD_BLOCK_DELIVERY_TYPE_ERROR";

export const LOAD_BLOCK_FILTER_COUNTRY_LIST_START =
  "LOAD_BLOCK_FILTER_COUNTRY_LIST_START";
export const LOAD_BLOCK_FILTER_COUNTRY_LIST_SUCCESS =
  "LOAD_BLOCK_FILTER_COUNTRY_LIST_SUCCESS";
export const LOAD_BLOCK_FILTER_COUNTRY_LIST_ERROR =
  "LOAD_BLOCK_FILTER_COUNTRY_LIST_ERROR";

export const LOAD_BLOCK_FILTER_STATE_LIST_START =
  "LOAD_BLOCK_FILTER_STATE_LIST_START";
export const LOAD_BLOCK_FILTER_STATE_LIST_SUCCESS =
  "LOAD_BLOCK_FILTER_STATE_LIST_SUCCESS";
export const LOAD_BLOCK_FILTER_STATE_LIST_ERROR =
  "LOAD_BLOCK_FILTER_STATE_LIST_ERROR";

export const LOAD_BLOCK_FILTER_CITY_LIST_START =
  "LOAD_BLOCK_FILTER_CITY_LIST_START";
export const LOAD_BLOCK_FILTER_CITY_LIST_SUCCESS =
  "LOAD_BLOCK_FILTER_CITY_LIST_SUCCESS";
export const LOAD_BLOCK_FILTER_CITY_LIST_ERROR =
  "LOAD_BLOCK_FILTER_CITY_LIST_ERROR";

export const LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_START =
  "LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_START";
export const LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_SUCCESS =
  "LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_SUCCESS";
export const LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_ERROR =
  "LOAD_ORDER_BLOCKS_AVAILABLE_DRIVERS_ERROR";
