import { combineReducers } from "redux";
import { getAuditReducer } from "./AuditRedux/auditReducer";
import {EmployeeReducers} from "./EmployeeRedux/employeeReducer";
import { PolicyReducers } from "./PolicyRedux/policyReducer";
import {MovebotPolicyReducers} from "./MovebotPolicyAndPermissionRedux/movebotReducer"
import { SettingReducers } from "./SettingsRedux/settingReducer";
import { ProfileReducers } from "./ProfileRedux/profileReducer";
import { TicketReducers } from "./TicketRedux/ticketReducer"; 
import { ResourcesReducers } from "./ResourcesRedux/ResourcesReducer"; 
import { DefectsReducer } from "./DefectRedux/DefectReducer";
import { TrackingReducer } from "./TrackingRedux/TrackingReducer";
import { ReviewReducer } from "./ReviewRedux/ReviewReducer";
import { paymentReducer } from "./PaymentRedux/reducer";
import { dashboardReducer } from "./DashboardRedux/reducer";


const rootReducer = combineReducers({
    audits: getAuditReducer,
    employees: EmployeeReducers,
    policies: PolicyReducers,
    movebotActions: MovebotPolicyReducers,
    settings: SettingReducers,
    profiles: ProfileReducers,
    tickets: TicketReducers, 
    resources: ResourcesReducers, 
    defects: DefectsReducer,
    trackings: TrackingReducer,
    reviews: ReviewReducer,
    payments: paymentReducer,
    dashboards: dashboardReducer
})


export default  rootReducer
