import * as types from "./actionType"



export const getPaymentStart = (payload) => ({
    type: types.GET_PAYMENT_START,
    payload
})

export const getPaymentSuccess = (payment) => ({
    type: types.GET_PAYMENT_SUCCESS,
    payload: payment,
})

export const getPaymentError = (error) => ({
    type: types.GET_PAYMENT_ERROR,
    payload: error
})


export const getDriverPaymentStart = () => ({
    type: types.GET_DRIVER_FILTER_START,
})

export const getDriverPaymentSuccess = (payment) => ({
    type: types.GET_DRIVER_FILTER_SUCCESS,
    payload: payment,
})

export const getDriverPaymentError = (error) => ({
    type: types.GET_DRIVER_FILTER_ERROR,
    payload: error
})