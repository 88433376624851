import Cookies from "js-cookie";
import { call, delay, put } from "redux-saga/effects";
import {
  HTTP_SUCCESS_STATUS_CODE,
  HTTP_UNAUTHORIZED_STATUS_TEXT,
} from "../api/invokeREST";
import { appConstants } from "../global/constants";

export function handleUnauthorized() {
  Cookies.remove(appConstants.accessToken);
  Cookies.remove(appConstants.refreshToken);
  window.location.reload();
}

const DELAY_MILLISECONDS = 500;

// Expects api function to use Axios
export function* runSagaActionWithAPIAsync01(
  api: () => Promise<any>,
  successSaga: (...args: any[]) => any,
  errorSaga: (...args: any[]) => any
) {
  try {
    // @ts-ignore
    const response = yield call(api);
    if (response.status === HTTP_SUCCESS_STATUS_CODE) {
      yield delay(DELAY_MILLISECONDS);
      yield put(successSaga(response.data));
    } else if (response.statusText === HTTP_UNAUTHORIZED_STATUS_TEXT) {
      console.log(response);
      console.error(response);
      handleUnauthorized();
      return;
    }
  } catch (error) {
    yield put(errorSaga(error));
    return;
  }
}

// Expects api to use invokeREST from /api folder
export function* runSagaActionWithAPIAsync02(
  api: () => Promise<any>,
  successSaga: (...args: any[]) => any,
  errorSaga: (...args: any[]) => any
) {
  try {
    // @ts-ignore
    const response = yield call(api);
    yield delay(DELAY_MILLISECONDS);
    yield put(successSaga(response));
  } catch (error) {
    yield put(errorSaga(error));
    return;
  }
}

export function createActionFns<
  StartActionType extends string,
  SuccessActionType extends string,
  ErrorActionType extends string
>(actions: [StartActionType, SuccessActionType, ErrorActionType]) {
  return {
    startAction: () => ({
      type: actions[0],
    }),

    successAction: (payload: any) => ({ payload, type: actions[1] }),

    errorAction: (error: any) => ({
      type: actions[2],
      payload: error,
    }),
  };
}
