import React from "react";
import { Space, Divider, Typography, Row, Col } from "antd";
import { IParcel } from "../../../definitions/orderBay";
import ParcelDetails from "./ParcelDetails";
import ParcelTransactionInfo from "./ParcelTransactionInfo";
import OrdersPagedByOrderTable from "../order/OrdersPagedByOrderTable";
import ParcelActionsButton from "./ParcelActionsButton";

export interface IParcelProps {
  parcel: IParcel;
  onCompleteUpdateOrder: () => void;
  onCompleteDeleteOrder: () => void;
  onCompleteDeleteParcel: () => void;
}

const Parcel: React.FC<IParcelProps> = (props) => {
  const {
    parcel,
    onCompleteDeleteOrder,
    onCompleteUpdateOrder,
    onCompleteDeleteParcel,
  } = props;

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Row>
        <Col span={18}>
          <Typography.Title level={5}>{parcel.ParcelNumber}</Typography.Title>
        </Col>
        <Col span={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ParcelActionsButton
              parcelId={parcel.ParcelId}
              onCompleteDelete={onCompleteDeleteParcel}
              // TODO: tell Kingsley to add payOnDelivery to parcel
              isPayOnDelivery={false}
              canAcknowledgeForm={!parcel.ParcelAlreadyAcknowledged}
            />
          </div>
        </Col>
      </Row>
      <Divider orientation="left">Parcel Details</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <ParcelDetails parcel={parcel} />
      </div>
      <Divider orientation="left">Parcel Transaction Info</Divider>
      <div style={{ maxWidth: "900px", margin: "auto" }}>
        <ParcelTransactionInfo parcelId={parcel.ParcelId} />
      </div>
      <Divider orientation="left">Order Items</Divider>
      <div
        style={{
          width: "100%",
          overflow: "auto",
          marginBottom: "32px",
        }}
      >
        <OrdersPagedByOrderTable
          data={parcel.OrdersInfo}
          onCompleteDelete={onCompleteDeleteOrder}
          onCompleteUpdate={onCompleteUpdateOrder}
        />
      </div>
    </Space>
  );
};

export default Parcel;
