import * as types from "./actionType";

const initialState = {
  defects: [],
  defectsById: null,
  loading: false,
  error: null,
};

export const DefectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEFECTS_START:
    case types.GET_DEFECTS_BY_ID_START:
    case types.GET_DEFECTS_SEARCH_START:
      // case types.GET_DEFECTS_EXECUTION_START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_DEFECTS_SUCCESS:
    case types.GET_DEFECTS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        defects: action.payload,
      };

    case types.GET_DEFECTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        defectsById: action.payload,
      };

    case types.GET_DEFECTS_ERROR:
    case types.GET_DEFECTS_BY_ID_ERROR:
    case types.GET_DEFECTS_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
