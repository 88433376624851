import { css } from "@emotion/css";
import { Button, Col, Input, Row, Select, Space } from "antd";
import React from "react";
import { appDimensions } from "../../components/utils/theme";
import {
  OrderBlockPriority,
  OrderBlockStatus,
  orderBlockStatusLabelMap,
  OrderBlockType,
} from "../../definitions/orderBlock";
import { IOrderBlockPageFetchExtra } from "./OrderBlockPageRoot";

export interface IOrderBlockPageControlsProps
  extends IOrderBlockPageFetchExtra {
  disabled?: boolean;
  onCreateBlockOrder: () => void;
  onEnterSearchQuery: (query: string) => void;
  onEnterBlockFilter: (input: IOrderBlockPageFetchExtra) => void;
}

const classes = {
  rightControls: css({
    display: "flex",
    justifyContent: "flex-end",
  }),
};

const OrderBlockPageControls: React.FC<IOrderBlockPageControlsProps> = (
  props
) => {
  const {
    disabled,
    priority,
    status,
    type,
    onCreateBlockOrder,
    onEnterSearchQuery,
    onEnterBlockFilter,
  } = props;

  const priorityInputNode = (
    <Select
      allowClear
      disabled={disabled}
      value={priority}
      placeholder="Select block priority"
      onChange={(value) =>
        onEnterBlockFilter({
          type,
          priority: value as OrderBlockPriority,
        })
      }
      style={{ width: appDimensions.optionsWidth }}
    >
      <Select.Option
        key={OrderBlockPriority.Critical}
        value={OrderBlockPriority.Critical}
      >
        {OrderBlockPriority.Critical}
      </Select.Option>
      <Select.Option
        key={OrderBlockPriority.Urgent}
        value={OrderBlockPriority.Urgent}
      >
        {OrderBlockPriority.Urgent}
      </Select.Option>
      <Select.Option
        key={OrderBlockPriority.High}
        value={OrderBlockPriority.High}
      >
        {OrderBlockPriority.High}
      </Select.Option>
      <Select.Option
        key={OrderBlockPriority.Medium}
        value={OrderBlockPriority.Medium}
      >
        {OrderBlockPriority.Medium}
      </Select.Option>
      <Select.Option
        key={OrderBlockPriority.Low}
        value={OrderBlockPriority.Low}
      >
        {OrderBlockPriority.Low}
      </Select.Option>
    </Select>
  );

  const statusInputNode = (
    <Select
      allowClear
      disabled={disabled}
      value={status}
      placeholder="Select block status"
      onChange={(value) =>
        onEnterBlockFilter({
          type,
          status: value as OrderBlockStatus,
        })
      }
      style={{ width: appDimensions.optionsWidth }}
    >
      {Object.keys(OrderBlockStatus).map((key) => (
        <Select.Option value={key} key={key}>
          {orderBlockStatusLabelMap[key as unknown as OrderBlockStatus]}
        </Select.Option>
      ))}
    </Select>
  );

  const typeInputNode = (
    <Select
      allowClear
      disabled={disabled}
      value={type}
      placeholder="Select block type"
      onChange={(value) =>
        onEnterBlockFilter({
          priority,
          type: value as OrderBlockType,
        })
      }
      style={{ width: appDimensions.optionsWidth }}
    >
      <Select.Option value={OrderBlockType.Public} key={OrderBlockType.Public}>
        {OrderBlockType.Public}
      </Select.Option>
      <Select.Option
        value={OrderBlockType.Private}
        key={OrderBlockType.Private}
      >
        {OrderBlockType.Private}
      </Select.Option>
    </Select>
  );

  const searchNode = (
    <Input.Search
      allowClear
      disabled={disabled}
      placeholder="Enter search text"
      onSearch={onEnterSearchQuery}
      style={{ width: appDimensions.searchInputWidth }}
    />
  );

  return (
    <Row wrap>
      <Col span={18}>
        <Space wrap size="middle">
          {priorityInputNode}
          {typeInputNode}
          {statusInputNode}
          {searchNode}
        </Space>
      </Col>
      <Col span={6}>
        <div className={classes.rightControls}>
          <Button type="primary" onClick={() => onCreateBlockOrder()}>
            Create Order Block
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default OrderBlockPageControls;
