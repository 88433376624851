import * as types from "./employeeActionType"


export const loadEmployeeStart = (payload) => ({
    type: types.LOAD_EMPLOYEE_START,
    payload
})

export const loadEmployeeSuccess = (users) => ({
    type: types.LOAD_EMPLOYEE_SUCCESS,
    payload: users
})

export const loadEmployeeError = (error) => ({
    type: types.LOAD_EMPLOYEE_ERROR,
    payload: error
})

export const filterEmployeeStart = (payload) => ({
    type: types.FILTER_EMPLOYEE_START,
    payload
})

export const filterEmployeeSuccess = (users) => ({
    type: types.FILTER_EMPLOYEE_SUCCESS,
    payload: users
})

export const filterEmployeeError = (error) => ({
    type: types.FILTER_EMPLOYEE_ERROR,
    payload: error
})

export const createEmployeeStart = (user) => ({
    type: types.CREATE_EMPLOYEE_START,
    payload: user,
})

export const createEmployeeSuccess = (users) => ({
    type: types.CREATE_EMPLOYEE_SUCCESS,
    payload: users
})

export const createEmployeeError = (error) => ({
    type: types.CREATE_EMPLOYEE_ERROR,
    payload: error
})



export const loadQualifyAdminStart = () => ({
    type: types.LOAD_QUALIFY_ADMIN_START,
})

export const loadQualifyAdminSuccess = (users) => ({
    type: types.LOAD_QUALIFY_ADMIN_SUCCESS,
    payload: users
})

export const loadQualifyAdminError = (error) => ({
    type: types.LOAD_QUALIFY_ADMIN_ERROR,
    payload: error
})