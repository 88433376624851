import React from "react";
import { Input, Form } from "antd";
import { FormikProps } from "formik";
import { IOrderInput } from "../../../definitions/orderBay";


export interface IOrderItemDriverNoteInputProps {
  loading?: boolean;
  formik: FormikProps<Omit<IOrderInput, "recipientInfo">>;
}

const OrderItemDriverNoteInput: React.FC<IOrderItemDriverNoteInputProps> = (
  props
) => {
  const { loading, formik } = props;

  return (
    <div>
      <Form.Item
        label="Note to Driver"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Input.TextArea
          value={formik.values.note}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="note"
          placeholder="Note to Driver"
          autoSize={{ minRows: 3, maxRows: 5 }}
          disabled={loading}
        />
      </Form.Item>
    </div>
  );
};

export default OrderItemDriverNoteInput;
