import {
  IAcknowledgeParcelInput,
  IOrder,
  IOrderTripHistory,
  IParcel,
  IParcelInput,
  IParcelTransactionInfo,
  PackageStatus,
  ParcelOrderStatus,
  ParcelStatus,
} from "../../definitions/orderBay";
import {
  IIdWithReasonInput,
  IPaginatedDataInput,
  IPaginatedDataResult,
} from "../../global/types";
import { API_URL } from "../../globalVariable";
import { invokeEndpoint, invokeEndpointWithAuth } from "../invokeREST";
import { IEndpointMessageResult } from "../types";
import { selectEndpointParam, setEndpointParam } from "../utils";

const orderBayURL = `${API_URL}OrderBay`;
const searchOrdersURL = `${orderBayURL}/SearchOrders`;
const searchItemsOrdersURL = `${orderBayURL}/SearchItemsOrders`;
const pagedByParcelURL = `${orderBayURL}/PagedByParcel`;
const pagedByOrderURL = `${orderBayURL}/PagedByOrder`;
const getTransactionInfoURL = `${orderBayURL}/GetTransactionInfo`;
const getTripHistoryURL = `${orderBayURL}/GetTripHistory`;
const submitOrderURL = `${orderBayURL}/SubmitOrder`;
const submitCustomerOrderURL = `${orderBayURL}/SubmitCustomerOrder`;
const acknowledgeParcelURL = `${orderBayURL}/AcknowledgeParcel`;
const activateOrderURL = `${orderBayURL}/ActivateOrderItem`;
const deactivateOrderURL = `${orderBayURL}/DeactivateOrderItem`;
const acknowledgeOrderPickedByRecipientURL = `${orderBayURL}/AcknowledgedOrderItemPickUpByRecipient`;
const prepareOrderForAnotherTripURL = `${orderBayURL}/PrepareOrderForAnotherTrip`;
const deleteOrderURL = `${orderBayURL}/DeleteOrder`;
const deleteParcelURL = `${orderBayURL}/DeleteParcel`;
const getOrderItemURL = `${orderBayURL}/GetOrderItem`;

export interface IGetOrdersPagedByParcelEndpointParams
  extends IPaginatedDataInput {
  status?: ParcelStatus;
}

export interface IGetOrdersPagedByParcelEndpointResult
  extends IPaginatedDataResult<IParcel> {}

async function getOrdersPagedByParcel(
  props: IGetOrdersPagedByParcelEndpointParams = {}
): Promise<IGetOrdersPagedByParcelEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "status", props.status);
  const endpointURL = selectEndpointParam(pagedByParcelURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetOrdersPagedByParcelEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export interface IGetOrdersPagedByOrderEndpointInput
  extends IPaginatedDataInput {
  parcelOrderStatus?: ParcelOrderStatus;
  packageStatus?: PackageStatus;
}

export interface IGetOrdersPagedByOrderEndpointResult
  extends IPaginatedDataResult<IOrder> {}

async function getOrdersPagedByOrder(
  props: IGetOrdersPagedByOrderEndpointInput = {}
): Promise<IGetOrdersPagedByOrderEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "page_number", props.pageNumber);
  setEndpointParam(params, "page_size", props.pageSize);
  setEndpointParam(params, "parcelOrderStatus", props.parcelOrderStatus);
  setEndpointParam(params, "packageStatus", props.packageStatus);

  const endpointURL = selectEndpointParam(pagedByOrderURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetOrdersPagedByOrderEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export interface ISearchOrdersEndpointInput {
  query: string;
}

export type ISearchOrdersEndpointResult = Array<IParcel>;

async function searchOrders(
  props: ISearchOrdersEndpointInput
): Promise<ISearchOrdersEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "query", props.query);

  const endpointURL = selectEndpointParam(searchOrdersURL, params, props);
  const result = await invokeEndpointWithAuth<ISearchOrdersEndpointResult>({
    path: endpointURL,
    method: "GET",
    // defaultValue: [],
  });

  return result;
}

export interface ISearchItemsOrdersEndpointInput {
  query: string;
}

export type ISearchItemsOrdersEndpointResult = Array<IOrder>;

async function searchItemsOrders(
  props: ISearchItemsOrdersEndpointInput
): Promise<ISearchItemsOrdersEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "query", props.query);

  const endpointURL = selectEndpointParam(searchItemsOrdersURL, params, props);
  const result = await invokeEndpointWithAuth<ISearchItemsOrdersEndpointResult>(
    {
      path: endpointURL,
      method: "GET",
      // defaultValue: [],
    }
  );

  return result;
}

export interface IGetParcelTransactionInfoEndpointInput {
  parcelId: string;
}

export type IGetParcelTransactionInfoEndpointResult = IParcelTransactionInfo;

async function getTransactionInfo(
  props: IGetParcelTransactionInfoEndpointInput
): Promise<IGetParcelTransactionInfoEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "parcelId", props.parcelId);

  const endpointURL = selectEndpointParam(getTransactionInfoURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetParcelTransactionInfoEndpointResult>({
      path: endpointURL,
      method: "GET",
    });

  return result;
}

export interface IGetOrderTripHistoryEndpointInput {
  orderItemId: string;
}

export type IGetOrderTripHistoryEndpointResult = Array<IOrderTripHistory>;

async function getTripHistory(
  props: IGetOrderTripHistoryEndpointInput
): Promise<IGetOrderTripHistoryEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orderItemId", props.orderItemId);
  const endpointURL = selectEndpointParam(getTripHistoryURL, params, props);
  const result =
    await invokeEndpointWithAuth<IGetOrderTripHistoryEndpointResult>({
      path: endpointURL,
      method: "GET",
      defaultValue: [],
    });

  return result;
}

export type ISubmitOrdersEndpointInput = IParcelInput;
export type ISubmitOrdersEndpointResult = IParcel;

async function submitOrders(
  props: ISubmitOrdersEndpointInput
): Promise<ISubmitOrdersEndpointResult> {
  const result = await invokeEndpointWithAuth<ISubmitOrdersEndpointResult>({
    path: submitOrderURL,
    method: "POST",
    data: props,
  });

  return result;
}

export type ISubmitCustomerOrderEndpointInput = {
  orgId: string;
  parcel: IParcelInput;
};

export type ISubmitCustomerOrderEndpointResult = IEndpointMessageResult;

async function submitCustomerOrder(
  props: ISubmitCustomerOrderEndpointInput
): Promise<ISubmitCustomerOrderEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orgId", props.orgId);
  const endpointURL = selectEndpointParam(
    submitCustomerOrderURL,
    params,
    props
  );
  const result = await invokeEndpoint<ISubmitCustomerOrderEndpointResult>({
    path: endpointURL,
    method: "POST",
    data: props.parcel,
  });

  return result;
}

export type IAcknowledgeParcelEndpointInput = IAcknowledgeParcelInput;
export type IAcknowledgeParcelEndpointResult = IEndpointMessageResult;

async function acknowledgeParcel(
  props: IAcknowledgeParcelEndpointInput
): Promise<IAcknowledgeParcelEndpointResult> {
  const result = await invokeEndpointWithAuth<IAcknowledgeParcelEndpointResult>(
    {
      path: acknowledgeParcelURL,
      method: "POST",
      data: props,
    }
  );

  return result;
}

export type IActivateOrderItemEndpointInput = IIdWithReasonInput;
export type IActivateOrderItemEndpointResult = IEndpointMessageResult;

async function activateOrder(
  props: IActivateOrderItemEndpointInput
): Promise<IActivateOrderItemEndpointResult> {
  const result = await invokeEndpointWithAuth<IActivateOrderItemEndpointResult>(
    {
      path: activateOrderURL,
      method: "PUT",
      data: props,
    }
  );

  return result;
}

export type IDeactivateOrderItemEndpointInput = IIdWithReasonInput;
export type IDeactivateOrderItemEndpointResult = IEndpointMessageResult;

async function deactivateOrder(
  props: IDeactivateOrderItemEndpointInput
): Promise<IDeactivateOrderItemEndpointResult> {
  const result =
    await invokeEndpointWithAuth<IDeactivateOrderItemEndpointResult>({
      path: deactivateOrderURL,
      method: "PUT",
      data: props,
    });

  return result;
}

export interface IAcknowledgeOrderPickupByRecipientEndpointInput {
  orderItemId: string;
}

export type IAcknowledgeOrderPickupByRecipientEndpointResult =
  IEndpointMessageResult;

async function acknowledgeOrderPickupByRecipient(
  props: IAcknowledgeOrderPickupByRecipientEndpointInput
): Promise<IAcknowledgeOrderPickupByRecipientEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orderItemId", props.orderItemId);

  const endpointURL = selectEndpointParam(
    acknowledgeOrderPickedByRecipientURL,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IAcknowledgeOrderPickupByRecipientEndpointResult>(
      {
        path: endpointURL,
        method: "GET",
      }
    );

  return result;
}

export interface IPrepareOrderForAnotherTripEndpointInput {
  orderItemId: string;
}

export type IPrepareOrderForAnotherTripEndpointResult = IEndpointMessageResult;

async function prepareOrderForAnotherTrip(
  props: IPrepareOrderForAnotherTripEndpointInput
): Promise<IPrepareOrderForAnotherTripEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orderItemId", props.orderItemId);

  const endpointURL = selectEndpointParam(
    prepareOrderForAnotherTripURL,
    params,
    props
  );
  const result =
    await invokeEndpointWithAuth<IPrepareOrderForAnotherTripEndpointResult>({
      path: endpointURL,
      method: "GET",
    });

  return result;
}

export type IDeleteOrderEndpointInput = IIdWithReasonInput;
export type IDeleteOrderItemEndpointResult = IEndpointMessageResult;

async function deleteOrder(
  props: IDeleteOrderEndpointInput
): Promise<IDeleteOrderItemEndpointResult> {
  const result = await invokeEndpointWithAuth<IDeleteOrderItemEndpointResult>({
    path: deleteOrderURL,
    method: "DELETE",
    data: props,
  });

  return result;
}

export type IDeleteParcelEndpointInput = IIdWithReasonInput;
export type IDeleteParcelItemEndpointResult = IEndpointMessageResult;

async function deleteParcel(
  props: IDeleteParcelEndpointInput
): Promise<IDeleteParcelItemEndpointResult> {
  const result = await invokeEndpointWithAuth<IDeleteParcelItemEndpointResult>({
    path: deleteParcelURL,
    method: "DELETE",
    data: props,
  });

  return result;
}

export interface IGetOrderItemEndpointInput {
  orderId: string;
}

export type IGetOrderItemEndpointResult = IOrder;

async function getOrderItem(
  props: IGetOrderItemEndpointInput
): Promise<IGetOrderItemEndpointResult> {
  const params = new URLSearchParams();
  setEndpointParam(params, "orderId", props.orderId);
  const endpointURL = selectEndpointParam(getOrderItemURL, params, props);
  const result = await invokeEndpointWithAuth<IGetOrderItemEndpointResult>({
    path: endpointURL,
    method: "GET",
  });

  return result;
}

export default class OrderBayAPI {
  static getTripHistory = getTripHistory;
  static getTransactionInfo = getTransactionInfo;
  static searchOrders = searchOrders;
  static searchItemsOrders = searchItemsOrders;
  static getOrdersPagedByOrder = getOrdersPagedByOrder;
  static getOrdersPagedByParcel = getOrdersPagedByParcel;
  static submitOrders = submitOrders;
  static submitCustomerOrder = submitCustomerOrder;
  static acknowledgeParcel = acknowledgeParcel;
  static activateOrder = activateOrder;
  static deactivateOrder = deactivateOrder;
  static deleteOrder = deleteOrder;
  static prepareOrderForAnotherTrip = prepareOrderForAnotherTrip;
  static acknowledgeOrderPickupByRecipient = acknowledgeOrderPickupByRecipient;
  static getOrderItem = getOrderItem;
  static deleteParcel = deleteParcel;
}
