
export const GET_REVIEW_START = "GET_REVIEW_START"
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS"
export const GET_REVIEW_ERROR = "GET_REVIEW_ERROR"


export const GET_REVIEW_SEARCH_START = "GET_REVIEW_SEARCH_START"
export const GET_REVIEW_SEARCH_SUCCESS = "GET_REVIEW_SEARCH_SUCCESS"
export const GET_REVIEW_SEARCH_ERROR = "GET_REVIEW_SEARCH_ERROR"

export const GET_REVIEW_DRIVER_START = "GET_REVIEW_DRIVER_START"
export const GET_REVIEW_DRIVER_SUCCESS = "GET_REVIEW_DRIVER_SUCCESS"
export const GET_REVIEW_DRIVER_ERROR = "GET_REVIEW_DRIVER_ERROR"
