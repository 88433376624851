

export const GET_AUDIT_START = "GET_AUDIT_START"
export const GET_AUDIT_SUCCESS = "GET_AUDIT_SUCCESS"
export const GET_AUDIT_ERROR = "GET_AUDIT_ERROR"


export const GET_AUDIT_REF_START = "GET_AUDIT_REF_START"
export const GET_AUDIT_REF_SUCCESS = "GET_AUDIT_REF_SUCCESS"
export const GET_AUDIT_REF_ERROR = "GET_AUDIT_REF_ERROR"

export const FILTER_AUDIT_BY_TYPE_START = "FILTER_AUDIT_BY_TYPE_START"
export const FILTER_AUDIT_BY_TYPE_SUCCESS = "FILTER_AUDIT_BY_TYPE_SUCCESS"
export const FILTER_AUDIT_BY_TYPE_ERROR = "FILTER_AUDIT_BY_TYPE_ERROR"



export const FILTER_AUDIT_BY_DATE_START = "FILTER_AUDIT_BY_DATE_START"
export const FILTER_AUDIT_BY_DATE_SUCCESS = "FILTER_AUDIT_BY_DATE_SUCCESS"
export const FILTER_AUDIT_BY_DATE_ERROR = "FILTER_AUDIT_BY_DATE_ERROR"