import React from "react";
import { Space, Typography } from "antd";
import { css } from "@emotion/css";
import { ContactsOutlined } from "@ant-design/icons";

export interface ISupportContactProps {
  contactName: string;
  phoneNumber: string;
}

const classes = {
  phoneContainer: css({
    display: "flex",
    width: "100%",
    alignItems: "center",
  }),
  phone: css({
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    marginLeft: "10px",
    alignItems: "center",
    color: "#FF7F50 !important",
  }),
  contactName: css({
    display: "flex",
    alignItems: "center",
  }),
  contactIcon: css({ display: "inline-block", marginRight: "10px" }),
};

const SupportContact: React.FC<ISupportContactProps> = (props) => {
  const { phoneNumber, contactName } = props;
  return (
    <div className={classes.phoneContainer} key={phoneNumber}>
      <Typography.Text type="secondary" className={classes.contactName}>
        <ContactsOutlined className={classes.contactIcon} />
        {contactName}
      </Typography.Text>
      <Typography.Text className={classes.phone}>{phoneNumber}</Typography.Text>
    </div>
  );
};

export default SupportContact;
