import * as yup from "yup";
import React from "react";
import { useFormik } from "formik";
import {
  Input,
  Form,
  Button,
  Alert,
  Typography,
  Modal as ModalComponent,
} from "antd";
import FormItemError from "../utils/FormItemError";
import { appFormControlClasses } from "../utils/theme";

export interface IReasonForm {
  loading?: boolean;
  value?: string;
  error?: string;
  useModal?: boolean;
  title?: string;
  onSubmit: (reason: string) => void;
  onCancel: () => void;
}

const Modal = ModalComponent as React.FC<
  React.ComponentProps<typeof ModalComponent> & { children: React.ReactNode }
>;

const validationSchema = yup.object().shape({
  reason: yup.string().required(),
});

const ReasonForm: React.FC<IReasonForm> = (props) => {
  const { loading, error, value, useModal, title, onSubmit, onCancel } = props;
  const formik = useFormik({
    validationSchema,
    initialValues: { reason: value || "" },
    onSubmit: (values) => onSubmit(values.reason),
  });

  const reasonNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Reason"
      help={
        formik.touched.reason && (
          <FormItemError message={formik.errors.reason} />
        )
      }
    >
      <Input.TextArea
        value={formik.values.reason}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="reason"
        placeholder="Enter reason"
        autoSize={{ minRows: 3 }}
        disabled={loading}
      />
    </Form.Item>
  );

  const contentNode = (
    <React.Fragment>
      {error && (
        <Form.Item>
          <Alert message={error} type="error" />
        </Form.Item>
      )}
      {reasonNode}
    </React.Fragment>
  );

  if (useModal) {
    return (
      <Modal
        visible
        title={title || "Please provide a reason for the action"}
        onOk={formik.submitForm}
        onCancel={onCancel}
        children={<form onSubmit={formik.handleSubmit}>{contentNode}</form>}
      />
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {title && (
        <Form.Item>
          <Typography.Title level={4}>{title}</Typography.Title>
        </Form.Item>
      )}
      {contentNode}
      <Form.Item style={{ marginTop: "32px", marginBottom: "4px" }}>
        <div className={appFormControlClasses.controlRoot}>
          <div
            className={appFormControlClasses.spaceEvenContainer}
            style={{ marginRight: "16px" }}
          >
            <Button htmlType="button" disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
          </div>
          <Button type="primary" disabled={loading} htmlType="submit">
            Submit
          </Button>
        </div>
      </Form.Item>
    </form>
  );
};

export default ReasonForm;
