import * as types from "./settingActionType"


export const loadSettingPaymentStart = () => ({
    type: types.LOAD_SETTINGS_PAYMENT_START,
})

export const loadSettingPaymentSuccess = (payment) => ({
    type: types.LOAD_SETTINGS_PAYMENT_SUCCESS,
    payload: payment
})

export const loadSettingPaymentError = (error) => ({
    type: types.LOAD_SETTINGS_PAYMENT_ERROR,
    payload: error
})

export const loadPodStart = (payload) => ({
    type: types.LOAD_POD_START,
    payload
})

export const loadPodSuccess = (payment) => ({
    type: types.LOAD_POD_SUCCESS,
    payload: payment
})

export const loadPodError = (error) => ({
    type: types.LOAD_POD_ERROR,
    payload: error
})


export const loadSettingTeamStart = () => ({
    type: types.LOAD_SETTINGS_TEAM_START,
})

export const loadSettingTeamSuccess = (team) => ({
    type: types.LOAD_SETTINGS_TEAM_SUCCESS,
    payload: team
})

export const loadSettingTeamError = (error) => ({
    type: types.LOAD_SETTINGS_TEAM_ERROR,
    payload: error
})

export const loadBranchStart = () => ({
    type: types.LOAD_BRANCH_START,
})

export const loadBranchSuccess = (branch) => ({
    type: types.LOAD_BRANCH_SUCCESS,
    payload: branch
})

export const loadBranchError = (error) => ({
    type: types.LOAD_BRANCH_ERROR,
    payload: error
})