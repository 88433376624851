import { Form } from "antd";
import React from "react";
import { IOrderInput, IRecipientInput } from "../../../definitions/orderBay";
import OrderItemSenderOrRecipientDetails from "../order/OrderItemRecipientDetails";
import OrderItemListForm from "./OrderItemListForm";

export interface IRecipientWithOrdersEntry {
  recipient: IRecipientInput;
  orders: Array<Omit<IOrderInput, "recipientInfo">>;
}

export interface IOrderItemsByRecipientProps {
  isPreview?: boolean;
  orgId?: string;
  loading?: boolean;
  entry: IRecipientWithOrdersEntry;
  onUpdate: (entry: IRecipientWithOrdersEntry) => void;
}

const OrderItemsByRecipient: React.FC<IOrderItemsByRecipientProps> = (
  props
) => {
  const { isPreview, entry, loading, onUpdate, orgId } = props;
  return (
    <div>
      <Form.Item>
        <OrderItemSenderOrRecipientDetails
          {...entry.recipient}
          {...entry.recipient.address}
        />
      </Form.Item>
      <OrderItemListForm
        isPreview={isPreview}
        loading={loading}
        orders={entry.orders || []}
        onAddOrder={(order) =>
          onUpdate({ ...entry, orders: [...entry.orders, order] })
        }
        onDeleteOrder={(index) => {
          const orders = [...entry.orders];
          orders.splice(index, 1);
          onUpdate({ ...entry, orders });
        }}
        onUpdateOrder={(index, order) => {
          const orders = [...entry.orders];
          orders[index] = order;
          onUpdate({ ...entry, orders });
        }}
        orgId={orgId}
      />
    </div>
  );
};

export default OrderItemsByRecipient;
