import { css } from "@emotion/css";
import { Col, Row } from "antd";
import LabeledNode from "../../../components/utils/LabeledNode";

const classes = {
  root: css({
    maxWidth: "720px",
    margin: "auto",
  }),
};

function OrderBaySenderDetails(props) {
  const { sender, isSmallScreen } = props;
  const midSpan = isSmallScreen ? 24 : 12;
  return (
    <div className={classes.root}>
      <Row wrap gutter={[16, 16]}>
        <Col span={24}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Name"
            node={sender.name}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={24}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Email Address"
            node={sender.emailAddress}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Country Code"
            node={sender.countryCode}
          />
        </Col>
        <Col span={12}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Phone Number"
            node={sender.phoneNumber}
          />
        </Col>
        <Col span={24}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Street Address"
            node={sender.address.street}
          />
        </Col>
        <Col span={midSpan}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Country"
            node={sender.address.country}
          />
        </Col>
        <Col span={midSpan}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="State"
            node={sender.address.state}
          />
        </Col>
        <Col span={midSpan}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="City"
            node={sender.address.city}
          />
        </Col>
        <Col span={midSpan}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="ZipCode"
            node={sender.address.zipCode}
          />
        </Col>
        <Col span={24}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Is Parcel Likely To Be PoD"
            node={sender.isParcelLikelyToBePOD ? "Yes" : "No"}
          />
        </Col>
      </Row>
    </div>
  );
}

export default OrderBaySenderDetails;
