import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Space, Table, TableColumnType, Tag, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import NodeWithTip from "../../components/utils/NodeWithTip";
import TableSkeleton from "../../components/utils/TableSkeleton";
import { IOrderBlock } from "../../definitions/orderBlock";
import { appLoggedInPaths } from "../../global/paths";
import OrdersPagedByOrderTable from "../OrderBayPage/order/OrdersPagedByOrderTable";
import OrderBlockActionsButton from "./OrderBlockActionsButton";
import { isOrderBlockPublished, isPublicOrderBlock } from "./utils";

export interface IOrderBlockTableProps {
  loading?: boolean;
  data: IOrderBlock[];
  onCompleteUpdateBlock: () => void;
  onCompleteDeleteBlock: () => void;
  onSelectUpdateBlock: (blockId: string) => void;
}

const OrderBlockTable: React.FC<IOrderBlockTableProps> = (props) => {
  const {
    data,
    loading,
    onCompleteDeleteBlock,
    onCompleteUpdateBlock,
    onSelectUpdateBlock,
  } = props;

  // const ref = React.useRef(null);
  // const size = useSize(ref);
  const columns: TableColumnType<IOrderBlock>[] = [
    {
      title: "Block Number",
      width: 150,
      ellipsis: true,
      dataIndex: "BlockNumber",
      key: "BlockNumber",
      render(text, record) {
        return (
          <Link to={`${appLoggedInPaths.orderBlock}/${record.BlockId}`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: "Description",
      width: 280,
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Status",
      width: 170,
      dataIndex: "Status",
      key: "Status",
      render(text, record) {
        return (
          <NodeWithTip tip={record.Status.Description}>
            <Tag
              style={{ color: record.Status.Color }}
              // color={record.Status.Color}
            >
              {record.Status.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Order Counts",
      width: 140,
      dataIndex: "OrderCounts",
      key: "OrderCounts",
    },
    {
      title: "Type",
      width: 150,
      dataIndex: "Type",
      key: "Type",
      render(text, record) {
        return (
          <NodeWithTip tip={record.Type.Description}>
            <Tag
              style={{ color: record.Type.Color }}
              // color={record.Type.Color}
            >
              {record.Type.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Delivery Type",
      width: 180,
      dataIndex: "DeliveryType",
      key: "DeliveryType",
      render(text, record) {
        return (
          <NodeWithTip tip={record.DeliveryType.Description}>
            <Tag
              style={{ color: record.DeliveryType.Color }}
              // color={record.DeliveryType.Color}
            >
              {record.DeliveryType.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Priority",
      width: 150,
      dataIndex: "Priority",
      key: "Priority",
      render(text, record) {
        return (
          <NodeWithTip tip={record.Priority.Description}>
            <Tag
              style={{ color: record.Priority.Color }}
              // color={record.Priority.Color}
            >
              {record.Priority.Name}
            </Tag>
          </NodeWithTip>
        );
      },
    },
    {
      title: "Date Created",
      width: 250,
      dataIndex: "DateCreated_",
      key: "DateCreated_",
    },
    {
      title: "Last Modified",
      width: 180,
      dataIndex: "LastModified_",
      key: "LastModified_",
    },
    {
      title: "",
      key: "Actions",
      width: 48,
      fixed: "right",
      render: (unused, record) => {
        return (
          <OrderBlockActionsButton
            block={record}
            isPublicBlock={isPublicOrderBlock(record)}
            isPublished={isOrderBlockPublished(record)}
            onCompleteDelete={onCompleteDeleteBlock}
            onCompleteUpdate={onCompleteUpdateBlock}
            onSelectUpdateBlock={() => onSelectUpdateBlock(record.BlockId)}
          />
        );
      },
    },
  ];

  const expandedRowRender = React.useCallback(
    (parcel) => (
      <Space
        direction="vertical"
        style={{
          overflow: "auto",
          paddingTop: "8px",
          paddingBottom: "16px",
          paddingRight: "8px",
          // width: size?.width,
          width: "100%",
        }}
        className={css({
          "& .ant-table": {
            marginLeft: "0px !important",
          },
        })}
      >
        <Typography.Title level={5} style={{ marginLeft: "0px" }}>
          Orders
        </Typography.Title>
        <OrdersPagedByOrderTable
          data={parcel.AssignedOrders}
          onCompleteDelete={onCompleteDeleteBlock}
          onCompleteUpdate={onCompleteUpdateBlock}
        />
      </Space>
    ),
    [
      onCompleteDeleteBlock,
      onCompleteUpdateBlock,
      // size?.width
    ]
  );

  if (loading) {
    return <TableSkeleton columns={columns} />;
  }

  return (
    <div
    // ref={ref}
    >
      <Table
        bordered
        size="small"
        dataSource={data}
        columns={columns}
        rowKey={(row) => row.BlockId}
        pagination={false}
        expandable={{
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
    </div>
  );
};

export default OrderBlockTable;
