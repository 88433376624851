import { Col, Form, Input, Row, Select } from "antd";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ResourcesAPI from "../../api/endpoints/resources";
import { appFormMessages } from "../../global/messages";
import { IAddress, IAddressInput } from "../../global/types";
import { loadCountryStateCityStart } from "../../redux/ResourcesRedux/resourcesAction";
import { IReduxState, IReduxStateResources } from "../../redux/types";
import FormItemError from "../utils/FormItemError";
import { filterOptionWithLabel, filterSortWithLabel } from "./utils";

export interface IAddressFormInputProps {
  loading?: boolean;
  formik: FormikProps<any>;
  basePath: string;
}

export const addressInitialValues: IAddressInput = {
  street: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
};

export const optionalAddressValidationSchema = {
  street: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  zipCode: yup.string().nullable(),
  country: yup.string().nullable(),
};

export const addressValidationSchema = {
  street: yup.string().required(appFormMessages.requiredField),
  city: yup.string().required(appFormMessages.requiredField),
  state: yup.string().required(appFormMessages.requiredField),
  zipCode: yup.string().nullable(),
  country: yup.string().required(appFormMessages.requiredField),
};

export function addressToAddressInput(address: IAddress) {
  const input: IAddressInput = {
    street: address.Street,
    city: address.City,
    state: address.State,
    zipCode: address.ZipCode,
    country: address.Country,
  };

  return input;
}

const AddressFormInput: React.FC<IAddressFormInputProps> = (props) => {
  const { loading, formik, basePath } = props;
  const dispatch = useDispatch();
  const [cities, setCities] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const { countryStateCities: countries } = useSelector<
    IReduxState,
    IReduxStateResources
  >((state) => state.resources);

  useEffect(() => {
    dispatch(loadCountryStateCityStart());
  }, [dispatch]);

  function getFieldPath(field: string) {
    return `${basePath}.${field}`;
  }

  const base = (get(formik.values, basePath) ||
    addressInitialValues) as IAddressInput;
  const errors = (get(formik.errors, basePath) ||
    {}) as FormikErrors<IAddressInput>;
  const touched = (get(formik.touched, basePath) ||
    {}) as FormikTouched<IAddressInput>;

  useEffect(() => {
    async function getStates() {
      const result = await ResourcesAPI.getCountryStateCities({
        country: base.country,
      });

      setStates(result);
      setCities([]);
    }

    if (base.country) {
      getStates();
    }
  }, [base.country]);

  useEffect(() => {
    async function getCities() {
      const result = await ResourcesAPI.getCountryStateCities({
        country: base.country,
        state: base.state,
      });

      setCities(result);
    }

    if (base.country && base.state) {
      getCities();
    }
  }, [base.state, base.country]);

  const streetNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Street Address"
      help={touched?.street && <FormItemError message={errors?.street} />}
    >
      <Input.TextArea
        placeholder="Street"
        value={base.street}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name={getFieldPath("street")}
        autoSize={{ minRows: 2 }}
        disabled={loading}
      />
    </Form.Item>
  );

  const countryNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="Country"
      help={touched?.country && <FormItemError message={errors?.country} />}
    >
      <Select
        showSearch
        autoClearSearchValue={false}
        style={{ width: "100%" }}
        value={base.country}
        onChange={(country) => {
          formik.setFieldValue(getFieldPath("country"), country);
          formik.setFieldValue(getFieldPath("state"), undefined);
          formik.setFieldValue(getFieldPath("city"), undefined);
        }}
        onSearch={(value) => {
          if (value) {
            formik.setFieldValue(getFieldPath("country"), value);
            // formik.setFieldValue(getFieldPath("state"), undefined);
            // formik.setFieldValue(getFieldPath("city"), undefined);
            setStates([]);
            setCities([]);
          }
        }}
        onBlur={formik.handleBlur}
        disabled={loading}
        placeholder="Select country"
        optionFilterProp="label"
        filterOption={filterOptionWithLabel}
        filterSort={filterSortWithLabel}
      >
        {countries?.map((country: string) => {
          return (
            <Select.Option key={country} value={country} label={country}>
              {country}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const stateNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="State"
      help={touched?.state && <FormItemError message={errors?.state} />}
    >
      {base.country?.length > 0 ? (
        <Select
          showSearch
          autoClearSearchValue={false}
          placeholder="Select State"
          value={base.state}
          onChange={(state) => {
            formik.setFieldValue(getFieldPath("state"), state);
            formik.setFieldValue(getFieldPath("city"), undefined);
          }}
          onSearch={(value) => {
            if (value) {
              formik.setFieldValue(getFieldPath("state"), value);
              // formik.setFieldValue(getFieldPath("city"), undefined);
              setCities([]);
            }
          }}
          onBlur={formik.handleBlur}
          disabled={loading}
          optionFilterProp="label"
          filterOption={filterOptionWithLabel}
          filterSort={filterSortWithLabel}
        >
          {states?.map((state) => {
            return (
              <Select.Option key={state} value={state} label={state}>
                {state}
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        <Select disabled placeholder="Please select a country" />
      )}
    </Form.Item>
  );

  const cityNode = (
    <Form.Item
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label="City"
      help={touched?.city && <FormItemError message={errors?.city} />}
    >
      {base.state?.length > 0 ? (
        <Select
          showSearch
          placeholder="Select City"
          value={base.city}
          onChange={(city) => formik.setFieldValue(getFieldPath("city"), city)}
          onSearch={(value) => {
            if (value) {
              formik.setFieldValue(getFieldPath("city"), value);
            }
          }}
          onBlur={formik.handleBlur}
          disabled={loading}
          optionFilterProp="label"
          filterOption={filterOptionWithLabel}
          filterSort={filterSortWithLabel}
        >
          {cities?.map((code) => {
            return (
              <Select.Option key={code} value={code} label={code}>
                {code}
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        <Select disabled placeholder="Please select a state" />
      )}
    </Form.Item>
  );

  const zipcodeNode = (
    <Form.Item
      label="ZipCode"
      help={touched?.zipCode && <FormItemError message={errors?.zipCode} />}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input
        placeholder="ZipCode"
        value={base.zipCode}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name={getFieldPath("zipCode")}
        disabled={loading}
      />
    </Form.Item>
  );

  return (
    <div>
      {streetNode}
      <Row wrap gutter={16}>
        <Col span={12}>{countryNode}</Col>
        <Col span={12}>{stateNode}</Col>
        <Col span={12}>{cityNode}</Col>
        <Col span={12}>{zipcodeNode}</Col>
      </Row>
    </div>
  );
};

export default AddressFormInput;
