import { IAddress, IAddressInput, IEnum } from "../global/types";
import { IOrder } from "./orderBay";

export interface IOrderBlockAddress {
  IsCustomAddress: true;
  BranchAddressId: string;
  Address: IAddress;
  Address_: string;
  SupportContactInfo: Array<{
    ContactName: string;
    PhoneNumber: {
      Number: string;
      Code: string;
      GetStyled: string;
      GetE64: string;
      GetNumberOnly: string;
      GetMaskedNo: string;
    };
  }>;
  LinkingId: string;
}

export interface IOrderBlock {
  BlockNumber: string;
  EstimatedTripDuration_: string;
  DateCreated_: string;
  LastModified_: string;
  DeliveryCost_: string;
  BonusCost_: string;
  EarningCost_: string;
  BlockPickUpdateTime_: string;
  AssignedDriverTimeStamp_: string;
  BlockPickUpdateTime: string;
  PublicTypeExpirationDate_: string;
  DriverPaymentTimestamp_: string;
  DeliveryTripStarted_: string;
  DeliveryTripEnded_: string;
  TotalDeliveryTripInfo: string;
  OrderCounts: number;
  BlockId: string;
  PublicTypeExpirationDate: string;
  Status: IEnum;
  TripTimeType: IEnum;
  EstimatedTripDuration: number;
  AssignedDriverId: string;
  AssignedDriverTimeStamp: string;
  DateCreated: string;
  LastModified: string;
  CurrencyType?: IEnum;
  Description: string;
  DeliveryCost: number;
  BonusCost: number;
  EarningCost: number;
  Priority: IEnum;
  Type: IEnum;
  DeliveryType: IEnum;
  OrganizationId: string;
  UpdatedBy: string;
  HasPaidDriver: true;
  DriverPaymentTimestamp: string;
  DriverPaymentAcknowledgedBy: string;
  AssignedOrders?: IOrder[];
  DeliveryTripStarted: string;
  DeliveryTripEnded: string;
  PickUpAddress: IOrderBlockAddress;
  DeliveryAddress: IOrderBlockAddress;
}

export enum OrderBlockPriority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Urgent = "Urgent",
  Critical = "Critical",
}

export enum OrderBlockType {
  Public = "Public",
  Private = "Private",
}

export enum OrderBlockStatus {
  New = "New",
  Published = "Published",
  UnPublished = "UnPublished",
  Declined = "Declined",
  Accepted = "Accepted",
  EnroutePickUp = "EnroutePickUp",
  DriverArrived = "DriverArrived",
  DeliveryCommence = "DeliveryCommence",
  DeliveryCompleted = "DeliveryCompleted",
  DeliveryTerminated = "DeliveryTerminated",
}

export const orderBlockStatusLabelMap: Record<OrderBlockStatus, string> = {
  New: "New",
  Published: "Published",
  UnPublished: "Un-published",
  Declined: "Declined",
  Accepted: "Accepted",
  EnroutePickUp: "Enroute Pick Up",
  DriverArrived: "Driver Arrived",
  DeliveryCommence: "Delivery Commence",
  DeliveryCompleted: "Delivery Completed",
  DeliveryTerminated: "Delivery Terminated",
};

export interface ISupportContactInput {
  contactName: string;
  phoneNumber: {
    number: string;
    code: string;
  };
}

export interface IOrderBlockAddressInput {
  branchAddressId?: string;
  customAddress?: IAddressInput | null;
  supportContactInfo?: ISupportContactInput[];
}

export interface IOrderBlockInput {
  currencyType?: string;
  description: string;
  deliveryCost: number;
  bonusCost: number;
  priority: string;
  tripTimeType: string;
  estimatedTripDuration: string;
  type: string;
  publicTypeExpirationDate: string;
  pickUpDateTime: string;
  orderIds: string[];
  deliveryType: string;
  deliveryAddressInfo: IOrderBlockAddressInput;
  pickUpAddressInfo: IOrderBlockAddressInput;
}

export interface IOrderBlockDriver {
  UserId: string;
  Name: string;
  DriverNumber: string;
  Availability: IEnum;
  Status: IEnum;
}

export interface IFilterOrderItemsInput {
  category?: string;
  parcelSize?: string;
  deliveryCity?: string;
  deliveryState?: string;
  deliveryCountry?: string;
  fragile?: boolean;
  perishable?: boolean;
  canSpill?: boolean;
  hazardious?: boolean;
  explosive?: boolean;
  flammable?: boolean;
  dateCreated?: string;
  from_EstDeliveryDate?: string;
  to_EstDeliveryDate?: string;
}
