import {
  ArrowLeftOutlined,
  DeleteOutlined,
  SmileOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Result,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import { API_URL } from "../../globalVariable";
import Loader from "../Loader";

function OrderSearch(props) {
  const navigate = useNavigate();
  const [searchOrder, setSearchOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderMessage, setOrderMessage] = useState("");
  const [imgMessage, setImgMessage] = useState("");
  const [selectedOrder, setSelectedOrder] = useState();
  const [acknowledgeModal, setAcknowledgeModal] = useState(false);
  const [acknowledgeId, setAcknowledgeId] = useState("");
  const [deliveryDate, setDeliveryDate] = useState();
  const [paymentPod, setPaymentPod] = useState(false);
  const [customPay, setCustomPay] = useState();
  const [payOption, setPayOption] = useState();
  const [currencyType, setCurrencyType] = useState();
  const [cost, setCost] = useState();
  const [taxPer, setTaxPer] = useState();
  const [insuranceCost, setInsuranceCost] = useState();
  const [paidOption, setPaidOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState(["Naira", "Dollar"]);
  const [activateOpen, setActivateOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [reasonId, setReasonId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const open = Boolean(anchorEl);
  const [loader, setLoader] = useState(false);
  const [totalSize, setTotalSize] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [imageArr, setImageArr] = useState([]);
  const [files, setFiles] = useState();
  const acknowledgeUuid = Cookies.get("acknowledgesId");
  const unId = Cookies.get("acknowledgesId");

  const { search } = useParams();

  const [gridApi, setGridApi] = useState(null);
  const { Title } = Typography;
  const { Option } = Select;
  const pagination = true;
  const paginationPageSize = 10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const ColourCellRenderer = (props) => (
    <div>
      <Tooltip title="Delete">
        <DeleteOutlined
          style={{ marginRight: "5%", cursor: "pointer" }}
          onClick={() => deleteModal(props.data.ParcelId)}
        />
      </Tooltip>
      <Tooltip title="Detail">
        <SyncOutlined
          style={{ marginRight: "5%", cursor: "pointer" }}
          onClick={() => handleDetailModalOpen()}
        />
      </Tooltip>
    </div>
  );

  const ButtonCellRenderer = (props) => (
    <Tag
      style={{ cursor: "pointer" }}
      onClick={() => handleAcknowledgeModalOpen(props.data.ParcelId)}
    >
      Acknowledge
    </Tag>
  );

  const parcelCellRenderer = (props) => {
    return (
      <Tag
        style={{ cursor: "pointer" }}
        onClick={() => handleDetailModalOpens(props)}
      >
        {props.data.ParcelNumber}
      </Tag>
    );
  };

  const statusCellRenderer = (props) => {
    return <Tag>{props.data.ParcelStatus_}</Tag>;
  };

  const frameworkComponents = {
    colourCellRenderer: ColourCellRenderer,
    parcelCellRenderer: parcelCellRenderer,
    statusCellRenderer: statusCellRenderer,
    ButtonCellRenderer: ButtonCellRenderer,
  };

  const getTicketImages = (setItems, unId, setImgMessage) => {
    fetch(API_URL + `img/group/GetFileNames/${unId}`, {
      method: "GET",
    })
      .then((res) => {
        if (res.statusText === "Unauthorized") {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");

          return window.location.reload();
        }
        return res.json();
      })
      .then((data) => {
        console.log({ data });
        if (data.Message) {
          setImgMessage(data.Message);
        } else {
          setItems(data);
        }
        setItems(data);
      })
      .catch((err) => console.log({ err }));
  };

  const getOrderSearch = (
    setSearchOrder,
    search,
    setOrderMessage,
    setLoading
  ) => {
    fetch(API_URL + `OrderBay/SearchOrders?query=${search}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    })
      .then((res) => {
        if (res.statusText === "Unauthorized") {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");

          return window.location.reload();
        }
        return res.json();
      })
      .then((data) => {
        if (data.Message) {
          setOrderMessage(data.Message);
          setLoading(false);
        } else {
          var s = [];
          var st = data?.map((d, i) => {
            var id = i + 1;
            s.push({ id, ...d });
          });
          setLoading(false);
          setSearchOrder(s);
        }
      })
      .catch((err) => console.log({ err }));
  };

  const handleImage = async (event) => {
    var file = event.target.files[0];
    setFileLoader(true);

    var fileName = file.name;
    setFiles(file);

    let data = new FormData();
    data.append("image", file, fileName);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
      },
    };
    var response = await fetch(
      API_URL + `img/group/upload?id=${acknowledgeUuid}`,
      {
        method: "POST",
        body: data,
      }
    );

    var datas = await response.json();
    if (response.ok) {
      return swal({
        text: datas.Message,
        icon: "success",
      }).then(() => {
        setFileLoader(false);
        getTicketImages(setImageArr, unId, setImgMessage);
      });
    } else {
      setFileLoader(false);
      swal({
        text: datas.Message,
        icon: "warning",
      });
    }
  };

  const onPodChange = (checked) => {
    setPaymentPod(checked);
  };

  const handleActivateModalOpen = (id) => {
    setActivateOpen(true);
    setReasonId(id);
  };
  const handleActivateModalClose = () => {
    setActivateOpen(false);
    setReasonId("");
    setReason("");
  };

  const handleDeactivateModalOpen = (id) => {
    setDeactivateOpen(true);
    setReasonId(id);
  };
  const handleDeactivateModalClose = () => {
    setDeactivateOpen(false);
    setReasonId("");
    setReason("");
  };

  function onChangeDeliveryDate(date, dateString) {
    setDeliveryDate(dateString);
  }

  const handleAcknowledgeModalOpen = (id) => {
    const unique = uuidv4();
    Cookies.set("acknowledgesId", unique);
    setAcknowledgeModal(true);
    setAcknowledgeId(id);
  };

  const handleAcknowledgeModalClose = () => {
    setAcknowledgeModal(false);
  };
  const handleClick = (event, order) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrder(order);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteId("");
  };

  function handlePaymentChange(value) {
    setPayOption(value);
  }

  const handleDetailModalOpen = (pol) => {
    setDetailOpen(true);
  };
  const handleDetailModalOpens = (pol) => {
    setDetailOpen(true);
    setSelectedOrder(pol);
  };

  const handleDetailModalClose = () => {
    setDetailOpen(false);
  };

  const deleteModal = (id) => {
    handleDeleteModalOpen();
    setDeleteId(id);
  };
  function handleCurrencyType(value) {
    setCurrencyType(value);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await getOrderSearch(setSearchOrder, search, setOrderMessage, setLoading);
    };

    fetchData();
  }, []);

  const tableHeader = [
    {
      headerName: "Parcel Number",
      field: "ParcelNumber",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => handleDetailModalOpens(params)}
            >
              {params.row.ParcelNumber}
            </Tag>
          </>
        );
      },
    },
    {
      headerName: "Parcel Status",
      field: "ParcelStatus_",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Tag>{params.row.ParcelStatus_}</Tag>
          </>
        );
      },
    },
    {
      headerName: "Package Counts",
      field: "PackageCounts",
      sortable: false,
      width: 200,
    },
    {
      headerName: "Total Estimated Weight",
      field: "TotalEstimatedWeight",
      sortable: false,
      width: 200,
    },
    {
      headerName: "Parcel Date Created",
      field: "ParcelDateCreated_",
      sortable: false,
      width: 200,
    },
    {
      headerName: "Updated By",
      field: "UpdatedBy",
      sortable: false,
      width: 200,
    },
    {
      headerName: "Acknowledge Order",
      field: "AcknowledgeOrder",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => handleAcknowledgeModalOpen(params.row.ParcelId)}
            >
              Acknowledge
            </Button>
          </>
        );
      },
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <MoreVertIcon
              onClick={(event) => handleClick(event, params)}
              style={{ cursor: "pointer" }}
            />
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={handleClose}>
                <div className="actions">
                  <p onClick={() => deleteModal(params.row.ParcelId)}>
                    <DeleteIcon /> <span>Delete</span>
                  </p>
                  <p onClick={() => handleDetailModalOpen()}>
                    <InfoIcon />
                    <span>Details</span>
                  </p>
                </div>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const deleteOrder = async () => {
    const data = {
      reason,
      id: deleteId,
    };

    setLoader(true);
    var response = await fetch(API_URL + "OrderBay/DeleteDraftedOrder", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.Message,
        icon: "success",
      })
        .then(() => {
          setLoader(false);
          setReason("");
          getOrderSearch(setSearchOrder, search, setOrderMessage, setLoading);
        })
        .then(() => {
          handleDeleteModalClose();
        });
    } else {
      setLoader(false);
      return swal({
        text: dataResponse.Message,
        icon: "warning",
      });
    }
  };

  const submitAcknowledge = async () => {
    const data = {
      parcelId: acknowledgeId,
      estimatedDeliveryDate: deliveryDate,
      isPaymentOptionPOD: paymentPod,
      paidInfo: {
        paidCustomOption: customPay,
        paidOption: payOption,
        recieptAttachmentId: acknowledgeUuid,
      },
      currencyType: currencyType,
      cost,
      taxPerc: taxPer,
      insuranceCost: insuranceCost,
    };
    setLoader(true);
    var response = await fetch(API_URL + "OrderBay/AcknowledgeParcel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.Message,
        icon: "success",
      })
        .then(() => {
          setLoader(false);
          setReason("");
          getOrderSearch(setSearchOrder, search, setOrderMessage, setLoading);
        })
        .then(() => {
          handleAcknowledgeModalClose();
        });
    } else {
      setLoader(false);
      return swal({
        text: dataResponse.Message,
        icon: "warning",
      });
    }
  };

  const deactivateOrder = async () => {
    setLoader(true);
    const data = {
      id: reasonId,
      reason: reason,
    };

    var response = await fetch(API_URL + "OrderBay/DeactivateOrderItem", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.Message,
        icon: "success",
      }).then(() => {
        setLoader(false);
        setReason("");
        handleDeactivateModalClose();
      });
    } else {
      setLoader(false);

      return swal({
        text: dataResponse.Message,
        icon: "warning",
      });
    }
  };

  const activateOrder = async () => {
    setLoader(true);
    const data = {
      id: reasonId,
      reason: reason,
    };

    var response = await fetch(API_URL + "OrderBay/ActivateOrderItem", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${localStorage.getItem("token")}`
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(data),
    });

    var dataResponse = await response.json();

    if (response.ok) {
      return swal({
        text: dataResponse.Message,
        icon: "success",
      }).then(() => {
        setLoader(false);
        setReason("");
        handleActivateModalClose();
      });
    } else {
      setLoader(false);
      return swal({
        text: dataResponse.Message,
        icon: "warning",
      });
    }
  };

  const deleteImage = async (data) => {
    var response = await fetch(
      API_URL + `img/group/del?id=${acknowledgeUuid}&fileName=${data}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // "Authorization": `Bearer ${localStorage.getItem("token")}`
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }
    );

    var data = await response.json();

    if (response.ok) {
      return swal({
        text: data.Message,
        icon: "success",
      }).then(() => {
        getTicketImages(setImageArr, unId, setImgMessage);
      });
    } else {
      return swal({
        text: data.Message,
        icon: "warning",
      });
    }
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> Back
      </div>
      <br />
      <div>
        {orderMessage.length < 1 ? (
          <div
            className="ag-theme-alpine"
            style={{
              height: 600,
              width: "100%",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <AgGridReact
              onGridReady={onGridReady}
              suppressScrollOnNewData={true}
              enableColResize={true}
              frameworkComponents={frameworkComponents}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              rowData={searchOrder}
            >
              <AgGridColumn
                field="ParcelNumber"
                cellRenderer="parcelCellRenderer"
                resizable={true}
                sortable={true}
              ></AgGridColumn>
              <AgGridColumn
                field="ParcelStatus_"
                cellRenderer="statusCellRenderer"
                resizable={true}
              ></AgGridColumn>
              <AgGridColumn
                field="PackageCounts"
                resizable={true}
              ></AgGridColumn>
              <AgGridColumn
                field="TotalEstimatedWeight"
                resizable={true}
                sortable={true}
              ></AgGridColumn>
              <AgGridColumn
                resizable={true}
                field="ParcelDateCreated"
              ></AgGridColumn>
              <AgGridColumn field="UpdatedBy" resizable={true}></AgGridColumn>
              <AgGridColumn
                resizable={true}
                cellRenderer="ButtonCellRenderer"
                cellRendererParams={{ color: "guinnessBlack" }}
                field="Acknowledge Order"
              ></AgGridColumn>
              <AgGridColumn
                field="Action"
                resizable={true}
                cellRenderer="colourCellRenderer"
                cellRendererParams={{ color: "guinnessBlack" }}
              ></AgGridColumn>
            </AgGridReact>
          </div>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Result icon={<SmileOutlined />} title={orderMessage} />
            )}
          </>
        )}
      </div>

      {/* Modal for order details */}
      <div>
        <Modal
          title="Order Details"
          visible={detailOpen}
          footer={null}
          onCancel={handleDetailModalClose}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Parcel Number: </span>{" "}
              <Title className="address" level={5}>
                <Tag>{selectedOrder?.data.ParcelNumber}</Tag>
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Total Package: </span>{" "}
              <Title className="address" level={5}>
                {selectedOrder?.data.PackageCounts}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Parcel Date Created: </span>{" "}
              <Title className="address" level={5}>
                {selectedOrder?.data.ParcelDateCreated_}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Parcel Item Info: </span>{" "}
              <Title className="address" level={5}>
                {selectedOrder?.data.ParcelItemInfo}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Total Estimated Weight: </span>{" "}
              <Title className="address" level={5}>
                {selectedOrder?.data.TotalEstimatedWeight}
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Parcel Status: </span>{" "}
              <Title className="address" level={5}>
                <Tag
                  style={{ color: `${selectedOrder?.data.ParcelStatusColor}` }}
                >
                  {selectedOrder?.data.ParcelStatus_}
                </Tag>
              </Title>
            </Col>
            <Col className="gutter-row" span={8}>
              <span className="span-address">Updated By: </span>{" "}
              <Title className="address" level={5}>
                {selectedOrder?.data.UpdatedBy}
              </Title>
            </Col>
            {/* <Col className="gutter-row" span={8}>
                                <span className="span-address">Total Estimated Weight: </span> <Title className="address" level={5}>{selectedOrder?.row.TotalEstimatedWeight}</Title>
                            </Col> */}
            <Col className="gutter-row" span={8}></Col>
            <br />
            <br />
            {selectedOrder?.data?.OrdersInfo.map((data, i) => {
              return (
                <div style={{ padding: "0% 2%" }}>
                  <br />
                  <br />
                  <div>
                    <p style={{ color: "#FF7F50" }}>Order Items ({i + 1})</p>
                    <div style={{ width: "300px", display: "flex" }}>
                      {data.OrderItemStatus === "Active" ? (
                        <Button
                          onClick={() =>
                            handleDeactivateModalOpen(data.OrderId)
                          }
                        >
                          Deactivate Order
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleActivateModalOpen(data.OrderId)}
                        >
                          Activate Order
                        </Button>
                      )}
                    </div>
                  </div>

                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <br />
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Order Name: </span>{" "}
                      <Title className="address" level={5}>
                        {data.OrderName}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Order Category: </span>{" "}
                      <Title className="address" level={5}>
                        {data.OrderCategory}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Estimated Weight: </span>{" "}
                      <Title className="address" level={5}>
                        {data.EstimatedWeight} {data.EstimatedWeightUnit_}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Order Description: </span>{" "}
                      <Title className="address" level={5}>
                        {data.OrderDescription}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">MsgNote: </span>{" "}
                      <Title className="address" level={5}>
                        {data.MsgNote}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Content Can Spill: </span>{" "}
                      <Title className="address" level={5}>
                        {data.CheckListInfo.ContentCanSpill ? "Yes" : "No"}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">
                        Is Content Explosive:{" "}
                      </span>{" "}
                      <Title className="address" level={5}>
                        {data.CheckListInfo.Explosive ? "Yes" : "No"}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">
                        Is Content Flammable:{" "}
                      </span>{" "}
                      <Title className="address" level={5}>
                        {data.CheckListInfo.Flammable ? "Yes" : "No"}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Is Content Fragile: </span>{" "}
                      <Title className="address" level={5}>
                        {data.CheckListInfo.Fragile ? "Yes" : "No"}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">
                        Is Content Hazardious:{" "}
                      </span>{" "}
                      <Title className="address" level={5}>
                        {data.CheckListInfo.Hazardious ? "Yes" : "No"}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">
                        Is Content Perishable:{" "}
                      </span>{" "}
                      <Title className="address" level={5}>
                        {data.CheckListInfo.Perishable ? "Yes" : "No"}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Package Size:</span>
                      <Title className="address" level={5}>
                        {data.PackageEstimatedSize_}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Sender Name: </span>{" "}
                      <Title className="address" level={5}>
                        {data.From_Sender.FullName}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Receiver Name: </span>{" "}
                      <Title className="address" level={5}>
                        {data.To_Receiver.FullName}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Sender Address: </span>{" "}
                      <Title className="address" level={5}>
                        {data.From_Sender.Address}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <span className="span-address">Receiver Address: </span>{" "}
                      <Title className="address" level={5}>
                        {data.To_Receiver.Address}
                      </Title>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Row>
        </Modal>
      </div>

      {/* Modal for deleting Order */}
      <div>
        <Modal
          title="Deactivate Order"
          visible={deactivateOpen}
          footer={null}
          onCancel={handleDeactivateModalClose}
        >
          <form className="policy-form">
            <TextField
              id="outlined-multiline-static"
              label="Reason for Deactivating Order"
              placeholder="Reason for Deactivating Order"
              multiline
              rows={4}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              variant="outlined"
              className="delete-textarea"
            />
            <br />
            <div className="form-btn">
              <Button type="primary" id="but" onClick={() => deactivateOrder()}>
                {loader ? <Loader /> : "Deactivate Order"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      {/* Modal for Activating Order */}
      <div>
        <Modal
          title="Activate Order"
          visible={activateOpen}
          footer={null}
          onCancel={handleActivateModalClose}
        >
          <form className="policy-form">
            <TextField
              id="outlined-multiline-static"
              label="Reason for Activating Order"
              placeholder="Reason for Deleting Order"
              multiline
              rows={4}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              variant="outlined"
              className="delete-textarea"
            />
            <br />
            <div className="form-btn">
              <Button type="primary" id="but" onClick={() => activateOrder()}>
                {loader ? <Loader /> : "Activate Order"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      {/* Modal for deactivating Order */}
      <div>
        <Modal
          title="Deactivate Order"
          visible={deleteModalOpen}
          footer={null}
          onCancel={handleDeleteModalClose}
        >
          <form className="policy-form">
            <TextField
              id="outlined-multiline-static"
              label="Reason for Deleting Order"
              placeholder="Reason for Deleting Order"
              multiline
              rows={4}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              variant="outlined"
              className="delete-textarea"
            />
            <br />
            <div className="form-btn">
              <Button type="primary" id="but" onClick={() => deleteOrder()}>
                {loader ? <Loader /> : "Delete Order"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>

      {/* Modal for Acknowledged Order */}
      <div>
        <Modal
          title="Acknowledged Order"
          visible={acknowledgeModal}
          footer={null}
          onCancel={handleAcknowledgeModalClose}
        >
          <form>
            <div className="employee-form-div">
              <Title level={5}>is Payment on Delivery: </Title>
              <Switch
                style={{ marginLeft: "2%" }}
                defaultChecked={paymentPod}
                onChange={onPodChange}
              />
            </div>
            <br />
            <div className="employee-form-div">
              <Select
                defaultValue=" Currency Type"
                className="half-width"
                onChange={handleCurrencyType}
              >
                <Option value=" Currency Type" disabled>
                  Currency Type
                </Option>
                {currencyOption?.map((code) => {
                  return <Option value={code}>{code}</Option>;
                })}
              </Select>
              <DatePicker
                className="half-width"
                onChange={onChangeDeliveryDate}
              />
            </div>
            <br />
            {paymentPod ? null : (
              <div className="employee-form-div">
                <Select
                  defaultValue="Payment Option"
                  className={
                    payOption === "Others" ? "half-width" : "all-width"
                  }
                  onChange={handlePaymentChange}
                >
                  <Option value="Payment Option" disabled>
                    Payment Option
                  </Option>
                  {paidOption?.map((code) => {
                    return <Option value={code}>{code}</Option>;
                  })}
                </Select>
                {payOption === "Others" ? (
                  <Input
                    placeholder="Paid Custom Option"
                    className="half-width"
                    value={customPay}
                    onChange={(e) => setCustomPay(e.target.value)}
                  />
                ) : null}
              </div>
            )}

            <br />
            <div className="employee-form-div">
              <Input
                type="number"
                placeholder="Cost"
                className="half-width"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
              />
              <Input
                type="number"
                placeholder="Tax Percentage (%)"
                className="half-width"
                value={taxPer}
                onChange={(e) => setTaxPer(e.target.value)}
              />
            </div>
            <br />
            <div className="employee-form-div">
              <Input
                type="number"
                placeholder="Insurance Cost (%)"
                style={{ width: "100%" }}
                value={insuranceCost}
                onChange={(e) => setInsuranceCost(e.target.value)}
              />
            </div>
            <br />
            <div>
              <div class="upload-btn-wrapper pp">
                {fileLoader ? (
                  "File Upload In progress.."
                ) : (
                  <Badge count={imageArr?.length}>
                    <>
                      <label>Receipt Attachment </label>
                      <UploadOutlined />
                      <input type="file" name="myfile" onChange={handleImage} />
                    </>
                  </Badge>
                )}
              </div>
            </div>
            <br />
            <div>
              {imgMessage?.length > 1
                ? null
                : imageArr?.map((data) => {
                    return (
                      <div className="arr-img">
                        <p>{data}</p>
                        <DeleteIcon onClick={() => deleteImage(data)} />
                      </div>
                    );
                  })}
            </div>

            <div className="form-btn">
              <Button
                type="primary"
                id="but"
                onClick={() => submitAcknowledge()}
              >
                {loader ? <Loader /> : "Submit"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default OrderSearch;
