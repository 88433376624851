
import * as types from "./ticketActionType"


export const loadTicketStart = (payload) => ({
    type: types.LOAD_TICKET_START,
    payload
})

export const loadTicketSuccess = (payment) => ({
    type: types.LOAD_TICKET_SUCCESS,
    payload: payment
})

export const loadTicketError = (error) => ({
    type: types.LOAD_TICKET_ERROR,
    payload: error
})


export const loadTicketByStatusStart = (payload) => ({
    type: types.LOAD_FILTER_BY_STATUS_START,
    payload
})

export const loadTicketByStatusSuccess = (payment) => ({
    type: types.LOAD_FILTER_BY_STATUS_SUCCESS,
    payload: payment
})

export const loadTicketByStatusError = (error) => ({
    type: types.LOAD_FILTER_BY_STATUS_ERROR,
    payload: error
})


export const loadTicketByPriorityStart = (payload) => ({
    type: types.LOAD_FILTER_BY_PRIORITY_START,
    payload
})

export const loadTicketByPrioritySuccess = (payment) => ({
    type: types.LOAD_FILTER_BY_PRIORITY_SUCCESS,
    payload: payment
})

export const loadTicketByPriorityError = (error) => ({
    type: types.LOAD_FILTER_BY_PRIORITY_ERROR,
    payload: error
})


