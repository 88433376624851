import { css } from "@emotion/css";
import { Col, Row, Space, Typography } from "antd";
import { isString } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabeledNode from "../../../components/utils/LabeledNode";
import { IEnum } from "../../../global/types";
import {
  loadPackageSizeStart,
  loadWeightUnitStart,
} from "../../../redux/ResourcesRedux/resourcesAction";
import { IReduxState, IReduxStateResources } from "../../../redux/types";
import { OTHERS_CATEGORY } from "../form/OrderItemPackageDetailsInput";

export interface IOrderItemPackageDetailsProps {
  packageEstimatedSize: string | IEnum;
  estimatedWeightUnit: string | IEnum;
  packageName: string;
  description: string;
  category: string;
  otherCategory: string;
  estimatedWeight: number;
  lastUpdatedAt?: string;
  isSmallScreen?: boolean;
}

const classes = {
  packageImage: css({
    width: "100%",
    height: "100%",
    maxWidth: "150px",
    maxHeight: "100px",
  }),
  packageSizeImgWrapper: css({
    display: "flex",
    width: "100%",
    height: "100%",
  }),
};

const OrderItemPackageDetails: React.FC<IOrderItemPackageDetailsProps> = (
  props
) => {
  const {
    packageEstimatedSize,
    estimatedWeightUnit,
    packageName,
    description,
    category,
    otherCategory,
    estimatedWeight,
    lastUpdatedAt,
    isSmallScreen,
  } = props;

  const dispatch = useDispatch();
  const { packageSizes, weightUnit } = useSelector<
    IReduxState,
    IReduxStateResources
  >((state) => state.resources);

  useEffect(() => {
    dispatch(loadPackageSizeStart());
    dispatch(loadWeightUnitStart());
  }, [dispatch]);

  const getEnumId = (item: string | IEnum) => {
    return isString(item) ? item : item.Id;
  };

  const selectedPackageInfo = packageSizes?.find((data) => {
    const size = isString(packageEstimatedSize)
      ? packageEstimatedSize
      : packageEstimatedSize.Name;

    return data.packageSize_?.toLowerCase() === size?.toLowerCase();
  });

  const selectedWeightUnit = weightUnit?.find((code) => {
    return code.id === getEnumId(estimatedWeightUnit);
  });

  const midSpan = isSmallScreen ? 24 : 12;
  const oneThirdSpan = isSmallScreen ? 24 : 8;
  return (
    <Row wrap gutter={[32, 16]}>
      <Col span={24}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Package Name"
          node={packageName}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={24}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Description"
          node={description}
          style={{ width: "100%" }}
        />
      </Col>
      {lastUpdatedAt && (
        <Col span={midSpan}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Last Updated"
            node={lastUpdatedAt}
          />
        </Col>
      )}
      <Col span={oneThirdSpan}>
        <LabeledNode
          nodeIsText
          direction="vertical"
          label="Category"
          node={category}
        />
      </Col>
      {category === OTHERS_CATEGORY && (
        <Col span={oneThirdSpan}>
          <LabeledNode
            nodeIsText
            direction="vertical"
            label="Other Category"
            node={otherCategory}
          />
        </Col>
      )}
      <Col span={oneThirdSpan}>
        <LabeledNode
          direction="vertical"
          label="Estimated Weight"
          node={
            <Typography.Text>
              {estimatedWeight} {selectedWeightUnit?.name}
            </Typography.Text>
          }
        />
      </Col>
      <Col span={oneThirdSpan}>
        <LabeledNode
          direction="vertical"
          label="Estimated Package Size"
          node={
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Typography.Text>
                {selectedPackageInfo?.packageSize_}
              </Typography.Text>
              <div className={classes.packageSizeImgWrapper}>
                <img
                  className={classes.packageImage}
                  src={selectedPackageInfo?.imgLink}
                  alt={selectedPackageInfo?.id}
                />
              </div>
            </Space>
          }
        />
      </Col>
    </Row>
  );
};

export default OrderItemPackageDetails;
