import * as types from "./profileActionType";

const initialState = {
  userProfile: [],
  companyProfile: [],
  loading: false,
  error: null,
  userAvatarLastUpdated: null,
  companyAvatarLastUpdated: null,
};

export const ProfileReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USER_PROFILE_START:
    case types.LOAD_COMPANY_PROFILE_START:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfile: action.payload,
      };

    case types.LOAD_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        companyProfile: action.payload,
      };

    case types.LOAD_USER_PROFILE_ERROR:
    case types.LOAD_COMPANY_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.USER_PROFILE_UPDATED:
      return {
        ...state,
        userAvatarLastUpdated: Date.now(),
      };

    case types.COMPANY_PROFILE_UPDATED:
      return {
        ...state,
        companyAvatarLastUpdated: Date.now(),
      };

    default:
      return state;
  }
};
