import React from "react";
import { Row, Col, Switch, Form } from "antd";
import { css } from "@emotion/css";
import { FormikProps } from "formik";
import { IOrderInput } from "../../../definitions/orderBay";

const classes = {
  root: css({
    "& .ant-form-item": {
      marginBottom: "8px !important",
    },
  }),
};

export interface IOrderItemCheckListInputProps {
  loading?: boolean;
  formik: FormikProps<Omit<IOrderInput, "recipientInfo">>;
}

const OrderItemCheckListInput: React.FC<IOrderItemCheckListInputProps> = (
  props
) => {
  const { loading, formik } = props;

  const fragileNode = (
    <Form.Item label="Fragile" labelCol={{ span: 18 }} wrapperCol={{ span: 6 }}>
      <Switch
        checked={formik.values.checkList.fragile}
        onChange={(unit) => formik.setFieldValue("checkList.fragile", unit)}
        disabled={loading}
      />
    </Form.Item>
  );

  const perishableNode = (
    <Form.Item
      label="Perishable"
      labelCol={{ span: 18 }}
      wrapperCol={{ span: 6 }}
    >
      <Switch
        checked={formik.values.checkList.perishable}
        onChange={(unit) => formik.setFieldValue("checkList.perishable", unit)}
        disabled={loading}
      />
    </Form.Item>
  );

  const hazardiousNode = (
    <Form.Item
      label="Hazardious"
      labelCol={{ span: 18 }}
      wrapperCol={{ span: 6 }}
    >
      <Switch
        checked={formik.values.checkList.hazardious}
        onChange={(unit) => formik.setFieldValue("checkList.hazardious", unit)}
        disabled={loading}
      />
    </Form.Item>
  );

  const spillNode = (
    <Form.Item
      label="Content Can Spill"
      labelCol={{ span: 18 }}
      wrapperCol={{ span: 6 }}
    >
      <Switch
        checked={formik.values.checkList.contentCanSpill}
        onChange={(unit) =>
          formik.setFieldValue("checkList.contentCanSpill", unit)
        }
        disabled={loading}
      />
    </Form.Item>
  );

  const explosiveNode = (
    <Form.Item
      label="Explosive"
      labelCol={{ span: 18 }}
      wrapperCol={{ span: 6 }}
    >
      <Switch
        checked={formik.values.checkList.explosive}
        onChange={(unit) => formik.setFieldValue("checkList.explosive", unit)}
        disabled={loading}
      />
    </Form.Item>
  );

  const flammableNode = (
    <Form.Item
      label="Flammable"
      labelCol={{ span: 18 }}
      wrapperCol={{ span: 6 }}
    >
      <Switch
        checked={formik.values.checkList.flammable}
        onChange={(unit) => formik.setFieldValue("checkList.flammable", unit)}
        disabled={loading}
      />
    </Form.Item>
  );

  return (
    <div className={classes.root}>
      <Row wrap gutter={16}>
        <Col span={12}>{fragileNode}</Col>
        <Col span={12}>{perishableNode}</Col>
        <Col span={12}>{hazardiousNode}</Col>
        <Col span={12}>{spillNode}</Col>
        <Col span={12}>{explosiveNode}</Col>
        <Col span={12}>{flammableNode}</Col>
      </Row>
    </div>
  );
};

export default OrderItemCheckListInput;
