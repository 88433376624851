import { Button, Divider, Form, Space } from "antd";
import { noop } from "lodash";
import React from "react";
import { appFormControlClasses } from "../../../components/utils/theme";
import { IParcelSenderInfoInput } from "../../../definitions/orderBay";
import useScreenSize from "../../../hooks/useScreenSize";
import OrderBaySenderDetails from "../order/OrderBaySenderDetails";
import { IRecipientWithOrdersEntry } from "./OrderItemsByRecipient";
import OrderItemsByRecipientList from "./OrderItemsByRecipientList";

export interface IOrderBayFormPreviewProps {
  submitting?: boolean;
  senderInfo: IParcelSenderInfoInput;
  entries: IRecipientWithOrdersEntry[];
  onSubmit: () => void;
  onBack: () => void;
}

const OrderBayFormPreview: React.FC<IOrderBayFormPreviewProps> = (props) => {
  const { submitting, senderInfo, entries, onSubmit, onBack } = props;
  const { isSmallScreen } = useScreenSize();
  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Divider orientation="left">Sender Details</Divider>
      <OrderBaySenderDetails
        sender={senderInfo}
        isSmallScreen={isSmallScreen}
      />
      <Divider orientation="left">Order Items</Divider>
      <OrderItemsByRecipientList
        isPreview
        loading={submitting}
        entries={entries}
        onNext={noop}
        onPrev={noop}
      />
      <Form.Item
        style={{
          marginTop: "32px",
          maxWidth: "720px",
          margin: "auto",
          marginBottom: "32px",
        }}
      >
        <div className={appFormControlClasses.controlRoot}>
          <div
            className={appFormControlClasses.spaceEvenContainer}
            style={{ marginRight: "16px" }}
          >
            <Button htmlType="button" disabled={submitting} onClick={onBack}>
              Back
            </Button>
          </div>
          <Button
            htmlType="button"
            disabled={submitting}
            type="primary"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </Form.Item>
    </Space>
  );
};

export default OrderBayFormPreview;
